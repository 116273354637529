import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/cjs/Button';
import Container from 'react-bootstrap/cjs/Container';
import { ApiComponent, IApiComponentState, Logo, RequestReason } from '../common';
import { PRIVACY_URL, TERMS_AND_CONDITIONS_URL } from '../constants';
interface IProps {
  onAccepted: () => Promise<void>;
}

interface IState extends IApiComponentState {
  acceptTermsAndConditions?: boolean;
  acceptPrivacyPolicy?: boolean;
  saving?: boolean;
}

export class TermsPage extends ApiComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props, {
      acceptTermsAndConditions: false,
      acceptPrivacyPolicy: false,
    });

    this.save = this.wrapApiCallFunction(this.save.bind(this), RequestReason.Save);
  }

  async load(): Promise<Partial<IState>> {
    return {};
  }

  async save() {
    const { acceptTermsAndConditions, acceptPrivacyPolicy } = this.state;

    this.setState({
      saving: true,
    });

    if (!acceptTermsAndConditions || !acceptPrivacyPolicy) {
      throw new Error('Invalid.');
    }

    await this.api.authentication.users.acceptTerms({
      acceptTermsAndConditions,
      acceptPrivacyPolicy,
    });

    this.setState({
      saving: false,
    });

    await this.props.onAccepted();
  }

  render() {
    const { acceptTermsAndConditions, acceptPrivacyPolicy, saving } = this.state;

    if (saving) {
      return this.renderRequest(RequestReason.Save);
    }

    return (
      <Container id="terms-page" className={'content'}>
        <header>
          <Logo height={45} />
        </header>
        <div className={'main'}>
          <h1>Before you continue...</h1>

          <Form.Check
            type={'checkbox'}
            id={'accept-terms-and-conditions'}
            label={
              <strong>
                I accept the <a href={TERMS_AND_CONDITIONS_URL}>terms and conditions</a>.
              </strong>
            }
            required
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              this.setState({
                acceptTermsAndConditions: e.target.checked,
              });
            }}
          />
          <Form.Check
            type={'checkbox'}
            id={'accept-privacy-policy'}
            label={
              <strong>
                I accept the <a href={PRIVACY_URL}>privacy policy</a>.
              </strong>
            }
            required
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              this.setState({
                acceptPrivacyPolicy: e.target.checked,
              });
            }}
          />

          <Button className={'mt-3'} variant={'primary'} disabled={!acceptTermsAndConditions || !acceptPrivacyPolicy} onClick={this.save}>
            Continue
          </Button>
        </div>
      </Container>
    );
  }
}
