import { ClientCustomerEntity, ContentSyncApi, IContentSyncApi } from '@edgebox/api-rest-client';
import { ClientError, ClientErrorType } from '@edgebox/data-definition-kit';
import { IAppConfiguration } from '@edgebox/react-components';
import { ValidationError } from 'class-validator';
import React from 'react';
import { Optional } from 'utility-types';
import { ApiContext } from '../contexts/ApiContext';
import { Request, RequestLoadingAnimation } from './Request';

export type IDataComponentConfiguration = IAppConfiguration<{
  apiDomain: string;
}>;

export enum RequestReason {
  LoadComponent = 'load',
  SubmitForm = 'submit',
  ExecuteAction = 'action',
  RequestDetails = 'details',
  Save = 'save',
}

type ErrorCollection = {
  [type in RequestReason]?: ClientError;
};

type RequestCollection = {
  [type in RequestReason]?: Promise<any>;
};

export interface IApiComponentState {
  apiErrors: ErrorCollection;
  openRequests: RequestCollection;
  hideUnauthorizedModal?: boolean;
}

export abstract class ApiComponent<Props = {}, State extends IApiComponentState = IApiComponentState> extends React.Component<
  Props,
  State
> {
  constructor(props: Props, state?: Optional<State, 'apiErrors' | 'openRequests'>) {
    super(props);

    const defaults = {
      apiErrors: {},
      openRequests: {},
    };

    // React provides us the context sometimes.
    this.state = !state || (state as any) instanceof ContentSyncApi ? (defaults as State) : (Object.assign(state, defaults) as State);

    this.load = this.wrapApiCallFunction(this.load.bind(this), RequestReason.LoadComponent, true) as any;
  }

  context!: IContentSyncApi;
  static contextType = ApiContext;
  get api(): IContentSyncApi {
    return this.context;
  }

  protected __isMounted = false;

  async getCurrentCustomer(): Promise<ClientCustomerEntity | undefined>;
  async getCurrentCustomer(expectExistence: true): Promise<ClientCustomerEntity>;
  async getCurrentCustomer(): Promise<ClientCustomerEntity | undefined> {
    try {
      return await this.api.billing.customers.item('self');
    } catch (e) {
      if (!(e instanceof ClientError) || e.type !== ClientErrorType.NotFound) {
        throw e;
      }
    }

    return undefined;
  }

  get formValidationError(): ValidationError[] | undefined {
    if (
      this.state.apiErrors[RequestReason.SubmitForm]?.type === ClientErrorType.Validation &&
      Array.isArray(this.state.apiErrors[RequestReason.SubmitForm]!.details)
    ) {
      return this.state.apiErrors[RequestReason.SubmitForm]!.details;
    }

    return undefined;
  }

  componentDidMount(): void {
    this.__isMounted = true;
    this.load();
  }

  componentWillUnmount(): void {
    this.__isMounted = false;
  }

  wrapApiCallFunction<ResultType>(
    fn: (...args: any) => Promise<ResultType>,
    requestReason: RequestReason = RequestReason.RequestDetails,
    updateState?: boolean
  ): () => Promise<ResultType | undefined> {
    if (updateState) {
      return async (...args: any) => {
        const state = await this.wrapApiCall(fn(...args), requestReason);
        if (this.__isMounted && state) {
          this.setState(state as any);
        }

        return state;
      };
    }

    return async (...args: any) => {
      return await this.wrapApiCall(fn(...args), requestReason);
    };
  }

  async wrapApiCall<ResultType>(
    call: Promise<ResultType>,
    requestReason: RequestReason = RequestReason.RequestDetails
  ): Promise<ResultType | undefined> {
    const { apiErrors, openRequests } = this.state;

    apiErrors[requestReason] = undefined;

    openRequests[requestReason] = call;

    this.setState({
      apiErrors,
      openRequests,
    });

    try {
      const result = await call;

      const { openRequests: updatedOpenRequests } = this.state;
      updatedOpenRequests[requestReason] = undefined;
      if (this.__isMounted) {
        this.setState({
          openRequests: updatedOpenRequests,
        });
      }

      return result;
    } catch (e) {
      if (!(e instanceof ClientError)) {
        console.error('Unexpected request error in component', this, e);
      }
      const error = e instanceof ClientError ? e : new ClientError(ClientErrorType.Other, undefined as any, (e as Error).message);

      const { apiErrors: updatedApiErrors, openRequests: updatedOpenRequests } = this.state;
      updatedApiErrors[requestReason] = error;
      updatedOpenRequests[requestReason] = undefined;

      if (this.__isMounted) {
        this.setState({
          apiErrors: updatedApiErrors,
          openRequests: updatedOpenRequests,
        });
      }
    }
  }

  renderRequest(requestReason = RequestReason.LoadComponent, loadingAnimation?: RequestLoadingAnimation): React.ReactElement {
    const hideUnauthorizedModal = this.state.hideUnauthorizedModal;
    const error = this.state.apiErrors[requestReason];
    const request = this.state.openRequests[requestReason];

    return <Request loadingAnimation={request ? loadingAnimation : 'none'} error={error} showUnauthorizedModal={!hideUnauthorizedModal} />;
  }

  abstract load(): Promise<Partial<State>>;
  abstract render(): React.ReactNode;
}
