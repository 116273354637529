import React from 'react';
import { EntityContext, IEntityContext } from '../../contexts/EntityContext';

export abstract class EntityFormElement<Type, Props, State> extends React.Component<Props, State> {
  constructor(props: Props, state?: State) {
    super(props);

    if (state) {
      this.state = state;
    } else {
      this.state = {} as any;
    }
  }

  context!: IEntityContext<Type>;
  static contextType = EntityContext;
  get entityContext(): IEntityContext<Type> {
    return this.context;
  }

  abstract render(): React.ReactNode;
}
