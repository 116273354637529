import { IPagination } from '@edgebox/data-definition-kit';
import { ApiComponent, IApiComponentState } from '../services';
import { IContentSyncApi } from '@edgebox/api-rest-client';
import React from 'react';

interface IRequestProps {
  request: (api: IContentSyncApi) => Promise<{
    totalNumberOfItems?: number;
  }>;
}

interface IProps extends IRequestProps {
  any?: () => React.ReactNode;
  none?: () => React.ReactNode;
}

interface IState extends IApiComponentState {
  totalNumberOfItems?: number;
}

export class HasSwitch extends ApiComponent<IProps, IState> {
  async load() {
    const { request } = this.props;
    const response = await request(this.api);

    return {
      ...response,
    };
  }

  render() {
    const { any, none } = this.props;
    const { totalNumberOfItems } = this.state;

    if (totalNumberOfItems === undefined) {
      return this.renderRequest();
    }

    if (totalNumberOfItems) {
      return any ? any() : null;
    }

    return none ? none() : null;
  }
}

export function HasAny(props: IRequestProps & { children: React.ReactNode }) {
  return <HasSwitch {...props} any={() => props.children} />;
}

export function HasNone(props: IRequestProps & { children: React.ReactNode }) {
  return <HasSwitch {...props} none={() => props.children} />;
}
