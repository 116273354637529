import { ClientCustomerEntity } from '@edgebox/api-rest-client';
import { IPagedListResponse } from '@edgebox/data-definition-kit';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { EntitySelector, IEntitySelectorProps, IEntitySelectorState } from '../../Form/EntitySelector';
import { FormatDateTime } from '../../Localization/FormatDateTime';
import { ITextSearchFilter } from '../../PagedList';

interface IProps extends IEntitySelectorProps<ClientCustomerEntity> {}

interface IState extends IEntitySelectorState<ClientCustomerEntity> {}

export class CustomerSelector extends EntitySelector<ClientCustomerEntity, IProps, IState> {
  renderEmpty() {
    return 'Select customer';
  }

  renderCurrentValue(selected: ClientCustomerEntity): React.ReactElement | string {
    return selected.name;
  }

  async search(page: number, filters?: ITextSearchFilter): Promise<IPagedListResponse<ClientCustomerEntity>> {
    return await this.api.billing.customers.findByName(filters?.search || '', { page });
  }

  renderAddForm(): React.ReactElement | null {
    return null;
  }

  renderItem(entity: ClientCustomerEntity): React.ReactElement {
    return (
      <Row>
        <Col>
          <h3>{entity.name}</h3>
          <div className={'text-muted'}>
            Joined: <FormatDateTime date={entity.createdAt} />
          </div>
        </Col>
      </Row>
    );
  }

  renderItemAsText(entity: ClientCustomerEntity): string {
    return entity.name;
  }
}
