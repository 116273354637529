import { ClientCustomerEntity } from '@edgebox/api-rest-client';
import { ExternalServiceId } from '@edgebox/data-definition-kit';
import { Right } from '@edgebox/react-components';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe, Stripe, StripeCardElement } from '@stripe/stripe-js';
import React, { PropsWithChildren } from 'react';
import Alert from 'react-bootstrap/cjs/Alert';
import Button from 'react-bootstrap/cjs/Button';
import { ApiComponent, IApiComponentState, RequestReason } from '../../services/ApiComponent';

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

interface IProps extends PropsWithChildren {
  onPaid: () => void;
  update?: boolean;
  className?: string;
  disabled?: boolean;
}

enum Status {
  Loading,
  Checkout,
}

interface IState extends IApiComponentState {
  status: Status;
  stripe?: Stripe;
  customer?: ClientCustomerEntity;
  clientSecret?: string;
  saving?: boolean;
}

export class Checkout extends ApiComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props, { status: Status.Loading });

    this.savePayment = this.wrapApiCallFunction(this.savePayment.bind(this), RequestReason.Save);
  }

  async load(): Promise<Partial<IState>> {
    const stripe = await stripePromise;
    if (!stripe) {
      throw new Error("Couldn't load checkout. Please try again later.");
    }

    const customer = (await this.api.currentUser!.customer!.get()) as ClientCustomerEntity;

    const { secret: clientSecret } = await this.api.integrationStripe.stripe.createSetupIntent();

    return {
      stripe,
      customer,
      clientSecret,
      status: Status.Checkout,
    };
  }

  async savePayment(cardElement: StripeCardElement) {
    const stripe = this.state.stripe!;
    const customer = this.state.customer!;
    const clientSecret = this.state.clientSecret!;

    this.setState({ saving: true });

    try {
      const billingAddress = customer.billingAddress;
      if (!billingAddress) {
        throw new Error(`Missing billing address.`);
      }

      const user = this.api.currentUser!;

      const billingDetails = {
        email: user.primaryEmail,
        name: `${billingAddress.name} / ${user.firstName} ${user.lastName}`,
        address: {
          city: billingAddress.city,
          country: billingAddress.country,
          postal_code: billingAddress.zip,
          state: billingAddress.state,
          line1: billingAddress.street,
          line2: billingAddress.name2,
        },
      };

      let paymentMethodId: ExternalServiceId;

      // Stripe integration is in "test" mode, so no actual Stripe API calls are made.
      if (clientSecret === 'test') {
        paymentMethodId = `pm_test${Date.now()}` as ExternalServiceId;
      } else {
        const { error, setupIntent } = await stripe!.confirmCardSetup(clientSecret, {
          payment_method: {
            card: cardElement!,
            billing_details: billingDetails,
          },
        });

        if (error) {
          throw error;
        }

        paymentMethodId =
          typeof setupIntent!.payment_method! === 'string'
            ? (setupIntent!.payment_method as ExternalServiceId)
            : (setupIntent!.payment_method!.id as ExternalServiceId);
      }

      await this.api.integrationStripe.stripe.savePaymentMethod({
        paymentMethodId,
      });

      this.props.onPaid();
    } finally {
      // Allow users to retry on failure.
      this.setState({ saving: false });
    }
  }

  render(): React.ReactElement {
    const { update, children, className, disabled } = this.props;
    const { stripe, saving } = this.state;

    return (
      <div className={className}>
        {this.renderMessage()}

        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {(context) => (
              <form
                className={'mt-3 mb-3'}
                onSubmit={(e) => {
                  e.preventDefault();

                  // Get a reference to a mounted CardElement. Elements knows how
                  // to find your CardElement because there can only ever be one of
                  // each type of element.
                  const cardElement = context.elements!.getElement(CardElement);

                  this.savePayment(cardElement!);
                }}
              >
                <Right className={'mt-3'}>
                  <div style={{ width: '400px' }}>
                    <CardElement
                      options={{
                        hidePostalCode: true,
                        style: {
                          base: {
                            fontFamily:
                              "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                            fontSize: '16px',
                          },
                          invalid: {
                            color: '#dc3545',
                          },
                        },
                      }}
                    />
                  </div>
                </Right>
                <Right className={'mt-3'}>
                  {children}
                  <Button type={'submit'} disabled={disabled || !stripe || saving}>
                    {update ? 'Save' : 'Pay'}
                  </Button>
                </Right>
              </form>
            )}
          </ElementsConsumer>
        </Elements>
      </div>
    );
  }

  renderMessage() {
    const { status } = this.state;

    if (status === Status.Loading) {
      return this.renderRequest();
    }

    if (status === Status.Checkout) {
      return undefined;
    }

    return (
      <div className={'text-danger'}>
        <p>Payment failed. Please double check the information below.</p>
        <p>If your details are correct, please contact your bank to understand why the payment has been rejected.</p>
      </div>
    );
  }
}
