import { ClientUserEntity } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import { ApiComponent, IApiComponentState } from '../../../services/ApiComponent';
import React from 'react';

interface IProps {
  entityId: InternalId;
}

interface IState extends IApiComponentState {
  user?: ClientUserEntity;
}

export class UserName extends ApiComponent<IProps, IState> {
  async load() {
    const user = await this.api.authentication.users.item(this.props.entityId);

    return {
      user,
    };
  }

  render(): React.ReactElement {
    const { user } = this.state;

    if (!user) {
      return this.renderRequest();
    }

    return (
      <span className={'name user-name'}>
        {user.firstName} {user.lastName}
      </span>
    );
  }
}
