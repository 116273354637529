import { ClientPricingEntity } from '@edgebox/api-rest-client';
import { formatMoneyAmount } from '@edgebox/data-definition-kit';
import { PricingVisibility } from '@edgebox/data-definitions';
import React from 'react';
import { SyncCoreDataDefinitionsEnumValue } from '../../Localization/SyncCoreDataDefinitionsEnumValue';
import { PricingVisibilityBadge } from '../Badges/PricingVisibilityBadge';
import { ViewPackageName } from './ViewPackageName';

interface IProps {
  pricing: ClientPricingEntity;
  withTitle?: boolean;
}

export class ViewPricingSummary extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { pricing, withTitle } = this.props;

    return (
      <>
        {withTitle && (
          <div>
            <strong>
              <ViewPackageName product={pricing.product} packageType={pricing.packageType} />{' '}
              {pricing.visibility === PricingVisibility.Custom && <PricingVisibilityBadge visibility={pricing.visibility} />}
            </strong>
          </div>
        )}

        <div>
          {pricing.fixedMonthlyPrice
            ? `${formatMoneyAmount(pricing.fixedMonthlyPrice, pricing.currency)} per month`
            : `${formatMoneyAmount(pricing.pricePerSite!, pricing.currency)} per site per month`}
        </div>
        {pricing.minMonthlyPrice ? <div>min. {formatMoneyAmount(pricing.minMonthlyPrice, pricing.currency)} per month</div> : undefined}
      </>
    );
  }
}
