import { ClientContractEntity, ClientCustomerEntity } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import { UserType } from '@edgebox/data-definitions';
import { ButtonCheckbox, CustomCardColumns } from '@edgebox/react-components';
import { faBrowser } from '@fortawesome/pro-light-svg-icons/faBrowser';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import React from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import { ApiComponent, CustomerLink, CustomerSelector, IApiComponentState, PagedList, ViewContractName } from '../../common/index';
import { IconSummaryItem } from './IconSummaryItem';
import { SummaryCard } from './SummaryCard';
import { ViewContractPeriod } from './ViewContractPeriod';
import { ViewContractSites } from './ViewContractSites';
import { ViewUpgradeButton } from './ViewUpgradeButton';

interface IProps {
  backend?: boolean;
  customerId?: InternalId;
  emptyMessage?: React.ReactNode;
  emptyMessageWithNoFilters?: React.ReactNode;
  fallBackToInactive?: boolean;
}

interface IFilters {
  search?: string;
  customerId?: InternalId;
  includeInactive?: boolean;
}

interface IState extends IApiComponentState {
  search?: string;
  filterCustomer?: ClientCustomerEntity;
  includeInactive?: boolean;
}

export class PagedContractList extends ApiComponent<IProps, IState> {
  async load() {
    return {};
  }

  render() {
    const { backend, customerId, emptyMessage, emptyMessageWithNoFilters, fallBackToInactive } = this.props;
    const { filterCustomer, includeInactive } = this.state;

    const renderBackendProperties = this.api.currentUser?.type === UserType.Internal && backend;

    return (
      <PagedList<ClientContractEntity, IFilters>
        searchable
        hidePagerIfNotNeeded
        renderList={(items) => <CustomCardColumns>{items}</CustomCardColumns>}
        request={async (page, filter) => {
          let result = await this.api.billing.contracts.search(
            filter?.search,
            { page, itemsPerPage: 8 },
            renderBackendProperties ? filter?.customerId || customerId : this.api.currentUser?.customer?.getId(),
            filter?.includeInactive
          );

          if (!result.totalNumberOfItems && fallBackToInactive && !filter?.includeInactive) {
            this.setState({
              includeInactive: true,
            });

            result = await this.api.billing.contracts.search(
              filter?.search,
              { page, itemsPerPage: 8 },
              renderBackendProperties ? filter?.customerId || customerId : this.api.currentUser?.customer?.getId(),
              true
            );
          }

          return result;
        }}
        renderFilters={(onChange) => {
          const onType = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | React.KeyboardEvent<any>) => {
            const value = (e.target as HTMLInputElement).value;
            onChange('search', value);
            this.setState({ search: value });
          };
          const backendFilters =
            renderBackendProperties && !customerId ? (
              <Col xs={3}>
                <CustomerSelector
                  value={filterCustomer}
                  onRemove={() => {
                    this.setState({ filterCustomer: undefined });
                    onChange('customerId', undefined, true);
                  }}
                  onSelected={(filterCustomer) => {
                    this.setState({ filterCustomer });
                    onChange('customerId', filterCustomer.id, true);
                  }}
                  emptyLabel={'Filter by customer'}
                />
              </Col>
            ) : undefined;
          return (
            <Row>
              <Col className={'p-0'}>
                <Form.Control placeholder={'Enter search term...'} onKeyPress={onType} onChange={onType} />
              </Col>
              {backendFilters}
              <Col xs={2}>
                <ButtonCheckbox
                  checked={!!includeInactive}
                  onToggle={() => {
                    this.setState({ includeInactive: !includeInactive });
                    onChange('includeInactive', !includeInactive, true);
                  }}
                >
                  Show inactive
                </ButtonCheckbox>
              </Col>
            </Row>
          );
        }}
        emptyMessageWithNoFilters={emptyMessageWithNoFilters}
        emptyMessage={emptyMessage || <Alert variant={'light'}>No subscriptions match your filter.</Alert>}
        renderItem={(contract) => (
          <SummaryCard key={contract.id} target={`/subscriptions/${contract.id}`} title={<ViewContractName entity={contract} />}>
            <IconSummaryItem icon={faBrowser}>
              <ViewContractSites entity={contract} summaryOnly />
            </IconSummaryItem>
            <IconSummaryItem icon={faClock}>
              <ViewContractPeriod entity={contract} multiLine />
              {!renderBackendProperties && <ViewUpgradeButton contractId={contract.id} />}
            </IconSummaryItem>
            {renderBackendProperties && !customerId && (
              <IconSummaryItem icon={faBuilding}>
                <CustomerLink entityId={contract.customer.getId()} />
              </IconSummaryItem>
            )}
          </SummaryCard>
        )}
      />
    );
  }
}
