import { DateTimeFormat, formatDate } from '@edgebox/data-definition-kit';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import Badge from 'react-bootstrap/Badge';

interface IProps {
  date: number | moment.Moment;
  icon?: 'clock';
  variant?: 'danger' | 'warning';
  deadline?: boolean;
  format?: DateTimeFormat;
}

interface IState {
  variant?: 'danger' | 'warning' | 'none';
}

export class FormatDateTime extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let variant: 'danger' | 'warning' | undefined = props.variant;

    if (props.deadline) {
      const date = moment(props.date);
      const now = moment();
      if (now.isAfter(date)) {
        variant = 'danger';
      } else if (now.add(30, 'days').isAfter(date)) {
        variant = 'warning';
      }
    }

    this.state = {
      variant,
    };
  }

  render(): React.ReactElement {
    const { icon, variant, format } = this.props;

    const date = moment(this.props.date);

    const content = (
      <>
        {icon && <FontAwesomeIcon icon={faClock} />}
        <span>{formatDate(date, format)}</span>
      </>
    );

    if (variant === 'danger' || variant === 'warning') {
      return (
        <Badge bg={variant} className={'date-time'}>
          {content}
        </Badge>
      );
    }

    return <span className={'date-time'}>{content}</span>;
  }
}
