import { ClientPricingDraft, ClientPricingEntity, ClientProjectDraft } from '@edgebox/api-rest-client';
import { DynamicReference, InternalId, IPagedListResponse, StaticReference } from '@edgebox/data-definition-kit';
import { PricingStatus, PricingVisibility, UserType } from '@edgebox/data-definitions';
import { ButtonCheckbox, Right } from '@edgebox/react-components';
import { Package, Product, SyncCoreDataDefinitionsEnumTranslator } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import { Badge, Button } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { EntitySelector, IEntitySelectorProps, IEntitySelectorState } from '../../Form/EntitySelector';
import { EnumAsDropdown } from '../../Form/EnumAsDropdown';
import { DataDefinitionsEnumValue } from '../../Localization/DataDefinitionsEnumValue';
import { FormatDateTime } from '../../Localization/FormatDateTime';
import { RenderFiltersCallback } from '../../PagedList';
import { PricingForm } from '../Forms/PricingForm';

interface IProps extends IEntitySelectorProps<ClientPricingEntity> {
  forCustomer?: InternalId;
  product?: Product;
  packageType?: Package;
}

interface IFilter {
  includeRetired?: boolean;
  product?: Product;
  packageType?: Package;
}

interface IState extends IEntitySelectorState<ClientPricingEntity>, IFilter {
  newEntity?: ClientPricingDraft;
}

export class PricingSelector extends EntitySelector<ClientPricingEntity, IProps, IState, IFilter> {
  async load() {
    const state = await super.load();
    if (this.props.forCustomer && this.api.currentUser?.type === UserType.Internal) {
      state.newEntity = await this.createDraft();
    }
    return state;
  }

  async createDraft() {
    const customer = await this.api.billing.customers.item(this.props.forCustomer!);
    const packageType = Package.Enterprise;
    const product = this.props.product || Product.Syndication;
    const currency = customer.billingCurrency;
    const parentItems = await this.api.billing.pricing.list(false, product, packageType);
    const parent = parentItems.find((c) => c.currency === currency);

    return new ClientPricingDraft({
      name: `Custom ${product} ${packageType} for ${customer.name}`,
      product,
      packageType,
      currency,
      visibility: PricingVisibility.Custom,
      minMonthlyPrice: parent!.minMonthlyPrice,
      discounts: parent!.discounts,
      status: PricingStatus.Active,
      pricePerSite: parent!.pricePerSite,
      minimumPricePerSite: parent!.minimumPricePerSite,
      customer: new StaticReference(customer),
    });
  }

  renderEmpty() {
    return 'Select pricing';
  }

  renderCurrentValue(selected: ClientPricingEntity): React.ReactElement | string {
    return selected.name;
  }

  async search(page: number, query?: IFilter): Promise<IPagedListResponse<ClientPricingEntity>> {
    const { packageType: fixedPackageType, product: fixedProduct } = this.props;
    const { forCustomer } = this.props;

    const items = await this.api.billing.pricing.list(
      query?.includeRetired,
      fixedProduct || query?.product,
      fixedPackageType || query?.packageType,
      forCustomer
    );
    return {
      items,
      itemsPerPage: items.length,
      page: 0,
      numberOfPages: 1,
      totalNumberOfItems: items.length,
    };
  }

  renderFilters(): undefined | RenderFiltersCallback<IFilter> {
    const { packageType: fixedPackageType, product: fixedProduct } = this.props;
    const { product, includeRetired, packageType } = this.state;

    return (onChange) => {
      return [
        ...(fixedPackageType
          ? []
          : [
              <EnumAsDropdown<ClientPricingEntity, Product>
                enumValues={SyncCoreDataDefinitionsEnumTranslator.translateEnum('Product')}
                currentValue={product!}
                name={'product'}
                label={'Product'}
                allowEmpty
                emptyName={'Any'}
                setValue={(name, value) => {
                  this.setState({ product: value });
                  onChange('product', value);
                }}
              />,
            ]),
        ...(fixedProduct
          ? []
          : [
              <EnumAsDropdown<ClientPricingEntity, Package>
                enumValues={SyncCoreDataDefinitionsEnumTranslator.translateEnum('Package')}
                currentValue={packageType!}
                name={'packageType'}
                label={'Package'}
                allowEmpty
                emptyName={'Any'}
                setValue={(name, value) => {
                  this.setState({ packageType: value });
                  onChange('packageType', value);
                }}
              />,
            ]),
        <ButtonCheckbox
          checked={!!includeRetired}
          onToggle={() => {
            this.setState({ includeRetired: !includeRetired });
            onChange('includeRetired', !includeRetired);
          }}
        >
          Include retired
        </ButtonCheckbox>,
      ];
    };
  }

  renderAddForm(): React.ReactElement | null {
    const { newEntity } = this.state;
    if (!newEntity) {
      return null;
    }

    return (
      <PricingForm
        onSave={async (draft) => {
          const entity = await this.api.billing.pricing.create(draft);

          this.addNew(entity, {
            newEntity: await this.createDraft(),
          });
        }}
        mode={'create'}
        entity={newEntity}
      >
        <Right>
          <Button variant={'primary'} type={'submit'}>
            Save custom pricing
          </Button>
        </Right>
      </PricingForm>
    );
  }

  renderItem(entity: ClientPricingEntity): React.ReactElement {
    return (
      <Row>
        <Col>
          <h3>
            {entity.name}{' '}
            <Badge bg={'light'}>
              <DataDefinitionsEnumValue enumName={'PricingVisibility'} keyName={entity.visibility} />
            </Badge>
          </h3>
          <div className={'text-muted'}>
            Created: <FormatDateTime date={entity.createdAt} />
          </div>
        </Col>
      </Row>
    );
  }

  renderItemAsText(entity: ClientPricingEntity): string {
    return entity.name;
  }
}
