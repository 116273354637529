import { ClientCustomerEntity, ClientSiteEntity } from '@edgebox/api-rest-client';
import { Right } from '@edgebox/react-components';
import moment from 'moment';
import React from 'react';
import { XYChart, Scale, Legend } from '@visx/visx';
import { ClientUsageStatsEntity } from '@edgebox/api-rest-client/dist/client/Syndication/UsageStatsService';
import { UserType } from '@edgebox/data-definitions';
import { Alert } from 'react-bootstrap';
import { formatIntegerShort } from '@edgebox/data-definition-kit';
import { ApiComponent, CustomerSelector, IApiComponentState, SiteSelector } from '../../../common';
import { AppContextSiteSelector, IAppContextProp, withAppContext } from '../../../common/contexts/AppContext';
import { propertyChangeReload } from '../../../common/helpers/propertyChangeReload';

interface IProps extends JSX.IntrinsicAttributes, IAppContextProp {
  displayType: 'bars';
  forSite?: ClientSiteEntity;
  forCustomer?: ClientCustomerEntity;
}
interface IState extends IApiComponentState {
  selectedSite?: ClientSiteEntity;
  selectedCustomer?: ClientCustomerEntity;
  data?: IStatItem[];
  loading?: boolean;
}

interface IStatItem {
  updates: number;
  freeUpdates: number;
  date: string;
}

const primaryColor = '#009ee3';
const secondaryColor = '#71d3ff';

const colorScale = Scale.scaleOrdinal<string, string>({
  domain: ['Updates', 'Test updates'],
  range: [primaryColor, secondaryColor],
});

function makeData(clb: (start: moment.Moment, end: moment.Moment) => ClientUsageStatsEntity[]) {
  const data: IStatItem[] = [];
  for (let day = 7; day >= 0; day--) {
    const start = moment().subtract(day, 'days').startOf('day');
    const end = start.clone().add(1, 'day');
    const matches = clb(start, end);
    data.push({
      date: day ? start.format('ddd') : 'Today',
      updates: matches.reduce((a, b) => a + b.updates, 0),
      freeUpdates: matches.reduce((a, b) => a + b.freeUpdates, 0),
    });
  }
  return data;
}
const NO_DATA = makeData(() => []);

export class GraphStatsClass extends ApiComponent<IProps, IState> {
  async load() {
    const selectedSite = this.props.forSite || this.props.appContext.site;
    const selectedCustomer = selectedSite ? undefined : this.props.forCustomer || this.props.appContext.customer;

    // This will be 8 items to cover the last 7 days of activity plus today.
    const from = moment().subtract(7, 'days').startOf('day');
    const to = moment();

    const individual = await this.api.syndication.usageStats.list(
      {
        from: from.valueOf(),
        to: to.valueOf(),
      },
      selectedSite
        ? { siteId: selectedSite.id }
        : { customerId: selectedCustomer ? selectedCustomer.id : this.api.currentUser!.customer!.getId()! },
      'hourly'
    );

    const data = makeData((start, end) => individual.filter((c) => c.from.isBefore(end) && c.to.isAfter(start)));

    return {
      selectedCustomer,
      selectedSite,
      data,
      loading: false,
    };
  }

  render() {
    const { forSite } = this.props;
    const { selectedSite, selectedCustomer, data, loading } = this.state;

    if (!data) {
      return this.renderRequest();
    }

    return (
      <div className="d-flex flex-column">
        {!forSite && (
          <div className="flex-grow-0 flex-shrink-0">
            <Right>
              {!this.props.forSite && (
                <div style={{ width: '50%' }} className="ps-1">
                  <AppContextSiteSelector />
                </div>
              )}
            </Right>
          </div>
        )}

        {data && !loading && !data.reduce((a, b) => a + b.updates + b.freeUpdates, 0) && (
          <Alert className="mt-1 flex-grow-0 flex-shrink-0" variant="warning">
            No data yet.
          </Alert>
        )}

        <div className="flex-grow-1 flex-shrink-1">
          <XYChart.XYChart
            height={300}
            xScale={{ type: 'band', padding: 0.2 }}
            yScale={{ type: 'linear' }}
            margin={{
              top: 20,
              right: 20,
              bottom: 30,
              left: 40,
            }}
          >
            <XYChart.AnimatedAxis orientation="bottom" animationTrajectory="min" strokeWidth={1} />
            <XYChart.AnimatedAxis
              orientation="left"
              animationTrajectory="min"
              strokeWidth={1}
              hideZero
              numTicks={5}
              tickFormat={(c: number) => formatIntegerShort(c)}
            />
            {/*<XYChart.BarSeries data={data || []} dataKey="updates" xAccessor={item => item.date} yAccessor={item => item.updates} colorAccessor={item => '#000000'} />*/}
            <XYChart.AnimatedBarStack>
              <XYChart.AnimatedBarSeries
                data={data || []}
                dataKey="updates"
                xAccessor={(item: IStatItem) => item.date}
                yAccessor={(item: IStatItem) => item.updates}
                colorAccessor={(item: IStatItem) => primaryColor}
              />
              <XYChart.AnimatedBarSeries
                data={data || (loading ? NO_DATA : [])}
                dataKey="freeUpdates"
                xAccessor={(item: IStatItem) => item.date}
                yAccessor={(item: IStatItem) => item.freeUpdates}
                colorAccessor={(item: IStatItem) => secondaryColor}
              />
            </XYChart.AnimatedBarStack>
          </XYChart.XYChart>
        </div>

        <div className="d-flex justify-content-center text-muted flex-grow-0 flex-shrink-0">
          <Legend.LegendOrdinal scale={colorScale} direction="row" labelMargin="0 15px 0 0" />
        </div>
      </div>
    );
  }
}

export const GraphStats = withAppContext(propertyChangeReload(GraphStatsClass, (props) => props.appContext.siteKey));
