import { ClientContractEntity, ClientProjectDraft, ClientProjectEntity } from '@edgebox/api-rest-client';
import { DynamicReference, InternalId, IPagedListResponse, StaticReference } from '@edgebox/data-definition-kit';
import { Right } from '@edgebox/react-components';
import { ProjectType, SiteApplicationType, SiteEnvironmentType } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import { Badge, Button } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { EntitySelector, IEntitySelectorProps, IEntitySelectorState } from '../../Form/EntitySelector';
import { FormatDateTime } from '../../Localization/FormatDateTime';
import { ITextSearchFilter } from '../../PagedList';
import { ProjectForm } from '../Forms/ProjectForm';
import { ViewSiteCount } from '../../Billing/Views/ViewSiteCount';

interface IProps extends IEntitySelectorProps<ClientProjectEntity> {
  contract?: ClientContractEntity;
  forCustomer?: InternalId;
  forType?: ProjectType;
  forAppType?: SiteApplicationType;
  autoSelectIfOnlyOneExists?: boolean;
}

interface IState extends IEntitySelectorState<ClientProjectEntity> {
  newEntity?: ClientProjectDraft;
}

export class ProjectSelector extends EntitySelector<ClientProjectEntity, IProps, IState> {
  async load() {
    const state = await super.load();
    state.newEntity = this.props.contract
      ? new ClientProjectDraft({
          name: '',
          type: this.props.forType || SiteEnvironmentType.Production,
          appType: this.props.forAppType || SiteApplicationType.Drupal,
          contract: new StaticReference(this.props.contract),
          customer: this.props.forCustomer
            ? new DynamicReference(this.props.forCustomer, this.api.billing.customers.item)
            : new StaticReference(await this.getCurrentCustomer(true)),
        })
      : undefined;

    const { forCustomer, forType, forAppType, contract, autoSelectIfOnlyOneExists } = this.props;
    const response = await this.api.syndication.projects.search(
      { customerId: forCustomer, contractId: contract?.id },
      { itemsPerPage: 1 },
      { type: forType, appType: forAppType }
    );

    if (!this.props.value && !this.state.selected && autoSelectIfOnlyOneExists && response.totalNumberOfItems === 1) {
      setTimeout(() => this.select(response.items[0]));
    }

    return state;
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    super.componentDidUpdate(prevProps, prevState, snapshot);

    // Must update if required type changes.
    if (this.props.forType !== prevProps.forType) {
      const { newEntity } = this.state;
      if (newEntity && this.props.forType) {
        newEntity.type = this.props.forType;
      }
      if (newEntity && this.props.forAppType) {
        newEntity.appType = this.props.forAppType;
      }
      this.setState({
        newEntity,
        updateResults: Date.now(),
      });
    }
    // Must update if contract changes.
    if (this.props.contract?.id !== prevProps.contract?.id) {
      const { newEntity } = this.state;
      if (newEntity && this.props.contract) {
        newEntity.contract = new StaticReference(this.props.contract);
      }
      this.setState({
        newEntity,
        updateResults: Date.now(),
      });
    }
  }

  renderEmpty() {
    return 'Select project';
  }

  renderCurrentValue(selected: ClientProjectEntity): React.ReactElement | string {
    return selected.name;
  }

  search(page: number, filters?: ITextSearchFilter): Promise<IPagedListResponse<ClientProjectEntity>> {
    const { forCustomer, forType, forAppType, contract } = this.props;

    return this.api.syndication.projects.search(
      { customerId: forCustomer, contractId: contract?.id },
      { page },
      { type: forType, search: filters?.search, appType: forAppType }
    );
  }

  renderAddForm(): React.ReactElement | null {
    const { contract, forType, forAppType } = this.props;
    const { newEntity } = this.state;
    if (!newEntity) {
      return null;
    }

    return (
      <ProjectForm
        fixedContract={!!contract?.id}
        mode={'create'}
        staticType={!!forType}
        staticAppType={!!forAppType}
        key={`${forType || ''}-${forAppType || ''}-${contract?.id || ''}`}
        onSave={async (draft) => {
          const entity = await this.api.syndication.projects.create(draft);

          this.addNew(entity, {
            newEntity: new ClientProjectDraft({
              name: '',
              type: this.props.forType || SiteEnvironmentType.Production,
              appType: this.props.forAppType || SiteApplicationType.Drupal,
              contract: new StaticReference(contract!),
              customer: this.props.forCustomer
                ? new DynamicReference(this.props.forCustomer, this.api.billing.customers.item)
                : new StaticReference(await this.getCurrentCustomer(true)),
            }),
          });
        }}
        entity={newEntity}
      >
        <Right>
          <Button variant={'primary'} type={'submit'}>
            Create
          </Button>
        </Right>
      </ProjectForm>
    );
  }

  renderItem(entity: ClientProjectEntity): React.ReactElement {
    if (this.state.variant === 'radios') {
      return (
        <>
          {entity.name} <ViewSiteCount projectId={entity.id} badge warnIfEmpty />
        </>
      );
    }
    return (
      <Row>
        <Col>
          <h3>{entity.name}</h3>
          <div className={'text-muted'}>
            Created: <FormatDateTime date={entity.createdAt} />
          </div>
        </Col>
      </Row>
    );
  }

  renderItemAsText(entity: ClientProjectEntity): string {
    return entity.name;
  }
}
