import { IInternalAuthentication } from '@edgebox/api-rest-client';
import React, { Component, MouseEvent } from 'react';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import { Header } from '@edgebox/react-components';
import { Tracking, TrackingEvent } from '../../contexts/TrackingContext';
import { AuthenticationContext } from '../../common';

export class LoginHeader extends Component<{}, {}> {
  static contextType = AuthenticationContext;
  context!: IInternalAuthentication;
  get authentication(): IInternalAuthentication {
    return this.context;
  }

  public render() {
    const onClick = (e: MouseEvent) => {
      Tracking.track(TrackingEvent.StartLogin, () => {
        this.authentication.loginWithRedirect();
      });
    };

    return (
      <Header>
        <Navbar.Collapse className="justify-content-end">
          <Button variant={'primary'} onClick={onClick}>
            Login
          </Button>
        </Navbar.Collapse>
      </Header>
    );
  }
}
