import { ClientCustomerEntity } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import React from 'react';
import { Link } from 'react-router-dom';
import { ApiComponent, IApiComponentState } from '../../../services/ApiComponent';

interface IProps {
  entityId: InternalId;
}

interface IState extends IApiComponentState {
  entity?: ClientCustomerEntity;
}

export class CustomerLink extends ApiComponent<IProps, IState> {
  async load(): Promise<Partial<IState>> {
    const entity = await this.api.billing.customers.item(this.props.entityId);

    return {
      entity,
    };
  }

  render(): React.ReactElement {
    const { entity } = this.state;

    if (!entity) {
      return this.renderRequest();
    }

    return <Link to={`/backend/billing/customers/${entity.id}`}>{entity.name}</Link>;
  }
}
