import { ClientContractRevisionEntity } from '@edgebox/api-rest-client/dist/client/Billing/ContractRevisionService';
import { InternalId } from '@edgebox/data-definition-kit';
import React from 'react';
import { ApiComponent, IApiComponentState } from '../../../services/ApiComponent';

interface IProps {
  contractId?: InternalId;
  children: (mostRecent: { current?: ClientContractRevisionEntity; next?: ClientContractRevisionEntity }) => React.ReactNode;
}

interface IState extends IApiComponentState {
  mostRecent?: {
    current?: ClientContractRevisionEntity;
    next?: ClientContractRevisionEntity;
  };
}

export class WithMostRecentContractRevision extends ApiComponent<IProps, IState> {
  async load() {
    const { contractId } = this.props;
    const mostRecent: {
      current?: ClientContractRevisionEntity;
      next?: ClientContractRevisionEntity;
    } = contractId ? await this.api.billing.contractRevisions.getMostRecentForContract(contractId) : {};

    return {
      mostRecent,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (prevProps.contractId !== this.props.contractId) {
      this.load();
    }
  }

  render(): React.ReactNode {
    const { children } = this.props;
    const { mostRecent } = this.state;

    if (!mostRecent) {
      return this.renderRequest();
    }

    return children(mostRecent);
  }
}
