import { PricingVisibility } from '@edgebox/data-definitions';
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { DataDefinitionsEnumValue } from '../../Localization/DataDefinitionsEnumValue';

interface IProps {
  visibility: PricingVisibility;
  size?: 'small';
}

export class PricingVisibilityBadge extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { visibility, size } = this.props;

    const classes = size ? 'small' : '';

    const variants = {
      [PricingVisibility.Public]: 'primary',
      [PricingVisibility.Hidden]: 'warning',
      [PricingVisibility.Custom]: 'danger',
    };

    return (
      <Badge bg={variants[visibility]} className={classes}>
        <DataDefinitionsEnumValue enumName={'PricingVisibility'} keyName={visibility} />
      </Badge>
    );
  }
}
