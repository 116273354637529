import {
  ClientContractEntity,
  ClientCustomerEntity,
  ClientInvoiceEntity,
  ClientUserEntity,
  IInternalAuthentication,
} from '@edgebox/api-rest-client';
import { DateTimeFormat, formatDate, InternalId } from '@edgebox/data-definition-kit';
import { InvoiceStatus } from '@edgebox/data-definitions';
import React from 'react';
import Alert from 'react-bootstrap/cjs/Alert';
import Button from 'react-bootstrap/cjs/Button';
import Row from 'react-bootstrap/cjs/Row';
import {
  ApiComponent,
  AuthenticationContext,
  ContractLink,
  FormatDateTime,
  IApiComponentState,
  InvoiceStatusBadge,
  MoneyAmount,
  PagedList,
  RequestReason,
} from '../../../common/index';
import { API_DOMAIN } from '../../../constants';
import { ButtonLink } from '@edgebox/react-components';
import { ContentCol } from '@edgebox/react-components';
import { ExternalLink } from '@edgebox/react-components';
import { HeaderCol } from '@edgebox/react-components';
import { ContentBox } from '../../Shared/ContentBox';
import { LogsButton } from '../LogsButton';

interface IProps {
  status: InvoiceStatus[];
  onStatusChange?: () => void;
  onTotalNumberOfItems?: (totalNumberOfItems: number) => void;
}

interface IState extends IApiComponentState {
  page?: number;
  customers?: ClientCustomerEntity[];
  users?: ClientUserEntity[];
  contracts?: ClientContractEntity[];
  executingAction?: boolean;
}

export class InvoiceListByStatus extends ApiComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.approve = this.wrapApiCallFunction(this.approve.bind(this), RequestReason.ExecuteAction);
    this.retryPayment = this.wrapApiCallFunction(this.retryPayment.bind(this), RequestReason.ExecuteAction);
  }

  async load() {
    return {};
  }

  async paid(invoiceId: InternalId) {
    this.setState({ executingAction: true });
    await this.api.billing.invoices.paid(invoiceId);
    if (this.props.onStatusChange) {
      this.props.onStatusChange();
    }
    this.setState({ executingAction: false });
  }

  async approve(invoiceId: InternalId) {
    this.setState({ executingAction: true });
    await this.api.billing.invoices.approve(invoiceId);
    if (this.props.onStatusChange) {
      this.props.onStatusChange();
    }
    this.setState({ executingAction: false });
  }

  async retryPayment(invoiceId: InternalId) {
    this.setState({ executingAction: true });
    await this.api.billing.invoices.retryPayment(invoiceId);
    if (this.props.onStatusChange) {
      this.props.onStatusChange();
    }
    this.setState({ executingAction: false });
  }

  render() {
    const { users, contracts, customers, executingAction } = this.state;

    return (
      <ContentBox>
        <div className={'mt-3'}>
          {executingAction ? this.renderRequest(RequestReason.ExecuteAction) : undefined}

          <PagedList<ClientInvoiceEntity>
            emptyMessage={<Alert variant={'light'}>No invoices with this status.</Alert>}
            renderListHeader={() => (
              <Row>
                <HeaderCol xs={2}>Name / ID</HeaderCol>
                <HeaderCol xs={2}>Total</HeaderCol>
                <HeaderCol xs={1}>Date</HeaderCol>
                <HeaderCol xs={3}>Contract</HeaderCol>
                <HeaderCol xs={2}>Approval</HeaderCol>
                <HeaderCol xs={2}>Actions</HeaderCol>
              </Row>
            )}
            renderItem={(invoice) => {
              const customer = customers!.find((c) => c.id === invoice.customer.getId());
              const approvedBy = users!.find((c) => c.id === invoice.approvedBy?.getId());
              const contract = contracts!.find((c) => c.id === invoice.contract.getId());

              let actions: React.ReactNode = <span className={'text-secondary'}>-</span>;
              if (invoice.status === InvoiceStatus.Saved) {
                actions = (
                  <Button variant={'primary'} onClick={() => this.approve(invoice.id)}>
                    Approve
                  </Button>
                );
              } else if (invoice.status === InvoiceStatus.Approved) {
                actions = (
                  <Button variant={'warning'} onClick={() => this.paid(invoice.id)}>
                    Mark as Paid
                  </Button>
                );
              } else if (invoice.status === InvoiceStatus.PaymentFailed) {
                actions = (
                  <Button variant={'warning'} onClick={() => this.retryPayment(invoice.id)}>
                    Retry payment
                  </Button>
                );
              }

              return (
                <Row key={invoice.id}>
                  <ContentCol xs={2}>
                    {invoice.name ? (
                      <AuthenticationContext.Consumer>
                        {(auth: IInternalAuthentication) => {
                          return (
                            <ExternalLink to={`//${API_DOMAIN}/invoice/${invoice.id}/in-xero?jwt=${encodeURIComponent(auth.jwt!)}`}>
                              {invoice.name}
                            </ExternalLink>
                          );
                        }}
                      </AuthenticationContext.Consumer>
                    ) : (
                      <span className="text-break">{invoice.id}</span>
                    )}{' '}
                    <InvoiceStatusBadge status={invoice.status} />
                  </ContentCol>
                  <ContentCol xs={2}>
                    {invoice.totalWithTaxes !== undefined ? (
                      <MoneyAmount amount={invoice.totalWithTaxes} currency={invoice.currency} />
                    ) : invoice.total !== undefined ? (
                      <MoneyAmount amount={invoice.total} currency={invoice.currency}>
                        +TAXES
                      </MoneyAmount>
                    ) : (
                      <em>unknown</em>
                    )}
                  </ContentCol>
                  <ContentCol
                    xs={1}
                    title={invoice.paymentDate ? `Paid ${formatDate(invoice.paymentDate, DateTimeFormat.Date)}` : undefined}
                  >
                    {invoice.date ? (
                      <FormatDateTime date={invoice.date} format={DateTimeFormat.Date} />
                    ) : (
                      <FormatDateTime date={invoice.createdAt} format={DateTimeFormat.Date} />
                    )}
                  </ContentCol>
                  <ContentCol xs={3}>
                    {contract ? <ContractLink entityId={contract.id} /> : undefined}
                    {customer?.name ? (
                      <div>
                        <ButtonLink to={`/backend/billing/customers/${customer?.id}`} variant={'link'} className={'text-muted'}>
                          {customer.name}
                        </ButtonLink>
                      </div>
                    ) : undefined}
                  </ContentCol>
                  <ContentCol xs={2}>
                    {invoice.approvalDate ? (
                      <FormatDateTime date={invoice.approvalDate} format={DateTimeFormat.DateAndTime} />
                    ) : (
                      <span className={'text-secondary'}>-</span>
                    )}
                    {approvedBy ? (
                      <div className={'text-muted'}>
                        by {approvedBy.firstName} {approvedBy.lastName}
                      </div>
                    ) : invoice.approvalDate ? (
                      <div>
                        <em className={'text-secondary'}>automatically</em>
                      </div>
                    ) : undefined}
                  </ContentCol>
                  <ContentCol xs={2}>
                    {actions}
                    <LogsButton name={invoice.name || invoice.id} invoiceId={invoice.id} />
                  </ContentCol>
                </Row>
              );
            }}
            request={async (page) => {
              const { status, onTotalNumberOfItems } = this.props;

              const invoices = await this.api.billing.invoices.listByStatus(status as any, { page });
              const customerIds = invoices.items.map((c) => c.customer.getId());
              const customers = await Promise.all(
                customerIds.filter((c, i) => customerIds.indexOf(c) === i).map((c) => this.api.billing.customers.item(c))
              );
              const userIds = invoices.items.map((c) => c.approvedBy?.getId());
              const users = await Promise.all(
                userIds.filter((c, i) => !!c && userIds.indexOf(c) === i).map((c) => this.api.authentication.users.item(c!))
              );
              const contractIds = invoices.items.map((c) => c.contract.getId());
              const contracts = await Promise.all(
                contractIds.filter((c, i) => contractIds.indexOf(c) === i).map((c) => this.api.billing.contracts.item(c))
              );

              if (onTotalNumberOfItems) {
                onTotalNumberOfItems(invoices.totalNumberOfItems);
              }

              this.setState({
                customers,
                users,
                contracts: contracts,
              });

              return invoices;
            }}
          />
        </div>
      </ContentBox>
    );
  }
}
