import { IPagedListResponse } from '@edgebox/data-definition-kit';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { IconButton, ValidationError } from '@edgebox/react-components';
import { RemoveButton } from '@edgebox/react-components';
import { RichSelectItem } from '@edgebox/react-components';
import { Right } from '@edgebox/react-components';
import { ApiComponent, IApiComponentState } from '../../services/ApiComponent';
import { getStyleColors } from '../../services/Helpers';
import { ITextSearchFilter, PagedList, RenderFiltersCallback } from '../PagedList';
import { AsyncPaginate } from 'react-select-async-paginate';
import { GroupBase, OptionProps } from 'react-select';

type OnSelected<Entity> = (entity: Entity) => void;
type OnCancelled = () => void;
type OnRemove = () => void;

// TODO: Don't use inheritance. Use component-style approach instead.

export interface IEntitySelectorProps<Entity> {
  show?: boolean;
  startOpen?: boolean;
  title?: string;
  name?: string;
  value?: Entity;
  onSelected?: OnSelected<Entity>;
  onCancelled?: OnCancelled;
  onRemove?: OnRemove;
  disabled?: ((item: Entity) => boolean) | boolean;
  error?: string;
  className?: string;
  emptyLabel?: string;
  variant?: 'drop-down' | 'modal' | 'radios';
  display?: 'default' | 'menu' | 'menu-solid';
  noAdd?: boolean;
  inactive?: boolean;
}

export interface IEntitySelectorState<Entity> extends IApiComponentState {
  show: boolean;
  searchable: boolean;
  selected?: Entity;
  activeTab?: 'search' | 'add';
  updateResults?: number;
  variant?: 'drop-down' | 'modal' | 'radios';
  newItems?: Entity[];
  newItemsFrom?: number;
}

export abstract class EntitySelector<
  Entity,
  Props extends IEntitySelectorProps<Entity>,
  State extends IEntitySelectorState<Entity>,
  Filter extends object = ITextSearchFilter,
> extends ApiComponent<Props, State> {
  constructor(props: Props, state?: Partial<State>) {
    super(
      props,
      Object.assign(
        {
          searchable: true,
        },
        state || {},
        {
          show: props.show || props.startOpen || false,
        }
      ) as State
    );
  }

  async load(): Promise<Partial<State>> {
    const state = {
      variant: this.props.variant || (this.renderFilters() ? 'modal' : 'drop-down'),
    } as Partial<State>;

    if (this.props.value) {
      this.setSelected(this.props.value);
    }

    return state;
  }

  isDisabled(entity: Entity): boolean {
    const { disabled } = this.props;

    return !!disabled && disabled !== true && disabled(entity);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.show !== this.props.show) {
      this.setState({
        show: !!this.props.show,
      });
    }

    if (prevProps.value !== this.props.value) {
      this.setSelected(this.props.value);
    }
  }

  setSelected(selected?: Entity) {
    this.setState({
      selected,
    });
  }

  abstract search(page: number, filter?: Filter): Promise<IPagedListResponse<Entity>>;
  abstract renderItem(entity: Entity): React.ReactNode;
  abstract renderItemAsText(entity: Entity): string;
  renderItemAsSubText(entity: Entity): string | null {
    return null;
  }
  abstract renderCurrentValue(selected: Entity): React.ReactNode;
  abstract renderEmpty(): React.ReactNode;

  renderFilters(): undefined | RenderFiltersCallback<Filter> {
    return undefined;
  }

  renderAddForm(): React.ReactElement | null {
    return null;
  }

  select(entity: Entity, state?: Partial<State>) {
    if (this.props.onSelected) {
      this.props.onSelected(entity);
    }

    this.setState({
      ...((state as any) || {}),
      selected: this.props.value === undefined ? entity : this.props.value,
      show: false,
      activeTab: 'search',
    });
  }

  getNewItems() {
    if (this.state.newItemsFrom === this.state.updateResults) {
      return this.state.newItems || [];
    }
    return [];
  }

  addNew(entity: Entity, state?: Partial<State>) {
    this.select(entity, {
      ...((state as any) || {}),
      newItems: [...this.getNewItems(), entity],
      newItemsFrom: this.state.updateResults,
    });
  }

  render(): React.ReactElement | null {
    if (this.state.variant === 'modal') {
      return this.renderAsModal();
    } else if (this.state.variant === 'drop-down') {
      return this.renderAsDropDown();
    } else if (this.state.variant === 'radios') {
      return this.renderAsRadios();
    } else {
      return null;
    }
  }

  renderAsDropDown() {
    const { onRemove, disabled, className, display, inactive, noAdd } = this.props;
    const { selected, activeTab, updateResults } = this.state;

    const remove = () => {
      this.setState({
        show: false,
        selected: this.props.value !== undefined ? selected : undefined,
      });

      onRemove!();
    };

    const displayMenu = display === 'menu' || display === 'menu-solid';
    const solid = display === 'menu-solid';

    const { primary, primaryDarker, danger, primaryLighter, primaryLighter50 } = getStyleColors(
      inactive && displayMenu ? 'menu-inactive' : display
    );

    // Required to re-render the component as that's the only way to re-load the list of options. Otherwise when
    // you add a new entity, the list will not show it.
    const key = `dropdown-${(selected as any)?.id || ''}-${updateResults || 0}`;

    const dropDown = (
      <AsyncPaginate
        className={`display-${display || 'default'} ${className || ''}`}
        key={key}
        isClearable={!!onRemove}
        loadOptions={async (search, lastOptions, additional: any) => {
          const sites = await this.search(additional ? additional.page + 1 : 0, { search } as any);

          return {
            options: sites.items,
            hasMore: sites.page < sites.numberOfPages - 1,
            additional: { page: sites.page },
          };
        }}
        isDisabled={disabled === true}
        placeholder={selected ? this.renderCurrentValue(selected as Entity) : this.renderEmpty()}
        value={selected}
        getOptionValue={(option) => (option as any).id}
        getOptionLabel={(option) => this.renderItemAsText(option as Entity)}
        formatOptionLabel={(option) => {
          const text = this.renderItemAsText(option as Entity);
          const subtext = this.renderItemAsSubText(option as Entity);
          return (
            <>
              <div title={text} className="text-truncate" style={subtext ? { fontWeight: 'bold' } : {}}>
                {text}
              </div>
              {subtext && (
                <div title={subtext} className="text-truncate opacity-3" style={{ fontWeight: 'normal' }}>
                  <small>{subtext}</small>
                </div>
              )}
            </>
          );
        }}
        onChange={(option) => (option ? this.select(option as Entity) : remove())}
        //components={display==='menu' ? { Option: CustomOption } : undefined}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary,
            danger,
          },
        })}
        styles={
          displayMenu
            ? {
                control: (provided, state) => ({
                  ...provided,
                  outline: 'none',
                  cursor: state.isDisabled ? 'default' : 'pointer',
                  boxShadow: state.isFocused ? '0 0.5rem 1rem rgba(0, 0, 0, 0.3)' : undefined,
                  ...(solid
                    ? {
                        border: 'none',
                        background: state.isFocused ? primaryDarker : primary,
                      }
                    : {
                        border: `3px solid ${state.isFocused ? primaryLighter : primary} !important`,
                        background: 'transparent',
                        borderRadius: '7px',
                      }),
                }),
                ['indicatorContainer' as any]: (provided: any, state: any) => ({
                  ...provided,
                  padding: '0 8px 0 0',
                }),
                ['indicatorsContainer' as any]: (provided: any, state: any) => ({
                  ...provided,
                  padding: '0',
                }),
                singleValue: (provided, state) => ({
                  ...provided,
                  ...(solid
                    ? {
                        color: '#fff',
                      }
                    : {
                        color: '#666',
                      }),
                  fontWeight: 'bold',
                  padding: '0.1em 0',
                }),
                indicatorSeparator: (provided, state) => ({
                  ...provided,
                  background: 'transparent',
                }),
                dropdownIndicator: (provided, state) => ({
                  ...provided,
                  ...(solid
                    ? {
                        color: '#fff',
                      }
                    : {
                        color: primary,
                      }),
                }),
                loadingIndicator: (provided, state) => ({
                  ...provided,
                  ...(solid
                    ? {
                        color: '#fff',
                      }
                    : {
                        color: primary,
                      }),
                }),
                clearIndicator: (provided, state) => ({
                  ...provided,
                  ...(solid
                    ? {
                        color: '#fff',
                      }
                    : {
                        color: '#666',
                      }),
                }),
                input: (provided, state) => ({
                  ...provided,
                  ...(solid
                    ? {
                        color: '#fff',
                      }
                    : {
                        color: '#666',
                      }),
                  padding: '0.5em',
                }),
                placeholder: (provided, state) => ({
                  ...provided,
                  ...(solid
                    ? {
                        color: '#ddd',
                      }
                    : {
                        color: '#888',
                      }),
                  fontStyle: 'italic',
                  padding: '0.5em',
                }),
                menu: (provided, state) => ({
                  ...provided,
                  margin: '2px 0 0 0',
                }),
                option: (provided, state) => ({
                  ...provided,
                  ...(solid
                    ? {
                        background: state.isSelected ? primary : state.isFocused ? primaryLighter50 : '#fff',
                        color: state.isSelected ? '#fff' : '#000',
                      }
                    : {
                        background: /*state.isSelected ? primary : state.isFocused ? primaryLighter50 :*/ '#fff',
                        color: state.isSelected || state.isFocused ? primary : '#000',
                      }),
                  cursor: state.isDisabled || state.isSelected ? 'default' : 'pointer',
                }),
              }
            : {}
        }
      />
    );

    let content: React.ReactNode;

    const addForm = !noAdd && this.renderAddForm();
    if (addForm) {
      content = (
        <Row>
          <Col className={'ps-0'}>{dropDown}</Col>
          <Col xs={2} className={'pe-0'}>
            <Button variant={'light'} onClick={() => this.setState({ activeTab: 'add' })}>
              Add
            </Button>
            <Modal size={'xl'} onHide={() => this.setState({ activeTab: 'search' })} show={activeTab === 'add'} scrollable>
              <Modal.Header closeButton>
                <Modal.Title>Add</Modal.Title>
              </Modal.Header>
              <Modal.Body>{addForm}</Modal.Body>
            </Modal>
          </Col>
        </Row>
      );
    } else {
      content = dropDown;
    }

    return content;
  }

  renderAsModal() {
    const { onCancelled, title, onRemove, name, error, className, emptyLabel } = this.props;
    const { show, activeTab, selected, updateResults } = this.state;

    const onClose = () => {
      this.setState({ show: false });
      if (onCancelled) {
        onCancelled();
      }
    };

    const onShow = () => {
      this.setState({ show: true });
    };

    const addForm = this.renderAddForm();

    const renderFilters = this.renderFilters();

    const searchContent = (
      <PagedList<Entity, Filter>
        searchable={this.state.searchable}
        renderFilters={renderFilters}
        key={updateResults}
        request={this.search.bind(this)}
        renderItem={(item, index) => {
          const isSelected = (selected as any)?.id === (item as any)?.id;

          const isDisabled = this.isDisabled(item);

          return (
            <RichSelectItem
              selected={isSelected}
              disabled={isDisabled}
              onSelect={() => {
                this.select(item);
              }}
              key={index}
            >
              {this.renderItem(item)}
            </RichSelectItem>
          );
        }}
        emptyMessage={<Container className={'text-muted'}>No matches.</Container>}
      />
    );

    const remove = () => {
      this.setState({
        show: false,
        selected: this.props.value !== undefined ? selected : undefined,
      });

      onRemove!();
    };

    return (
      <span className={`entity-selector align-middle ${className || ''}`}>
        {name ? (
          <Form.Control name={name} type={'hidden'} value={(selected as any)?.id || ''} className={error ? 'is-invalid' : ''} />
        ) : undefined}

        {selected !== undefined ? (
          <span className={'me-1 align-middle'}>
            {this.renderCurrentValue(selected as Entity)} <IconButton icon={faEdit} variant={'light'} onClick={onShow} />{' '}
            {onRemove && <RemoveButton onClick={remove} />}
          </span>
        ) : (
          <Button className={'me-3'} variant={'link'} onClick={onShow}>
            {emptyLabel || this.renderEmpty()}
          </Button>
        )}

        {error ? (
          name ? (
            <Form.Control.Feedback type={'invalid'}>
              <ValidationError name={name as any} touched />
            </Form.Control.Feedback>
          ) : (
            <div>
              <Alert variant={'danger'}>{error}</Alert>
            </div>
          )
        ) : undefined}

        <Modal size={'xl'} onHide={onClose} show={show} scrollable>
          <Modal.Header closeButton>
            <Modal.Title>{title || this.renderEmpty()}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {addForm ? (
              <Tabs
                id={'entity-selector-tabs'}
                className={'mb-5'}
                activeKey={activeTab}
                onSelect={(k: string | null) => this.setState({ activeTab: k as 'search' })}
              >
                <Tab eventKey={'search'} title={'Search'}>
                  {searchContent}
                </Tab>
                <Tab eventKey={'add'} title={'Add'}>
                  {addForm}
                </Tab>
              </Tabs>
            ) : (
              searchContent
            )}
          </Modal.Body>
          <Modal.Footer>
            <Right>{onRemove && selected && <RemoveButton onClick={remove}>Remove</RemoveButton>}</Right>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }

  renderAsRadios() {
    const { onRemove, name, error, className } = this.props;
    const { activeTab, selected, updateResults } = this.state;

    const addForm = this.renderAddForm();

    const searchContent = (
      <PagedList<Entity, Filter>
        key={updateResults}
        infinite
        request={this.search.bind(this)}
        renderItem={(item, index) => {
          const isSelected = (selected as any)?.id === (item as any)?.id;

          const isDisabled = this.isDisabled(item);

          return (
            <Form.Check
              label={<span className="cursor-pointer">{this.renderItem(item)}</span>}
              key={index}
              type={'radio'}
              id={`${name?.toString()}-${(item as any)?.id || index}`}
              disabled={isDisabled}
              checked={isSelected}
              onChange={() => {
                this.select(item);
              }}
            />
          );
        }}
        emptyMessage={<em className={'text-muted'}>No items.</em>}
        staticItems={this.getNewItems()}
      />
    );

    const remove = () => {
      this.setState({
        show: false,
        selected: this.props.value !== undefined ? selected : undefined,
      });

      onRemove!();
    };

    return (
      <span className={`entity-selector align-middle ${className || ''}`}>
        {name ? (
          <Form.Control name={name} type={'hidden'} value={(selected as any)?.id || ''} className={error ? 'is-invalid' : ''} />
        ) : undefined}

        {error ? (
          name ? (
            <Form.Control.Feedback type={'invalid'}>
              <ValidationError name={name as any} touched />
            </Form.Control.Feedback>
          ) : (
            <div>
              <Alert variant={'danger'}>{error}</Alert>
            </div>
          )
        ) : undefined}

        {addForm ? (
          <>
            {searchContent}
            <Button variant={'light'} onClick={() => this.setState({ activeTab: 'add' })}>
              Add
            </Button>
            <Modal size={'xl'} onHide={() => this.setState({ activeTab: 'search' })} show={activeTab === 'add'} scrollable>
              <Modal.Header closeButton>
                <Modal.Title>Add</Modal.Title>
              </Modal.Header>
              <Modal.Body>{addForm}</Modal.Body>
            </Modal>
          </>
        ) : (
          searchContent
        )}

        <Right>{onRemove && selected && <RemoveButton onClick={remove}>Remove</RemoveButton>}</Right>
      </span>
    );
  }
}
