import { ContentCol, HeaderCol } from '@edgebox/react-components';
import { plainToInstance } from 'class-transformer';
import { validateSync, ValidationError } from 'class-validator';
import React, { useState } from 'react';
import { Alert, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router';

// TODO: Move into component library.

function urlSearchParamsToObject(entries: URLSearchParams): any {
  const result: any = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tuple
    result[key] = value;
  }
  return result;
}

function renderErrors(paramErrors: ValidationError[]) {
  return (
    <Alert variant={'danger'}>
      <p className={'fw-bold'}>
        We are sorry, there's an issue loading this page. Please provide the URL and the following technical information to your support.
      </p>
      <ul>
        {paramErrors.map((e, index) => (
          <li key={index}>
            {e.property}:
            <ul>
              {Object.keys(e.constraints || {}).map((key, index) => (
                <li key={index}>{e.constraints![key]}</li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </Alert>
  );
}

export interface IQueryParamsProp<Params extends object> {
  params: Params;
}
export function withQueryParams<Props, Params extends object>(
  Component: React.ComponentType<Props & IQueryParamsProp<Params>>,
  ParamsClass: { new (): Params }
): React.ComponentType<Props> {
  return function WithQueryParams(props: Props) {
    const location = useLocation();
    const [state] = useState(() => {
      const paramsRaw = new URLSearchParams(location.search);
      const params = plainToInstance(ParamsClass, urlSearchParamsToObject(paramsRaw));
      const paramErrors = validateSync(params);
      return {
        paramsRaw,
        params,
        paramErrors,
      };
    });

    const { params, paramsRaw, paramErrors } = state;
    const paramKeys = [...paramsRaw.keys()];
    const debug = paramsRaw.get('debug') === 'yes' || process.env.REACT_APP_DEBUG === 'yes';

    return (
      <Container>
        {debug && (
          <>
            <h3>Params (debug)</h3>
            {paramKeys.length ? (
              paramKeys.map((k) => (
                <Row key={k}>
                  <HeaderCol>{k}</HeaderCol>
                  <ContentCol>{JSON.stringify(paramsRaw.get(k))}</ContentCol>
                  <ContentCol>{typeof params[k as keyof Params]}</ContentCol>
                </Row>
              ))
            ) : (
              <Alert variant={'light'}>None.</Alert>
            )}
            <h3>Content</h3>
          </>
        )}

        {paramErrors?.length ? renderErrors(paramErrors) : <Component {...props} params={state.params} />}
      </Container>
    );
  };
}
