import React, { Component } from 'react';
import './App.css';
import { ApiContext, ApiProvider, ContentSyncPageLoading, IDataComponentConfiguration } from './common/index';
import { ILocationProp, INavigateProp, withLocationAndNavigate } from './components/RouterHelper';
import { SectionSwitch } from './components/SectionSwitch';
import { API_DOMAIN } from './constants';
import { AppConfiguration, IAppConfiguration } from '@edgebox/react-components';
import { ToastContainer } from 'react-toastify';
import './styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import { Auth0Context, Auth0Provider } from '@auth0/auth0-react';
import { Auth0AuthenticationProvider } from './components/Auth0AuthenticationProvider';

interface IExternalProps {}
interface IProps extends IExternalProps, INavigateProp, ILocationProp {}

const PUBLIC_PATHS = ['/', '/user/logout', '/login', '/register'];

type IAppConfig = IAppConfiguration<IDataComponentConfiguration>;

const AppConfigurationProvider = AppConfiguration.Provider as React.Provider<IAppConfig>;

export class AppClass extends Component<IProps, {}> {
  private appConfiguration: IAppConfig = {
    apiDomain: API_DOMAIN,
  };

  public render() {
    // Immediate redirect.
    if (this.props.location.pathname.substring(0, 3) === '/i/') {
      const invitation = this.props.location.pathname.substring(3);
      const url = `//${API_DOMAIN}/authentication/invitation?jwt=${invitation}`;
      window.localStorage.setItem('invitation', invitation);

      window.location.href = url;

      return <div>Redirecting...</div>;
    }

    const login = this.props.location.pathname === '/login';
    const register = this.props.location.pathname === '/register';

    return (
      <>
        <AppConfigurationProvider value={this.appConfiguration}>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_ISSUER_DOMAIN!}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
            authorizationParams={{
              redirect_uri: window.location.origin,
              audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            }}
            cacheLocation="localstorage"
            onRedirectCallback={(appState) => {
              this.props.navigate(
                ['/login', '/register'].includes(appState?.returnTo as string) ? '/' : appState?.returnTo || window.location.pathname
              );
            }}
          >
            {login || register ? (
              <Auth0Context.Consumer>
                {(auth0) => {
                  auth0.loginWithRedirect({
                    authorizationParams: {
                      screen_hint: register ? 'signup' : undefined,
                    },
                  });
                  return <ContentSyncPageLoading />;
                }}
              </Auth0Context.Consumer>
            ) : (
              <Auth0AuthenticationProvider requireAuthentication={!PUBLIC_PATHS.includes(this.props.location.pathname)}>
                <ApiProvider appConfiguration={this.appConfiguration}>
                  <ApiContext.Consumer>
                    {(api) => (!api || api.loading ? <ContentSyncPageLoading /> : <SectionSwitch />)}
                  </ApiContext.Consumer>
                </ApiProvider>
              </Auth0AuthenticationProvider>
            )}
          </Auth0Provider>
        </AppConfigurationProvider>
        <ToastContainer />
      </>
    );
  }
}

export const App = withLocationAndNavigate<IExternalProps>(AppClass);
