import mixpanel from 'mixpanel-browser';
import React from 'react';

/* The following are taken from: https://github.com/piotrwitek/utility-types/blob/master/src/mapped-types.ts */

/**
 * SetDifference (same as Exclude)
 * @desc Set difference of given union types `A` and `B`
 * @example
 *   // Expect: "1"
 *   SetDifference<'1' | '2' | '3', '2' | '3' | '4'>;
 *
 *   // Expect: string | number
 *   SetDifference<string | number | (() => void), Function>;
 */
export type SetDifference<A, B> = A extends B ? never : A;

/**
 * SetComplement
 * @desc Set complement of given union types `A` and (it's subset) `A1`
 * @example
 *   // Expect: "1"
 *   SetComplement<'1' | '2' | '3', '2' | '3'>;
 */
export type SetComplement<A, A1 extends A> = SetDifference<A, A1>;

/**
 * Subtract
 * @desc From `T` remove properties that exist in `T1` (`T1` has a subset of the properties of `T`)
 * @example
 *   type Props = { name: string; age: number; visible: boolean };
 *   type DefaultProps = { age: number };
 *
 *   // Expect: { name: string; visible: boolean; }
 *   type RestProps = Subtract<Props, DefaultProps>;
 */
export type Subtract<T extends T1, T1 extends object> = Pick<T, SetComplement<keyof T, keyof T1>>;

export enum TrackingEvent {
  // Front page
  ViewedFrontPage = 'viewed front page',

  // Registration
  StartSignUpAsCustomer = 'started sign up as customer',
  SignedUpAsNewCustomer = 'signed up as new customer',
  SavedPersonalProfile = 'saved personal profile',
  SavedCompanyProfile = 'saved company profile',

  // Account
  InviteCustomerMember = 'invite customer member',

  // Login
  StartLogin = 'started login',
  LoggedIn = 'logged in',
}

interface ITrackEvent {
  type: TrackingEvent;
}

type PlainTrackEvents =
  | TrackingEvent.ViewedFrontPage
  | TrackingEvent.SavedPersonalProfile
  | TrackingEvent.StartSignUpAsCustomer
  | TrackingEvent.SignedUpAsNewCustomer
  | TrackingEvent.StartLogin
  | TrackingEvent.LoggedIn
  | TrackingEvent.SavedCompanyProfile;

interface IPlainEvent extends ITrackEvent {
  type: PlainTrackEvents;
}

interface IUserEvent extends ITrackEvent {
  type: TrackingEvent.InviteCustomerMember;

  'user id': string;
}

export type AnyTrackingEvent = IPlainEvent | IUserEvent;

interface ITrackingContext {
  track(event: AnyTrackingEvent | PlainTrackEvents, clb?: () => void): void;
  trackAsync(event: AnyTrackingEvent | PlainTrackEvents, clb?: () => void): Promise<void>;

  startTimer(event: TrackingEvent): void;

  callback(event: AnyTrackingEvent, clb: (...args: any) => void): (...args: any) => void;
}

export const Tracking: ITrackingContext =
  typeof mixpanel === 'undefined'
    ? {
        track(event: AnyTrackingEvent | PlainTrackEvents, clb?: () => void) {},
        callback(event: AnyTrackingEvent, clb: (...args: any) => void): (...args: any) => void {
          return clb;
        },
        startTimer(event: TrackingEvent) {},
        trackAsync: async (event: AnyTrackingEvent | PlainTrackEvents, clb?: () => void): Promise<void> => {},
      }
    : {
        startTimer(event: TrackingEvent): void {
          mixpanel.time_event(event);
        },

        track(event: AnyTrackingEvent | PlainTrackEvents, clb?: () => void): void {
          Tracking.trackAsync(event, clb);
        },
        trackAsync(event: AnyTrackingEvent | PlainTrackEvents, clb?: () => void): Promise<void> {
          return new Promise((resolve, reject) => {
            //console.log("tracking", event);

            let name: TrackingEvent;
            let details: any = undefined;
            if (typeof event === 'string') {
              name = event;
            } else {
              const { type, ...data } = event;
              name = type;
              details = data;
            }

            mixpanel.track(name, details, () => {
              if (clb) {
                clb();
              }

              resolve();
            });

            const followUps = {
              [TrackingEvent.StartSignUpAsCustomer]: TrackingEvent.SignedUpAsNewCustomer,
              [TrackingEvent.StartLogin]: TrackingEvent.LoggedIn,
            };
            if (followUps.hasOwnProperty(name)) {
              const followUpEvent = followUps[name as TrackingEvent.StartSignUpAsCustomer];
              if (Array.isArray(followUpEvent)) {
                followUpEvent.forEach((additionalEvent) => mixpanel.time_event(additionalEvent));
              } else {
                mixpanel.time_event(followUpEvent);
              }
            }
          });
        },

        callback(event: AnyTrackingEvent, clb: (...args: any) => void) {
          return (...args: any) => {
            Tracking.track(event, () => {
              clb(...args);
            });
          };
        },
      };

mixpanel.init('a09d8880405bdb3bf3e5abed2b721cf1');

export const TrackingContext: React.Context<ITrackingContext> = React.createContext<ITrackingContext>(Tracking);
