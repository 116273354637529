import { ClientCustomerDraft, ClientCustomerEntity, ClientUserEntity } from '@edgebox/api-rest-client';
import { Country, StaticReference } from '@edgebox/data-definition-kit';
import { Currency, CustomerPaymentStatus, CustomerRole, CustomerVatValidationStatus } from '@edgebox/data-definitions';
import { MultiStepForm, Right } from '@edgebox/react-components';
import { IFormStep, NextStepCallback } from '@edgebox/react-components/dist/components/Form/MultiStepForm';
import { CustomerStatus, SalesRegion } from '@edgebox/sync-core-data-definitions';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { Button, Image } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { ApiComponent, CustomerForm, IApiComponentState, UserForm } from '../../common/index';
import { Tracking, TrackingEvent } from '../../contexts/TrackingContext';
import RegistrationImage from '../../images/undraw_To_the_stars_qhyy.svg';
import LogoImage from '../../images/CS-Logo-Positive-(Primary).png';

interface IProps {
  onDone: (user: ClientUserEntity, customer: ClientCustomerEntity) => void;
}

interface IState extends IApiComponentState {
  customer?: ClientCustomerDraft;
  personOnly?: boolean;
  done?: boolean;
}

export class CustomerRegistration extends ApiComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.renderPerson = this.renderPerson.bind(this);
    this.renderCustomer = this.renderCustomer.bind(this);
  }

  async load(): Promise<Partial<IState>> {
    let customer: ClientCustomerDraft | undefined = await this.getCurrentCustomer();

    if (!customer) {
      customer = new ClientCustomerDraft({
        vatValidationStatus: CustomerVatValidationStatus.AutomaticValidationPending,
        region: SalesRegion.Europe,
        name: '',
        billingCurrency: Currency.EUR,
        billingAddress: {
          name: '',
          city: '',
          zip: '',
          country: Country.Germany,
          street: '',
        },
        paymentStatus: CustomerPaymentStatus.None,
        status: CustomerStatus.Active,
      });
    }

    const personOnly = !!(customer as any).id;

    return {
      customer,
      personOnly,
    };
  }

  public renderPerson(step: number, next?: NextStepCallback) {
    const person = this.api.currentUser!;

    const save = async (draft: ClientUserEntity) => {
      const updatedUser = await this.api.authentication.users.update(draft);
      Tracking.track({ type: TrackingEvent.SavedPersonalProfile });

      this.api.currentUser = updatedUser;

      if (next) {
        next();
      } else {
        this.setState({
          done: true,
        });
        this.props.onDone(this.api.currentUser!, (await updatedUser.customer!.get()) as ClientCustomerEntity);
      }
    };

    return (
      <UserForm footer={'multi-step'} mode={'registration'} entity={person} onSave={save} name={' '}>
        <Right>
          <Button variant={'primary'} type={'submit'}>
            Continue
          </Button>
        </Right>
      </UserForm>
    );
  }

  public renderCustomer(step: number, next?: NextStepCallback) {
    const customer = this.state.customer!;

    const save = async (draft: ClientCustomerDraft) => {
      const updatedCustomer = await this.api.billing.customers.updateSelf(draft);
      Tracking.track(TrackingEvent.SavedCompanyProfile);
      if (typeof mixpanel !== 'undefined') {
        (mixpanel as any).get_group('customer', updatedCustomer.id).set({
          name: updatedCustomer.name,
        });
      }

      this.api.currentUser!.customer = new StaticReference(updatedCustomer);

      this.setState({
        customer: updatedCustomer,
        done: true,
      });
      this.props.onDone(this.api.currentUser!, updatedCustomer);
    };

    return <CustomerForm footer={'multi-step'} mode={'registration'} entity={customer} onSave={save}></CustomerForm>;
  }

  public render() {
    const { done, customer, personOnly } = this.state;

    if (!customer) {
      return this.renderRequest();
    }

    if (done) {
      // Handled by the parent component when .onDone() is called.
      return null;
    }

    const steps: IFormStep<any>[] = [
      {
        name: 'Personal profile',
        render: this.renderPerson,
        saveTemporarily: async () => {},
      },
    ];

    if (this.api.currentUser?.customerRoles?.includes(CustomerRole.Owner) && !personOnly) {
      steps.push({
        name: 'Company profile',
        render: this.renderCustomer,
        saveTemporarily: async () => {},
      });
    }

    return (
      <>
        <Container className={'content'}>
          <div className="text-center" style={{ width: '100%', height: '100%' }}>
            <Image src={RegistrationImage} style={{ maxWidth: '100%', maxHeight: '100%' }} className="ps-5 pe-3 pt-3" />
            <div style={{ height: '50px', marginTop: '-60px' }}>
              <Image src={LogoImage} style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </div>
          </div>
        </Container>

        <MultiStepForm<any> size={'medium'} steps={steps} />
      </>
    );
  }
}
