import { ExternalLink, InfoIcon } from '@edgebox/react-components';
import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight';
import ConfigureContentSyncMenu from '../../images/ConfigureContentSyncMenu.png';
import CheckContentSyncRequirements from '../../images/undraw_check_boxes_m3d0.svg';

const INSTALL_CODE = `# Use composer to automatically resolve all dependencies
composer require drupal/cms_content_sync:~3.0

# Now enable the module
drush en cms_content_sync`;

const PUBLIC_IPS_DOCS = 'https://support.content-sync.io/support/solutions/articles/80000811241-technical-requirements';
const TECHNICAL_REQUIREMENTS_DOCS = 'https://support.content-sync.io/support/solutions/articles/80000811241-technical-requirements';

export class GetStartedInstructions extends React.Component {
  render() {
    return (
      <>
        <Row className={'mt-5'}>
          <Col xs={6} className={'ps-5 pt-3'}>
            <Image src={CheckContentSyncRequirements} height={150} />
          </Col>
          <Col xs={6}>
            <h3>1. Check the requirements</h3>
            <ul>
              <li>
                Use with <strong>Drupal 9.0+</strong> and <strong>PHP 8.0+</strong>.
              </li>
              <li>Entity types should be identical on all connected sites.</li>
            </ul>
            These are the most important requirements. For all the details see{' '}
            <ExternalLink to={TECHNICAL_REQUIREMENTS_DOCS}>here</ExternalLink>.
          </Col>
        </Row>

        <Row className={'mt-5'}>
          <Col xs={6}>
            <h3>2. Install the module</h3>
            <ul>
              <li>
                Add the <ExternalLink to={'https://www.drupal.org/project/cms_content_sync'}>Content Sync module</ExternalLink> to your
                site.
              </li>
              <li>
                On localhost sites, please enable the submodule <em>cms_content_sync_private_environment</em>.
              </li>
              <li>
                On protected sites, please whitelist <ExternalLink to={PUBLIC_IPS_DOCS}>these IPs</ExternalLink>.
              </li>
            </ul>
          </Col>
          <Col xs={6}>
            <SyntaxHighlighter language={'bash'}>{INSTALL_CODE}</SyntaxHighlighter>
          </Col>
        </Row>

        <Row className={'mt-5'}>
          <Col xs={6}>
            <Image src={ConfigureContentSyncMenu} height={150} style={{ boxShadow: '3px 3px 3px rgba(0,0,0,.5)' }} />
          </Col>
          <Col xs={6}>
            <h3>3. Connect your site</h3>
            <ul>
              <li>
                Open the Content Sync settings on the sites you want to connect and click <em>Register</em>.<br />
              </li>
              <li>
                If you don't have a subscription yet, just select <em>Trial</em> and continue (no credit card required).
              </li>
            </ul>
          </Col>
        </Row>

        {/*<Row>
          <Col xs={6}>
            <h3>4. Create your first Pool</h3>
            <ul>
              <li>Pools separate content logically.</li>
              <li>Each site can connect to any number of Pools.</li>
              <li>If you have already tagged your content, just create a <em>default</em> Pool and use your tags instead.</li>
              <li>For most use-cases, you will only need one Pool.</li>
            </ul>
          </Col>
          <Col xs={6}>
            <SyntaxHighlighter language={"shell"}>
              {INSTALL_CODE}
            </SyntaxHighlighter>
          </Col>
        </Row>

        <Row>
          <Col xs={6}>
            <SyntaxHighlighter language={"shell"}>
              {INSTALL_CODE}
            </SyntaxHighlighter>
          </Col>
          <Col xs={6}>
            <h3>4. Create your first Flow</h3>
            <ul>
              <li>Flows define the rules for pushing and pulling content.</li>
              <li>Each site can create any number of Flows.</li>
              <li>You can re-use Flows between sites by using Drupal's config management.</li>
              <li>See <ExternalLink to={FLOW_SETUP_DOCS}>here</ExternalLink> for details on how to configure different use-cases.</li>
            </ul>
          </Col>
        </Row>*/}
      </>
    );
  }
}
