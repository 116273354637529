import { ButtonLink } from '@edgebox/react-components';
import React from 'react';
import { ContentBox } from '../../Shared/ContentBox';
import { PagedContractList } from '../../Shared/PagedContractList';
import { HeadlineWithBreadcrumbNavigation } from '../../../common/components/BreadcrumbNavigation';

export class ContractList extends React.Component {
  async load() {
    return {};
  }

  render() {
    return (
      <>
        <HeadlineWithBreadcrumbNavigation className="ms-2">Subscriptions</HeadlineWithBreadcrumbNavigation>

        <PagedContractList
          fallBackToInactive
          emptyMessageWithNoFilters={
            <>
              <ContentBox className="px-4 py-4">
                <em className="text-muted">You have no active subscriptions yet.</em>
                <div className="mt-4">
                  <ButtonLink to={'/'} variant={'primary'}>
                    Connect your first site
                  </ButtonLink>
                </div>
              </ContentBox>
            </>
          }
        />
      </>
    );
  }
}
