import React, { useEffect, useState } from 'react';

export function WithPromise<Type>(props: { promise: Promise<Type>; children: (t: Type) => React.ReactElement }) {
  const [state, setState] = useState<Type | undefined>(undefined);
  useEffect(() => {
    props.promise.then((t) => setState(t));
  }, [props.promise, setState]);
  if (state) {
    return props.children(state);
  }
  return null;
}
