import { InternalId } from '@edgebox/data-definition-kit';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { ILocationProp, INavigateProp, withLocation, withNavigate } from '../../components/RouterHelper';
import { Link } from 'react-router-dom';
import { ApiContext } from '../contexts';
import { SyncCoreDataDefinitionsEnumTranslator } from '@edgebox/sync-core-data-definitions';

const BREADCRUMB_LOADING = '...';

function Breadcrumb({ parts, active }: { parts: string[]; active?: boolean }) {
  const api = useContext(ApiContext);

  let [name, setName] = useState('');

  useEffect(() => {
    if (parts[0] === 'subscriptions' && parts.length === 2) {
      setName(BREADCRUMB_LOADING);
      api?.billing.contractRevisions.getMostRecentForContract(parts[1] as InternalId).then(({ current }) => {
        if (current) {
          setName(
            `${SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue(
              'Product',
              current.product
            )} ${SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('Package', current.packageType)}`
          );
        }
      });
    } else if (parts[0] === 'sites' && parts.length === 2 && parts[1] !== 'register-multiple') {
      setName(BREADCRUMB_LOADING);
      api?.billing.sites.item(parts[1] as InternalId).then((entity) => {
        if (entity) {
          setName(entity.name);
        }
      });
    } else if (parts[0] === 'projects' && parts.length === 4 && ['sites', 'dashboard', 'content', 'updates'].includes(parts[2])) {
      setName(BREADCRUMB_LOADING);
      api?.billing.sites.item(parts[3] as InternalId).then((entity) => {
        if (entity) {
          setName(entity.name);
        }
      });
    } else if (parts[0] === 'sync-cores' && parts.length === 2) {
      setName(BREADCRUMB_LOADING);
      api?.syndication.syncCores.item(parts[1] as InternalId).then((entity) => {
        if (entity) {
          setName(entity.name);
        }
      });
    } else if (parts[0] === 'projects' && parts.length === 2) {
      setName(BREADCRUMB_LOADING);
      api?.syndication.projects.item(parts[1] as InternalId).then((entity) => {
        if (entity) {
          setName(entity.name);
        }
      });
    } else if (parts[0] === 'checkout' && parts.length === 2) {
      setName(BREADCRUMB_LOADING);
      api?.billing.baskets.item(parts[1] as InternalId).then((entity) => {
        if (entity) {
          entity.pricing.get().then((pricing) => {
            if (pricing) {
              setName(
                `${SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue(
                  'Product',
                  pricing.product
                )} ${SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('Package', pricing.packageType)}`
              );
            }
          });
        }
      });
    } else {
      const lastPart = parts[parts.length - 1];
      const guessedName = lastPart.substring(0, 1).toLocaleUpperCase() + lastPart.substring(1).replace(/-/g, ' ');

      setName(guessedName);
    }
  }, [parts, setName]);

  return (
    <Link to={`/${parts.join('/')}`} className={`${active ? 'text-dark text-decoration-none' : ''}`}>
      {name}
    </Link>
  );
}

function BreadcrumbNavigationComponent(props: INavigateProp & ILocationProp) {
  let parts = props.location.pathname.substring(1).split('/');
  if (parts.length < 1) {
    return null;
  }

  if (parts[0] === 'subscriptions' && parts[2] === 'revisions') {
    const [p1, p2, , , ...pn] = parts;
    parts = [p1, p2, ...pn];
  }

  return (
    <span className="d-inline-block ms-1">
      {parts.map((c, index) => (
        <React.Fragment key={index}>
          <Breadcrumb parts={parts.slice(0, index + 1)} active={index === parts.length - 1} />
          {index < parts.length - 1 ? <span className="ms-2 me-2"> / </span> : null}
        </React.Fragment>
      ))}
    </span>
  );
}

export const BreadcrumbNavigation = withNavigate<{}>(withLocation<INavigateProp>(BreadcrumbNavigationComponent));

export function HeadlineWithBreadcrumbNavigation({ children, className }: { className?: string } & PropsWithChildren) {
  return (
    <>
      <div className={`${className}`}>
        <BreadcrumbNavigation />
        <h1 className={`mt-0`}>{children}</h1>
      </div>
    </>
  );
}
