import { ClientCustomerEntity, ClientDiscountEntity } from '@edgebox/api-rest-client';
import { InternalId, IPagedListResponse } from '@edgebox/data-definition-kit';
import { DataDefinitionsEnumTranslator, DiscountType, UserType } from '@edgebox/data-definitions';
import { ButtonCheckbox } from '@edgebox/react-components';
import React from 'react';
import { Badge } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { EntitySelector, IEntitySelectorProps, IEntitySelectorState } from '../../Form/EntitySelector';
import { EnumAsDropdown } from '../../Form/EnumAsDropdown';
import { DataDefinitionsEnumValue } from '../../Localization/DataDefinitionsEnumValue';
import { RenderFiltersCallback } from '../../PagedList';
import { DiscountStatusBadge } from '../Badges/DiscountStatusBadge';
import { CustomerLink } from '../Links/CustomerLink';
import { CustomerSelector } from './CustomerSelector';

interface IProps extends IEntitySelectorProps<ClientDiscountEntity> {
  forCustomer?: InternalId;
}

interface IFilter {
  includeRetired?: boolean;
  type?: DiscountType;
  customerId?: InternalId;
}

interface IState extends IEntitySelectorState<ClientDiscountEntity> {
  selectedCustomer?: ClientCustomerEntity;
  includeRetired?: boolean;
  type?: DiscountType;
}

export class DiscountSelector extends EntitySelector<ClientDiscountEntity, IProps, IState, IFilter> {
  renderEmpty() {
    return 'Select discount';
  }

  renderCurrentValue(selected: ClientDiscountEntity): React.ReactElement | string {
    return selected.name;
  }

  async search(page: number, query?: IFilter): Promise<IPagedListResponse<ClientDiscountEntity>> {
    const { forCustomer } = this.props;

    return await this.api.billing.discounts.search({ page }, query?.type, forCustomer || query?.customerId, query?.includeRetired);
  }

  renderFilters(): undefined | RenderFiltersCallback<IFilter> {
    const { selectedCustomer, includeRetired, type } = this.state;

    const isInternal = this.api.currentUser?.type === UserType.Internal;

    return (onChange) => {
      return [
        <EnumAsDropdown<ClientDiscountEntity, DiscountType>
          enumValues={DataDefinitionsEnumTranslator.translateEnum('DiscountType')}
          currentValue={type!}
          name={'type'}
          label={'Type'}
          allowEmpty
          emptyName={'Any'}
          setValue={(name, type) => {
            this.setState({ type });
            onChange('type', type);
          }}
        />,
        ...(isInternal
          ? [
              <CustomerSelector
                name={'customer'}
                value={selectedCustomer}
                onSelected={(selectedCustomer) => {
                  this.setState({ selectedCustomer });
                  onChange('customerId', selectedCustomer.id);
                }}
                onRemove={() => {
                  this.setState({ selectedCustomer: undefined });
                  onChange('customerId', undefined);
                }}
              />,
            ]
          : []),
        <ButtonCheckbox
          checked={!!includeRetired}
          onToggle={() => {
            this.setState({ includeRetired: !includeRetired });
            onChange('includeRetired', !includeRetired);
          }}
        >
          Include retired
        </ButtonCheckbox>,
      ];
    };
  }

  renderAddForm(): React.ReactElement | null {
    // TODO: Render pricing form and pass on the customerId. First step of the DiscountForm should be to select another pricing entity to copy the initial values from.
    return null;
  }

  renderItem(entity: ClientDiscountEntity): React.ReactElement {
    return (
      <Row>
        <Col>
          <h3>
            {entity.name}{' '}
            <Badge bg={'light'}>
              <DataDefinitionsEnumValue enumName={'DiscountType'} keyName={entity.type} />
            </Badge>{' '}
            <DiscountStatusBadge status={entity.status} />
          </h3>
          <div className={'text-muted'}>
            {entity.customer?.getId() ? (
              <div>
                For customer <CustomerLink entityId={entity.customer.getId()!} />
              </div>
            ) : undefined}
          </div>
        </Col>
      </Row>
    );
  }

  renderItemAsText(entity: ClientDiscountEntity): string {
    return entity.name;
  }
}
