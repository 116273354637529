import { ClientCustomerEntity } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import React from 'react';
import { ApiComponent, IApiComponentState } from '../common/index';

interface IProps {
  entityId?: InternalId;
  children: (entity?: ClientCustomerEntity) => React.ReactNode;
}

interface IState extends IApiComponentState {
  entity?: ClientCustomerEntity;
  loaded?: boolean;
}

export class WithCustomer extends ApiComponent<IProps, IState> {
  async load() {
    try {
      if (this.props.entityId) {
        const entity = await this.api.billing.customers.item(this.props.entityId);
        return {
          loaded: true,
          entity,
        };
      }
    } catch (e) {}
    return {
      loaded: true,
    };
  }

  render() {
    const { children } = this.props;
    const { loaded, entity } = this.state;

    if (!loaded) {
      return this.renderRequest();
    }

    return children(entity);
  }
}
