import React from 'react';
import Badge from 'react-bootstrap/cjs/Badge';

export class EnvironmentBadge extends React.Component<{}, {}> {
  render() {
    const environment = `${process.env.REACT_APP_ENVIRONMENT_TYPE}`;

    const renderBadge = (text: string) => (
      <Badge bg={'danger'} className={'pt-1 pb-1 ps-2 pe-2 me-1'}>
        {text}
      </Badge>
    );

    if (environment === 'test' || environment === 'local') {
      return renderBadge('LOCAL');
    } else if (environment === 'stage') {
      return renderBadge('STAGE');
    }

    return null;
  }
}
