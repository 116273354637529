import { EnumTranslation } from '@edgebox/data-definitions';
import { FormRow, ValidationError } from '@edgebox/react-components';
import React from 'react';
import { FormSelect } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { EntityFormRow } from './EntityFormRow';

interface IProps<Type, Enum extends string> {
  enumValues: EnumTranslation<Enum>;
  currentValue: Enum;
  name: keyof Type;
  label: string;
  error?: string;
  renderValue?: (value: Enum) => React.ReactNode;
  setValue: (name: keyof Type, value?: Enum) => void;
  allowEmpty?: boolean;
  emptyName?: string;
  showStatic?: boolean;
  help?: React.ReactNode;
  withoutEntity?: boolean;
}

export class EnumAsDropdown<Type, Enum extends string> extends React.Component<IProps<Type, Enum>> {
  render(): React.ReactElement {
    const { name, enumValues, currentValue, setValue, label, renderValue, allowEmpty, emptyName, showStatic, error, help, withoutEntity } =
      this.props;

    const keys = Object.keys(enumValues);

    const edit = () => (
      <>
        <FormSelect
          value={currentValue}
          onChange={(e: React.ChangeEvent<any>) => {
            setValue(name, e.target.value ? (e.target.value as Enum) : undefined);
          }}
        >
          {allowEmpty ? (
            <option key={''} value={''} id={`${name.toString()}-`}>
              {emptyName || 'None'}
            </option>
          ) : undefined}
          {keys.map((key) => {
            const enumKey = key as Enum;
            return (
              <option key={key} value={enumKey} id={`${name.toString()}-${key}`}>
                {enumValues[enumKey]}
              </option>
            );
          })}
        </FormSelect>
        {error && (
          <Form.Control.Feedback type={'invalid'}>
            <ValidationError name={name as any} touched />
          </Form.Control.Feedback>
        )}
      </>
    );

    if (withoutEntity) {
      return (
        <FormRow name={name as string} label={label}>
          {edit()}
        </FormRow>
      );
    }

    return (
      <EntityFormRow
        name={name}
        label={label}
        help={help}
        edit={!showStatic ? edit : undefined}
        view={currentValue ? () => (renderValue ? renderValue(currentValue) : enumValues[currentValue]) : undefined}
      />
    );
  }
}
