import { InternalId } from '@edgebox/data-definition-kit';
import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { BackendContractList } from './BackendContractList';
import { BackendProposalList } from './BackendProposalList';
import { BackendPricingList } from './BackendPricingList';
import { BackendDiscountList } from './BackendDiscountList';
import { INavigateProp, IParamsProp, withParamsAndNavigate } from '../../RouterHelper';
import { ContentBox } from '../../Shared/ContentBox';

export interface IRouteProps extends Record<string, string | undefined> {
  tab?: string;
  id?: InternalId;
}
export interface IExternalProps {}
export interface IProps extends IExternalProps, IParamsProp<IRouteProps>, INavigateProp {}

interface IState {}

class BackendContractsComponent extends React.Component<IProps, IState> {
  render() {
    const activeTab = this.props.params.tab || 'contracts';
    const id = this.props.params.id;

    return (
      <>
        <Tabs
          activeKey={activeTab}
          onSelect={(k: string | null) => this.props.navigate('/backend/billing/contracts' + (k === 'contracts' ? '' : '/' + k))}
        >
          <Tab title="Contracts" eventKey={'contracts'}>
            <ContentBox>
              <BackendContractList />
            </ContentBox>
          </Tab>
          <Tab title="Proposals" eventKey={'proposals'}>
            <ContentBox>
              <BackendProposalList id={id} />
            </ContentBox>
          </Tab>
          <Tab title="Pricing" eventKey={'pricing'}>
            <ContentBox>
              <BackendPricingList id={id} />
            </ContentBox>
          </Tab>
          <Tab title="Discounts" eventKey={'discounts'}>
            <ContentBox>
              <BackendDiscountList id={id} />
            </ContentBox>
          </Tab>
        </Tabs>
      </>
    );
  }
}

export const BackendContracts = withParamsAndNavigate<IExternalProps, IRouteProps>(BackendContractsComponent);
