import { ClientContractEntity, ClientContractRevisionEntity } from '@edgebox/api-rest-client';
import React from 'react';
import { Button } from 'react-bootstrap';
import { ApiComponent, IApiComponentState } from '../../common/index';

interface IProps {
  entity: ClientContractEntity;
  summaryOnly?: boolean;
  onEdit?: () => void;
}

interface IState extends IApiComponentState {
  currentContractRevision?: ClientContractRevisionEntity;
  loaded?: boolean;
}

export class ViewContractSites extends ApiComponent<IProps, IState> {
  async load() {
    const mostRecent = await this.api.billing.contractRevisions.getMostRecentForContract(this.props.entity.id);

    return {
      currentContractRevision: mostRecent.current,
      loaded: true,
    };
  }

  render() {
    const { entity, summaryOnly, onEdit } = this.props;
    const { loaded, currentContractRevision } = this.state;

    if (!loaded) {
      return this.renderRequest();
    }

    if (!currentContractRevision) {
      return <em>None</em>;
    }

    const activeSummary = `${entity.currentProductionSites} / ${entity.licensedSites} active sites`;
    const requiresMoreLicenses = entity.currentProductionSites > entity.licensedSites;

    if (summaryOnly) {
      return requiresMoreLicenses ? (
        <span className={requiresMoreLicenses ? 'text-danger' : undefined}>{activeSummary}</span>
      ) : (
        activeSummary
      );
    }

    return (
      <>
        <div className={requiresMoreLicenses ? 'text-danger' : undefined}>
          <strong>{activeSummary}</strong>
        </div>
        {entity.autoScaleLicenses ? (
          <div>
            {entity.minProdSites !== undefined ? (
              entity.maxProdSites ? (
                `Licenses scale automatically between ${entity.minProdSites} and ${entity.maxProdSites}.`
              ) : (
                `Licenses scale automatically with at least ${entity.minProdSites}.`
              )
            ) : entity.maxProdSites ? (
              `Licenses scale automatically to max. ${entity.maxProdSites}.`
            ) : (
              <>
                Licenses scale automatically.{' '}
                {onEdit ? (
                  <Button variant={'link'} onClick={onEdit}>
                    Set limit
                  </Button>
                ) : undefined}
              </>
            )}
          </div>
        ) : undefined}
      </>
    );
  }
}
