import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { CONTACT_URL, IMPRINT_URL, PRIVACY_URL, TERMS_AND_CONDITIONS_URL } from '../../constants';

export class DisclaimerFooter extends Component<{}, {}> {
  public render() {
    const year = new Date().getFullYear();

    return (
      <div className={'fixed-bottom'}>
        <Navbar variant="dark">
          <Container>
            <Navbar.Text>
              Made with ❤ in the digital city Darmstadt, Germany.
              <br />© Copyright {year}. All rights reserved.
            </Navbar.Text>
            <Navbar.Text>
              <Nav className="me-auto">
                <Nav.Link href={CONTACT_URL}>Contact</Nav.Link>
                <Nav.Link href={IMPRINT_URL}>Imprint</Nav.Link>
                <Nav.Link href={PRIVACY_URL}>Privacy policy</Nav.Link>
                <Nav.Link href={TERMS_AND_CONDITIONS_URL}>Terms and conditions</Nav.Link>
              </Nav>
            </Navbar.Text>
          </Container>
        </Navbar>
      </div>
    );
  }
}
