import { PageLoading } from '@edgebox/react-components';
import React, { useEffect, useState } from 'react';
import { Logo } from './Logo';
import { Alert } from 'react-bootstrap';

export function ContentSyncPageLoading() {
  const [showWaitingMessage, setShowWaitingMessage] = useState(false);
  useEffect(() => {
    let delay = setTimeout(() => setShowWaitingMessage(true), 3_000);
    return () => {
      clearTimeout(delay);
    };
  }, []);

  return (
    <PageLoading>
      <Logo />
      {showWaitingMessage && (
        <Alert variant="light" className="my-5 mx-3">
          It's taking longer than usual to load. Please wait a moment...
        </Alert>
      )}
    </PageLoading>
  );
}
