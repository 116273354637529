import React from 'react';
import { Alert } from 'react-bootstrap';
import { ApiComponent, IApiComponentState, WithMostRecentContractRevision } from '../../../common';
import { ILocationProp, INavigateProp, IParamsProp, withLocation, withParamsAndNavigate } from '../../RouterHelper';
import { ContentBox } from '../../Shared/ContentBox';
import { Embed } from '../../Shared/Embed';
import { AppContextSiteSelector, IAppContextProp, withAppContext } from '../../../common/contexts/AppContext';
import { propertyChangeReload } from '../../../common/helpers/propertyChangeReload';
import { WithPromise } from '../../../common/helpers/WithPromise';
import { HeadlineWithBreadcrumbNavigation } from '../../../common/components/BreadcrumbNavigation';
import { urlSearchParamsToObject } from '../../../common/helpers';

interface IRouteProps extends Record<string, string | undefined> {
  tab?: 'content' | 'synchronizations';
}
interface IExternalProps {
  activeTab?: 'content' | 'synchronizations';
}

interface IProps extends IExternalProps, INavigateProp, IParamsProp<IRouteProps>, IAppContextProp, JSX.IntrinsicAttributes, ILocationProp {}
interface IState extends IApiComponentState {}

class ContentDashboardComponent extends ApiComponent<IProps, IState> {
  async load() {
    return {};
  }

  render() {
    const { appContext, activeTab } = this.props;
    const { contract, site } = appContext;

    return (
      <>
        <div className="d-flex flex-row align-items-end mb-2">
          <div className="flex-grow-0 flex-shrink-0">
            <HeadlineWithBreadcrumbNavigation className="m-0 ps-1">
              <span style={{ position: 'relative', top: '12px' }}>{activeTab === 'synchronizations' ? 'Updates' : 'Content'}</span>{' '}
              <span
                className="flex-grow-0 flex-shrink-0 ms-3 mb-1 d-inline-block fs-6 fw-normal"
                style={{
                  width: '300px',
                  height: '40px',
                  position: 'relative',
                  top: site ? '-8px' : '',
                }}
              >
                <AppContextSiteSelector className="w-100" />
              </span>
            </HeadlineWithBreadcrumbNavigation>
          </div>
        </div>

        {site ? (
          <WithMostRecentContractRevision contractId={contract?.id}>
            {(revision) =>
              revision ? (
                <WithPromise promise={appContext.syncCore()}>
                  {(syncCore) =>
                    syncCore ? (
                      <Embed
                        key={appContext.siteKey + activeTab}
                        embed={activeTab === 'synchronizations' ? 'syndication-dashboard' : 'pull-dashboard'}
                        scope="configuration"
                        syncCore={syncCore}
                        site={site}
                        options={
                          activeTab === 'synchronizations'
                            ? {
                                viewOnly: true,
                                query: urlSearchParamsToObject(new URLSearchParams(this.props.location.search)),
                              }
                            : {
                                viewAll: true,
                                configurationAccess: true,
                                query: urlSearchParamsToObject(new URLSearchParams(this.props.location.search)),
                              }
                        }
                      />
                    ) : (
                      <Alert variant="light">Please select a project to continue.</Alert>
                    )
                  }
                </WithPromise>
              ) : (
                <Alert variant="light">Please select a project to continue.</Alert>
              )
            }
          </WithMostRecentContractRevision>
        ) : (
          <Alert variant="light">Please select a site to continue.</Alert>
        )}
      </>
    );
  }
}

export const ContentDashboard = withParamsAndNavigate<any, any>(
  withAppContext<any>(withLocation<any>(propertyChangeReload<any>(ContentDashboardComponent, (props) => props.appContext.siteKey)))
);
