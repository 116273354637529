import React from 'react';
import { PagedSiteList } from '../../Shared/PagedSiteList';

export class BackendSiteList extends React.Component {
  async load() {
    return {};
  }

  render() {
    return <PagedSiteList backend />;
  }
}
