import { ClientCustomerEntity, ClientSyncCoreDraft } from '@edgebox/api-rest-client';
import { StaticReference } from '@edgebox/data-definition-kit';
import { SyncCoreStatus, SyncCoreType } from '@edgebox/data-definitions';
import { AddButton, ExternalLink, Right } from '@edgebox/react-components';
import { HostingType } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import { Button, Col, Image, Modal, Row } from 'react-bootstrap';
import { ApiComponent, IApiComponentState, SyncCoreForm } from '../../../common';
import CreateNewSubscription from '../../../images/undraw_add_document_0hek.svg';
import RegisterSyncCore from '../../../images/undraw_server_down_s4lk.svg';
import { INavigateProp, withNavigate } from '../../RouterHelper';
import { ContentBox } from '../../Shared/ContentBox';
import { PagedSyncCoreList } from '../../Shared/PagedSyncCoreList';
import { HeadlineWithBreadcrumbNavigation } from '../../../common/components/BreadcrumbNavigation';

interface IProps extends INavigateProp {}

interface IState extends IApiComponentState {
  customer?: ClientCustomerEntity;
  hasActiveContract?: boolean;
  syncCoreDraft?: ClientSyncCoreDraft;
  addSyncCore?: boolean;
  reload?: boolean;
}

const SYNC_CORE_SETUP_DOCS = 'https://sync-core-on-premise.support.content-sync.io/';

class SyncCoreListComponent extends ApiComponent<IProps, IState> {
  async load() {
    const customer = await this.getCurrentCustomer();
    const onPremiseContracts = await this.api.billing.contractRevisions.search(
      undefined,
      undefined,
      customer?.id,
      undefined,
      undefined,
      HostingType.OnPremise
    );
    const syncCoreDraft = onPremiseContracts.items.length
      ? new ClientSyncCoreDraft({
          name: '',
          status: SyncCoreStatus.Active,
          type: SyncCoreType.OnPremise,
          baseUrl: '',
          customer: new StaticReference(customer),
          contract: onPremiseContracts.items[0]?.contract,
        })
      : undefined;

    const allContracts = await this.api.billing.contracts.search(undefined, undefined, customer?.id);
    const hasActiveContract = allContracts.totalNumberOfItems > 0;

    return {
      hasActiveContract,
      customer,
      syncCoreDraft,
    };
  }

  render() {
    const { customer, syncCoreDraft, reload, addSyncCore } = this.state;

    if (!customer) {
      return this.renderRequest();
    }

    const syncCoreForm = () => (
      <SyncCoreForm
        onSave={async (draft) => {
          const entity = await this.api.syndication.syncCores.create(draft);
          this.setState({
            reload: true,
            addSyncCore: false,
          });

          this.props.navigate(`/sync-cores/${entity.id}`);
        }}
        entity={syncCoreDraft!}
        mode={'create'}
      >
        <Right>
          <Button type="submit" variant="primary">
            Save
          </Button>
        </Right>
      </SyncCoreForm>
    );

    return (
      <>
        <HeadlineWithBreadcrumbNavigation className="ms-2">Sync Cores</HeadlineWithBreadcrumbNavigation>

        <PagedSyncCoreList
          key={reload ? '1' : '0'}
          notEmptyheader={
            <Right className="mb-4">
              <AddButton variant="primary" onClick={() => this.setState({ addSyncCore: true })}>
                Sync Core
              </AddButton>
            </Right>
          }
          emptyMessageWithNoFilters={
            <>
              <h2>Host your own Sync Core</h2>

              <ContentBox>
                <Row className={'mt-5'}>
                  <Col xs={6} className={'ps-5 pt-3 text-center'}>
                    <Image src={CreateNewSubscription} height={150} />
                  </Col>
                  <Col xs={6}>
                    <h3>1. Sign up for the Enterprise package</h3>
                    <ul>
                      <li>Full control over your data.</li>
                      <li>Unlimited updates.</li>
                      <li>Unlimited file storage.</li>
                      <li>Quick and personal support.</li>
                    </ul>
                    {/*
                  TODO: Test that upgrading existing contracts to Enterprise doesn't break anything.

                  {syncCoreDraft ? undefined : hasActiveContract ? (
                    <>
                      Upgrade subscription:{' '}
                      <ContractRevisionSelector
                        forCustomer={customer.id}
                        onSelected={(contractRevision) =>
                          this.props.history.push(
                            `/subscriptions/${contractRevision.contract.getId()}/revisions/${contractRevision.id}/upgrade?type=${
                              HostingType.OnPremise
                            }`
                          )
                        }
                      />
                    </>
                  ) : (
                    <PrimaryActionButton onClick={() => this.props.history.push(`/sites`)}>Connect your first site</PrimaryActionButton>
                  )}*/}
                  </Col>
                </Row>

                {syncCoreDraft ? (
                  <Row className={'mt-5'}>
                    <Col xs={6}>
                      <h3>2. Register Sync Core</h3>
                      {syncCoreForm()}
                    </Col>
                    <Col xs={6} className={'text-center'}>
                      <Image src={RegisterSyncCore} height={150} />
                    </Col>
                  </Row>
                ) : (
                  <Row className={'mt-5'}>
                    <Col xs={6}>
                      <h3>2. Register Sync Core</h3>
                      <p>
                        The Sync Core requires a Mongo database and either a local file system or S3 for file storage. In the simplest form,
                        you can just start it as a Docker container and you're good to go. For larger projects, you can switch to a
                        Kubernetes based setup for full scalability and redundancy, using the same core technologies that we use for our
                        Cloud offering.
                      </p>
                      <p>
                        Check out the <ExternalLink to={SYNC_CORE_SETUP_DOCS}>documentation</ExternalLink> for all details.
                      </p>
                    </Col>
                    <Col xs={6} className={'text-center'}>
                      <Image src={RegisterSyncCore} height={150} />
                    </Col>
                  </Row>
                )}
              </ContentBox>
            </>
          }
        />

        {syncCoreDraft && (
          <Modal show={addSyncCore} scrollable onHide={() => this.setState({ addSyncCore: undefined })}>
            <Modal.Header closeButton>
              <Modal.Title>Add Sync Core</Modal.Title>
            </Modal.Header>
            <Modal.Body>{syncCoreForm()}</Modal.Body>
          </Modal>
        )}
      </>
    );
  }
}

export const SyncCoreList = withNavigate(SyncCoreListComponent);
