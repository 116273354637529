import { ClientContractEntity, ClientPricingEntity } from '@edgebox/api-rest-client';
import { PaymentCycleType } from '@edgebox/data-definitions';
import { InfoIcon } from '@edgebox/react-components';
import { Package, Product } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import { ApiComponent, DataDefinitionsEnumValue, IApiComponentState, SyncCoreDataDefinitionsEnumValue } from '../../common';
import { ViewDiscountedPrice } from '../Customer/Syndication/ViewDiscountedPrice';
import { ComparisonTable, IComparisonRow } from './ComparisonTable';
import { SwitchButton } from './SwitchButton';

interface IProps {
  contract?: ClientContractEntity;
  selectedPricing?: ClientPricingEntity;
  product: Product;
  onSelectPricing: (pricing: ClientPricingEntity, paymentCycle: PaymentCycleType) => void;
  onSelectEnterprise: (paymentCycle: PaymentCycleType) => void;
  paymentInterval?: PaymentCycleType;
  changePaymentInterval?: boolean;
}

interface IState extends IApiComponentState {
  packages?: ClientPricingEntity[];
  contractIsTrial?: boolean;
  paymentInterval?: PaymentCycleType;
}

export class PricingComparison extends ApiComponent<IProps, IState> {
  async load() {
    const { contract, selectedPricing } = this.props;

    // TODO: Include discounts from customer profile.

    const mostRecent = contract ? await this.api.billing.contractRevisions.getMostRecentForContract(contract.id) : undefined;

    const contractPricing = await mostRecent?.current?.pricing.get();
    const contractIsTrial = contractPricing ? contractPricing.packageType === Package.Trial : true;

    const packageOrder = [Package.Trial, Package.Starter, Package.Business, Package.Enterprise];

    const customer = await this.getCurrentCustomer();

    const pricings = await this.api.billing.pricing.list(undefined, undefined, undefined, customer?.id);
    if (selectedPricing && !pricings.find((c) => c.id === selectedPricing.id)) {
      pricings.push(selectedPricing);
    }
    const nonTrialPricings = pricings.filter((c) => c.packageType !== Package.Trial);

    nonTrialPricings.sort((a, b) => {
      const indexA = packageOrder.indexOf(a.packageType);
      const indexB = packageOrder.indexOf(b.packageType);
      return indexA - indexB;
    });

    return {
      packages: nonTrialPricings,
      contractIsTrial,
      paymentInterval: this.props.paymentInterval || PaymentCycleType.Monthly,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (prevProps.paymentInterval !== this.props.paymentInterval && this.props.paymentInterval) {
      this.setState({
        paymentInterval: this.props.paymentInterval,
      });
    }
  }

  render() {
    const { contract, product, onSelectPricing, onSelectEnterprise, changePaymentInterval, selectedPricing } = this.props;
    const { packages, paymentInterval } = this.state;

    if (!contract || !packages || !paymentInterval) {
      return this.renderRequest();
    }

    const showPackages = packages.filter((c) => c.product === product && c.packageType !== Package.Enterprise);

    const packageNames = showPackages.map((c) => <SyncCoreDataDefinitionsEnumValue enumName={'Package'} keyName={c.packageType} />);
    const rows: IComparisonRow[] = [
      {
        label: 'Price',
        packages: showPackages.map((pricing) => {
          return {
            good: true,
            content: (
              <>
                {pricing.fixedMonthlyPrice ? (
                  <div>
                    <ViewDiscountedPrice
                      price={pricing.fixedMonthlyPrice}
                      currency={pricing.currency}
                      paymentCycle={paymentInterval}
                      pricingDiscounts={pricing.discounts}
                    />
                    <span className={'text-muted'}> / month</span>
                  </div>
                ) : undefined}
                {pricing.pricePerSite ? (
                  <div>
                    <ViewDiscountedPrice
                      price={pricing.pricePerSite}
                      currency={pricing.currency}
                      paymentCycle={paymentInterval}
                      pricingDiscounts={pricing.discounts}
                    />
                    <span className={'text-muted'}> / site / month</span>
                  </div>
                ) : undefined}
              </>
            ),
          };
        }),
      },
      {
        label: (
          <>
            Monthly update limit
            <InfoIcon>
              One request to create, update or delete a content item from a site is counted as one update. Your total monthly update limit
              is (number of licensed sites) x (this limit).
            </InfoIcon>
          </>
        ),
        packages: showPackages.map((c) => ({
          good: true,
          content: c.maxUpdatesPerSitePerMonth ? `${c.maxUpdatesPerSitePerMonth} per site` : 'Unlimited',
        })),
      },
      {
        label: <>File size limit</>,
        packages: showPackages.map((c) => ({
          good: c.packageType === Package.Enterprise,
          content: c.packageType === Package.Enterprise ? 'Unlimited' : '10 MB per file',
        })),
      },
      {
        label: (
          <>
            Test environment
            <InfoIcon>
              You can connect any number of non-production sites for testing free of charge. With the Enterprise package developers can also
              setup and test Content Sync on their local machine; other packages require the sites to be available via Internet.
            </InfoIcon>
          </>
        ),
        packages: showPackages.map((c) => ({
          good: true,
        })),
      },
      {
        label: 'On-premise',
        packages: showPackages.map((c) => ({
          good: c.packageType === Package.Enterprise,
        })),
      },
      {
        label: 'Availability',
        packages: showPackages.map((c) => ({
          good: [Package.Business, Package.Enterprise].includes(c.packageType),
          content: [Package.Business, Package.Enterprise].includes(c.packageType) ? '24/7' : 'Business day guarantee',
        })),
      },
      {
        label: 'Support',
        packages: showPackages.map((c) => ({
          good: [Package.Business, Package.Enterprise].includes(c.packageType),
          content:
            c.packageType === Package.Enterprise ? 'Personal with SLA' : c.packageType === Package.Business ? 'Personal' : 'Documentation',
        })),
      },
      {
        label: 'Reselling allowed',
        packages: showPackages.map((c) => ({
          good: c.packageType === Package.Enterprise,
        })),
      },
    ];

    const addEnterprise = !showPackages.find((c) => c.packageType === Package.Enterprise);

    if (addEnterprise) {
      packageNames.push(<SyncCoreDataDefinitionsEnumValue enumName={'Package'} keyName={Package.Enterprise} />);
      rows[0].packages.push({
        good: true,
        content: `Based on volume`,
      });
      rows[1].packages.push({
        good: true,
        content: `Unlimited`,
      });
      rows[2].packages.push({
        good: true,
        content: `Unlimited`,
      });
      rows[3].packages.push({
        good: true,
      });
      rows[4].packages.push({
        good: true,
      });
      rows[5].packages.push({
        good: true,
        content: `24/7`,
      });
      rows[6].packages.push({
        good: true,
        content: `Personal with SLA`,
      });
      rows[7].packages.push({
        good: true,
      });
    }

    let selected: number | undefined = showPackages.findIndex((c) => c.id === selectedPricing?.id);
    if (selected < 0) {
      selected = undefined;
    }

    return (
      <>
        {changePaymentInterval && (
          <div className={'d-flex justify-content-center mt-2 mb-3'} style={{ marginLeft: '25%' }}>
            <SwitchButton<PaymentCycleType>
              selected={paymentInterval}
              onChange={(paymentInterval) => this.setState({ paymentInterval })}
              options={[PaymentCycleType.Monthly, PaymentCycleType.Yearly].map((value) => ({
                value,
                label: <DataDefinitionsEnumValue enumName={'PaymentCycleType'} keyName={value} />,
              }))}
            />
          </div>
        )}

        <ComparisonTable
          onSelect={(index) => {
            if (addEnterprise && index === showPackages.length) {
              onSelectEnterprise(paymentInterval);
            } else {
              onSelectPricing(showPackages[index], paymentInterval);
            }
          }}
          packageNames={packageNames}
          recommendedPackage={showPackages.findIndex((c) => c.packageType === Package.Business)}
          rows={rows}
          selected={selected}
        />
      </>
    );
  }
}
