import { InternalId } from '@edgebox/data-definition-kit';
import React from 'react';
import ImageLocal from '../../../../images/project-local.png';
import ImageProduction from '../../../../images/project-production.png';
import ImageStagingProduction from '../../../../images/project-staging-production.png';
import ImageTesting from '../../../../images/project-testing.png';
import { Form } from 'react-bootstrap';
import { SiteEnvironmentType, SyncCoreDataDefinitionsEnumTranslator } from '@edgebox/sync-core-data-definitions';
import { ViewProjectCount } from '../Views/ViewProjectCount';
import { ViewSiteCount } from '../Views/ViewSiteCount';

interface IProps {
  mode: 'local' | 'staging' | 'syndication';
  currentValue?: SiteEnvironmentType;
  setValue: (value: SiteEnvironmentType) => void;
  className?: string;
  contractId?: InternalId;
}

interface IState {}

const OPTIONS = {
  local: [
    {
      image: ImageLocal,
      values: [SiteEnvironmentType.Local],
    },
  ],
  staging: [
    {
      image: ImageTesting,
      values: [SiteEnvironmentType.Local, SiteEnvironmentType.Testing],
    },
    {
      image: ImageStagingProduction,
      values: [SiteEnvironmentType.Staging, SiteEnvironmentType.Production],
    },
  ],
  syndication: [
    {
      image: ImageTesting,
      values: [SiteEnvironmentType.Local, SiteEnvironmentType.Testing],
    },
    {
      image: ImageProduction,
      values: [SiteEnvironmentType.Production],
    },
  ],
};

export class SiteEnvironmentTypeSelector extends React.Component<IProps, IState> {
  render() {
    const { mode, currentValue, setValue, className, contractId } = this.props;

    const HEIGHT = 145;

    const renderOptions = (image: string, keys: SiteEnvironmentType[]) => {
      return (
        <div className="d-inline-block">
          <div className="text-center pt-1 pb-2">
            <ViewProjectCount
              environmentType={
                keys.includes(SiteEnvironmentType.Local) || keys.includes(SiteEnvironmentType.Testing)
                  ? SiteEnvironmentType.Testing
                  : SiteEnvironmentType.Production
              }
              badge
              warnIfEmpty
              contractId={contractId}
            />
          </div>
          <img src={image} height={HEIGHT.toString()} />
          <div className="position-relative d-flex" style={{ marginTop: -HEIGHT + 'px' }}>
            {keys.map((key: SiteEnvironmentType) => (
              <div className="text-center cursor-pointer flex-even" style={{ margin: '-2px 0 0 -1px' }} onClick={() => setValue(key)}>
                <div
                  style={{ width: 'calc(100% + 1px)', height: 'calc(100% + 1px)', margin: '0 -2px -2px 0' }}
                  className="hover-primary-border rounded"
                >
                  <div style={{ width: '100%', height: HEIGHT + 'px' }} />
                  <Form.Check
                    label={<span className="cursor-pointer" />}
                    key={key}
                    type={'radio'}
                    id={`environmentType-${key}`}
                    checked={currentValue === key}
                    className="d-inline-block ms-1"
                    onChange={() => {
                      setValue(key);
                    }}
                  />
                  <div className="mb-1 fw-bold text-center">
                    {SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('SiteEnvironmentType', key)}
                  </div>
                  <div className="mb-3 text-center">
                    <ViewSiteCount badge environmentType={key} contractId={contractId} highlightIfNotEmpty />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    };

    const options = OPTIONS[mode];
    return (
      <div className={`d-flex justify-start pb-3 select-exclusive-children ${className || ''}`} style={{ gap: '1em' }}>
        {options.map(({ image, values }) => (
          <div className="flex-grow-0 flex-shrink-0 border border-dark rounded">{renderOptions(image, values)}</div>
        ))}
      </div>
    );
  }
}
