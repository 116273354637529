import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { ApiComponent, AuthenticationContext, IApiComponentState } from '../../../common/index';
import { API_DOMAIN } from '../../../constants';
import { IInternalAuthentication } from '@edgebox/api-rest-client';
import { ContentBox } from '../../Shared/ContentBox';

export class BackendSettings extends ApiComponent<{}, IApiComponentState> {
  async load(): Promise<Partial<IApiComponentState>> {
    return {};
  }

  render() {
    return (
      <Container>
        <h1>DevOps Settings</h1>
        <ContentBox>
          <AuthenticationContext.Consumer>
            {(auth: IInternalAuthentication) => (
              <Button href={`//${API_DOMAIN}/integrations/xero/login?jwt=${encodeURIComponent(auth.jwt!)}`} variant={'primary'}>
                Xero login
              </Button>
            )}
          </AuthenticationContext.Consumer>
        </ContentBox>
      </Container>
    );
  }
}
