import { ClientDiscountEntity } from '@edgebox/api-rest-client';
import { IRuntimeReference } from '@edgebox/data-definition-kit';
import { Currency } from '@edgebox/data-definitions';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { ApiComponent, IApiComponentState } from '../../../services/ApiComponent';
import { DataDefinitionsEnumValue } from '../../Localization/DataDefinitionsEnumValue';
import { MoneyAmount } from '../../Localization/MoneyAmount';

interface IProps {
  currency: Currency;
  discounts: IRuntimeReference<ClientDiscountEntity>[];
}

interface IState extends IApiComponentState {
  discounts?: ClientDiscountEntity[];
}

export class ViewDiscounts extends ApiComponent<IProps, IState> {
  async load() {
    return {
      discounts: await Promise.all(this.props.discounts.map((reference) => reference.get())),
    };
  }

  async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    const previousIds = prevProps.discounts.map((c) => c.getId());
    const newIds = this.props.discounts.map((c) => c.getId());
    if (JSON.stringify(previousIds) !== JSON.stringify(newIds)) {
      const newDiscounts = await this.load();
      this.setState(newDiscounts);
    }
  }

  render(): React.ReactElement {
    const { currency } = this.props;
    const { discounts } = this.state;

    if (!discounts) {
      return this.renderRequest();
    }

    return (
      <>
        {discounts.map((discount, index) => {
          return (
            <div key={index}>
              <strong>{discount.name}</strong>{' '}
              <Badge bg={'light'}>
                <DataDefinitionsEnumValue enumName={'DiscountType'} keyName={discount.type} />
              </Badge>
              {discount.amountFixed ? (
                <div>
                  -<MoneyAmount amount={discount.amountFixed} currency={currency} />
                </div>
              ) : undefined}
              {discount.amountPercentage ? <div>-{discount.amountPercentage * 100}%</div> : undefined}
              {discount.amountByThreshold?.map((discount, index) => (
                <div key={index}>
                  &gt;={discount.threshold}:{' '}
                  {discount.amountFixed ? (
                    <span>
                      -<MoneyAmount amount={discount.amountFixed} currency={currency} />
                    </span>
                  ) : undefined}
                  {discount.amountPercentage ? <span>-{discount.amountPercentage * 100}%</span> : undefined}
                </div>
              ))}
            </div>
          );
        })}
      </>
    );
  }
}
