import { formatFloat } from '@edgebox/data-definition-kit';
import React from 'react';

interface IProps {
  number: number;
}

export class FormatNumber extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { number } = this.props;

    return <span>{formatFloat(number)}</span>;
  }
}
