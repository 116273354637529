import { ClientBasketEntity } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import React from 'react';
import { Alert } from 'react-bootstrap';
import Row from 'react-bootstrap/cjs/Row';
import { IconButton } from '@edgebox/react-components';
import { ContentCol } from '@edgebox/react-components';
import { HeaderCol } from '@edgebox/react-components';
import { ContentBox } from '../../Shared/ContentBox';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons/faLongArrowRight';
import { BackendBasketStatus } from './BackendBasketStatus';
import { ApiComponent, CustomerLink, FormatDateTime, IApiComponentState, PagedList } from '../../../common';
import { BackendProposalDetail } from './BackendProposalDetail';

interface IProps {
  id?: InternalId;
}
interface IState extends IApiComponentState {}

export class BackendProposalList extends ApiComponent<IProps, IState> {
  async load() {
    return {};
  }

  render() {
    const { id } = this.props;

    if (id) {
      return <BackendProposalDetail id={id} />;
    }

    return (
      <PagedList<ClientBasketEntity>
        searchable
        request={(page, filter) => this.api.billing.baskets.list(undefined, undefined, undefined, { page })}
        renderListHeader={() => (
          <ContentBox glueBottom>
            <Row>
              <HeaderCol>Customer</HeaderCol>
              <HeaderCol>Status</HeaderCol>
              <HeaderCol>Created</HeaderCol>
              <HeaderCol>Actions</HeaderCol>
            </Row>
          </ContentBox>
        )}
        emptyMessage={<Alert variant={'light'}>No proposals or proposal requests yet.</Alert>}
        renderList={(items) => <ContentBox glueTop>{items}</ContentBox>}
        renderItem={(basket) => (
          <Row key={basket.id}>
            <ContentCol>
              <CustomerLink entityId={basket.customer.getId()} />
            </ContentCol>
            <ContentCol>
              <BackendBasketStatus status={basket.status} />
            </ContentCol>
            <ContentCol>
              <FormatDateTime date={basket.createdAt} />
            </ContentCol>
            <ContentCol>
              <IconButton
                variant={'light'}
                iconPosition={'right'}
                icon={faLongArrowRight}
                to={`/backend/billing/contracts/proposals/${basket.id}`}
              >
                View
              </IconButton>
            </ContentCol>
          </Row>
        )}
      />
    );
  }
}
