import { IEntityWithId, INamed, IRuntimeEntity } from '@edgebox/data-definition-kit';
import {
  FormRow,
  formWithValidation,
  FormWithValidationHelper,
  IFormWithValidationProps,
  RenderFooterCallback,
  RenderMultiValueFieldsOptions,
} from '@edgebox/react-components';
import { mapClassValidatorToFormikErrors } from '@edgebox/react-components/dist/components/Form/FormikErrorHandler';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Optional } from 'utility-types';
import { EditMode, IEntityContext } from '../../contexts/EntityContext';
import { ApiComponent, IApiComponentState, RequestReason } from '../../services/ApiComponent';
import { EditName } from './Properties/EditName';

type OnToggleEditCallback = () => void;

// static => none
// edit => full
// inline-edit => inline
// focused => selective

export interface IEntityFormProps<Draft extends object, SerializedData> {
  entity: Draft;
  onToggleEdit?: OnToggleEditCallback;
  name?: React.ReactNode;
  footer?: 'multi-step' | 'multi-step-fixed' | RenderFooterCallback<SerializedData>;
  className?: string;
}

export interface IEntityFormState<Draft> extends IApiComponentState {
  extendProperties: (keyof Draft)[];
  edit: EditMode;
}

interface IFieldRenderOptions {
  help?: React.ReactNode;
  noRow?: boolean;
}

// TODO: Use entity form as component rather than with inheritance.

interface IEntityFormWithValidationProps<SerializedProperties, RuntimeProperties>
  extends IFormWithValidationProps<SerializedProperties, RuntimeProperties> {
  apiComponent: ApiComponent<any, any>;
}

export function entityFormWithValidation<SerializedProperties extends object, RuntimeProperties extends object>(
  Draft: { new (props: RuntimeProperties): RuntimeProperties },
  Entity?: { new (props: RuntimeProperties & IRuntimeEntity & IEntityWithId): RuntimeProperties }
): React.ComponentType<IEntityFormWithValidationProps<SerializedProperties, RuntimeProperties>> {
  const Form = formWithValidation<SerializedProperties, RuntimeProperties>(
    (values) => (Entity && (values as any).id ? plainToInstance(Entity, values) : plainToInstance(Draft, values)),
    (entity) => instanceToPlain(entity) as SerializedProperties
  );

  return function EntityFormWithValidation(props) {
    let submitting = false;

    const { onSubmit, ...pass } = props;

    return (
      <Form
        onSubmit={async (instance, formikBag) => {
          submitting = true;
          try {
            await props.apiComponent.wrapApiCall(onSubmit(instance, formikBag), RequestReason.SubmitForm);

            if (props.apiComponent.formValidationError) {
              const errors = mapClassValidatorToFormikErrors<SerializedProperties>(props.apiComponent.formValidationError);

              Object.keys(errors).forEach((property) => {
                formikBag.setFieldError(property, (errors as any)[property]);
              });
            }
          } finally {
            submitting = false;
          }
        }}
        message={submitting ? props.apiComponent.renderRequest(RequestReason.SubmitForm) : undefined}
        {...pass}
      />
    );
  };
}

export abstract class EntityForm<
  Draft extends object,
  SerializedData,
  Props extends IEntityFormProps<Draft, SerializedData>,
  State extends IEntityFormState<Draft>,
> extends ApiComponent<Props, State> {
  protected constructor(props: Props, state?: Optional<State, 'extendProperties' | 'apiErrors' | 'openRequests'>) {
    super(
      props,
      Object.assign(
        {
          extendProperties: [],
        },
        state
      ) as Optional<State, 'apiErrors' | 'openRequests'>
    );
  }

  get entityContext(): IEntityContext<Draft> {
    return {
      editMode: this.state.edit,
      entity: this.props.entity,
      edit: (name) => this.setState({ extendProperties: [...this.state.extendProperties, name] }),
      isEditing: (name) => {
        const { edit, extendProperties } = this.state;

        if (edit === EditMode.Full) {
          return true;
        }

        if (edit === EditMode.Selective) {
          return extendProperties.includes(name);
        }

        if (edit === EditMode.Inline) {
          // Inline edit: Only show when explicitly clicked "edit"
          return extendProperties.includes(name);
        }

        return false;
      },
      requiredProperties: FormWithValidationHelper.getFormRequiredPropertiesArray(this.props.entity),
    };
  }

  protected formProperties(): {
    className?: string;
    footer?: 'multi-step' | 'multi-step-fixed' | RenderFooterCallback<SerializedData>;
  } {
    const { className, footer } = this.props;

    return {
      footer,
      className,
    };
  }

  protected renderField(
    name: keyof Draft,
    label: string | undefined,
    formWidget: React.ReactNode,
    staticProperty?: React.ReactNode,
    options?: IFieldRenderOptions
  ) {
    const { extendProperties } = this.state;
    const { help, noRow } = options || {};

    if (!staticProperty && !formWidget) {
      return null;
    }

    const { edit } = this.state;

    const showInput = this.entityContext.isEditing(name);

    const extend = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      extendProperties.push(name);
      this.setState({
        extendProperties,
      });
    };

    if (edit === EditMode.None || edit === EditMode.Selective) {
      if (!staticProperty && !showInput) {
        return null;
      }
    }

    const content = (
      <>
        {showInput ? (
          formWidget ? (
            formWidget
          ) : (
            staticProperty
          )
        ) : edit === EditMode.None ? (
          staticProperty
        ) : (
          <div>
            {staticProperty}

            {edit === EditMode.Selective || !formWidget ? undefined : (
              <Button variant={'link'} onClick={extend}>
                {edit === EditMode.Inline ? (staticProperty ? 'Edit' : 'Add') : label ? 'Extend' : 'Change'}
              </Button>
            )}
          </div>
        )}
      </>
    );

    if (noRow) {
      return content;
    }

    return (
      <FormRow
        name={name as string}
        key={name as string}
        label={label}
        labelPlacement={label ? undefined : 'none'}
        help={showInput && formWidget ? help : undefined}
      >
        {content}
      </FormRow>
    );
  }

  protected renderCheckboxArray(
    renderOptions: RenderMultiValueFieldsOptions<Draft>,
    name: keyof Draft,
    label: string,
    allowedValues: {
      [name: string]: string;
    }
  ) {
    const keys = Object.keys(allowedValues);

    const currentValue: string[] = (renderOptions.values[name] as any as string[]) || [];

    return this.renderField(
      name,
      label,
      <div>
        {keys.map((key) => {
          return (
            <Form.Check
              inline
              label={allowedValues[key]}
              key={key}
              type={'checkbox'}
              id={`${name.toString()}-${key}`}
              checked={currentValue.includes(key)}
              onChange={() => {
                if (currentValue.includes(key)) {
                  currentValue.splice(currentValue.indexOf(key), 1);
                } else {
                  currentValue.push(key);
                }
                renderOptions.setValue(name, currentValue);
              }}
            />
          );
        })}
      </div>,
      currentValue.length ? currentValue.map((value) => allowedValues[value]).join(', ') : undefined
    );
  }

  protected renderName(
    renderOptions: RenderMultiValueFieldsOptions<Draft>,
    options?: {
      help?: React.ReactNode;
      showLabel?: boolean;
    }
  ) {
    const { name } = this.props;
    if (name) {
      return name;
    }

    const currentValue = (renderOptions.values as any as INamed).name;

    return <EditName value={currentValue} help={options?.help} showLabel={options?.showLabel} onToggleEdit={this.props.onToggleEdit} />;
  }
}
