import { ClientUserEntity } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import { UserType } from '@edgebox/data-definitions';
import { EmailLink } from '@edgebox/react-components';
import React from 'react';
import { Link } from 'react-router-dom';
import { ApiComponent, IApiComponentState } from '../../../services/ApiComponent';

interface IProps {
  entityId: InternalId;
  linkToEmail?: boolean;
}

interface IState extends IApiComponentState {
  entity?: ClientUserEntity;
}

export class UserLink extends ApiComponent<IProps, IState> {
  async load(): Promise<Partial<IState>> {
    const entity = await this.api.authentication.users.item(this.props.entityId);

    return {
      entity,
    };
  }

  render(): React.ReactElement {
    const { linkToEmail } = this.props;
    const { entity } = this.state;

    if (!entity) {
      return this.renderRequest();
    }

    const name = `${entity.firstName} ${entity.lastName}`;

    if (linkToEmail) {
      return <EmailLink email={entity.primaryEmail}>{name}</EmailLink>;
    }

    if (entity.type === UserType.Internal) {
      if (this.api.currentUser?.type === UserType.Internal) {
        return <Link to={`/account/users`}>{name}</Link>;
      }
    } else if (entity.type === UserType.Customer) {
      if (this.api.currentUser?.type === UserType.Internal) {
        return <Link to={`/backend/billing/customers/${entity.customer?.getId()}`}>{name}</Link>;
      } else if (this.api.currentUser?.type === UserType.Customer) {
        return <Link to={`/account/users`}>{name}</Link>;
      }
    }

    return <span>{name}</span>;
  }
}
