import { ClientContractEntity } from '@edgebox/api-rest-client';
import { ClientContractRevisionEntity } from '@edgebox/api-rest-client/dist/client/Billing/ContractRevisionService';
import { DateTimeFormat } from '@edgebox/data-definition-kit';
import moment from 'moment';
import React from 'react';
import { ApiComponent, IApiComponentState } from '../../../services/ApiComponent';
import { FormatDateTime } from '../../Localization/FormatDateTime';
import { isContractActive } from '../Badges/ContractStatusBadge';
import { ViewContractRevisionName } from './ViewContractRevisionName';

interface IProps {
  entity: ClientContractEntity;
  includeDate?: boolean;
  short?: boolean;
}

interface IState extends IApiComponentState {
  currentRevision?: ClientContractRevisionEntity;
  loaded?: boolean;
}

export class ViewContractName extends ApiComponent<IProps, IState> {
  async load() {
    const mostRecent = await this.api.billing.contractRevisions.getMostRecentForContract(this.props.entity.id);
    const currentRevision = mostRecent.current;

    return {
      currentRevision,
      loaded: true,
    };
  }

  render(): React.ReactElement {
    const { currentRevision, loaded } = this.state;
    const { entity, includeDate, short } = this.props;

    if (!loaded) {
      return this.renderRequest();
    }

    let packageName = currentRevision ? (
      <ViewContractRevisionName entity={currentRevision} includeDate={includeDate} />
    ) : (
      <>
        {entity.startDate.isAfter(moment()) ? 'Upcoming contract' : 'Expired contract'}
        {includeDate && (
          <>
            {' '}
            from <FormatDateTime date={entity.startDate} format={DateTimeFormat.Date} />
          </>
        )}
        {includeDate && entity.endDate && !isContractActive(entity) && (
          <>
            {' '}
            to <FormatDateTime date={entity.endDate} format={DateTimeFormat.Date} />
          </>
        )}
      </>
    );

    if (entity.name) {
      packageName = (
        <>
          {entity.name} ({packageName})
        </>
      );
    }

    if (short) {
      return <div className={'mw-100 text-truncate'}>{packageName}</div>;
    }

    return packageName;
  }
}
