import { InvoiceStatus } from '@edgebox/data-definitions';
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { DataDefinitionsEnumValue } from '../../Localization/DataDefinitionsEnumValue';

interface IProps {
  status: InvoiceStatus;
  size?: 'small';
}

export class InvoiceStatusBadge extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { status, size } = this.props;

    const classes = size ? 'small' : '';

    const variants = {
      [InvoiceStatus.New]: 'info',
      [InvoiceStatus.Draft]: 'info',
      [InvoiceStatus.Saved]: 'info',
      [InvoiceStatus.Approved]: 'info',
      [InvoiceStatus.FeedbackRequired]: 'warning',
      [InvoiceStatus.RetryPayment]: 'danger',
      [InvoiceStatus.PaymentDispute]: 'danger',
      [InvoiceStatus.PaymentFailed]: 'danger',
      [InvoiceStatus.Cancelled]: 'dark',
      [InvoiceStatus.Paid]: 'primary',
      [InvoiceStatus.ProcessingPayment]: 'info',
    };

    return (
      <Badge bg={variants[status]} className={classes}>
        <DataDefinitionsEnumValue enumName={'InvoiceStatus'} keyName={status} />
      </Badge>
    );
  }
}
