import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

type IProps = React.ComponentPropsWithRef<'div'> & ButtonProps;

export class PrimaryActionButton extends React.Component<IProps, {}> {
  render() {
    return (
      <Button
        {...this.props}
        variant={'primary'}
        className={`btn-primary-action pt-2 pb-2 ps-4 pe-4 rounded fw-bold ${this.props.className || ''}`}
      />
    );
  }
}
