import { SyncCoreType } from '@edgebox/data-definitions';
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { DataDefinitionsEnumValue } from '../../Localization/DataDefinitionsEnumValue';

interface IProps {
  type: SyncCoreType;
}

export class SyncCoreTypeBadge extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { type } = this.props;

    const classes = 'opacity-3';

    const variants = {
      [SyncCoreType.SaaS_Prod]: 'primary',
      [SyncCoreType.OnPremise]: 'primary',
    };

    return (
      <Badge bg={variants[type]} className={classes}>
        <DataDefinitionsEnumValue enumName={'SyncCoreType'} keyName={type} />
      </Badge>
    );
  }
}
