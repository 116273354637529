import { ClientContractEntity } from '@edgebox/api-rest-client';
import { DateTimeFormat, formatDate, InternalId, IPagedListResponse } from '@edgebox/data-definition-kit';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { EntitySelector, IEntitySelectorProps, IEntitySelectorState } from '../../Form/EntitySelector';
import { ITextSearchFilter } from '../../PagedList';
import { ViewContractName } from '../Views/ViewContractName';

interface IProps extends IEntitySelectorProps<ClientContractEntity> {
  forCustomer?: InternalId;
  forPricing?: InternalId;
  includeInactive?: boolean;
}

interface IState extends IEntitySelectorState<ClientContractEntity> {}

export class ContractSelector extends EntitySelector<ClientContractEntity, IProps, IState> {
  renderEmpty() {
    return 'Select subscription';
  }

  renderCurrentValue(selected: ClientContractEntity): React.ReactElement {
    return <ViewContractName entity={selected} />;
  }

  search(page: number, query?: ITextSearchFilter): Promise<IPagedListResponse<ClientContractEntity>> {
    const { forCustomer, includeInactive } = this.props;

    return this.api.billing.contracts.search(query?.search, { page }, forCustomer, includeInactive);
  }

  renderAddForm(): React.ReactElement | null {
    return null;
  }

  renderItem(entity: ClientContractEntity): React.ReactElement {
    if (this.state.variant === 'radios') {
      return <ViewContractName entity={entity} />;
    }
    return (
      <Row>
        <Col>
          <h3>
            <ViewContractName entity={entity} />
          </h3>
        </Col>
      </Row>
    );
  }

  renderItemAsText(entity: ClientContractEntity): string {
    return entity.name || `From ${formatDate(entity.startDate, DateTimeFormat.Date)} for ${entity.licensedSites} sites`;
  }
}
