import {
  ClientBasketDraft,
  ClientBasketEntity,
  ClientContractEntity,
  ClientContractRevisionDraft,
  ClientContractRevisionEntity,
  ClientPricingEntity,
} from '@edgebox/api-rest-client';
import { StaticReference } from '@edgebox/data-definition-kit';
import { BasketStatus, BasketType, PaymentCycleType, UserType } from '@edgebox/data-definitions';
import { ButtonLink, EditButton, IconButton, LeftRightContainer, LeftRightH1, Right } from '@edgebox/react-components';
import { HostingType, Package } from '@edgebox/sync-core-data-definitions';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faDollarSign } from '@fortawesome/pro-light-svg-icons/faDollarSign';
import { faBrowser } from '@fortawesome/pro-light-svg-icons/faBrowser';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons/faLongArrowRight';
import React from 'react';
import { Alert, Button, Col, Container, Image, Modal, Row, Form, Badge, Tab, Tabs } from 'react-bootstrap';
import { ApiComponent, ContractForm, IApiComponentState, IItemProps, ViewContractName, ViewPricingSummary } from '../../../common/index';
import ActiveContractImage from '../../../images/undraw_Agreement_re_d4dv.svg';
import TrialContractImage from '../../../images/undraw_online_test_gba7.svg';
import PurchasedContractImage from '../../../images/undraw_well_done_i2wr.svg';
import { IIdParamProp, ILocationProp, INavigateProp, withIdParamAndLocationAndNavigate } from '../../RouterHelper';
import { CircleIcon } from '../../Shared/CircleIcon';
import { ContentBox } from '../../Shared/ContentBox';
import { PagedSiteList } from '../../Shared/PagedSiteList';
import { ViewContractPeriod } from '../../Shared/ViewContractPeriod';
import { ViewContractSites } from '../../Shared/ViewContractSites';
import { PagedContractChangeLogItemList } from '../../Shared/PagedContractChangeLogItemList';
import { PagedLogItemList } from '../../Backend/PagedLogItemList';
import { HeadlineWithBreadcrumbNavigation } from '../../../common/components/BreadcrumbNavigation';
import { propertyChangeReload } from '../../../common/helpers/propertyChangeReload';
import { IAppContextProp, withAppContext } from '../../../common/contexts/AppContext';

interface IExternalProps {}
interface IProps extends JSX.IntrinsicAttributes, IExternalProps, ILocationProp, INavigateProp, IIdParamProp, IAppContextProp {}

interface IState extends IApiComponentState {
  contract?: ClientContractEntity;
  editContract?: ClientContractEntity;
  currentContractRevision?: ClientContractRevisionEntity;
  nextContractRevision?: ClientContractRevisionEntity;
  editNextRevision?: ClientContractRevisionEntity | ClientContractRevisionDraft;
  currentPricing?: ClientPricingEntity;
  currentBasket?: ClientBasketEntity;
  showPurchasedMessage?: boolean;
  increaseLicenses?: boolean;
  increaseLicensesBy?: number;
}

class ContractDetailComponent extends ApiComponent<IProps, IState> {
  async load() {
    const contract = await this.api.billing.contracts.item(this.props.id);

    const mostRecent = await this.api.billing.contractRevisions.getMostRecentForContract(contract.id);
    const currentPricing = await (mostRecent.current || mostRecent.next)?.pricing.get();

    const urlParams = new URLSearchParams(this.props.location.search);
    const showPurchasedMessage = urlParams.get('purchased') === 'yes';

    let currentBasket: ClientBasketEntity | undefined = showPurchasedMessage
      ? undefined
      : (
          await this.api.billing.baskets.list(undefined, contract.id, [BasketStatus.Requested, BasketStatus.Created, BasketStatus.Proposal])
        ).items.find((c) => c.contract?.getId() === contract.id && c.numberOfSites >= contract.currentProductionSites);

    return {
      currentContractRevision: mostRecent.current,
      nextContractRevision: mostRecent.next,
      currentPricing,
      contract,
      showPurchasedMessage,
      currentBasket,
    };
  }
  render() {
    const {
      contract,
      editContract,
      showPurchasedMessage,
      currentContractRevision,
      nextContractRevision,
      currentPricing,
      increaseLicenses,
      increaseLicensesBy,
      currentBasket,
    } = this.state;

    if (!contract) {
      return this.renderRequest();
    }

    const renderBackendProperties = this.api.currentUser?.type === UserType.Internal;

    const minLicensesIncrease =
      contract.currentProductionSites > contract.licensedSites ? contract.currentProductionSites - contract.licensedSites : 1;

    const onCancelEdit = () => {
      this.setState({
        editContract: undefined,
      });
    };

    const onSave = async (updatedContract: ClientContractEntity) => {
      const previous = this.state.contract;
      this.setState({
        contract: updatedContract,
      });

      try {
        await this.api.billing.contracts.update(updatedContract);

        this.props.appContext.refreshContracts?.();
      } catch (e) {
        this.setState({
          contract: previous,
        });
      }

      onCancelEdit();
    };

    const onEdit = () => this.setState({ editContract: new ClientContractEntity(contract) });

    let message: React.ReactNode = showPurchasedMessage ? <Alert variant={'success'}>Your purchase was successful.</Alert> : undefined;
    let actions: React.ReactNode = undefined;
    if (currentBasket?.status === BasketStatus.Requested) {
      message = (
        <Alert variant="success" className="mt-4">
          We are providing you with a proposal soon.
        </Alert>
      );
    } else if (currentBasket?.status === BasketStatus.Created) {
      actions = (
        <IconButton
          variant={'primary'}
          iconPosition={'right'}
          icon={faLongArrowRight}
          to={`/checkout/${currentBasket.id}`}
          className={`mt-4 ps-4 pe-4 pt-2 pb-2 fw-bold`}
        >
          Continue checkout
        </IconButton>
      );
    } else if (currentBasket?.status === BasketStatus.Proposal) {
      message = <Alert variant={'success'}>Your proposal is ready.</Alert>;
      actions = (
        <IconButton
          variant={'primary'}
          iconPosition={'right'}
          icon={faLongArrowRight}
          to={`/checkout/${currentBasket.id}`}
          className={`mt-4 ps-4 pe-4 pt-2 pb-2 fw-bold`}
        >
          View proposal
        </IconButton>
      );
    } else if (currentContractRevision?.packageType === Package.Trial) {
      actions = (
        <IconButton
          variant={'primary'}
          iconPosition={'right'}
          icon={faLongArrowRight}
          to={`/subscriptions/${contract.id}/revisions/${currentContractRevision.id}/upgrade`}
          className={`mt-4 ps-4 pe-4 pt-2 pb-2 fw-bold`}
        >
          Upgrade
        </IconButton>
      );
    }
    if (currentContractRevision && currentContractRevision?.packageType !== Package.Trial) {
      actions = (
        <>
          {actions}
          <br />
          <Button
            variant="primary"
            className={`mt-4 ps-4 pe-4 pt-2 pb-2`}
            onClick={() => this.setState({ increaseLicenses: true, increaseLicensesBy: minLicensesIncrease })}
          >
            Increase licenses
          </Button>
        </>
      );
    }
    if (renderBackendProperties) {
      if (!nextContractRevision) {
        actions = (
          <>
            {actions}
            <br />
            <ButtonLink
              variant="primary"
              className={`mt-4 ps-4 pe-4 pt-2 pb-2`}
              to={`/backend/billing/customers/${contract.customer.getId()}/contracts/${contract.id}/add`}
            >
              Add revision
            </ButtonLink>
          </>
        );
      }
    }

    const canRenew = currentContractRevision && currentContractRevision.packageType !== Package.Trial;

    const unlicensedDomains =
      contract.currentProductionSiteDomains && contract.licensedProductionSiteDomains
        ? contract.currentProductionSiteDomains.filter((c) => !contract.licensedProductionSiteDomains!.includes(c))
        : [];

    return (
      <Container>
        <LeftRightContainer
          className="mb-4"
          left={
            <HeadlineWithBreadcrumbNavigation>
              <ViewContractName entity={contract} />{' '}
              {currentContractRevision?.hostingType === HostingType.OnPremise ? <Badge bg="primary">On-premise</Badge> : undefined}
            </HeadlineWithBreadcrumbNavigation>
          }
          right={editContract ? undefined : <EditButton onClick={onEdit} />}
        />

        <Tabs id="content-tabs" defaultActiveKey={'details'} key={editContract ? 'edit' : 'view'}>
          <Tab eventKey="details" title={'Details'}>
            <ContentBox className="pb-4">
              <Row>
                <Col xs={6} className={'p-5'}>
                  <Image
                    src={
                      currentContractRevision?.packageType === Package.Trial
                        ? TrialContractImage
                        : showPurchasedMessage
                          ? PurchasedContractImage
                          : ActiveContractImage
                    }
                    width={'100%'}
                  />
                </Col>
                <Col>
                  {editContract ? (
                    <ContractForm
                      name={<></>}
                      onSave={onSave}
                      mode={editContract ? 'edit-full' : 'view-full'}
                      key={editContract ? '1' : '0'}
                      entity={editContract || contract}
                    >
                      {editContract && (
                        <Right>
                          <Button type={'button'} variant={'light'} onClick={onCancelEdit}>
                            Cancel
                          </Button>
                          <Button type={'submit'} variant={'primary'}>
                            Save
                          </Button>
                        </Right>
                      )}
                    </ContractForm>
                  ) : (
                    <div>
                      {message}

                      <div className={'d-flex flex-columns align-items-center'}>
                        <CircleIcon className={'m-3'} icon={faDollarSign} withBackground />
                        <div className={'flex-grow-1'}>
                          {currentPricing ? (
                            <ViewPricingSummary pricing={currentPricing} />
                          ) : (
                            <div className={'fw-bold text-danger'}>Inactive.</div>
                          )}
                        </div>
                      </div>

                      <div className={'d-flex flex-columns align-items-center'}>
                        <CircleIcon className={'m-3'} icon={faBrowser} withBackground />
                        <div className={'flex-grow-1'}>
                          <ViewContractSites entity={contract} onEdit={onEdit} />
                        </div>
                      </div>

                      <div className={'d-flex flex-columns align-items-top'}>
                        <CircleIcon className={'ms-3 me-3'} icon={faClock} withBackground />
                        <div className={'flex-grow-1'}>
                          <div>
                            <strong>Period</strong>
                          </div>
                          <div>
                            <ViewContractPeriod entity={contract} />
                            <div>
                              {nextContractRevision && canRenew /*nextContractRevision.startDate.diff(moment(), "minutes") > 64
                                      ? <ButtonLink
                                        variant="light"
                                        className={`ps-0 pe-0 pt-2 pb-2`}
                                        to={`/subscriptions/${contract.id}/revisions/${currentContractRevision!.id}/renew`}
                                      >
                                        Edit renewal
                                      </ButtonLink>
                                    : <Alert variant="light">This contract is renewing soon, so the terms can't be edited right now.</Alert>*/ ? undefined : !contract.renewAutomatically &&
                                canRenew ? (
                                <ButtonLink
                                  variant="light"
                                  className={`ps-0 pe-0 pt-2 pb-2`}
                                  to={`/subscriptions/${contract.id}/revisions/${currentContractRevision!.id}/renew`}
                                >
                                  Renew
                                </ButtonLink>
                              ) : undefined}
                            </div>
                          </div>
                          {actions}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </ContentBox>
          </Tab>
          <Tab eventKey="sites" title={'Sites'}>
            <ContentBox className="pt-3">
              <PagedSiteList forContract={contract} emptyMessage={<Alert variant="light">This subscription has no sites.</Alert>} />
            </ContentBox>
          </Tab>
          <Tab
            eventKey="licenses"
            title={<span>Licenses {unlicensedDomains.length ? <Badge bg="danger">{unlicensedDomains.length}</Badge> : undefined}</span>}
          >
            <ContentBox>
              {contract.currentProductionSiteDomains && contract.licensedProductionSiteDomains ? (
                <>
                  {unlicensedDomains.length ? (
                    <Alert variant="danger">
                      <strong>You have {unlicensedDomains.length} unlicensed domain(s):</strong>
                      <ul>
                        {unlicensedDomains.map((c) => (
                          <li key={c}>{c}</li>
                        ))}
                      </ul>
                      If you see domains here that shouldn't be billed please make sure that the sites are correctly registered as
                      testing/production sites. If you are using the Drupal <em>domain</em> module double check that your non-production
                      domain records are disabled on your production sites; otherwise they are included here and invoiced accordingly.
                      <br />
                      It can take up to 1h for domain record changes to be updated here. If you removed or disabled a domain record but
                      don't see it reflected here after 1h, please export your site's configuration manually, e.g. using <em>drush cse</em>.
                    </Alert>
                  ) : (
                    <Alert variant="success">All domains are licensed.</Alert>
                  )}

                  <h4>Licensed domains</h4>
                  {contract.licensedProductionSiteDomains.length ? (
                    <ul>
                      {contract.licensedProductionSiteDomains.map((c) => (
                        <li key={c}>{c}</li>
                      ))}
                    </ul>
                  ) : (
                    <em>Only preproduction sites were registered yet.</em>
                  )}
                </>
              ) : (
                <Alert variant="light">No information yet.</Alert>
              )}
            </ContentBox>
          </Tab>
          <Tab eventKey="history" title={'History'}>
            <ContentBox>
              <PagedContractChangeLogItemList forContract={contract} />
            </ContentBox>
          </Tab>
          {renderBackendProperties && (
            <Tab eventKey="logs" title={'Logs'}>
              <ContentBox className="pt-3">
                <PagedLogItemList id={`contract-${contract.id}`} contractId={contract.id} />
              </ContentBox>
            </Tab>
          )}
        </Tabs>

        <Modal show={increaseLicenses} scrollable onHide={() => this.setState({ increaseLicenses: false })}>
          <Modal.Header>
            <Modal.Title>Increase licenses</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label htmlFor="site-count">Additional sites</label>
            <Form.Control
              id="site-count"
              type="number"
              value={increaseLicensesBy}
              min={minLicensesIncrease}
              max={500}
              onChange={(e) => this.setState({ increaseLicensesBy: parseInt(e.target.value) })}
            />
          </Modal.Body>
          <Modal.Footer>
            <Right>
              <Button
                variant="primary"
                disabled={!increaseLicensesBy || increaseLicensesBy < minLicensesIncrease}
                onClick={async () => {
                  const customer = await currentContractRevision!.customer.get();
                  const basketDraft = new ClientBasketDraft({
                    type: BasketType.IncreaseLicensesManually,
                    status: BasketStatus.Created,
                    cycle: currentContractRevision!.advancePaymentsInMonths === 12 ? PaymentCycleType.Yearly : PaymentCycleType.Monthly,
                    product: currentContractRevision!.product,
                    packageType: currentContractRevision!.packageType,
                    currency: customer.billingCurrency,
                    pricing: currentContractRevision!.pricing,
                    numberOfSites: contract.licensedSites + increaseLicensesBy!,
                    customer: contract.customer,
                    createdBy: new StaticReference(this.api.currentUser!),
                    contract: new StaticReference(contract),
                    renewAutomatically: contract.renewAutomatically,
                  });
                  const basket = await this.api.billing.baskets.create(basketDraft);

                  this.props.navigate(`/checkout/${basket.id}`);
                }}
              >
                Continue
              </Button>
            </Right>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export const ContractDetail = withIdParamAndLocationAndNavigate<IExternalProps>(
  propertyChangeReload(withAppContext(ContractDetailComponent), (props) => props.id)
);
