import { SiteStatus } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { SyncCoreDataDefinitionsEnumValue } from '../../Localization/SyncCoreDataDefinitionsEnumValue';

interface IProps {
  status: SiteStatus;
  size?: 'small';
}

export class SiteStatusBadge extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { status, size } = this.props;

    const classes = size ? 'small' : '';

    const variants = {
      [SiteStatus.Active]: 'primary',
      [SiteStatus.Inactive]: 'danger',
    };

    return (
      <Badge bg={variants[status]} className={classes}>
        <SyncCoreDataDefinitionsEnumValue enumName={'SiteStatus'} keyName={status} />
      </Badge>
    );
  }
}
