import { ClientCustomerEntity } from '@edgebox/api-rest-client';
import { CustomerVatValidationStatus } from '@edgebox/data-definitions';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { Right } from '@edgebox/react-components';
import { Alert } from 'react-bootstrap';
import { ApiComponent, CustomerForm, IApiComponentState } from '../../../common';

interface IProps {}

interface IState extends IApiComponentState {
  customer?: ClientCustomerEntity;
  saving?: boolean;
  editCustomer?: ClientCustomerEntity;
}

export class AccountCompany extends ApiComponent<IProps, IState> {
  async load(): Promise<Partial<IState>> {
    const customer = await this.getCurrentCustomer();

    return {
      customer,
    };
  }

  render() {
    const { customer, saving, editCustomer } = this.state;

    if (!customer) {
      return this.renderRequest();
    }

    return (
      <Container>
        {!customer.dontBill && !!customer.vatValidationStatus && customer.vatValidationStatus !== CustomerVatValidationStatus.Approved ? (
          customer.vatValidationStatus === CustomerVatValidationStatus.Declined ? (
            <Alert variant="danger">Validating your VAT ID has failed. Please update your VAT ID or company address to try again.</Alert>
          ) : (
            <Alert variant="warning">
              EU law requires us to validate your VAT ID before you can make a purchase. Validation is pending for your profile and can take
              up to a day.
            </Alert>
          )
        ) : null}
        <CustomerForm
          mode={editCustomer ? 'edit-full' : 'view-full'}
          onToggleEdit={() => this.setState({ editCustomer: new ClientCustomerEntity(customer) })}
          onSave={async (update) => {
            this.setState({
              saving: true,
            });

            const updatedCustomer = await this.api.billing.customers.updateSelf(update);

            this.setState({
              customer: updatedCustomer,
              saving: false,
              editCustomer: undefined,
            });
          }}
          key={editCustomer ? '1' : '0'}
          entity={editCustomer || customer}
        >
          <Right>
            {editCustomer && (
              <>
                <Button variant={'light'} onClick={() => this.setState({ editCustomer: undefined })}>
                  Cancel
                </Button>
                <Button type={'submit'} variant={'primary'} disabled={saving}>
                  Save
                </Button>
              </>
            )}
          </Right>
        </CustomerForm>
      </Container>
    );
  }
}
