import { ClientCustomerEntity, ClientSiteEntity } from '@edgebox/api-rest-client';
import { Right } from '@edgebox/react-components';
import moment from 'moment';
import React from 'react';
import { Group, Scale, Heatmap } from '@visx/visx';
import { ClientUsageStatsEntity } from '@edgebox/api-rest-client/dist/client/Syndication/UsageStatsService';
import { Alert } from 'react-bootstrap';
import { ApiComponent, IApiComponentState } from '../../../common';
import { AppContextSiteSelector, IAppContextProp, withAppContext } from '../../../common/contexts/AppContext';
import { propertyChangeReload } from '../../../common/helpers/propertyChangeReload';

interface IProps extends JSX.IntrinsicAttributes, IAppContextProp {
  forSite?: ClientSiteEntity;
  forCustomer?: ClientCustomerEntity;
}
interface IState extends IApiComponentState {
  selectedSite?: ClientSiteEntity;
  selectedCustomer?: ClientCustomerEntity;
  data?: IStatCol[];
  loading?: boolean;
}

interface IStatRow {
  bin: number;
  count: number;
}
interface IStatCol {
  bin: number;
  bins: IStatRow[];
}

const primaryColor = '#009ee3';

function makeData(clb: (start: moment.Moment, end: moment.Moment) => ClientUsageStatsEntity[]) {
  const data: IStatCol[] = [];
  for (let day = 7; day >= 0; day--) {
    const bins: IStatRow[] = [];
    for (let hour = 0; hour < 24; hour++) {
      const start = moment().subtract(day, 'days').startOf('day').add(hour, 'hours');
      const end = start.clone().add(1, 'hour');
      const matches = clb(start, end);
      bins.push({
        bin: day,
        count: matches.reduce((a, b) => a + b.updates + b.freeUpdates, 0),
      });
    }
    data.push({
      bin: day,
      bins,
    });
  }
  return data;
}
const NO_DATA = makeData(() => []);

class HeatmapStatsClass extends ApiComponent<IProps, IState> {
  async load() {
    const selectedSite = this.props.forSite || this.props.appContext.site;
    const selectedCustomer = selectedSite ? undefined : this.props.forCustomer || this.props.appContext.customer;

    // This will be 8 items to cover the last 7 days of activity plus today.
    const from = moment().subtract(7, 'days').startOf('day');
    const to = moment();

    const individual = await this.api.syndication.usageStats.list(
      {
        from: from.valueOf(),
        to: to.valueOf(),
      },
      selectedSite
        ? { siteId: selectedSite.id }
        : { customerId: selectedCustomer ? selectedCustomer.id : this.api.currentUser!.customer!.getId()! },
      'hourly'
    );

    const data = makeData((start, end) => individual.filter((c) => c.from.isSame(start)));

    return {
      selectedCustomer,
      selectedSite,
      data,
      loading: false,
    };
  }

  render() {
    const { forSite } = this.props;
    const { selectedSite, selectedCustomer, data, loading } = this.state;

    if (!data) {
      return this.renderRequest();
    }

    // scales
    const xScale = Scale.scaleLinear<number>({
      domain: [0, 8],
    });
    const yScale = Scale.scaleLinear<number>({
      domain: [0, 24],
    });

    const colorMax =
      data?.reduce((a, b) => {
        let other = b.bins.reduce((a, b) => (a > b.count ? a : b.count), 0);
        return a > other ? a : other;
      }, 0) || 1;
    const colorScale = Scale.scaleLinear<string>({
      range: [primaryColor, primaryColor],
      domain: [0, 1_000_000],
    });
    const opacityScale = Scale.scaleLinear<number>({
      range: [0.005, 1],
      domain: [0, colorMax],
    });

    return (
      <div className="d-flex flex-column">
        {!forSite && (
          <div className="flex-grow-0 flex-shrink-0">
            <Right>
              {!this.props.forSite && (
                <div style={{ width: '50%' }} className="ps-1">
                  <AppContextSiteSelector />
                </div>
              )}
            </Right>
          </div>
        )}

        {data && !loading && !data.reduce((a, b) => a + b.bins.reduce((a, b) => a + b.count, 0), 0) && (
          <Alert className="mt-1 flex-grow-0 flex-shrink-0" variant="warning">
            No data yet.
          </Alert>
        )}

        <div className="d-flex flex-grow-1 flex-shrink-1">
          <div
            className="d-flex flex-shrink-0 flex-grow-0 flex-column flex-nowrap"
            style={{ flexBasis: '40px', maxHeight: '300px', height: '300px', minHeight: '0', margin: '10px 0 0 0' }}
          >
            {new Array(24).fill(0).map((c, i) => (
              <div
                key={i}
                className={`${(i + 1) % 6 ? 'text-light' : 'text-muted'} text-end pe-1`}
                style={{ fontSize: '0.6rem', flex: '1 1 20px', minHeight: '10px', maxHeight: '13px' }}
              >
                {moment()
                  .startOf('day')
                  .add(23 - i, 'hours')
                  .format('hh a')}
              </div>
            ))}
          </div>
          <div className="flex-shrink-1 flex-grow-1">
            <svg height={'300px'} width="100%" style={{ margin: '10px 0 0 0' }}>
              <Group.Group>
                <Heatmap.HeatmapRect
                  data={data || NO_DATA}
                  xScale={(d: number) => xScale(d) ?? 0}
                  yScale={(d: number) => yScale(d) ?? 0}
                  colorScale={colorScale}
                  opacityScale={opacityScale}
                  binWidth={300 / 8}
                  binHeight={300 / 24}
                  gap={1}
                >
                  {(heatmap) =>
                    heatmap.map((heatmapBins) =>
                      heatmapBins.map((bin) => (
                        <rect
                          key={`heatmap-rect-${bin.row}-${bin.column}`}
                          className="visx-heatmap-rect"
                          width={(1 / 8) * 100 * 0.99 + '%'}
                          height={(1 / 24) * 100 * 0.95 + '%'}
                          x={(bin.column / 8) * 100 + '%'}
                          y={((23 - bin.row) / 24) * 100 + '%'}
                          fill={bin.color}
                          fillOpacity={bin.opacity}
                        ></rect>
                      ))
                    )
                  }
                </Heatmap.HeatmapRect>
              </Group.Group>
            </svg>

            <div className="d-flex">
              {data?.map((c, i) => (
                <div key={i} className="flex-fill text-center text-muted">
                  {i === 7
                    ? 'Today'
                    : moment()
                        .subtract(7 - i, 'days')
                        .format('ddd')}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const HeatmapStats = withAppContext(propertyChangeReload(HeatmapStatsClass, (props) => props.appContext.siteKey));
