import { ClientCustomerEntity } from '@edgebox/api-rest-client';
import { PaymentMethod } from '@edgebox/api-rest-client/dist/services/api';
import { CustomerPaymentStatus } from '@edgebox/data-definitions';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons/faCreditCard';
import React from 'react';
import Alert from 'react-bootstrap/cjs/Alert';
import Button from 'react-bootstrap/cjs/Button';
import Col from 'react-bootstrap/cjs/Col';
import Row from 'react-bootstrap/cjs/Row';
import { EditButton, IconButton, LeftRightContainer } from '@edgebox/react-components';
import { LeftRightH1 } from '@edgebox/react-components';
import { ApiComponent, Checkout, CustomerForm, IApiComponentState } from '../../../common';
import { HeadlineWithBreadcrumbNavigation } from '../../../common/components/BreadcrumbNavigation';

interface IState extends IApiComponentState {
  customer?: ClientCustomerEntity;
  show?: boolean;
  saved?: boolean;
  hasExistingCard?: boolean;
  last4CardDigits?: string;
  paymentMethod: PaymentMethod;
}

export class AccountPayment extends ApiComponent<{}, IState> {
  async load(): Promise<Partial<IState>> {
    const customer = await this.getCurrentCustomer();

    const hasExistingCard =
      customer?.paymentStatus === CustomerPaymentStatus.PaymentAuthorised ||
      customer?.paymentStatus === CustomerPaymentStatus.PaymentSuccessful;

    const paymentMethodResponse = await this.api.integrationStripe.stripe.getPaymentMethod();

    return {
      show: customer?.paymentStatus === CustomerPaymentStatus.PaymentFailed,
      customer,
      hasExistingCard,
      last4CardDigits: paymentMethodResponse?.last4CardDigits || undefined,
      paymentMethod: paymentMethodResponse?.paymentMethod,
    };
  }

  render() {
    const { customer, show, saved, hasExistingCard, last4CardDigits, paymentMethod } = this.state;

    if (!customer) {
      return this.renderRequest();
    }

    const noPaymentDetails = [CustomerPaymentStatus.None, CustomerPaymentStatus.CheckoutStarted].includes(customer.paymentStatus);

    const details = last4CardDigits
      ? `Your current ${paymentMethod === PaymentMethod.SepaDebit ? 'IBAN number' : 'card'} ends with ${last4CardDigits}.`
      : '';

    const renderStatus = () => {
      if (saved) {
        return <Alert variant={'primary'}>Your payment details have been saved. {details}</Alert>;
      }

      if (customer.paymentStatus === CustomerPaymentStatus.CheckoutRefused) {
        return (
          <Alert variant={'danger'}>
            Your last checkout was declined. Please contact your bank if this issue persists or try a different card. {details}
          </Alert>
        );
      } else if (customer.paymentStatus === CustomerPaymentStatus.CheckoutCancelled) {
        return <Alert variant={'danger'}>You have cancelled your last checkout. {details}</Alert>;
      } else if (customer.paymentStatus === CustomerPaymentStatus.CheckoutError) {
        return <Alert variant={'warning'}>There was an unexpected error during your last checkout; please try again. {details}</Alert>;
      } else if (noPaymentDetails) {
        return <Alert variant={'warning'}>No payment data saved yet. {details}</Alert>;
      } else if (customer.paymentStatus === CustomerPaymentStatus.CheckoutPending) {
        return <Alert variant={'info'}>We are waiting for confirmation from your bank to accept payments. {details}</Alert>;
      } else if (customer.paymentStatus === CustomerPaymentStatus.PaymentFailed) {
        return <Alert variant={'danger'}>Your last payment failed. Please update your card details below. {details}</Alert>;
      }

      return <Alert variant={'primary'}>Your payment details are saved and ready. {details}</Alert>;
    };

    const billingAddress = <CustomerForm entity={customer} onSave={async () => {}} mode={'view-billing-address'} name={<></>} />;

    return (
      <>
        <LeftRightContainer
          left={<HeadlineWithBreadcrumbNavigation>Payment</HeadlineWithBreadcrumbNavigation>}
          right={
            show ? undefined : noPaymentDetails ? (
              <IconButton variant={'primary'} onClick={() => this.setState({ show: true })} icon={faCreditCard}>
                Add card
              </IconButton>
            ) : (
              <EditButton variant={'light'} onClick={() => this.setState({ show: true })}>
                Change card
              </EditButton>
            )
          }
        />

        {renderStatus()}

        {billingAddress}

        {show ? (
          <Row>
            <Col xs={6}>
              <h4>Change card</h4>
              <Checkout
                update={true}
                onPaid={async () => {
                  const paymentMethodResponse = await this.api.integrationStripe.stripe.getPaymentMethod();

                  this.setState({
                    show: false,
                    saved: true,
                    last4CardDigits: paymentMethodResponse.last4CardDigits || undefined,
                    paymentMethod: paymentMethodResponse.paymentMethod,
                  });
                }}
              >
                {hasExistingCard ? (
                  <Button variant={'light'} onClick={() => this.setState({ show: false })}>
                    Use existing card
                  </Button>
                ) : undefined}
              </Checkout>
            </Col>
          </Row>
        ) : undefined}
      </>
    );
  }
}
