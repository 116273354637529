import { UserType } from '@edgebox/data-definitions';
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { DataDefinitionsEnumValue } from '../../Localization/DataDefinitionsEnumValue';

interface IProps {
  type: UserType;
}

export class UserTypeBadge extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { type } = this.props;

    const classes = 'opacity-3';

    const variants = {
      [UserType.Internal]: 'light',
      [UserType.Customer]: 'info',
    };

    return (
      <Badge bg={variants[type]} className={classes}>
        <DataDefinitionsEnumValue enumName={'UserType'} keyName={type} />
      </Badge>
    );
  }
}
