import React from 'react';
import Button from 'react-bootstrap/Button';
import { FormRow } from '@edgebox/react-components';
import { EditMode } from '../../contexts/EntityContext';
import { EntityFormElement } from './EntityFormElement';

type RenderCallback = () => React.ReactNode;

interface IProps<Type> {
  name: keyof Type;
  label?: React.ReactNode;
  edit?: RenderCallback;
  view?: RenderCallback;
  help?: React.ReactNode;
  className?: string;
  noRow?: boolean;
  required?: boolean;
}

interface IState {}

export class EntityFormRow<Type> extends EntityFormElement<Type, IProps<Type>, IState> {
  render(): React.ReactElement | null {
    const { name, label, edit, view, help, noRow, className, required } = this.props;

    if (!edit && !view) {
      return null;
    }

    const { editMode, requiredProperties } = this.entityContext;

    const showInput = this.entityContext.isEditing(name);

    if (editMode === EditMode.None || editMode === EditMode.Selective) {
      if (!view && !showInput) {
        return null;
      }
    }

    const content = (
      <>
        {showInput ? (
          edit ? (
            edit()
          ) : view ? (
            view()
          ) : undefined
        ) : editMode === EditMode.None ? (
          view ? (
            view()
          ) : undefined
        ) : (
          <div>
            {view ? view() : undefined}

            {editMode === EditMode.Selective || !edit ? undefined : (
              <Button variant={'link'} className={'ms-2'} onClick={() => this.entityContext.edit(name)}>
                {editMode === EditMode.Inline ? (view ? 'Change' : 'Add') : label ? 'Extend' : 'Change'}
              </Button>
            )}
          </div>
        )}
      </>
    );

    if (noRow) {
      return content;
    }

    return (
      <FormRow
        name={name as string}
        key={name as string}
        label={label || ''}
        labelPlacement={label ? undefined : 'none'}
        help={showInput && edit ? help : undefined}
        required={showInput && edit && (required === undefined ? requiredProperties.includes(name as string) : required)}
        className={className}
      >
        {content}
      </FormRow>
    );
  }
}
