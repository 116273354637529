import { IInternalAuthentication } from '@edgebox/api-rest-client';
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import { DisclaimerFooter } from './DisclaimerFooter';
import './Login.scss';
import VerifyEmailImage from '../../images/undraw_join_of2w.svg';
import { Alert, Image } from 'react-bootstrap';
import { AuthenticationContext, Logo } from '../../common';

export class VerifyEmail extends Component<{}, {}> {
  static contextType = AuthenticationContext;
  context!: IInternalAuthentication;
  get authentication(): IInternalAuthentication {
    return this.context;
  }

  public render() {
    return (
      <div className="mx-auto">
        <Container className={'login'}>
          <div className={'logo'}>
            <Logo height={75} />
          </div>
          <h1>Content Sync</h1>
          <h2 className={'mt-3 text-muted'}>Create Content Once &amp; Use It Everywhere.</h2>

          <Alert variant="success" className="p-5">
            Welcome aboard! <strong>Please verify your email address</strong>, then refresh this page to continue.
          </Alert>
          <div>
            <Image src={VerifyEmailImage} style={{ width: '80%' }} className="mx-auto mt-2" />
          </div>

          <div className={'mt-5 mb-5'}>&nbsp;</div>

          <DisclaimerFooter />
        </Container>
      </div>
    );
  }
}
