import { SyncCoreStatus } from '@edgebox/data-definitions';
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { DataDefinitionsEnumValue } from '../../Localization/DataDefinitionsEnumValue';

interface IProps {
  status: SyncCoreStatus;
}

export class SyncCoreStatusBadge extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { status } = this.props;

    const classes = 'opacity-3';

    const variants = {
      [SyncCoreStatus.Active]: 'success',
      [SyncCoreStatus.Retired]: 'dark',
    };

    return (
      <Badge bg={variants[status]} className={classes}>
        <DataDefinitionsEnumValue enumName={'SyncCoreStatus'} keyName={status} />
      </Badge>
    );
  }
}
