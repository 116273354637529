import React from 'react';

export enum EditMode {
  // Default. Don't allow to edit anything. Hide properties that are empty.
  None = 'none',
  // Edit the entity. Show all available properties.
  Full = 'full',
  // Edit the entity inline. Show all fields. User has to select "Edit" per property to edit it.
  Inline = 'inline',
  // Edit the entity inline. Hide empty properties. User can only edit properties that are explicitly
  // provided in the "extendProperties" state.
  Selective = 'selective',
}

export interface IEntityContext<Type> {
  entity: Type;
  editMode: EditMode;
  isEditing: (name: keyof Type) => boolean;
  edit: (name: keyof Type) => void;
  requiredProperties: string[];
}

export const EntityContext: React.Context<IEntityContext<any>> = React.createContext<IEntityContext<any>>({
  isEditing: () => false,
  edit: () => {
    throw new Error('Please provide an EntityContext.');
  },
  entity: {} as any,
  editMode: EditMode.None,
  requiredProperties: [],
});
