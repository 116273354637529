import { ClientBasketEntity, ClientContractRevisionEntity } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import { BasketStatus, CustomerRole } from '@edgebox/data-definitions';
import { IconButton } from '@edgebox/react-components';
import { Package } from '@edgebox/sync-core-data-definitions';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons/faLongArrowRight';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { ApiComponent, IApiComponentState } from '../../common';
import { withUserRoles } from '../../common/components/UserRoleGate';

interface IProps extends JSX.IntrinsicAttributes {
  contractId: InternalId;
}

interface IState extends IApiComponentState {
  currentContractRevision?: ClientContractRevisionEntity;
  existingBasket?: ClientBasketEntity;
}

class ViewUpgradeButtonComponent extends ApiComponent<IProps, IState> {
  async load() {
    const mostRecent = await this.api.billing.contractRevisions.getMostRecentForContract(this.props.contractId);
    const baskets = await this.api.billing.baskets.list(
      mostRecent.current?.customer.getId(),
      undefined,
      [BasketStatus.Created, BasketStatus.Proposal, BasketStatus.Requested],
      { itemsPerPage: 100 }
    );
    const existingBasket = baskets.items.find((c) => c.contract?.getId() === this.props.contractId);

    return {
      currentContractRevision: mostRecent.current,
      existingBasket,
    };
  }

  render() {
    const { contractId } = this.props;
    const { currentContractRevision, existingBasket } = this.state;

    if (currentContractRevision?.packageType === Package.Trial) {
      if (existingBasket) {
        if (existingBasket.status === BasketStatus.Requested) {
          return (
            <Alert variant="light" className="p-2 mt-2">
              Proposal requested.
            </Alert>
          );
        }

        return (
          <IconButton
            variant={'primary'}
            iconPosition={'right'}
            icon={faLongArrowRight}
            to={`/checkout/${existingBasket.id}`}
            className={`mt-4 ps-2 pe-2 pt-2 pb-2 fw-bold`}
          >
            View {existingBasket.status === BasketStatus.Proposal ? 'Proposal' : 'Basket'}
          </IconButton>
        );
      }

      return (
        <IconButton
          variant={'primary'}
          iconPosition={'right'}
          icon={faLongArrowRight}
          to={`/subscriptions/${contractId}/revisions/${currentContractRevision.id}/upgrade`}
          className={`mt-4 ps-4 pe-4 pt-2 pb-2 fw-bold`}
        >
          Upgrade
        </IconButton>
      );
    }

    return null;
  }
}

export const ViewUpgradeButton = withUserRoles(ViewUpgradeButtonComponent, [CustomerRole.Owner, CustomerRole.ManageFinances]);
