import { ClientContractEntity, ClientContractRevisionEntity } from '@edgebox/api-rest-client';
import { DateTimeFormat, formatDate, InternalId, IPagedListResponse } from '@edgebox/data-definition-kit';
import { HostingType, SyncCoreDataDefinitionsEnumTranslator } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { EntitySelector, IEntitySelectorProps, IEntitySelectorState } from '../../Form/EntitySelector';
import { FormatDateTime } from '../../Localization/FormatDateTime';
import { SyncCoreDataDefinitionsEnumValue } from '../../Localization/SyncCoreDataDefinitionsEnumValue';
import { ITextSearchFilter } from '../../PagedList';
import { ContractRevisionStatusBadge } from '../Badges/ContractRevisionStatusBadge';
import { ViewContractRevisionName } from '../Views/ViewContractRevisionName';
import { ViewPackageName } from '../Views/ViewPackageName';

type ClientContractRevisionEntityWithContract = ClientContractRevisionEntity & {
  contractEntity?: ClientContractEntity;
};

interface IProps extends IEntitySelectorProps<ClientContractRevisionEntityWithContract> {
  forCustomer?: InternalId;
  forPricing?: InternalId;
  forContract?: InternalId;
  forType?: HostingType;
  includeInactive?: boolean;
}

interface IState extends IEntitySelectorState<ClientContractRevisionEntityWithContract> {}

export class ContractRevisionSelector extends EntitySelector<ClientContractRevisionEntityWithContract, IProps, IState> {
  renderEmpty() {
    return 'Select subscription';
  }

  renderCurrentValue(selected: ClientContractRevisionEntityWithContract): React.ReactElement {
    return <ViewContractRevisionName entity={selected} />;
  }

  setSelected(selected?: ClientContractRevisionEntityWithContract) {
    super.setSelected(selected);

    if (selected && !selected.contractEntity) {
      selected.contract.get().then((contractEntity) => {
        if (this.state.selected === selected) {
          this.setSelected(
            Object.assign(new ClientContractRevisionEntity(), {
              ...selected,
              contractEntity,
            })
          );
        }
      });
    }
  }

  async search(page: number, query?: ITextSearchFilter): Promise<IPagedListResponse<ClientContractRevisionEntityWithContract>> {
    const { forCustomer, forContract, forPricing, forType, includeInactive } = this.props;

    const result = await this.api.billing.contractRevisions.search(
      query?.search,
      { page },
      forCustomer,
      forContract,
      forPricing,
      forType,
      includeInactive
    );
    return {
      ...result,
      items: await Promise.all(
        result.items.map(async (revision) => {
          const contractEntity = await revision.contract.get();
          return Object.assign(new ClientContractRevisionEntity(), {
            ...revision,
            contractEntity,
          });
        })
      ),
    };
  }

  renderAddForm(): React.ReactElement | null {
    return null;
  }

  renderItem(entity: ClientContractRevisionEntityWithContract): React.ReactElement {
    return (
      <Row>
        <Col>
          {entity.contractEntity?.name ? (
            <>
              <h3>{entity.contractEntity.name}</h3>
              <strong>
                <ViewPackageName product={entity.product} packageType={entity.packageType} />{' '}
                <ContractRevisionStatusBadge contract={entity} />
              </strong>
            </>
          ) : (
            <h3>
              <ViewPackageName product={entity.product} packageType={entity.packageType} />{' '}
              <ContractRevisionStatusBadge contract={entity} />
            </h3>
          )}

          <div className={'text-muted'}>
            from <FormatDateTime date={entity.startDate} format={DateTimeFormat.Date} /> to{' '}
            <FormatDateTime date={entity.endDate} format={DateTimeFormat.Date} />
          </div>
        </Col>
      </Row>
    );
  }

  renderItemAsText(entity: ClientContractRevisionEntityWithContract): string {
    if (entity.contractEntity?.name) {
      return entity.contractEntity.name;
    }
    return `${SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue(
      'Product',
      entity.product
    )} ${SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('Package', entity.packageType)} from ${formatDate(
      entity.startDate,
      DateTimeFormat.Date
    )} to ${formatDate(entity.endDate, DateTimeFormat.Date)}`;
  }
}
