import { ClientCustomerEntity, ClientDiscountDraft, ClientDiscountEntity } from '@edgebox/api-rest-client';
import {
  Currency,
  DataDefinitionsEnumTranslator,
  DiscountStatus,
  DiscountType,
  ISerializedDiscountProperties,
  UserType,
} from '@edgebox/data-definitions';
import { FormField } from '@edgebox/react-components';
import React, { PropsWithChildren } from 'react';
import { EditMode, EntityContext } from '../../../contexts/EntityContext';
import { EntityForm, entityFormWithValidation, IEntityFormProps, IEntityFormState } from '../../Form/EntityForm';
import { EntityFormRow } from '../../Form/EntityFormRow';
import { EnumAsDropdown } from '../../Form/EnumAsDropdown';
import { EditName } from '../../Form/Properties/EditName';
import { DataDefinitionsEnumValue } from '../../Localization/DataDefinitionsEnumValue';
import { MoneyAmount } from '../../Localization/MoneyAmount';
import { DiscountStatusBadge } from '../Badges/DiscountStatusBadge';

interface IProps<Entity extends ClientDiscountDraft> extends IEntityFormProps<Entity, ISerializedDiscountProperties>, PropsWithChildren {
  onSave: (entity: Entity) => Promise<void>;
  className?: string;
  mode: 'edit-full' | 'create' | 'view-full-backend';
}

const EDIT_MODES = {
  'view-full-backend': EditMode.None,
  'edit-full': EditMode.Full,
  create: EditMode.Full,
};

interface IState<Entity extends ClientDiscountDraft> extends IEntityFormState<Entity> {
  customer?: ClientCustomerEntity;
}

const FormWithValidation = entityFormWithValidation<ISerializedDiscountProperties, ClientDiscountDraft>(
  ClientDiscountDraft,
  ClientDiscountEntity
);

export class DiscountForm<Entity extends ClientDiscountDraft = ClientDiscountDraft> extends EntityForm<
  Entity,
  ISerializedDiscountProperties,
  IProps<Entity>,
  IState<Entity>
> {
  constructor(props: IProps<Entity>) {
    super(props, {
      edit: EDIT_MODES[props.mode],
    });
  }

  async load(): Promise<Partial<IState<Entity>>> {
    const customer = await this.props.entity.customer?.get();

    return { customer };
  }

  componentDidUpdate(prevProps: Readonly<IProps<Entity>>, prevState: Readonly<IState<Entity>>, snapshot?: any): void {
    if (this.props.mode !== prevProps.mode) {
      this.setState({
        edit: EDIT_MODES[this.props.mode],
      });
    }
  }

  render(): React.ReactElement {
    const { mode, children, onToggleEdit } = this.props;

    const { customer } = this.state;

    const renderBackendProperties = this.api.currentUser!.type === UserType.Internal;

    const createNew = mode === 'create';

    return (
      <EntityContext.Provider value={this.entityContext}>
        <FormWithValidation
          {...(this.formProperties() as any)}
          apiComponent={this}
          initialValues={this.props.entity}
          labels={'above'}
          onSubmit={async (draft) => {
            this.setState({
              extendProperties: [],
            });

            await this.props.onSave(draft as Entity);
          }}
        >
          {({ values, setValue }) => (
            <>
              {this.props.name || <EditName value={values.name} onToggleEdit={onToggleEdit} />}

              <EnumAsDropdown<ClientDiscountEntity, DiscountType>
                enumValues={DataDefinitionsEnumTranslator.translateEnum('DiscountType')}
                currentValue={values.type}
                name={'type'}
                label={'Type'}
                setValue={setValue as any}
                showStatic={!createNew}
                renderValue={(value) => <DataDefinitionsEnumValue enumName={'DiscountType'} keyName={value} />}
              />

              <EnumAsDropdown<ClientDiscountEntity, DiscountStatus>
                enumValues={DataDefinitionsEnumTranslator.translateEnum('DiscountStatus')}
                currentValue={values.status}
                name={'status'}
                label={'Status'}
                setValue={setValue as any}
                renderValue={(value) => <DiscountStatusBadge status={value} />}
              />

              <EntityFormRow
                name={'amountPercentage'}
                label={'Percentage discount'}
                view={() => (values.amountPercentage ? `${values.amountPercentage * 100}%` : 'none')}
                edit={createNew ? () => <FormField type={'number'} name={'amountPercentage'} noRow label={'Percentage'} /> : undefined}
              />

              <EntityFormRow
                name={'amountFixed'}
                label={'Fixed discount'}
                view={() => (values.amountFixed ? <MoneyAmount amount={values.amountFixed} currency={Currency.EUR} /> : 'none')}
                edit={createNew ? () => <FormField type={'money-amount'} name={'amountFixed'} noRow label={'Fixed'} /> : undefined}
              />

              {children}
            </>
          )}
        </FormWithValidation>
      </EntityContext.Provider>
    );
  }
}
