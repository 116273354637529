import { Package, Product } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import { SyncCoreDataDefinitionsEnumValue } from '../../Localization/SyncCoreDataDefinitionsEnumValue';

interface IProps {
  product: Product;
  packageType: Package;
}

interface IState {}

export class ViewPackageName extends React.Component<IProps, IState> {
  render(): React.ReactElement {
    const { product, packageType } = this.props;

    return (
      <>
        <SyncCoreDataDefinitionsEnumValue<Product> enumName={'Product'} keyName={product} />{' '}
        <SyncCoreDataDefinitionsEnumValue<Package> enumName={'Package'} keyName={packageType} />
      </>
    );
  }
}
