import React from 'react';
import { PagedContractList } from '../../Shared/PagedContractList';

export class BackendContractList extends React.Component {
  render() {
    return (
      <>
        <PagedContractList backend />
      </>
    );
  }
}
