import { AuthenticationClient, IInternalAuthentication } from '@edgebox/api-rest-client';
import { ClientError } from '@edgebox/data-definition-kit';
import { UserType } from '@edgebox/data-definitions';
import { IAppConfigurationProp, withAppConfiguration } from '@edgebox/react-components';
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';
import { ContentSyncPageLoading } from '../components/LogoPageLoading';
import { IDataComponentConfiguration } from '../services/ApiComponent';
import { Request } from '../services/Request';
import { AuthenticationContext } from './AuthenticationContext';

export const LOGIN_PATH = '/login';
export const LOGIN_PATH_SUCCESS = `${LOGIN_PATH}/success/`;
export const LOGIN_PATH_FAILURE = `${LOGIN_PATH}/failure/`;
interface IState extends IInternalAuthentication {
  authClient: AuthenticationClient;
  isLoading: boolean;
  isAuthenticated: boolean;
  jwt?: string;
  loginWithRedirect: (redirectPath?: string, userType?: UserType) => void;
  logout: (redirectPath?: string) => void;
  redirectPath?: string;
  error?: ClientError;
}

interface IOutsideProps {
  requireAuthentication?: boolean;
}

interface IProps extends IOutsideProps, IAppConfigurationProp<IDataComponentConfiguration>, PropsWithChildren {}

function AuthenticationProviderComponent(props: IProps) {
  const authClient = AuthenticationClient.getInstance({
    apiDomain: props.appConfiguration.apiDomain,
    onStatusChanged: () => {
      // Propagate change of auth status
      setState({
        ...state,
        authClient: authClient,
        isAuthenticated: authClient.isAuthenticated,
        isLoading: false,
        jwt: authClient.jwt,
      });

      if (props.requireAuthentication) {
        if (!authClient.isAuthenticated) {
          state.loginWithRedirect();
        }
      }
    },
  });

  const defaultState: IState = {
    isLoading: !!props.requireAuthentication,
    isAuthenticated: false,
    authClient: authClient,
    loginWithRedirect: (redirectPath?: string, userType?: UserType) =>
      authClient.loginWithRedirect(
        redirectPath === undefined ? `${window.location.pathname}${window.location.search}` : redirectPath,
        userType
      ),
    logout: (redirectPath?: string) =>
      authClient.logout(redirectPath === undefined ? `${window.location.pathname}${window.location.search}` : redirectPath),
    handleRedirectCallback: () => {},
  };

  const [state, setState] = useState<IState>(defaultState);

  const { children } = props;
  let redirectPath = state.redirectPath;
  const { isLoading, error } = state;

  const handleRedirectCallback = useCallback(
    async (jwt: string, redirectPath?: string) => {
      setState({ ...state, redirectPath });
      await state.authClient.handleRedirectCallback(jwt);
    },
    [state]
  );
  const handleFailedRedirect = useCallback(
    async (message: string) => {
      state.authClient.handleFailedRedirect(message);
    },
    [state.authClient]
  );

  useEffect(() => {
    initializeAuth();
  }, []);

  const location = useLocation();

  const isSuccessPage = useCallback(() => {
    return location.pathname.includes(LOGIN_PATH_SUCCESS);
  }, [location.pathname]);

  const isFailurePage = useCallback(() => {
    return location.pathname.includes(LOGIN_PATH_FAILURE);
  }, [location.pathname]);

  const initializeAuth = async () => {
    // SUCCESS check to see if they have been redirected after login
    if (isSuccessPage()) {
      const jwt = location.pathname.replace(LOGIN_PATH_SUCCESS, '');
      const redirectPath = location.search.replace('?redirectPath=', '');

      return await handleRedirectCallback(jwt, decodeURIComponent(redirectPath));
    }

    // FAILURE check to see if they have been redirected after login
    if (isFailurePage()) {
      const message = location.pathname.replace(LOGIN_PATH_FAILURE, '');
      return await handleFailedRedirect(decodeURIComponent(message));
    }

    try {
      await state.authClient.getAuthenticationStatus();
    } catch (error) {
      console.log('auth error', error);
      setState({
        ...state,
        error: error as ClientError,
      });
    }
  };

  // In case of a successful login, we redirect the user to the home page.
  // In case of a failed login, the routing of the app will handle displaying a message to the user.
  return (
    <AuthenticationContext.Provider value={state}>
      {isLoading ? (
        <Request error={error} loadingAnimation={error ? 'none' : () => <ContentSyncPageLoading />} showUnauthorizedModal={true} />
      ) : isSuccessPage() ? (
        <Navigate to={redirectPath ? redirectPath : '/'} />
      ) : (
        children
      )}
    </AuthenticationContext.Provider>
  );
}

export const AuthenticationProvider: React.ComponentType<IOutsideProps> = withAppConfiguration<IOutsideProps, IDataComponentConfiguration>(
  AuthenticationProviderComponent
);
