import React, { PropsWithChildren } from 'react';
import NotFoundImage from '../images/undraw_not_found_60pq.svg';
import { ContainerWithImage } from './ContainerWithImage';

interface IProps extends PropsWithChildren {}
interface IState {}

export class ErrorPage extends React.Component<IProps, IState> {
  render() {
    const { children } = this.props;

    return (
      <ContainerWithImage title="Oh no!" image={NotFoundImage}>
        {children}
      </ContainerWithImage>
    );
  }
}
