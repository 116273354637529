import { IInternalAuthentication } from '@edgebox/api-rest-client';
import { ClientError, ClientErrorType } from '@edgebox/data-definition-kit';
import { mapClassValidatorToFormikErrors } from '@edgebox/react-components/dist/components/Form/FormikErrorHandler';
import { FormikErrors } from 'formik';
import React, { CSSProperties } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { LoadingBar } from '@edgebox/react-components';
import { Throbber } from '@edgebox/react-components';
import { AuthenticationContext } from '../contexts/AuthenticationContext';

export type RequestLoadingAnimation = 'none' | 'bar' | 'throbber' | (() => React.ReactElement);

interface IProps {
  loadingAnimation?: RequestLoadingAnimation;
  error?: ClientError;
  errorStyle?: CSSProperties;
  errorClassName?: string;
  showUnauthorizedModal?: boolean;
}

interface IState {
  hideUnauthorizedModal?: boolean;
  showLoadingAnimation?: boolean;
}

const SHOW_LOADING_ANIMATION_DELAY = 1_000;

export class Request extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  __isMounted = false;

  componentDidMount() {
    this.__isMounted = true;

    setTimeout(() => (this.__isMounted ? this.setState({ showLoadingAnimation: true }) : undefined), SHOW_LOADING_ANIMATION_DELAY);
  }

  componentWillUnmount() {
    this.__isMounted = false;
  }

  render(): React.ReactElement | null {
    const { loadingAnimation, error, showUnauthorizedModal, errorClassName, errorStyle } = this.props;
    const { hideUnauthorizedModal, showLoadingAnimation } = this.state;

    let loadingAnimationElement: React.ReactElement | null = null;

    if (!loadingAnimation || loadingAnimation === 'bar') {
      loadingAnimationElement = <LoadingBar />;
    } else if (loadingAnimation === 'throbber') {
      loadingAnimationElement = <Throbber />;
    } else if (loadingAnimation && loadingAnimation !== 'none') {
      loadingAnimationElement = loadingAnimation();
    }

    if (loadingAnimationElement) {
      return showLoadingAnimation ? loadingAnimationElement : null;
    }

    if (!error) {
      return null;
    }

    if (error.type === ClientErrorType.Validation) {
      if (error.details) {
        const details = error.details || error.message;

        if (Array.isArray(details)) {
          const errors = mapClassValidatorToFormikErrors(details) as FormikErrors<any>;
          const prettyName = (name: string) => {
            return (
              name
                // Add spacing
                .replace(/([a-z])([A-Z])/g, (match: string, start: string, letter: string) => `${start} ${letter.toLowerCase()}`)
                .replace(/^([a-z])/, (match: string, letter: string) => letter.toUpperCase())
            );
          };

          return (
            <Form.Group as={Row} controlId="formError">
              <Col>
                <Alert variant={'danger'}>
                  One of the values you entered failed further validation. Please fix the issues listed below:
                  <ul>
                    {Object.keys(errors).map((field, i) => {
                      const errorMessages: string[] = errors[field as any] as string[];
                      return (
                        <li key={i}>
                          <strong>{prettyName(field)}</strong>
                          {errorMessages.length === 1 ? (
                            `: ${errorMessages[0]}`
                          ) : (
                            <ul>
                              {errorMessages.map((errorMessage, n) => {
                                return <li key={n}>{errorMessage}</li>;
                              })}
                            </ul>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </Alert>
              </Col>
            </Form.Group>
          );
        } else if (typeof details === 'string') {
          return (
            <Form.Group as={Row} controlId="formError">
              <Col>
                <Alert variant={'danger'}>{details}</Alert>
              </Col>
            </Form.Group>
          );
        } else {
          return (
            <Form.Group as={Row} controlId="formError">
              <Col>
                <Alert variant={'danger'}>
                  An unexpected error occurred. You can try again, try reloading the page or contact us if this issue persists.
                </Alert>
              </Col>
            </Form.Group>
          );
        }
      }
    }

    if (error.type === ClientErrorType.Unauthorized && showUnauthorizedModal) {
      return (
        <AuthenticationContext.Consumer>
          {(context: IInternalAuthentication) => {
            const closeModal = () => {
              this.setState({ hideUnauthorizedModal: true });
            };

            const triggerLogin = () => {
              context.loginWithRedirect();
            };

            return (
              <Modal show={!hideUnauthorizedModal} onHide={closeModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Session expired</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <p>Please login again.</p>
                  <p>This will reload the page. If you have any unsaved data, you can close this modal and copy it first.</p>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="light" onClick={closeModal}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={triggerLogin}>
                    Login
                  </Button>
                </Modal.Footer>
              </Modal>
            );
          }}
        </AuthenticationContext.Consumer>
      );
    }

    const messages = {
      [ClientErrorType.Validation]: `Validation failed. Error message is: ${error.message}`,
      [ClientErrorType.NotFound]: "The requested item doesn't exist. If you think this is an error, please contact us.",
      [ClientErrorType.Unauthorized]: 'Your login expired. Please copy any unsaved work and refresh the page.',
      [ClientErrorType.Forbidden]: "You don't have the necessary permissions for this. Maybe you have to login with a different user.",
      [ClientErrorType.Timeout]: "Couldn't reach the Content Sync cloud. Please check your internet connection and try again.",
      [ClientErrorType.UnexpectedResponse]: "The Content Sync cloud didn't provide a valid response. Please try again later.",
      [ClientErrorType.Other]: `An unexpected error happened: ${error.message}`,
    };

    if (error.type === ClientErrorType.Other) {
      console.log('Unexpected error', error);
    }

    const message = messages[error.type];

    return (
      <Alert style={errorStyle} className={errorClassName} variant={'danger'}>
        {message}
      </Alert>
    );
  }
}
