import {
  ClientContractEntity,
  ClientCustomerEntity,
  ClientProjectEntity,
  ClientSiteEntity,
  ClientSyncCoreEntity,
} from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import React, { PropsWithChildren, useContext } from 'react';
import { ApiComponent, IApiComponentState } from '../services';
import { SiteEnvironmentType } from '@edgebox/sync-core-data-definitions';
import { SiteSelector } from '../components';
import { ILocationProp, INavigateProp, IParamsProp, withLocationAndNavigate, withParams } from '../../components/RouterHelper';
import { UserType } from '@edgebox/data-definitions';

export interface IAppContext {
  // Selected in the sidebar.
  customer?: ClientCustomerEntity;
  customerKey: string;
  hasMultipleCustomers?: boolean;
  contract?: ClientContractEntity;
  contractKey: string;
  hasMultipleContracts?: boolean;
  project?: ClientProjectEntity;
  projectKey: string;
  hasMultipleProjects?: boolean;

  // Optionally selected at some pages like Content and Updates.
  site?: ClientSiteEntity;
  customerHasAnySites?: boolean;
  customerHasAnySitesIncludingInactive?: boolean;
  siteKey: string;

  // The Sync Core, based on the selected site or selected project.
  syncCore: () => Promise<ClientSyncCoreEntity | undefined>;

  refreshContracts?: () => void;
  refreshProjects?: () => void;
  refreshSites?: () => void;

  setCustomer?: (customer: ClientCustomerEntity) => void;
  setContract?: (contract: ClientContractEntity, customer?: ClientCustomerEntity) => void;
  setProject?: (project: ClientProjectEntity, contract?: ClientContractEntity, customer?: ClientCustomerEntity) => void;
  setSite?: (selected?: ClientSiteEntity) => void;
}

export const AppContext: React.Context<IAppContext> = React.createContext<IAppContext>({
  customerKey: '',
  contractKey: '',
  projectKey: '',
  siteKey: '',
  syncCore: () => Promise.resolve(undefined as undefined | ClientSyncCoreEntity),
});

export interface IAppContextProp {
  appContext: IAppContext;
}
export function withAppContext<Props extends JSX.IntrinsicAttributes>(
  Component: React.ComponentType<Props>
): React.ComponentType<Omit<Props, 'appContext'>> {
  return function WithAppContext(props: Omit<Props, 'appContext'>) {
    const appContext = useContext(AppContext);
    const innerProps: Props = { ...props, appContext } as any;
    return <Component {...innerProps} />;
  };
}
export function WithAppContext<ReturnType extends React.ReactNode>({ children }: { children: (appContext?: IAppContext) => ReturnType }) {
  const appContext = useContext(AppContext);
  return children(appContext);
}

interface IState extends IApiComponentState {
  context?: IAppContext;
}
interface IProps
  extends JSX.IntrinsicAttributes,
    PropsWithChildren,
    INavigateProp,
    ILocationProp,
    IParamsProp<{ project?: InternalId; site?: InternalId }> {}
class AppContextProviderComponent extends ApiComponent<IProps, IState> {
  async load(
    customer?: ClientCustomerEntity,
    contract?: ClientContractEntity,
    project?: ClientProjectEntity,
    refresh?: boolean,
    site?: ClientSiteEntity | null
  ) {
    if (!customer) {
      customer = this.state.context?.customer || (await this.getCurrentCustomer());
    }

    let projectPathParam = this.props.params.project;
    if (!projectPathParam && this.props.location.pathname.match(/\/projects\/[a-z0-9]+/)) {
      projectPathParam = this.props.location.pathname.split('/')[2] as InternalId;
    }

    let sitePathParam = this.props.params.site;
    if (!sitePathParam && this.props.location.pathname.match(/\/projects\/[a-z0-9]+\/(dashboard|sites|updates|content)\/[a-z0-9]+/)) {
      sitePathParam = this.props.location.pathname.split('/')[4] as InternalId;
    }

    const localStorageProject = window.localStorage?.getItem('app-context/project') as InternalId | null;

    let hasMultipleContracts = false;
    let customerHasAnySites = false;
    let customerHasAnySitesIncludingInactive = false;
    let hasMultipleCustomers = false;
    if (customer?.id === this.state.context?.customer?.id && !refresh) {
      hasMultipleContracts = !!this.state.context?.hasMultipleContracts;
      hasMultipleCustomers = !!this.state.context?.hasMultipleCustomers;
      customerHasAnySites = !!this.state.context?.customerHasAnySites;
      customerHasAnySitesIncludingInactive = !!this.state.context?.customerHasAnySitesIncludingInactive;
      if (!contract) {
        contract = this.state.context?.contract;
      }
    } else {
      const contracts = await this.api.billing.contracts.search(undefined, { page: 0, itemsPerPage: 1 }, customer?.id, false);
      hasMultipleContracts = contracts.totalNumberOfItems > 1;
      if (!contract && contracts.items.length) {
        contract = contracts.items[0];
      }

      if (this.api.currentUser?.type === UserType.Internal) {
        const customers = await this.api.billing.customers.findByName('', { itemsPerPage: 0 });
        hasMultipleCustomers = customers.totalNumberOfItems > 1;
      }

      let sites = await this.api.billing.sites.search({ itemsPerPage: 0 }, { customerId: customer?.id });
      customerHasAnySites = sites.totalNumberOfItems > 0;

      if (customerHasAnySites) {
        customerHasAnySitesIncludingInactive = true;
      } else {
        sites = await this.api.billing.sites.search({ itemsPerPage: 0 }, { customerId: customer?.id }, { includeInactive: true });
        customerHasAnySitesIncludingInactive = sites.totalNumberOfItems > 0;
      }
    }

    let hasMultipleProjects = false;
    if (contract?.id === this.state.context?.contract?.id && !refresh) {
      hasMultipleProjects = !!this.state.context?.hasMultipleProjects;
      if (!project) {
        project = this.state.context?.project;
      }
    } else {
      if (!project && !refresh) {
        if (projectPathParam) {
          project = await this.api.syndication.projects.item(projectPathParam);
        } else if (localStorageProject) {
          project = await this.api.syndication.projects.item(localStorageProject);
        }
      }

      const productionProjects = await this.api.syndication.projects.search(
        { customerId: customer?.id, contractId: contract?.id },
        { page: 0, itemsPerPage: 1 },
        { type: SiteEnvironmentType.Production, includeInactive: false }
      );
      hasMultipleProjects = productionProjects.totalNumberOfItems > 1;
      if (!hasMultipleProjects) {
        const testingProjects = await this.api.syndication.projects.search(
          { customerId: customer?.id, contractId: contract?.id },
          { page: 0, itemsPerPage: 1 },
          { type: SiteEnvironmentType.Testing, includeInactive: false }
        );
        hasMultipleProjects = productionProjects.totalNumberOfItems + testingProjects.totalNumberOfItems > 1;
        if (!project) {
          if (productionProjects.items.length) {
            project = productionProjects.items[0];
          } else if (testingProjects.items.length) {
            project = testingProjects.items[0];
          }
        }
      } else if (!project) {
        if (productionProjects.items.length) {
          project = productionProjects.items[0];
        }
      }
    }

    if (project && contract && contract.id !== project.contract.getId()) {
      contract = await project.contract.get();
    }
    if (contract && customer && customer.id !== contract.customer.getId()) {
      customer = await contract.customer.get();
    }

    if (site === undefined) {
      site = this.state.context?.site;
    }
    if (site === undefined && sitePathParam) {
      site = await this.api.billing.sites.item(sitePathParam);
    }

    if (
      site &&
      ((customer && customer.id !== site.customer.getId()) ||
        (contract && contract.id !== site.contract.getId()) ||
        (project && project.id !== site.project.getId()))
    ) {
      site = undefined;
    }

    // Ensure we have fresh instances to work around React's shallow object
    // equality check.
    if (refresh) {
      if (customer) {
        customer = new ClientCustomerEntity(customer);
      }
      if (contract) {
        contract = new ClientContractEntity(contract);
      }
      if (project) {
        project = new ClientProjectEntity(project);
      }
      if (site) {
        site = new ClientSiteEntity(site);
      }
    }

    // New project was selected, so we need to update the URL and potentially move the user to a different page.
    if (this.state.context?.project && project && project.id !== this.state.context.project.id) {
      if (this.props.location.pathname.match(/^\/projects\/[a-z0-9]+/)) {
        const [, , , sub] = this.props.location.pathname.split('/');
        this.props.navigate(`/projects/${project.id}${sub ? `/${sub}` : ''}`, { replace: true });
      }
    }

    let newPath = '';
    // New site was selected, so we need to update the URL.
    if (this.state.context?.site && project && site && site.id !== this.state.context.site.id) {
      if (this.props.location.pathname.match(/^\/projects\/[a-z0-9]+\/(dashboard|sites|updates|content)/)) {
        const [, , , sub] = this.props.location.pathname.split('/');
        newPath = `/projects/${project.id}/${sub}/${site.id}`;
      }
    }
    // Or the site was unset and we need to remove it.
    else if (site === null) {
      if (this.props.location.pathname.match(/^\/projects\/[a-z0-9]+\/(dashboard|sites|updates|content)\/[a-z0-9]+/)) {
        const [, , projectId, sub] = this.props.location.pathname.split('/');
        newPath = `/projects/${projectId}/${sub}`;
      }
    }

    // Must be changed delayed as the RoutingContainer will otherwise do a redirect and immediately undo our change
    // as it requires the app context to be updated first.
    if (newPath) {
      setTimeout(() => this.props.navigate(newPath, { replace: true }), 1);
    }

    const context: IAppContext = {
      refreshContracts: async () =>
        this.setState(await this.load(this.state.context?.customer, this.state.context?.contract, this.state.context?.project, true)),
      refreshProjects: async () =>
        this.setState(await this.load(this.state.context?.customer, this.state.context?.contract, this.state.context?.project, true)),
      refreshSites: async () =>
        this.setState(await this.load(this.state.context?.customer, this.state.context?.contract, this.state.context?.project, true)),
      setCustomer: async (customer: ClientCustomerEntity) => this.setState(await this.load(customer, undefined, undefined, true, null)),
      setContract: async (contract: ClientContractEntity, customer?: ClientCustomerEntity) =>
        this.setState(await this.load(customer, contract)),
      setProject: async (project: ClientProjectEntity, contract?: ClientContractEntity, customer?: ClientCustomerEntity) =>
        this.setState(await this.load(customer, contract, project)),
      setSite: async (site?: ClientSiteEntity) => this.setState(await this.load(undefined, undefined, undefined, false, site || null)),

      get customerKey() {
        return this.customer?.id || '';
      },
      get contractKey() {
        return this.customerKey + '-' + (this.contract?.id || '');
      },
      get projectKey() {
        return this.contractKey + '-' + (this.project?.id || '');
      },
      get siteKey() {
        return this.projectKey + '-' + (this.site?.id || '');
      },

      syncCore: function () {
        return this.project?.syncCore?.getId()
          ? this.project.syncCore.get()
          : this.site?.syncCore.getId()
            ? this.site.syncCore.get()
            : Promise.resolve(undefined);
      },

      site: site || undefined,
      customer,
      contract,
      project,
      hasMultipleCustomers,
      hasMultipleContracts,
      hasMultipleProjects,
      customerHasAnySites,
      customerHasAnySitesIncludingInactive,
    };

    if (project?.id != localStorageProject) {
      window.localStorage?.setItem('app-context/project', project?.id || '');
    }

    return {
      context,
    };
  }

  render() {
    const { context } = this.state;

    if (!context) {
      return null;
    }

    return <AppContext.Provider value={context}>{this.props.children}</AppContext.Provider>;
  }
}
export const AppContextProvider = withLocationAndNavigate<PropsWithChildren & JSX.IntrinsicAttributes>(
  withParams<ILocationProp & INavigateProp & JSX.IntrinsicAttributes & PropsWithChildren, { project?: InternalId; site?: InternalId }>(
    AppContextProviderComponent
  )
);

function AppContextSiteSelectorComponent(props: IAppContextProp & JSX.IntrinsicAttributes & { className?: string }) {
  const { appContext } = props;
  return (
    <SiteSelector
      className={props.className}
      value={appContext.site}
      forCustomer={appContext.customer?.id}
      forProject={appContext.project?.id}
      forContract={appContext.contract?.id}
      key={(appContext.customer?.id || '') + '-' + (appContext.contract?.id || '') + '-' + (appContext.project?.id || '')}
      onSelected={async (newSelectedSite) => {
        appContext.setSite?.(newSelectedSite);
      }}
      onRemove={async () => {
        appContext.setSite?.();
      }}
      display="menu"
    />
  );
}
export const AppContextSiteSelector = withAppContext(AppContextSiteSelectorComponent);
