import { ClientContractEntity, ClientCustomerEntity, ClientSyncCoreEntity } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import { UserType } from '@edgebox/data-definitions';
import { ButtonCheckbox, ContentCol, HeaderCol } from '@edgebox/react-components';
import React from 'react';
import { Alert, Col, Form } from 'react-bootstrap';
import Row from 'react-bootstrap/cjs/Row';
import {
  ApiComponent,
  ContractLink,
  ContractSelector,
  CustomerLink,
  CustomerSelector,
  IApiComponentState,
  PagedList,
  SyncCoreLink,
  SyncCoreStatusBadge,
} from '../../common/index';
import { ContentBox } from './ContentBox';

// TODO: Move all Paged*List components to react-data-components

interface IProps {
  backend?: boolean;
  customerId?: InternalId;
  emptyMessage?: React.ReactNode;
  notEmptyheader?: React.ReactNode;
  emptyMessageWithNoFilters?: React.ReactNode;
}

interface IFilters {
  search?: string;
  customerId?: InternalId;
  contractId?: InternalId;
  includeRetired?: boolean;
}

interface IState extends IApiComponentState {
  search?: string;
  filterCustomer?: ClientCustomerEntity;
  filterContract?: ClientContractEntity;
  includeRetired?: boolean;
}

export class PagedSyncCoreList extends ApiComponent<IProps, IState> {
  async load() {
    return {};
  }

  render() {
    const { backend, customerId, emptyMessage, emptyMessageWithNoFilters, notEmptyheader } = this.props;
    const { filterCustomer, filterContract, includeRetired } = this.state;

    const renderBackendProperties = this.api.currentUser?.type === UserType.Internal && backend;

    return (
      <PagedList<ClientSyncCoreEntity, IFilters>
        notEmptyheader={notEmptyheader}
        searchable
        hidePagerIfNotNeeded
        renderList={(items) => <ContentBox>{items}</ContentBox>}
        request={(page, filter) =>
          this.api.syndication.syncCores.search(
            filter?.search,
            { page, itemsPerPage: 5 },
            {
              customerId: renderBackendProperties ? filter?.customerId || customerId : this.api.currentUser?.customer?.getId(),
              contractId: filter?.contractId,
              includeRetired,
            }
          )
        }
        renderFilters={(onChange) => {
          const onType = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | React.KeyboardEvent<any>) => {
            const value = (e.target as HTMLInputElement).value;
            onChange('search', value);
            this.setState({ search: value });
          };
          const backendFilters =
            renderBackendProperties && !customerId ? (
              <Col xs={3}>
                <CustomerSelector
                  value={filterCustomer}
                  onRemove={() => {
                    this.setState({ filterCustomer: undefined });
                    onChange('customerId', undefined, true);
                  }}
                  onSelected={(filterCustomer) => {
                    this.setState({ filterCustomer });
                    onChange('customerId', filterCustomer.id, true);
                  }}
                  emptyLabel={'Filter by customer'}
                />
              </Col>
            ) : undefined;
          return (
            <>
              <Row>
                <Col className={'p-0'}>
                  <Form.Control placeholder={'Enter search term...'} onKeyPress={onType} onChange={onType} />
                </Col>
                {backendFilters}
                <Col xs={3}>
                  <ContractSelector
                    value={filterContract}
                    onRemove={() => {
                      this.setState({ filterContract: undefined });
                      onChange('contractId', undefined, true);
                    }}
                    onSelected={(filterContract) => {
                      this.setState({ filterContract });
                      onChange('contractId', filterContract.id, true);
                    }}
                    emptyLabel={'Filter by subscription'}
                  />
                </Col>
                <Col xs={2}>
                  <ButtonCheckbox checked={!!includeRetired} onToggle={() => this.setState({ includeRetired: !includeRetired })}>
                    Show retired
                  </ButtonCheckbox>
                </Col>
              </Row>
            </>
          );
        }}
        emptyMessageWithNoFilters={emptyMessageWithNoFilters}
        emptyMessage={emptyMessage || <Alert variant={'light'}>No Sync Cores match your filter.</Alert>}
        renderListHeader={() => (
          <Row>
            <HeaderCol xs={3}>Name</HeaderCol>
            {renderBackendProperties && !customerId && <HeaderCol xs={2}>Customer</HeaderCol>}
            <HeaderCol xs={5}>Contract</HeaderCol>
            <HeaderCol xs={2}>Status</HeaderCol>
            {/*TODO: <HeaderCol xs={2}>Sites</HeaderCol>*/}
          </Row>
        )}
        renderItem={(syncCore) => (
          <Row key={syncCore.id}>
            <ContentCol xs={3}>
              <SyncCoreLink entityId={syncCore.id} backend={backend} />
            </ContentCol>
            {renderBackendProperties && !customerId && (
              <ContentCol xs={2}>{syncCore.customer?.getId() && <CustomerLink entityId={syncCore.customer.getId()!} />}</ContentCol>
            )}
            <ContentCol xs={5}>{syncCore.contract?.getId() && <ContractLink entityId={syncCore.contract.getId()!} />}</ContentCol>
            <ContentCol xs={2}>
              <SyncCoreStatusBadge status={syncCore.status} />
            </ContentCol>
            {/*TODO: <ContentCol xs={2}><SyncCoreSiteCount syncCoreId={syncCore.id} /></ContentCol>*/}
          </Row>
        )}
      />
    );
  }
}
