import { ClientCustomerDraft, ClientCustomerEntity, ClientUserEntity } from '@edgebox/api-rest-client';
import { IPagedListResponse } from '@edgebox/data-definition-kit';
import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/cjs/Container';
import Tab from 'react-bootstrap/cjs/Tab';
import Tabs from 'react-bootstrap/cjs/Tabs';
import { AddButton, Right } from '@edgebox/react-components';
import { LeftRightH3 } from '@edgebox/react-components';
import { IIdParamProp, withIdParam } from '../../RouterHelper';
import { ContentBox } from '../../Shared/ContentBox';
import { PagedContractList } from '../../Shared/PagedContractList';
import { PagedProjectList } from '../../Shared/PagedProjectList';
import { PagedSiteList } from '../../Shared/PagedSiteList';
import { GraphStats } from '../../Customer/Stats/GraphStats';
import { HeatmapStats } from '../../Customer/Stats/HeatmapStats';
import { PackStats } from '../../Customer/Stats/PackStats';
import { PagedLogItemList } from '../PagedLogItemList';
import { LogsButton } from '../LogsButton';
import { ApiComponent, CustomerForm, IApiComponentState, IItemProps, UserForm } from '../../../common';

interface IExternalProps extends IItemProps {}
interface IProps extends IExternalProps, IIdParamProp {}

interface IState extends IApiComponentState {
  customer?: ClientCustomerEntity;
  editCustomer?: ClientCustomerEntity;
  users?: IPagedListResponse<ClientUserEntity>;
}

class BackendCustomerDetailComponent extends ApiComponent<IProps, IState> {
  async load(): Promise<Partial<IState>> {
    const customer = await this.api.billing.customers.item(this.props.id);

    const users = await this.api.authentication.users.find(undefined, customer.id);

    return {
      customer,
      users,
    };
  }

  render() {
    const { customer, users, editCustomer } = this.state;

    if (!customer || !users) {
      return this.renderRequest();
    }

    const onEditCustomer = () => {
      this.setState({
        editCustomer: new ClientCustomerEntity(customer),
      });
    };

    const onSaveCustomer = async (updatedCustomer: ClientCustomerDraft) => {
      const previous = this.state.customer;
      this.setState({
        customer: updatedCustomer as ClientCustomerEntity,
      });

      try {
        await this.api.billing.customers.update(updatedCustomer as ClientCustomerEntity);
      } catch (e) {
        this.setState({
          customer: previous,
        });
      }

      onCancelEdit();
    };

    const onCancelEdit = () => {
      this.setState({
        editCustomer: undefined,
      });
    };

    return (
      <Container>
        <h1 className="text-truncate mw-100" title={customer.name}>
          {customer.name}
        </h1>

        <Tabs defaultActiveKey={'details'}>
          <Tab eventKey={'details'} title={'Details'}>
            <ContentBox>
              <div className={'mt-3'} />

              <CustomerForm
                key={editCustomer ? '1' : '0'}
                entity={editCustomer ? editCustomer : customer}
                onSave={onSaveCustomer}
                onToggleEdit={onEditCustomer}
                mode={editCustomer ? 'edit-full' : 'view-full-backend'}
                className={'mt-4'}
              >
                {editCustomer ? (
                  <>
                    <Right>
                      <Button type={'button'} variant={'light'} onClick={onCancelEdit}>
                        Cancel
                      </Button>
                      <Button type={'submit'} variant={'primary'}>
                        Save
                      </Button>
                    </Right>
                  </>
                ) : undefined}
              </CustomerForm>
            </ContentBox>
          </Tab>

          <Tab eventKey={'contracts'} title={'Contracts'}>
            <ContentBox>
              <div className={'mt-3'} />

              <Right>
                <AddButton to={`/backend/billing/customers/${customer.id}/contracts/add`} />
              </Right>

              <PagedContractList
                backend
                customerId={customer.id}
                emptyMessage={<Alert variant={'light'}>This customer doesn't have any contracts yet.</Alert>}
              />
            </ContentBox>
          </Tab>

          <Tab eventKey={'projects'} title={'Projects'}>
            <ContentBox>
              <div className={'mt-3'} />

              <PagedProjectList backend customerId={customer.id} />
            </ContentBox>
          </Tab>

          <Tab eventKey={'sites'} title={'Sites'}>
            <ContentBox>
              <div className={'mt-3'} />

              <PagedSiteList
                backend
                customerId={customer.id}
                emptyMessage={<Alert variant={'light'}>This customer doesn't have any sites yet.</Alert>}
              />
            </ContentBox>
          </Tab>

          <Tab eventKey={'users'} title={'Users'}>
            <ContentBox>
              <div className={'mt-3'} />

              {users.items.map((user) => {
                return (
                  <UserForm<ClientUserEntity>
                    className={'mb-3'}
                    key={user.id}
                    entity={user}
                    mode={'view-full'}
                    name={
                      <LeftRightH3
                        left={
                          <>
                            {user.firstName} {user.lastName} <LogsButton name={`${user.firstName} ${user.lastName}`} userId={user.id} />
                          </>
                        }
                        right={
                          /*<AuthenticationContext.Consumer>
                          {
                            (auth: IInternalAuthentication) => {
                              return <IconButton icon={faMask} to={`//${API_DOMAIN}/authentication/masquerade?userId=${user.id}&jwt=${encodeURIComponent(auth.jwt!)}`} />;
                            }
                          }
                        </AuthenticationContext.Consumer>*/ null
                        }
                      />
                    }
                    onSave={async (update) => {}}
                  ></UserForm>
                );
              })}
            </ContentBox>
          </Tab>

          <Tab eventKey={'stats'} title={'Stats'}>
            <ContentBox>
              <div className={'mt-3'} />

              <div>
                <Row>
                  <Col className="ps-0 pe-4" xs={12} sm={6}>
                    <h3>Daily updates</h3>
                    <GraphStats displayType="bars" forCustomer={customer} />
                  </Col>
                  <Col className="pe-0 ps-4" xs={12} sm={6}>
                    <h3>Heatmap</h3>
                    <HeatmapStats forCustomer={customer} />
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col className="ps-0 pe-0" xs={12}>
                    <h3>Most active sites</h3>
                    <PackStats forCustomer={customer} />
                  </Col>
                </Row>
              </div>
            </ContentBox>
          </Tab>

          <Tab eventKey={'logs'} title={'Logs'}>
            <ContentBox>
              <div className={'mt-3'} />

              <PagedLogItemList id={`customer-${customer.id}`} customerId={customer.id} />
            </ContentBox>
          </Tab>
        </Tabs>
      </Container>
    );
  }
}

export const BackendCustomerDetail = withIdParam(BackendCustomerDetailComponent);
