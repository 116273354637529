import { ClientContractRevisionEntity } from '@edgebox/api-rest-client';
import moment from 'moment';
import React from 'react';
import Badge from 'react-bootstrap/Badge';

interface IProps {
  contract: ClientContractRevisionEntity;
  size?: 'small';
}

export function isContractRevisionActive(contract: ClientContractRevisionEntity) {
  const now = moment();
  return now.isBefore(contract.endDate) && now.isAfter(contract.startDate);
}

export function isContractRevisionUpcoming(contract: ClientContractRevisionEntity) {
  const now = moment();
  return now.isBefore(contract.startDate);
}

export class ContractRevisionStatusBadge extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { contract, size } = this.props;

    const classes = size ? 'small' : '';

    const isUpcoming = isContractRevisionUpcoming(contract);
    const isActive = isContractRevisionActive(contract);

    return (
      <Badge bg={isActive ? 'primary' : 'dark'} className={classes}>
        {isActive ? 'Active' : isUpcoming ? 'Upcoming' : 'Finished'}
      </Badge>
    );
  }
}
