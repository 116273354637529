import { ClientSiteEntity, ClientSyncCoreEntity } from '@edgebox/api-rest-client';
import { loadRemoteScript } from '@edgebox/react-components/dist/components/Helpers/LoadRemoteScript';
import React from 'react';
import { ApiComponent, IApiComponentState } from '../../common';
import { INavigateProp, withNavigate } from '../RouterHelper';

interface IExternalProps {
  scope: 'content' | 'configuration';
  syncCore: ClientSyncCoreEntity;
  site: ClientSiteEntity;
  embed: 'pull-dashboard' | 'syndication-dashboard' | 'site-registered' | 'box/content-count/customer' | 'site-settings';
  width?: string;
  height?: string;
  options?: any;
}

interface IProps extends IExternalProps, INavigateProp {}

interface IState extends IApiComponentState {
  loaded?: boolean;
  backendJwt?: string;
  imageJwt?: string;
  iframeId?: string;
  previousOptions?: any;
}

const EMBED_DOMAIN = process.env.REACT_APP_EMBED_DOMAIN || 'embed.content-sync.io';
const CLOUD_EMBED_BASE_URL = `//${EMBED_DOMAIN}`;

class EmbedComponent extends ApiComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props, {
      iframeId: `embed--${props.embed.replace(/[^a-zA-Z0-9-_]/g, '-')}--${Date.now()}`,
    });
  }

  async load() {
    await loadRemoteScript('embed-iframe-resizer', `${CLOUD_EMBED_BASE_URL}/iframeResizer.js`);

    const [{ jwt: backendJwt }, { jwt: imageJwt }] = await Promise.all([
      this.api.syndication.syncCores.getEmbedToken(this.props.syncCore.id, this.props.scope, this.props.site.id),
      this.api.syndication.syncCores.getEmbedToken(this.props.syncCore.id, 'file-download', this.props.site.id),
    ]);

    return {
      loaded: true,
      backendJwt,
      imageJwt,
    };
  }

  protected iframeRef: any;
  protected iframe: any = undefined;

  setTextInputRef(element: any) {
    this.iframeRef = element;

    this.mounted();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    if (
      this.props.options
        ? !prevProps.options || JSON.stringify(this.props.options) !== JSON.stringify(prevProps.options)
        : !!prevProps.options
    ) {
      if (this.iframe) {
        this.iframe.iFrameResizer.sendMessage({
          type: 'update-options',
          options: {
            ...(this.props.options || {}),
            imagePreviewJwt: this.state.imageJwt,
          },
        });
      }
    }
  }

  mounted() {
    if (!this.state.loaded || !this.iframeRef || !this.__isMounted) {
      return;
    }

    const { backendJwt } = this.state;

    const config = {
      featureFlags: {
        'is-backend': 1,
      },
      syncCoreDomain: this.props.syncCore.baseUrl.replace(/^.*\/\/([^/]+).*$/, '$1'),
      siteUuid: this.props.site.uuid,
      backendJwt,
    };

    const init = () => {
      if (!(window as any).iFrameResize) {
        setTimeout(init, 250);
        return;
      }
      (window as any).iFrameResize(
        {
          //log: true,
          checkOrigin: false,
          onInit: (newIframe: any) => {
            this.iframe = newIframe;
            this.iframe.iFrameResizer.sendMessage({
              type: 'config',
              config,
            });
            this.iframe.iFrameResizer.sendMessage({
              type: 'options',
              options: {
                ...(this.props.options || {}),
                imagePreviewJwt: this.state.imageJwt,
              },
            });
          },
          onMessage: ({ message }: any) => {
            if (!this.__isMounted) {
              return;
            }

            // Need a fresh access token.
            if (message.type === 'reload') {
              window.location.reload();
            } else if (message.type === 'scroll-to-top') {
              // Doesn't work in IE but that's alright.
              const scrollContainer = window.document.getElementById('page-content');
              if (scrollContainer) {
                scrollContainer.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                });
              }
            } else if (message.type === 'modal-open' || message.type === 'modal-close') {
              if (this.iframeRef) {
                const container: HTMLIFrameElement = this.iframeRef;

                const open = message.type === 'modal-open';
                container.className = open ? 'iframe-modal' : '';
              }
            } else if (message.type === 'update-query') {
              const query = Object.entries<string>(message.query)
                .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                .join('&');
              this.props.navigate(`?${query}`);
            } else {
              throw new Error('Unknown message ' + JSON.stringify(message));
            }
          },
        },
        `#${this.state.iframeId}`
      );
    };

    init();
  }

  render() {
    const { embed, width, height } = this.props;
    const url = `${CLOUD_EMBED_BASE_URL}/${embed}`;

    if (!this.state.loaded) {
      return this.renderRequest();
    }

    return (
      <iframe
        key={this.props.site.id}
        title={this.state.iframeId}
        ref={(element) => this.setTextInputRef(element)}
        id={this.state.iframeId}
        src={url}
        frameBorder="0"
        style={{
          width: '1px',
          minWidth: width ? width : '100%',
          minHeight: height ? height : '200px',
          height: height ? height : undefined,
        }}
        allow="fullscreen"
      >
        The page could not be loaded as your browser does not support it.
      </iframe>
    );
  }
}

export const Embed = withNavigate<IExternalProps>(EmbedComponent);
