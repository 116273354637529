import { ClientCustomerEntity, ClientDiscountEntity } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import { DataDefinitionsEnumTranslator, DiscountType } from '@edgebox/data-definitions';
import React from 'react';
import { Alert, Badge, Col, FormSelect } from 'react-bootstrap';
import Row from 'react-bootstrap/cjs/Row';
import { ButtonCheckbox, ButtonLink } from '@edgebox/react-components';
import { ContentCol } from '@edgebox/react-components';
import { HeaderCol } from '@edgebox/react-components';
import { ContentBox } from '../../Shared/ContentBox';
import {
  ApiComponent,
  CustomerLink,
  CustomerSelector,
  DataDefinitionsEnumValue,
  DiscountStatusBadge,
  IApiComponentState,
  PagedList,
} from '../../../common';

interface IProps {
  id?: InternalId;
}

interface IDiscountFilters {
  includeRetired?: boolean;
  type?: DiscountType;
  forCustomer?: InternalId;
}

interface IState extends IApiComponentState, IDiscountFilters {
  forCustomerEntity?: ClientCustomerEntity;
}

export class BackendDiscountList extends ApiComponent<IProps, IState> {
  async load() {
    return {};
  }

  render() {
    const { id } = this.props;
    const { includeRetired, type, forCustomerEntity } = this.state;

    if (id) {
      return id;
    }

    return (
      <PagedList<ClientDiscountEntity, IDiscountFilters>
        searchable
        request={async (page, filter) => {
          return await this.api.billing.discounts.search({ page }, filter?.type, filter?.forCustomer, filter?.includeRetired);
        }}
        renderFilters={(onChange) => {
          const types = DataDefinitionsEnumTranslator.translateEnum('DiscountType');
          const typeKeys = Object.keys(types);

          return (
            <Row>
              <Col xs={2}>
                <FormSelect
                  value={type}
                  onChange={(e: React.ChangeEvent<any>) => {
                    const value = e.target.value ? (e.target.value as DiscountType) : undefined;
                    this.setState({ type: value });
                    onChange('type', value, true);
                  }}
                >
                  <option key={''} value={''} id={`type-`}>
                    Any discount
                  </option>
                  {typeKeys.map((key) => {
                    const enumKey = key as DiscountType;
                    return (
                      <option key={key} value={enumKey} id={`type-${key}`}>
                        {types[enumKey]}
                      </option>
                    );
                  })}
                </FormSelect>
              </Col>
              <Col xs={3}>
                <ButtonCheckbox
                  checked={!!includeRetired}
                  onToggle={() => {
                    this.setState({ includeRetired: !includeRetired });
                    onChange('includeRetired', !includeRetired, true);
                  }}
                >
                  Include retired
                </ButtonCheckbox>
              </Col>
              <Col xs={3}>
                <CustomerSelector
                  value={forCustomerEntity}
                  name={'customer'}
                  onRemove={() => {
                    this.setState({ forCustomerEntity: undefined, forCustomer: undefined });
                    onChange('forCustomer', undefined, true);
                  }}
                  onSelected={(forCustomerEntity) => {
                    this.setState({
                      forCustomerEntity,
                      forCustomer: forCustomerEntity.id,
                    });
                    onChange('forCustomer', forCustomerEntity.id, true);
                  }}
                  emptyLabel={'Filter by customer'}
                />
              </Col>
            </Row>
          );
        }}
        renderListHeader={() => (
          <ContentBox>
            <Row>
              <HeaderCol xs={5}>Name</HeaderCol>
              <HeaderCol xs={2}>Type</HeaderCol>
              <HeaderCol xs={2}>Status</HeaderCol>
              <HeaderCol xs={3}>Customer</HeaderCol>
            </Row>
          </ContentBox>
        )}
        renderList={(items) => <ContentBox>{items}</ContentBox>}
        emptyMessage={
          <Alert variant={'light'}>No pricing. Check out the installation instructions to create default / required data.</Alert>
        }
        renderItem={(discount) => (
          <Row key={discount.id}>
            <ContentCol xs={5}>
              <ButtonLink to={`/backend/billing/contracts/discounts/${discount.id}`} variant={'link'}>
                {discount.name}
              </ButtonLink>
            </ContentCol>
            <ContentCol xs={2}>
              <Badge>
                <DataDefinitionsEnumValue enumName={'DiscountType'} keyName={discount.type} />
              </Badge>
            </ContentCol>
            <ContentCol xs={2}>
              <DiscountStatusBadge status={discount.status} />
            </ContentCol>
            <ContentCol xs={3}>{discount.customer ? <CustomerLink entityId={discount.customer.getId()!} /> : <em>-</em>}</ContentCol>
          </Row>
        )}
      />
    );
  }
}
