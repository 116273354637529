import { DataDefinitionsEnumTranslator, IDataDefinitionsEnums } from '@edgebox/data-definitions';
import React from 'react';

interface IProps<Enum extends string> {
  enumName: keyof IDataDefinitionsEnums;
  keyName?: Enum;
}

export class DataDefinitionsEnumValue<Enum extends string> extends React.Component<IProps<Enum>, {}> {
  render(): React.ReactElement {
    const { enumName, keyName } = this.props;

    return <>{keyName ? DataDefinitionsEnumTranslator.transLateEnumValue(enumName, keyName) : 'unknown'}</>;
  }
}
