import { EnumTranslation } from '@edgebox/data-definitions';
import { FormRow } from '@edgebox/react-components';
import React from 'react';
import Form from 'react-bootstrap/Form';
import { EntityFormRow } from './EntityFormRow';

interface IProps<Type, Enum extends string> {
  enumValues: EnumTranslation<Enum>;
  currentValue: Enum[];
  name: keyof Type;
  label: string;
  disabled?: boolean;
  setValue: (name: keyof Type, value: Enum[]) => void;
  withoutEntity?: boolean;
}

export class EnumAsCheckboxes<Type, Enum extends string> extends React.Component<IProps<Type, Enum>> {
  render(): React.ReactElement {
    const { name, enumValues, currentValue, setValue, label, disabled, withoutEntity } = this.props;

    const keys = Object.keys(enumValues);

    const edit = () => (
      <div>
        {keys.map((key) => {
          const enumKey = key as Enum;

          return (
            <Form.Check
              label={enumValues[enumKey]}
              key={key}
              type={'checkbox'}
              id={`${name.toString()}-${key}`}
              checked={currentValue.includes(enumKey)}
              disabled={disabled}
              onChange={() => {
                if (currentValue.includes(enumKey)) {
                  currentValue.splice(currentValue.indexOf(enumKey), 1);
                } else {
                  currentValue.push(enumKey);
                }
                setValue(name, currentValue);
              }}
            />
          );
        })}
      </div>
    );

    if (withoutEntity) {
      return (
        <FormRow name={name as string} label={label}>
          {edit()}
        </FormRow>
      );
    }

    return (
      <EntityFormRow
        name={name}
        label={label}
        edit={edit}
        view={currentValue.length ? () => currentValue.map((value) => enumValues[value]).join(', ') : undefined}
      />
    );
  }
}
