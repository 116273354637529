import { ClientBasketDraft, ClientContractEntity, ClientPricingEntity } from '@edgebox/api-rest-client';
import { InternalId, StaticReference } from '@edgebox/data-definition-kit';
import { BasketStatus, BasketType, CustomerRole, PaymentCycleType } from '@edgebox/data-definitions';
import { Package } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ApiComponent, IApiComponentState } from '../../../services/ApiComponent';
import { isContractActive } from '../Badges/ContractStatusBadge';
import { ContractSelector } from './ContractSelector';

interface IProps {
  name?: string;
  error?: string;
  forCustomer?: InternalId;
  onChange?: (contract: ClientContractEntity, hasMultiple: boolean) => void;
  noPurchaseRequired?: boolean;
  variant?: 'radios' | 'drop-down';
}

interface IState extends IApiComponentState {
  activeContracts?: ClientContractEntity[];
  selectableContracts?: ClientContractEntity[];
  selectedContract?: ClientContractEntity;
}

export class ContractQuickSelect extends ApiComponent<IProps, IState> {
  async load() {
    const { forCustomer, onChange } = this.props;
    const load = async (page = 0) => {
      const contracts = await this.api.billing.contracts.search(undefined, { page }, forCustomer, true);
      let items = contracts.items;
      if (contracts.page + 1 < contracts.numberOfPages) {
        const add = await load(page + 1);
        items = items.concat(add);
      }
      return items;
    };

    const allContracts = await load();
    const activeContracts = allContracts.filter((c) => isContractActive(c));

    const selectableContracts = activeContracts.filter((c) => this.isEnabled(c));
    const selectedContract = selectableContracts.length === 1 ? selectableContracts[0] : undefined;

    if (selectedContract && onChange) {
      onChange(selectedContract, false);
    }

    return {
      activeContracts,
      selectableContracts,
      selectedContract,
    };
  }

  isEnabled(item: ClientContractEntity) {
    if (this.props.noPurchaseRequired) {
      return true;
    }

    return (
      item.currentProductionSites < item.licensedSites ||
      (item.autoScaleLicenses && (!item.maxProdSites || item.licensedSites < item.maxProdSites))
    );
  }

  render(): React.ReactElement {
    const { name, forCustomer, onChange, error, variant } = this.props;
    const { activeContracts, selectedContract, selectableContracts } = this.state;

    if (!activeContracts) {
      return this.renderRequest();
    }

    const set = (selectedContract: ClientContractEntity) => {
      this.setState({
        selectedContract,
      });
      if (onChange) {
        onChange(selectedContract, true);
      }
    };

    const canCreateNew =
      this.api.currentUser?.customerRoles?.includes(CustomerRole.Owner) ||
      this.api.currentUser?.customerRoles?.includes(CustomerRole.ManageFinances);

    if (!selectableContracts?.length && !canCreateNew) {
      return (
        <Alert variant={'light'}>
          There is no active subscription available to add the site to. Please contact your account owner to purchase a subscription or
          increase the site count of an existing subscription.
        </Alert>
      );
    }

    return (
      <>
        <ContractSelector
          value={selectedContract}
          onSelected={(selectedContract) => set(selectedContract)}
          forCustomer={forCustomer}
          name={name}
          error={error}
          disabled={(item) => !this.isEnabled(item)}
          variant={variant || 'drop-down'}
        />
        {!selectableContracts?.length && (
          <Alert variant={'light'}>
            There is no active subscription available to add the site to. Please update one of your{' '}
            <Link to={'/subscriptions'}>existing subscriptions</Link> to continue.
          </Alert>
        )}
      </>
    );
  }
}
