import { ClientBasketEntity, ClientCustomerEntity, ClientPricingEntity } from '@edgebox/api-rest-client';
import { InternalId, StaticReference } from '@edgebox/data-definition-kit';
import React, { ChangeEvent } from 'react';
import { Alert, Badge, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/cjs/Container';
import { ContentCol, HeaderCol, Right } from '@edgebox/react-components';
import {
  ApiComponent,
  ContractLink,
  CustomerLink,
  IApiComponentState,
  IItemProps,
  PricingSelector,
  SyncCoreDataDefinitionsEnumValue,
  ViewPricingSummary,
} from '../../../common/index';
import { IIdParamProp, withIdParam } from '../../RouterHelper';
import { ContentBox } from '../../Shared/ContentBox';
import { BackendBasketStatus } from './BackendBasketStatus';

interface IExternalProps {
  id: InternalId;
}
interface IProps extends IExternalProps {}

interface IState extends IApiComponentState {
  basket?: ClientBasketEntity;
  customer?: ClientCustomerEntity;
  pricing?: ClientPricingEntity;
  saving?: boolean;
}

class BackendProposalDetailComponent extends ApiComponent<IProps, IState> {
  async load(): Promise<Partial<IState>> {
    const basket = await this.api.billing.baskets.item(this.props.id);
    const pricing = await basket.pricing?.get();
    const customer = await basket.customer.get();

    return {
      customer,
      basket,
      pricing,
    };
  }

  render() {
    const { customer, basket, pricing } = this.state;

    if (!basket || !customer) {
      return this.renderRequest();
    }

    const provideProposal = pricing && pricing.id !== basket.pricing?.getId();

    return (
      <Container>
        <h1>
          {customer.name}{' '}
          <small>
            <BackendBasketStatus status={basket.status} />
            {pricing && (
              <>
                {' '}
                <Badge bg="dark">
                  <SyncCoreDataDefinitionsEnumValue enumName="Product" keyName={pricing.product} />
                </Badge>{' '}
                <Badge bg="light">
                  <SyncCoreDataDefinitionsEnumValue enumName="Package" keyName={pricing.packageType} />
                </Badge>
              </>
            )}
          </small>
        </h1>

        <ContentBox>
          <div className={'mt-3'} />

          <Row>
            <HeaderCol>Customer</HeaderCol>
            <ContentCol>
              <CustomerLink entityId={basket.customer.getId()} />
            </ContentCol>
          </Row>
          <Row>
            <HeaderCol>Purchase order / reference</HeaderCol>
            <ContentCol>{basket.customerReference || <em>(none)</em>}</ContentCol>
          </Row>
          <Row>
            <HeaderCol>Current contract</HeaderCol>
            <ContentCol>
              <ContractLink entityId={basket.contract!.getId()} />
            </ContentCol>
          </Row>
          <Row>
            <HeaderCol>Number of sites / licenses</HeaderCol>
            <ContentCol>{basket.numberOfSites}</ContentCol>
          </Row>
          <Row>
            <HeaderCol>Pricing</HeaderCol>
            <ContentCol>
              {basket.applyAsNonprofit && <Alert variant="warning">Applied for non-profit discount.</Alert>}
              {basket.applyAsReseller && <Alert variant="warning">Applied for reseller discount.</Alert>}
              {pricing ? (
                <ViewPricingSummary pricing={pricing} />
              ) : (
                <PricingSelector
                  forCustomer={basket.customer.getId()}
                  product={basket.product}
                  value={pricing}
                  onSelected={(pricing) => this.setState({ pricing })}
                />
              )}
            </ContentCol>
          </Row>
          {provideProposal && (
            <>
              <Row>
                <HeaderCol>Grant on-premise</HeaderCol>
                <ContentCol>
                  {basket.useOnPremise && (
                    <Alert variant="warning">
                      Before creating a proposal for an on-premise subscription you must first ask the customer to manually sign our
                      licensing contract.
                    </Alert>
                  )}
                  <Form.Check
                    type={'checkbox'}
                    id={'use-on-premise'}
                    label={'Yes'}
                    defaultChecked={basket.useOnPremise}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      basket.useOnPremise = e.target.checked;
                      this.setState({
                        basket,
                      });
                    }}
                  />
                </ContentCol>
              </Row>
              <Right>
                <Button
                  variant="primary"
                  onClick={async () => {
                    try {
                      this.setState({ saving: true });
                      let update = new ClientBasketEntity(basket);
                      // Backend will do this automatically.
                      //if (update.status===BasketStatus.Requested) {
                      //  update.status = BasketStatus.Proposal;
                      //}
                      update.pricing = new StaticReference(pricing!);
                      update = await this.api.billing.baskets.update(update);
                      this.setState({ basket: update });
                    } finally {
                      this.setState({ saving: false });
                    }
                  }}
                >
                  Save
                </Button>
              </Right>
            </>
          )}
        </ContentBox>
      </Container>
    );
  }
}

export const BackendProposalDetail = BackendProposalDetailComponent;
