import React from 'react';
import { PagedSyncCoreList } from '../../Shared/PagedSyncCoreList';

export class BackendSyncCoreList extends React.Component {
  async load() {
    return {};
  }

  render() {
    return <PagedSyncCoreList backend />;
  }
}
