import { ClientContractRevisionEntity } from '@edgebox/api-rest-client';
import { DateTimeFormat } from '@edgebox/data-definition-kit';
import React from 'react';
import { FormatDateTime } from '../../Localization/FormatDateTime';
import { isContractRevisionActive } from '../Badges/ContractRevisionStatusBadge';
import { ViewPackageName } from './ViewPackageName';

interface IProps {
  entity: ClientContractRevisionEntity;
  includeDate?: boolean;
}

export class ViewContractRevisionName extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { entity, includeDate } = this.props;

    return (
      <>
        <ViewPackageName product={entity.product} packageType={entity.packageType} />
        {includeDate && (
          <>
            {' '}
            from <FormatDateTime date={entity.startDate} format={DateTimeFormat.Date} />
          </>
        )}
        {includeDate && !isContractRevisionActive(entity) && (
          <>
            {' '}
            to <FormatDateTime date={entity.endDate} format={DateTimeFormat.Date} />
          </>
        )}
      </>
    );
  }
}
