import { ClientCustomerEntity, ClientPricingEntity } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import { Package, Product, SyncCoreDataDefinitionsEnumTranslator } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import { Alert, Col, FormSelect } from 'react-bootstrap';
import Row from 'react-bootstrap/cjs/Row';
import { ButtonCheckbox, ButtonLink } from '@edgebox/react-components';
import { ContentCol } from '@edgebox/react-components';
import { HeaderCol } from '@edgebox/react-components';
import {
  ApiComponent,
  CustomerLink,
  CustomerSelector,
  DataDefinitionsEnumValue,
  IApiComponentState,
  MoneyAmount,
  PagedList,
  PricingStatusBadge,
  SyncCoreDataDefinitionsEnumValue,
} from '../../../common/index';
import { ContentBox } from '../../Shared/ContentBox';
import { BackendPricingDetail } from './BackendPricingDetail';

interface IProps {
  id?: InternalId;
}

interface IPricingFilters {
  includeRetired?: boolean;
  product?: Product;
  packageType?: Package;
  forCustomer?: InternalId;
}

interface IState extends IApiComponentState, IPricingFilters {
  forCustomerEntity?: ClientCustomerEntity;
}

export class BackendPricingList extends ApiComponent<IProps, IState> {
  async load() {
    return {};
  }

  render() {
    const { id } = this.props;

    if (id) {
      return <BackendPricingDetail id={id} />;
    }

    const { product, includeRetired, packageType, forCustomerEntity } = this.state;

    return (
      <PagedList<ClientPricingEntity, IPricingFilters>
        searchable
        request={async (page, filter) => {
          const items = await this.api.billing.pricing.list(
            filter?.includeRetired,
            filter?.product,
            filter?.packageType,
            filter?.forCustomer
          );
          return {
            items,
            itemsPerPage: items.length,
            numberOfPages: 1,
            page: 0,
            totalNumberOfItems: items.length,
          };
        }}
        renderFilters={(onChange) => {
          const products = SyncCoreDataDefinitionsEnumTranslator.translateEnum('Product');
          const productsKeys = Object.keys(products);
          const packages = SyncCoreDataDefinitionsEnumTranslator.translateEnum('Package');
          const packagesKeys = Object.keys(packages);

          return (
            <Row>
              <Col xs={2}>
                <FormSelect
                  value={product}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const value = e.target.value ? (e.target.value as Product) : undefined;
                    this.setState({ product: value });
                    onChange('product', value, true);
                  }}
                >
                  <option key={''} value={''} id={`product-`}>
                    Any product
                  </option>
                  {productsKeys.map((key) => {
                    const enumKey = key as Product;
                    return (
                      <option key={key} value={enumKey} id={`product-${key}`}>
                        {products[enumKey]}
                      </option>
                    );
                  })}
                </FormSelect>
              </Col>
              <Col xs={2}>
                <FormSelect
                  value={packageType}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const value = e.target.value ? (e.target.value as Package) : undefined;
                    this.setState({ packageType: value });
                    onChange('packageType', value, true);
                  }}
                >
                  <option key={''} value={''} id={`package-`}>
                    Any package
                  </option>
                  {packagesKeys.map((key) => {
                    const enumKey = key as Package;
                    return (
                      <option key={key} value={enumKey} id={`package-${key}`}>
                        {packages[enumKey]}
                      </option>
                    );
                  })}
                </FormSelect>
              </Col>
              <Col xs={3}>
                <ButtonCheckbox
                  checked={!!includeRetired}
                  onToggle={() => {
                    this.setState({ includeRetired: !includeRetired });
                    onChange('includeRetired', !includeRetired, true);
                  }}
                >
                  Include retired
                </ButtonCheckbox>
              </Col>
              <Col xs={3}>
                <CustomerSelector
                  value={forCustomerEntity}
                  name={'customer'}
                  onRemove={() => {
                    this.setState({ forCustomerEntity: undefined, forCustomer: undefined });
                    onChange('forCustomer', undefined, true);
                  }}
                  onSelected={(forCustomerEntity) => {
                    this.setState({
                      forCustomerEntity,
                      forCustomer: forCustomerEntity.id,
                    });
                    onChange('forCustomer', forCustomerEntity.id, true);
                  }}
                  emptyLabel={'Filter by customer'}
                />
              </Col>
            </Row>
          );
        }}
        renderListHeader={() => (
          <ContentBox>
            <Row>
              <HeaderCol xs={3}>Product / Package</HeaderCol>
              <HeaderCol xs={2}>Type</HeaderCol>
              <HeaderCol xs={3}>Price</HeaderCol>
              <HeaderCol xs={2}>Status</HeaderCol>
              <HeaderCol xs={2}>Customer</HeaderCol>
            </Row>
          </ContentBox>
        )}
        renderList={(items) => <ContentBox>{items}</ContentBox>}
        emptyMessage={
          <Alert variant={'light'}>No pricing. Check out the installation instructions to create default / required data.</Alert>
        }
        renderItem={(pricing) => (
          <Row key={pricing.id}>
            <ContentCol xs={3}>
              <ButtonLink to={`/backend/billing/contracts/pricing/${pricing.id}`} variant={'link'}>
                <SyncCoreDataDefinitionsEnumValue<Product> enumName={'Product'} keyName={pricing.product} />{' '}
                <SyncCoreDataDefinitionsEnumValue<Package> enumName={'Package'} keyName={pricing.packageType} />
              </ButtonLink>
            </ContentCol>
            <ContentCol xs={2}>
              <DataDefinitionsEnumValue enumName={'PricingVisibility'} keyName={pricing.visibility} />
            </ContentCol>
            <ContentCol xs={3}>
              {pricing.pricePerSite ? (
                <>
                  <MoneyAmount amount={pricing.pricePerSite} currency={pricing.currency} /> / site / month
                </>
              ) : undefined}
              {pricing.fixedMonthlyPrice ? (
                <>
                  <MoneyAmount amount={pricing.fixedMonthlyPrice} currency={pricing.currency} /> / month
                </>
              ) : undefined}
              {!pricing.pricePerSite && !pricing.fixedMonthlyPrice ? <>Free</> : undefined}
              {pricing.minMonthlyPrice ? (
                <>
                  <br />
                  Min. <MoneyAmount amount={pricing.minMonthlyPrice} currency={pricing.currency} /> / month
                </>
              ) : undefined}
            </ContentCol>
            <ContentCol xs={2}>
              <PricingStatusBadge status={pricing.status} />
            </ContentCol>
            <ContentCol xs={2}>{pricing.customer ? <CustomerLink entityId={pricing.customer.getId()} /> : <em>-</em>}</ContentCol>
          </Row>
        )}
      />
    );
  }
}
