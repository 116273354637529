import { ClientContractRevisionDraft, ClientCustomerEntity } from '@edgebox/api-rest-client';
import { StaticReference } from '@edgebox/data-definition-kit';
import { ButtonLink, Right } from '@edgebox/react-components';
import { ContractRevisionTransition, HostingType, Package, Product } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import { ApiComponent, ContractRevisionForm, IApiComponentState, IItemProps } from '../../../common';
import { IIdParamProp, INavigateProp, withIdParamAndNavigate } from '../../RouterHelper';
import { ContentBox } from '../../Shared/ContentBox';

interface IExternalProps extends IItemProps {}
interface IProps extends IExternalProps, IIdParamProp, INavigateProp {}

interface IState extends IApiComponentState {
  customer?: ClientCustomerEntity;
  draft?: ClientContractRevisionDraft;
}

class BackendAddContractRevisionComponent extends ApiComponent<IProps, IState> {
  async load() {
    const contract = await this.api.billing.contracts.item(this.props.id);
    const customer = await contract.customer.get();
    const pricing = await this.api.billing.pricing.list();

    return {
      customer,
      draft: new ClientContractRevisionDraft({
        pricing: new StaticReference(pricing[0]),
        contractTermInMonths: 1,
        advancePaymentsInMonths: 1,
        startDate: contract.startDate.clone(),
        endDate: contract.endDate ? contract.endDate.clone() : contract.startDate.clone().add(1, 'month'),
        product: Product.Staging,
        packageType: Package.Business,
        hostingType: HostingType.Cloud,
        customer: new StaticReference(customer),
        contract: new StaticReference(contract),
        revisionTransition: ContractRevisionTransition.New,
      }),
    };
  }

  render() {
    const { customer, draft } = this.state;

    if (!customer || !draft) {
      return this.renderRequest();
    }

    const backUrl = `/backend/billing/customers/${customer.id}`;

    return (
      <Container>
        <h1>Create contract revision</h1>
        <Alert variant={'danger'}>
          <p>
            <strong>The customer will not be charged for this.</strong>
          </p>
          <p>
            Only create contracts manually to migrate existing contracts or to create a contract for an Enterprise customer that can't be
            invoiced automatically or to extend a trial.
          </p>
        </Alert>
        <ContentBox>
          <ContractRevisionForm
            onSave={async (draft: ClientContractRevisionDraft) => {
              await this.api.billing.contractRevisions.create(draft);
              this.props.navigate(backUrl, { replace: true });
            }}
            mode={'create'}
            entity={draft}
          >
            <Right>
              <ButtonLink to={backUrl} variant={'light'}>
                Cancel
              </ButtonLink>
              <Button variant={'primary'} type={'submit'}>
                Save
              </Button>
            </Right>
          </ContractRevisionForm>
        </ContentBox>
      </Container>
    );
  }
}

export const BackendAddContractRevision = withIdParamAndNavigate<IExternalProps>(BackendAddContractRevisionComponent);
