import { IInternalAuthentication } from '@edgebox/api-rest-client';
import { TermsConfiguration } from '@edgebox/api-rest-client/dist/services/api';
import { UserType } from '@edgebox/data-definitions';
import React from 'react';
import { Navigate } from 'react-router';
import { ILocationProp, withLocation } from './RouterHelper';
import { RoutingContainer } from './RoutingContainer';
import { CustomerRegistration } from './Customer/CustomerRegistration';
import { Login } from './Login/Login';
import { TermsPage } from './TermsPage';
import { VerifyEmail } from './Login/VerifyEmail';
import { IAuth0Authentication } from './Auth0AuthenticationProvider';
import { Alert } from 'react-bootstrap';
import { ClientError, Uuid } from '@edgebox/data-definition-kit';
import { WithCustomer } from './WithCustomer';
import { ErrorPage } from './ErrorPage';
import { ApiComponent, AuthenticationContext, ContentSyncPageLoading, IApiComponentState, Request } from '../common';
import { AppContextProvider } from '../common/contexts/AppContext';
import { WithSite } from './WithSite';
import { NotFoundPage } from './NotFoundPage';
import { WithProject } from './WithProject';

const REDIRECT_URL_AFTER_REGISTRATION = 'https://www.content-sync.io/thank-you-signup';

interface IProps extends ILocationProp {}

interface IState extends IApiComponentState {
  loaded?: boolean;
  termsConfiguration?: TermsConfiguration;
  signedUp?: boolean;
}

class SectionSwitchComponent extends ApiComponent<IProps, IState> {
  repeatedLoading = false;

  async load(signedUp?: boolean): Promise<Partial<IState>> {
    if ((window as any).FreshworksWidget) {
      if (this.api.currentUser) {
        (window as any).FreshworksWidget('identify', 'ticketForm', {
          name: `${this.api.currentUser.firstName} ${this.api.currentUser.lastName}`,
          email: this.api.currentUser.primaryEmail,
        });
      }

      (window as any).FreshworksWidget('prefill', 'ticketForm', {
        group_id: 80000500983,
      });
    }
    if ((window as any).fcWidget) {
      if (this.api.currentUser) {
        (window as any).fcWidget.setExternalId(this.api.currentUser.id);
        (window as any).fcWidget.user.setFirstName(this.api.currentUser.firstName);
        (window as any).fcWidget.user.setEmail(this.api.currentUser.primaryEmail);
      }
    }

    const termsConfiguration = this.api.currentUser ? await this.api.configuration.terms.get() : undefined;

    if (signedUp) {
      // Navigate the user to the website to thank them for signing up.
      // But not locally to not count new registrations when we only test.
      if (process.env.REACT_APP_ENVIRONMENT_TYPE === 'local') {
        window.alert('Please reload the page.');
      } else {
        setTimeout(() => (window.location.href = REDIRECT_URL_AFTER_REGISTRATION), 10);
      }
    }

    return {
      loaded: true,
      signedUp,
      termsConfiguration,
    };
  }

  public render() {
    if (!this.state.loaded) {
      return this.renderRequest();
    }

    if (this.api.loading) {
      return <ContentSyncPageLoading />;
    }

    // User is logged in.
    if (this.api.currentUser) {
      if (this.props.location.pathname === '/user/logout') {
        return (
          <AuthenticationContext.Consumer>
            {(auth: IInternalAuthentication) => {
              auth.logout();

              return <p>Logging you out...</p>;
            }}
          </AuthenticationContext.Consumer>
        );
      }

      if (this.state.termsConfiguration!.privacyPolicy.requiresUpdate || this.state.termsConfiguration!.termsAndConditions.requiresUpdate) {
        return (
          <TermsPage
            onAccepted={async () => {
              await this.load();
            }}
          />
        );
      }

      if (this.props.location.pathname.substring(0, 10) === '/redirect/') {
        const [type, id, subType, subId] = this.props.location.pathname.substring(10).split('/');
        if (type === 'invoice') {
          if (this.api.currentUser.type === UserType.Internal) {
            return <Navigate to={`/backend/billing/invoices/${id}`} />;
          } else {
            return <Navigate to={`/account/billing`} />;
          }
        } else if (type === 'contract' || type === 'subscription') {
          return <Navigate to={`/subscriptions/${id}`} />;
        } else if (type === 'site') {
          return <Navigate to={`/sites/${id}`} />;
        } else if (type === 'organization' || type === 'customer') {
          return <Navigate to={`/account/company`} />;
        } else if (type === 'project' || type === 'space' || type === 'environment') {
          return (
            <WithProject uuid={id as Uuid}>
              {(project) => (project ? <Navigate to={`/projects/${project.id}`} /> : <NotFoundPage />)}
            </WithProject>
          );
        } else if (type === 'task' || type === 'content') {
          if (subType !== 'site') {
            return <NotFoundPage />;
          }
          const siteUuid = subId as Uuid;
          return (
            <WithSite uuid={siteUuid}>
              {(site) =>
                site ? (
                  <WithProject entityId={site?.project.getId()}>
                    {(project) =>
                      project ? (
                        type === 'task' ? (
                          <Navigate to={`/projects/${project.id}/updates/${site.id}?syndicationId=${id}`} />
                        ) : (
                          <Navigate to={`/projects/${project.id}/content/${site.id}?search=${id}`} />
                        )
                      ) : (
                        <NotFoundPage />
                      )
                    }
                  </WithProject>
                ) : (
                  <NotFoundPage />
                )
              }
            </WithSite>
          );
        }
      }

      if (this.state.signedUp) {
        return <em>Redirecting...</em>;
      }

      return (
        <WithCustomer entityId={this.api.currentUser.customer?.getId()}>
          {(customer) =>
            customer ? (
              <AppContextProvider>
                <RoutingContainer />
              </AppContextProvider>
            ) : (
              <CustomerRegistration
                onDone={async () => {
                  await this.api.currentUser?.customer?.get();
                  this.setState({ loaded: false });
                  await this.load(true);
                }}
              />
            )
          }
        </WithCustomer>
      );
    }

    if (this.props.location.pathname === '/user/logout') {
      return <Navigate to={'/'} />;
    }

    return (
      <AuthenticationContext.Consumer>
        {(auth: IInternalAuthentication) => {
          const auth0 = auth as IAuth0Authentication;
          if (auth0.error) {
            return (
              <ErrorPage>
                <Alert variant="danger">
                  We were not able to log you in, please try again later.
                  <br />
                  <br />
                  <em>Technical details: {auth0.error.message}</em>
                </Alert>
              </ErrorPage>
            );
          }
          if (auth.isAuthenticated) {
            if (this.api.userLoadingError) {
              if (this.api.userLoadingError instanceof ClientError) {
                if (this.api.userLoadingError.message.includes('verify') && this.api.userLoadingError.message.includes('email')) {
                  return <VerifyEmail />;
                } else if (
                  this.api.userLoadingError.message.includes('account') &&
                  this.api.userLoadingError.message.includes('inactive')
                ) {
                  return (
                    <ErrorPage>
                      <Alert variant="danger">
                        Your account has been deactivated. Please reach out to your account owner to re-activate it. If you are the account
                        owner, please reach out to our support to re-activate it.
                      </Alert>
                    </ErrorPage>
                  );
                }
                // Sometimes the backend responds with Unauthorized instead of "Verify email" right after registering a new user account, so we need to try again.
                else if (this.api.userLoadingError.message === 'unauthorized') {
                  window.location.reload();
                  return <>Reloading...</>;
                }

                return (
                  <ErrorPage>
                    <Request error={this.api.userLoadingError} showUnauthorizedModal loadingAnimation="none" />
                  </ErrorPage>
                );
              }

              return (
                <ErrorPage>
                  <Alert variant="danger">
                    We were not able to fetch your user account, please try again later.
                    <br />
                    <br />
                    <em>Technical details: {this.api.userLoadingError.message}</em>
                  </Alert>
                </ErrorPage>
              );
            }

            return (
              <ErrorPage>
                <Alert variant="danger">We were not able to log you in, please try reloading the page.</Alert>
              </ErrorPage>
            );
          }

          return <Login />;
        }}
      </AuthenticationContext.Consumer>
    );
  }
}

export const SectionSwitch = withLocation(SectionSwitchComponent);
