import { ClientProjectEntity } from '@edgebox/api-rest-client';
import { InternalId, Uuid } from '@edgebox/data-definition-kit';
import React from 'react';
import { ApiComponent, IApiComponentState } from '../common/index';

interface IProps {
  entityId?: InternalId;
  uuid?: Uuid;
  children: (entity?: ClientProjectEntity) => React.ReactNode;
}

interface IState extends IApiComponentState {
  entity?: ClientProjectEntity;
  loaded?: boolean;
}

export class WithProject extends ApiComponent<IProps, IState> {
  async load() {
    try {
      if (this.props.entityId) {
        const entity = await this.api.syndication.projects.item(this.props.entityId);
        return {
          loaded: true,
          entity,
        };
      } else if (this.props.uuid) {
        const entity = await this.api.syndication.projects.itemByUuid(this.props.uuid);
        return {
          loaded: true,
          entity,
        };
      }
    } catch (e) {}
    return {
      loaded: true,
    };
  }

  render() {
    const { children } = this.props;
    const { loaded, entity } = this.state;

    if (!loaded) {
      return this.renderRequest();
    }

    return children(entity);
  }
}
