import { ClientProjectEntity } from '@edgebox/api-rest-client';
import { CustomerRole, UserType } from '@edgebox/data-definitions';
import { EditButton, LeftRightContainer, LeftRightH1, Right } from '@edgebox/react-components';
import React from 'react';
import { Alert, Badge, Button, Col, Image, Modal, Row } from 'react-bootstrap';
import {
  ApiComponent,
  ContractLink,
  CustomerLink,
  IApiComponentState,
  IItemProps,
  ProjectForm,
  SyncCoreDataDefinitionsEnumValue,
  SyncCoreLink,
} from '../../../common/index';
import { IIdParamProp, IProjectParamProp, withIdParam, withProjectParam } from '../../RouterHelper';
import { ContentBox } from '../../Shared/ContentBox';
import ProjectDetailImage from '../../../images/undraw_Mind_map_re_nlb6.svg';
import { PagedSiteList } from '../../Shared/PagedSiteList';
import { IconSummaryItem } from '../../Shared/IconSummaryItem';
import { faFingerprint } from '@fortawesome/pro-light-svg-icons/faFingerprint';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faDollarSign } from '@fortawesome/pro-light-svg-icons/faDollarSign';
import { ProjectStatusBadge } from '../../../common/components/Syndication/Badges/ProjectStatusBadge';
import { ProjectStatus, SiteApplicationType } from '@edgebox/sync-core-data-definitions';
import { HasNone } from '../../../common/helpers/HasSwitch';
import { faServer } from '@fortawesome/pro-light-svg-icons/faServer';
import { HeadlineWithBreadcrumbNavigation } from '../../../common/components/BreadcrumbNavigation';
import { propertyChangeReload } from '../../../common/helpers/propertyChangeReload';
import { IAppContextProp, withAppContext } from '../../../common/contexts/AppContext';
import { ProjectIcons } from '../../Icons';
import { UserRoleGate } from '../../../common/components/UserRoleGate';

interface IExternalProps {}
interface IProps extends JSX.IntrinsicAttributes, IExternalProps, IProjectParamProp, IAppContextProp {}

interface IState extends IApiComponentState {
  project?: ClientProjectEntity;
  editProject?: ClientProjectEntity;
  saving?: boolean;
  confirmAction?: 'deactivate' | 'reactivate';
}

class ProjectDetailComponent extends ApiComponent<IProps, IState> {
  async load() {
    const project = await this.api.syndication.projects.item(this.props.project);

    this.props.appContext.setProject?.(project);

    return {
      project,
    };
  }

  render() {
    const { project, editProject, saving, confirmAction } = this.state;

    if (!project) {
      return this.renderRequest();
    }

    const renderBackendProperties = this.api.currentUser?.type === UserType.Internal;

    return (
      <>
        <LeftRightContainer
          left={
            <HeadlineWithBreadcrumbNavigation>
              <span className="text-truncate mw-100 d-inline-block">
                <ProjectIcons project={project} />
                <span className="ms-3" title={project.name}>
                  {project.name}
                </span>{' '}
              </span>
            </HeadlineWithBreadcrumbNavigation>
          }
          right={
            <>
              {!project.status || project.status === ProjectStatus.Active ? (
                <HasNone request={(api) => api.billing.sites.search(undefined, { projectId: project.id })}>
                  <Button variant="danger" onClick={() => this.setState({ confirmAction: 'deactivate' })}>
                    Deactivate
                  </Button>
                </HasNone>
              ) : (
                <Button variant="primary" onClick={() => this.setState({ confirmAction: 'reactivate' })}>
                  Reactivate
                </Button>
              )}
              {editProject ? null : (
                <UserRoleGate allowed={[CustomerRole.Owner, CustomerRole.ManageSites]}>
                  {() => <EditButton onClick={() => this.setState({ editProject: new ClientProjectEntity(project) })} />}
                </UserRoleGate>
              )}
            </>
          }
        />

        <ContentBox className="pb-3">
          <Row>
            <Col xs={6} className={'p-5'}>
              <Image src={ProjectDetailImage} width={'100%'} />
            </Col>
            <Col className={'pt-4'}>
              {editProject ? (
                <ProjectForm
                  fixedContract
                  key={editProject ? 'edit' : 'view'}
                  name={<></>}
                  onSave={async (update) => {
                    this.setState({
                      saving: true,
                    });
                    try {
                      const project = await this.api.syndication.projects.update(update as ClientProjectEntity);
                      this.setState({
                        project,
                        editProject: undefined,
                      });

                      this.props.appContext.refreshProjects?.();
                    } finally {
                      this.setState({
                        saving: false,
                      });
                    }
                  }}
                  mode={editProject ? 'edit-full' : 'view-full'}
                  entity={editProject || project}
                >
                  {editProject && (
                    <Right>
                      <Button disabled={saving} variant="light" onClick={() => this.setState({ editProject: undefined })}>
                        Cancel
                      </Button>
                      <Button disabled={saving} type="submit" variant="primary">
                        Save
                      </Button>
                    </Right>
                  )}
                </ProjectForm>
              ) : (
                <>
                  <h4>Details</h4>
                  {renderBackendProperties && (
                    <IconSummaryItem icon={faBuilding}>
                      <CustomerLink entityId={project.customer.getId()} />
                    </IconSummaryItem>
                  )}
                  <IconSummaryItem icon={faDollarSign}>
                    <ContractLink entityId={project.contract.getId()} />
                  </IconSummaryItem>
                  {!!project.syncCore?.getId() && (
                    <IconSummaryItem icon={faServer}>
                      <SyncCoreLink entityId={project.syncCore.getId()!} />
                    </IconSummaryItem>
                  )}
                  <IconSummaryItem icon={faFingerprint}>{project.uuid}</IconSummaryItem>
                </>
              )}
            </Col>
          </Row>
        </ContentBox>

        <Modal show={!!confirmAction} scrollable onHide={() => this.setState({ confirmAction: undefined })}>
          <Modal.Header closeButton>
            <Modal.Title>{confirmAction === 'deactivate' ? 'Deactivate project' : 'Reactivate project'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {confirmAction === 'deactivate' ? (
              <>
                <strong>Are you sure you want to deactivate this project?</strong>
                <br />
                <br />
                You won't be able to add new sites to this project or reactivate existing sites, but you can reactivate the project later.
              </>
            ) : (
              <>
                <strong>Are you sure you want to reactivate this project?</strong>
                <br />
                <br />
                You will be able to add new sites to this project again and reactivate sites in this project.
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Right>
              <Button
                disabled={saving}
                variant={confirmAction === 'deactivate' ? 'danger' : 'primary'}
                onClick={async () => {
                  this.setState({ saving: true });
                  try {
                    project.status = confirmAction === 'deactivate' ? ProjectStatus.Inactive : ProjectStatus.Active;
                    const updatedProject = await this.api.syndication.projects.update(project);
                    this.setState({ project: updatedProject, confirmAction: undefined });
                  } finally {
                    this.setState({ saving: false });
                  }
                }}
              >
                Continue
              </Button>
            </Right>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export const ProjectDetail = withProjectParam<{}>(propertyChangeReload(withAppContext(ProjectDetailComponent), (props) => props.project));
