import { ClientCustomerEntity } from '@edgebox/api-rest-client';
import React from 'react';
import { Alert, Badge } from 'react-bootstrap';
import Row from 'react-bootstrap/cjs/Row';
import { ButtonLink } from '@edgebox/react-components';
import { ContentCol } from '@edgebox/react-components';
import { HeaderCol } from '@edgebox/react-components';
import { ContentBox } from '../../Shared/ContentBox';
import { ApiComponent, CustomerStatusBadge, CustomerVatValidationStatusBadge, FormatDateTime, PagedList } from '../../../common';

export class BackendCustomerList extends ApiComponent {
  async load() {
    return {};
  }

  render() {
    return (
      <PagedList<ClientCustomerEntity>
        searchable
        request={(page, filter) => this.api.billing.customers.findByName(filter?.search || '', { page })}
        renderListHeader={() => (
          <ContentBox glueBottom>
            <Row>
              <HeaderCol>Name</HeaderCol>
              <HeaderCol>Status</HeaderCol>
              <HeaderCol>Vat Validation Status</HeaderCol>
              <HeaderCol>Created</HeaderCol>
            </Row>
          </ContentBox>
        )}
        emptyMessage={
          <Alert variant={'light'}>No customers. Check out the installation instructions to create default / required data.</Alert>
        }
        renderList={(items) => <ContentBox glueTop>{items}</ContentBox>}
        renderItem={(customer) => (
          <Row key={customer.id}>
            <ContentCol>
              <ButtonLink to={`/backend/billing/customers/${customer.id}`} variant={'link'}>
                {customer.name}
              </ButtonLink>
            </ContentCol>
            <ContentCol>
              <CustomerStatusBadge status={customer.status} />
              {customer.dontBill ? <Badge bg={'danger'}>Don't bill</Badge> : undefined}
              {customer.requiresPurchaseOrder ? <Badge bg={'danger'}>Requires purchase order</Badge> : undefined}
              {customer.payManually ? <Badge bg={'danger'}>Pays manually</Badge> : undefined}
            </ContentCol>
            <ContentCol>
              <CustomerVatValidationStatusBadge status={customer.vatValidationStatus} />
            </ContentCol>
            <ContentCol>
              <FormatDateTime date={customer.createdAt} />
            </ContentCol>
          </Row>
        )}
      />
    );
  }
}
