import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Table } from 'react-bootstrap';

interface IGoodBadComparison {
  good: boolean;
}
interface ITextComparison {
  good: boolean;
  content: React.ReactNode;
}
type AnyComparison = IGoodBadComparison | ITextComparison;

export interface IComparisonRow {
  label: React.ReactNode;
  packages: AnyComparison[];
}

interface IProps {
  packageNames: React.ReactNode[];
  recommendedPackage?: number;
  rows: IComparisonRow[];
  selected?: number;
  onSelect: (index: number) => void;
}
export class ComparisonTable extends React.Component<IProps, {}> {
  render() {
    const { packageNames, recommendedPackage, rows, onSelect, selected } = this.props;
    return (
      <Table className={'comparison-table'} style={{ borderCollapse: 'separate', borderSpacing: '0 0' }}>
        <thead>
          <tr>
            <th className={'border-top-0'} style={{ width: '25%' }} />
            {packageNames.map((name, index) => {
              const classes = ['text-center'];
              if (recommendedPackage === index) {
                classes.push('border border-bottom-0 border-primary border-4 rounded-top pb-4 pt-2');
              } else {
                classes.push('border-top-0 pb-2 pt-4');
              }
              return (
                <th key={index} className={classes.join(' ')} style={{ borderCollapse: 'separate' }}>
                  {name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => {
            return (
              <tr key={rowIndex}>
                <th className={'border-0'}>{row.label}</th>
                {row.packages.map((column, columnIndex) => {
                  const data = column as ITextComparison;

                  const classes = ['text-center'];
                  if (!data.good) {
                    classes.push('text-muted');
                  }
                  if (recommendedPackage === columnIndex) {
                    classes.push('border border-top-0 border-primary border-4 border-bottom-0');
                  }

                  return (
                    <td key={columnIndex} className={classes.join(' ')}>
                      {data.content ? data.content : <FontAwesomeIcon icon={data.good ? faCheck : faTimes} />}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          <tr>
            <th className={'border-0'} />
            {packageNames.map((name, index) => {
              const classes = ['text-center'];
              if (recommendedPackage === index) {
                classes.push('border border-top-0 border-primary border-4 rounded-bottom pt-4');
              }
              return (
                <td key={index} className={classes.join(' ')}>
                  {selected === index ? (
                    <small>
                      <em>Currently selected.</em>
                    </small>
                  ) : (
                    <Button
                      variant={'primary'}
                      className={`rounded pt-2 pb-2 ps-5 pe-5 ${recommendedPackage === index ? 'fw-bold' : ''}`}
                      onClick={() => onSelect(index)}
                    >
                      Select
                    </Button>
                  )}
                </td>
              );
            })}
          </tr>
        </tbody>
      </Table>
    );
  }
}
