import React from 'react';
import { PagedLogItemList } from '../PagedLogItemList';

export class BackendLogs extends React.Component {
  async load() {
    return {};
  }

  render() {
    return <PagedLogItemList id={'page'} />;
  }
}
