import { ClientContractRevisionEntity, ClientPricingDraft, ClientPricingEntity } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import React from 'react';
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/cjs/Container';
import Tab from 'react-bootstrap/cjs/Tab';
import Tabs from 'react-bootstrap/cjs/Tabs';
import { Right } from '@edgebox/react-components';
import {
  ApiComponent,
  ContractLink,
  ContractRevisionForm,
  IApiComponentState,
  IItemProps,
  PagedList,
  PricingForm,
} from '../../../common/index';

interface IExternalProps extends IItemProps {
  id: InternalId;
}
interface IProps extends IExternalProps {}

interface IState extends IApiComponentState {
  pricing?: ClientPricingEntity;
  editPricing?: ClientPricingDraft;
  replaceExisting?: boolean;
}

class BackendPricingDetailComponent extends ApiComponent<IProps, IState> {
  async load(): Promise<Partial<IState>> {
    const pricing = await this.api.billing.pricing.item(this.props.id);

    return {
      pricing,
    };
  }

  render() {
    const { pricing, editPricing, replaceExisting } = this.state;

    if (!pricing) {
      return this.renderRequest();
    }

    const onEdit = () => {
      this.setState({
        editPricing: new ClientPricingDraft(pricing),
      });
    };

    const onSave = async (updatedCustomer: ClientPricingDraft) => {
      const previous = this.state.pricing;
      this.setState({
        pricing: updatedCustomer as ClientPricingEntity,
      });

      try {
        if (replaceExisting) {
          updatedCustomer = await this.api.billing.pricing.replace(updatedCustomer as ClientPricingEntity);
        } else {
          updatedCustomer = await this.api.billing.pricing.update(updatedCustomer as ClientPricingEntity);
        }
      } catch (e) {
        this.setState({
          pricing: previous,
        });
      }

      this.setState({
        pricing: updatedCustomer as ClientPricingEntity,
      });

      onCancelEdit();
    };

    const onCancelEdit = () => {
      this.setState({
        editPricing: undefined,
      });
    };

    return (
      <Container>
        <h1>{pricing.name}</h1>

        <Tabs defaultActiveKey={'details'}>
          <Tab eventKey={'details'} title={'Details'}>
            <div className={'mt-3'} />

            <PricingForm
              key={editPricing ? '1' : '0'}
              entity={editPricing ? editPricing : pricing}
              onSave={onSave}
              onToggleEdit={onEdit}
              mode={editPricing ? (replaceExisting ? 'replace' : 'edit-full') : 'view-full-backend'}
              className={'mt-4'}
            >
              {editPricing ? (
                <>
                  <Right>
                    <Button type={'button'} variant={'light'} onClick={onCancelEdit}>
                      Cancel
                    </Button>
                    <Button
                      type={'button'}
                      variant={replaceExisting ? 'light' : 'danger'}
                      onClick={() =>
                        this.setState({
                          replaceExisting: !replaceExisting,
                          editPricing: replaceExisting ? new ClientPricingDraft(pricing) : editPricing,
                        })
                      }
                    >
                      {replaceExisting ? 'Replace' : 'Edit existing'}
                    </Button>
                    <Button type={'submit'} variant={'primary'}>
                      Save
                    </Button>
                  </Right>
                </>
              ) : undefined}
            </PricingForm>
          </Tab>

          <Tab eventKey={'contracts'} title={'Contracts'}>
            <div className={'mt-3'} />

            <PagedList<ClientContractRevisionEntity>
              renderItem={(contract) => (
                <ContractRevisionForm
                  className={'mb-3'}
                  key={contract.id}
                  entity={contract}
                  mode={'view-full-backend'}
                  name={
                    <h3>
                      <ContractLink entityId={contract.id} />
                    </h3>
                  }
                  onSave={async () => {}}
                />
              )}
              request={(page: number, filter) =>
                this.api.billing.contractRevisions.search(filter?.search, { page }, undefined, undefined, pricing.id)
              }
              emptyMessage={<Alert variant={'light'}>This pricing isn't used in a contract yet.</Alert>}
            />
          </Tab>
        </Tabs>
      </Container>
    );
  }
}

export const BackendPricingDetail = BackendPricingDetailComponent;
