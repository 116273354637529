import { ClientBasketEntity, ClientCustomerEntity, ClientPricingEntity, ClientContractRevisionEntity } from '@edgebox/api-rest-client';
import { DateTimeFormat } from '@edgebox/data-definition-kit';
import { BasketType, PaymentCycleType } from '@edgebox/data-definitions';
import { Package } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  ApiComponent,
  DataDefinitionsEnumValue,
  FormatDateTime,
  IApiComponentState,
  SyncCoreDataDefinitionsEnumValue,
} from '../../common/index';
import { PricingTable } from './PricingTable';

interface IProps {
  basket: ClientBasketEntity;
  onChangeQuantity?: string;
}

interface IState extends IApiComponentState {
  pricing?: ClientPricingEntity;
  customer?: ClientCustomerEntity;
  currentRevision?: ClientContractRevisionEntity;
  showTaxes?: boolean;
}

export class Basket extends ApiComponent<IProps, IState> {
  async load() {
    const { basket } = this.props;
    const customer = await basket.customer.get();
    const pricing = await basket.pricing?.get();

    const contract = await basket.contract?.get();
    const mostRecent = contract ? await this.api.billing.contractRevisions.getMostRecentForContract(contract.id) : undefined;

    return {
      pricing,
      customer,
      currentRevision: mostRecent?.current,
    };
  }

  render() {
    const { basket, onChangeQuantity } = this.props;
    const { pricing, showTaxes, customer, currentRevision } = this.state;

    if (!pricing || !customer) {
      return this.renderRequest();
    }

    const charged = !customer.dontBill && !customer.payManually;

    const monthlyPricing = basket.monthlyPricing!;

    const regularChargeInMonths =
      monthlyPricing.cycle === PaymentCycleType.Yearly ? 12 : monthlyPricing.cycle === PaymentCycleType.Quarterly ? 3 : 1;

    const billNext = basket.type === BasketType.RenewContractManually && currentRevision && currentRevision.packageType !== Package.Trial;

    return (
      <>
        <Row>
          <Col xs={6}>
            <h4>Costs per month</h4>
            <PricingTable
              onChangeQuantity={onChangeQuantity}
              label={
                <>
                  Content <SyncCoreDataDefinitionsEnumValue enumName={'Product'} keyName={pricing.product} />
                </>
              }
              discounts={basket.discounts}
              showTaxes={!!showTaxes}
              pricing={monthlyPricing}
            />
          </Col>

          {basket.oneOffPartialMonths ? (
            <Col>
              <h4>{charged ? 'Charged now' : 'Billed now'}</h4>
              <PricingTable
                label={'Partial payment for upgrade until next regular charge'}
                chargeMultiplier={basket.oneOffPartialMonths}
                chargeMultiplierMonths={regularChargeInMonths > 1 ? regularChargeInMonths : undefined}
                chargeDiscounted
                showTaxes={!!showTaxes}
                pricing={monthlyPricing}
              />
            </Col>
          ) : (
            <Col>
              <h4>{charged ? 'Charged now' : billNext ? 'Billed next' : 'Billed now'}</h4>
              <PricingTable
                label={
                  <>
                    <DataDefinitionsEnumValue enumName={'PaymentCycleType'} keyName={monthlyPricing.cycle} /> charge
                    {billNext ? (
                      <div>
                        <em>
                          <FormatDateTime date={currentRevision!.endDate} format={DateTimeFormat.Date} />
                          {' to '}
                          <FormatDateTime
                            date={currentRevision!.endDate.clone().add(regularChargeInMonths, 'months')}
                            format={DateTimeFormat.Date}
                          />
                        </em>
                      </div>
                    ) : undefined}
                  </>
                }
                chargeMultiplierMonths={regularChargeInMonths}
                showTaxes={!!showTaxes}
                pricing={monthlyPricing}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <Form.Check
              id={'showTaxes'}
              name={'showTaxes'}
              type={'checkbox'}
              label={'Show taxes'}
              checked={!!showTaxes}
              onChange={() => this.setState({ showTaxes: !showTaxes })}
            />
          </Col>
        </Row>
      </>
    );
  }
}
