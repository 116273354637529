import { InternalId } from '@edgebox/data-definition-kit';
import React from 'react';
import { ApiComponent, IApiComponentState } from '../../../services/ApiComponent';
import { Throbber } from '@edgebox/react-components';
import { Badge } from 'react-bootstrap';
import { SiteEnvironmentType } from '@edgebox/sync-core-data-definitions';

interface IProps {
  projectId?: InternalId;
  contractId?: InternalId;
  customerId?: InternalId;
  environmentType?: SiteEnvironmentType;
  badge?: boolean;
  warnIfEmpty?: boolean;
  highlightIfNotEmpty?: boolean;
}

interface IState extends IApiComponentState {
  count?: number;
}

export class ViewSiteCount extends ApiComponent<IProps, IState> {
  async load() {
    const { projectId, environmentType, customerId, contractId } = this.props;
    const result = await this.api.billing.sites.search({ itemsPerPage: 0 }, { projectId, customerId, contractId }, { environmentType });
    const count = result.totalNumberOfItems;

    return {
      count,
    };
  }

  render(): React.ReactElement {
    const { count } = this.state;
    const { badge, warnIfEmpty, highlightIfNotEmpty } = this.props;

    if (count === undefined) {
      return <Throbber />;
    }

    if (badge) {
      return (
        <Badge bg={warnIfEmpty && !count ? 'danger' : highlightIfNotEmpty && count ? 'primary' : 'light'}>
          {count === 1 ? '1 site' : `${count} sites`}
        </Badge>
      );
    }

    return <>{count.toString()}</>;
  }
}
