import { IInternalAuthentication } from '@edgebox/api-rest-client';
import { CustomerRole, InternalRole } from '@edgebox/data-definitions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/pro-light-svg-icons/faGear';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import {
  ApiComponent,
  AuthenticationContext,
  CustomerSelector,
  IApiComponentState,
  Logo,
  ProjectSelector,
  ViewContractName,
  WithMostRecentContractRevision,
} from '../common';
import { API_DOMAIN } from '../constants';
import { EnvironmentBadge } from './EnvironmentBadge';
import { ICONS } from './Icons';
import { ILocationProp, INavigateProp, withLocation, withNavigate } from './RouterHelper';
import { IAppContextProp, withAppContext } from '../common/contexts/AppContext';
import { Alert, Dropdown } from 'react-bootstrap';
import { ButtonLink } from '@edgebox/react-components';
import { Package } from '@edgebox/sync-core-data-definitions';
import { ViewUpgradeButton } from './Shared/ViewUpgradeButton';

interface IProps extends ILocationProp, INavigateProp, IAppContextProp, JSX.IntrinsicAttributes {}

interface IState extends IApiComponentState {}

interface IMenuItemProps extends PropsWithChildren {
  to?: string;
  className?: string;
  id: string;
  icon: React.ReactNode;
  active?: boolean;
  onClick?: () => void;
}
function MenuItem(props: IMenuItemProps) {
  const content = (
    <>
      <div className={`flex-shrink-0 flex-grow-0 p-2 me-2 ${props.active ? 'text-white' : 'text-black'}`}>{props.icon}</div>
      <div className={`flex-shrink-1 flex-grow-1 ${props.active ? 'text-white fw-bold' : 'text-black'}`}>{props.children}</div>
    </>
  );

  const className = `d-flex flex-row align-items-center p-1 rounded text-decoration-none ${props.className || ''} ${
    props.active ? 'bg-primary' : ''
  }`;

  return props.to ? (
    <Link to={props.to} className={className} id={props.id} onClick={props.onClick}>
      {content}
    </Link>
  ) : (
    <div className={className} id={props.id} onClick={props.onClick}>
      {content}
    </div>
  );
}

function matchPath(current: string, link: string) {
  return (
    current === link ||
    current.substring(0, link.length + 1) === link + '/' ||
    (current.substring(0, 10) === '/projects/' &&
      ('/' + current.split('/').slice(3).join('/') === link ||
        ('/' + current.split('/').slice(3).join('/')).substring(0, link.length + 1) === link + '/'))
  );
}

class SidebarNavigationComponent extends ApiComponent<IProps, IState> {
  async load() {
    return {};
  }

  public render() {
    const { location, appContext } = this.props;
    const {
      customer,
      contract,
      hasMultipleContracts,
      project,
      customerHasAnySites,
      customerHasAnySitesIncludingInactive,
      hasMultipleCustomers,
    } = appContext;

    const firstName = this.api.currentUser?.firstName || this.api.currentUser?.firstName;

    const customerRoles = this.api.currentUser?.customerRoles;

    const canManageUsers = customerRoles?.includes(CustomerRole.Owner);

    const canViewInvoices = customerRoles?.includes(CustomerRole.Owner) || customerRoles?.includes(CustomerRole.ManageFinances);

    const canManageContracts = customerRoles?.includes(CustomerRole.Owner) || customerRoles?.includes(CustomerRole.ManageFinances);
    const canManageProjects = customerRoles?.includes(CustomerRole.Owner) || customerRoles?.includes(CustomerRole.ManageSites);
    const canManageSyncCores = customerRoles?.includes(CustomerRole.Owner) || customerRoles?.includes(CustomerRole.ManageSites);
    const canViewContent = !!customerRoles?.find((c) =>
      [CustomerRole.Owner, CustomerRole.ManageSites, CustomerRole.ManageContent].includes(c)
    );

    const internalRoles = this.api.currentUser?.customer?.getId() === customer?.id ? this.api.currentUser?.internalRoles : undefined;
    const canAdministerAccounting = internalRoles?.includes(InternalRole.Admin) || internalRoles?.includes(InternalRole.DevOps);
    const canAdministerSyndication = internalRoles?.includes(InternalRole.Admin) || internalRoles?.includes(InternalRole.DevOps);
    const canAdministerOperations = internalRoles?.includes(InternalRole.DevOps);

    const mode = matchPath(location.pathname, '/backend') ? 'backend' : customerHasAnySites ? 'frontend' : 'get-started';

    const isProjectRelevant = matchPath(location.pathname, '/projects') && location.pathname !== '/projects';

    return (
      <div className="sidebar d-flex flex-column bg-white h-100" style={{ width: '250px' }}>
        <div className="pt-3 pb-2 ps-2 pe-2 flex-shrink-0 flex-grow-0">
          <div className="text-center">
            <Logo height={45} />
          </div>
          <div style={{ marginTop: '-25px', height: '25px' }}>
            <EnvironmentBadge />
          </div>
        </div>

        {(mode === 'frontend' || mode === 'get-started') && (
          <div className="pt-2 pb-2 ps-2 pe-2 flex-shrink-0 flex-grow-0">
            {hasMultipleCustomers && (
              <CustomerSelector
                value={customer}
                onSelected={async (selected) => {
                  appContext?.setCustomer?.(selected);
                }}
                onRemove={
                  customer?.id === this.api.currentUser?.customer?.getId()
                    ? undefined
                    : async () => {
                        appContext?.setCustomer?.((await this.getCurrentCustomer())!);
                      }
                }
                display={'menu-solid'}
                inactive={!isProjectRelevant}
              />
            )}

            {hasMultipleContracts && (
              <div className="d-flex flex-row align-items-center">
                <div className="flex-shrink-1 flex-grow-1 pe-2 pt-1 text-truncate">
                  {
                    /*hasMultipleContracts ? (
                  <ContractSelector
                    value={contract}
                    onSelected={async (selected) => {
                      appContext?.setContract?.(selected, customer);
                    }}
                    forCustomer={customer?.id}
                    display={'menu'}
                  />
                ) :*/ contract ? (
                      <div className="pt-2 pb-1">
                        <ViewContractName entity={contract} includeDate={false} short key={contract.id} />
                      </div>
                    ) : null
                  }
                </div>
                {canManageContracts && contract?.customer.getId() === this.api.currentUser?.customer?.getId() && (
                  <div className="flex-shrink-0 flex-grow-0">
                    <Link to={hasMultipleContracts || !contract ? '/subscriptions' : `/subscriptions/${contract.id}`} className="text-dark">
                      <FontAwesomeIcon icon={faGear} size={'lg'} />
                    </Link>
                  </div>
                )}
              </div>
            )}

            {project && (
              <div className="d-flex flex-row align-items-center pb-3">
                <div className="flex-shrink-1 flex-grow-1 pt-1">
                  {project ? (
                    <ProjectSelector
                      value={project}
                      onSelected={async (selected) => {
                        appContext?.setProject?.(selected, contract, customer);
                      }}
                      forCustomer={customer?.id}
                      display={'menu-solid'}
                      inactive={!isProjectRelevant}
                    />
                  ) : null}
                </div>
                {canManageProjects && (
                  <div className="flex-shrink-0 flex-grow-0 ps-2 pt-2">
                    <Link to={project ? `/projects/${project.id}` : '/projects'} className="text-dark">
                      <FontAwesomeIcon icon={faGear} size={'lg'} />
                    </Link>
                  </div>
                )}
              </div>
            )}

            <div className="" style={{ borderBottom: '2px solid #eee' }} />
          </div>
        )}

        {(mode === 'frontend' || mode === 'get-started') && (
          <div className="flex-shrink-1 flex-grow-1 ps-2 pe-2 overflow-auto">
            <>
              <MenuItem
                id="menu-syndication-dashboard"
                icon={
                  <FontAwesomeIcon
                    icon={ICONS.Dashboard[location.pathname === '/' || !!matchPath(location.pathname, '/dashboard') ? 'regular' : 'light']}
                  />
                }
                active={location.pathname === '/' || !!matchPath(location.pathname, '/dashboard')}
                to={'/'}
              >
                {customerHasAnySitesIncludingInactive ? 'Dashboard' : 'Get started'}
              </MenuItem>

              {customerHasAnySitesIncludingInactive && (
                <MenuItem
                  id="menu-syndication-sites"
                  icon={<FontAwesomeIcon icon={ICONS.Site[matchPath(location.pathname, '/sites') ? 'regular' : 'light']} />}
                  active={!!matchPath(location.pathname, '/sites')}
                  to={'/sites'}
                >
                  Sites
                </MenuItem>
              )}

              {mode === 'frontend' && (
                <>
                  {canViewContent && (
                    <MenuItem
                      id="menu-syndication-content"
                      icon={<FontAwesomeIcon icon={ICONS.Content[matchPath(location.pathname, '/content') ? 'regular' : 'light']} />}
                      active={!!matchPath(location.pathname, '/content')}
                      to={'/content'}
                    >
                      Content
                    </MenuItem>
                  )}

                  {canViewContent && (
                    <MenuItem
                      id="menu-syndication-content"
                      icon={<FontAwesomeIcon icon={ICONS.Updates[matchPath(location.pathname, '/updates') ? 'regular' : 'light']} />}
                      active={!!matchPath(location.pathname, '/updates')}
                      to={'/updates'}
                    >
                      Updates
                    </MenuItem>
                  )}

                  {canManageSyncCores && (
                    <MenuItem
                      id="menu-syndication-sync-cores"
                      icon={<FontAwesomeIcon icon={ICONS.SyncCore[matchPath(location.pathname, '/sync-cores') ? 'regular' : 'light']} />}
                      active={!!matchPath(location.pathname, '/sync-cores')}
                      to={'/sync-cores'}
                    >
                      Sync Cores
                    </MenuItem>
                  )}
                </>
              )}
            </>

            {contract && (
              <WithMostRecentContractRevision contractId={contract.id}>
                {(revision) => {
                  let warning: string = '';
                  if (!revision.current) {
                    warning = 'Your contract has expired.';
                  } else if (revision.current.packageType === Package.Trial && !revision.next) {
                    warning = 'Your trial expires soon.';
                  }

                  if (warning) {
                    return (
                      <>
                        <div className="" style={{ borderBottom: '2px solid #eee' }} />

                        <Alert variant="danger" className="mt-3">
                          <p className="mb-0">{warning}</p>

                          <ViewUpgradeButton contractId={contract.id} />
                        </Alert>
                      </>
                    );
                  }
                }}
              </WithMostRecentContractRevision>
            )}

            {(canAdministerAccounting || canAdministerSyndication || canAdministerOperations) && (
              <>
                <div className="mt-2" style={{ borderBottom: '2px solid #eee' }} />
                <div className="text-center mt-4">
                  <ButtonLink variant="primary" to={'/backend'}>
                    Administer
                  </ButtonLink>
                </div>
              </>
            )}
          </div>
        )}

        {mode === 'backend' && (
          <div className="flex-shrink-1 flex-grow-1 ps-2 pe-2 overflow-auto">
            <div className="text-start mt-2 pb-2">
              <ButtonLink variant="light" to={'/'}>
                <FontAwesomeIcon icon={faChevronLeft} /> Back
              </ButtonLink>
            </div>
            <div className="mb-4" style={{ borderBottom: '2px solid #eee' }} />

            {canAdministerAccounting && (
              <>
                <div className={'ps-2 mt-2 headline text-uppercase text-danger'}>Accounting admin</div>
                <MenuItem
                  id="menu-accounting-admin-customers"
                  icon={
                    <FontAwesomeIcon
                      icon={ICONS.Customer[matchPath(location.pathname, '/backend/billing/customers') ? 'regular' : 'light']}
                    />
                  }
                  active={!!matchPath(location.pathname, '/backend/billing/customers')}
                  to={'/backend/billing/customers'}
                >
                  Customers
                </MenuItem>
                <MenuItem
                  id="menu-accounting-admin-invoices"
                  icon={
                    <FontAwesomeIcon
                      icon={ICONS.Invoice[matchPath(location.pathname, '/backend/billing/invoices') ? 'regular' : 'light']}
                    />
                  }
                  active={!!matchPath(location.pathname, '/backend/billing/invoices')}
                  to={'/backend/billing/invoices'}
                >
                  Invoices
                </MenuItem>
                <MenuItem
                  id="menu-accounting-admin-contracts"
                  icon={
                    <FontAwesomeIcon
                      icon={ICONS.Contract[matchPath(location.pathname, '/backend/billing/contracts') ? 'regular' : 'light']}
                    />
                  }
                  active={!!matchPath(location.pathname, '/backend/billing/contracts')}
                  to={'/backend/billing/contracts'}
                >
                  Contracts
                </MenuItem>
              </>
            )}

            {canAdministerSyndication && (
              <>
                <div className={'ps-2 mt-2 headline text-uppercase text-danger'}>Syndication admin</div>
                <MenuItem
                  id="menu-syndication-admin-sites"
                  icon={
                    <FontAwesomeIcon icon={ICONS.Site[matchPath(location.pathname, '/backend/syndication/sites') ? 'regular' : 'light']} />
                  }
                  active={!!matchPath(location.pathname, '/backend/syndication/sites')}
                  to={'/backend/syndication/sites'}
                >
                  Sites
                </MenuItem>
                <MenuItem
                  id="menu-syndication-admin-sync-cores"
                  icon={
                    <FontAwesomeIcon
                      icon={ICONS.SyncCore[matchPath(location.pathname, '/backend/syndication/sync-cores') ? 'regular' : 'light']}
                    />
                  }
                  active={!!matchPath(location.pathname, '/backend/syndication/sync-cores')}
                  to={'/backend/syndication/sync-cores'}
                >
                  Sync Cores
                </MenuItem>
              </>
            )}

            {canAdministerOperations && (
              <>
                <div className={'ps-2 mt-2 headline text-uppercase text-danger'}>Operations admin</div>
                <MenuItem
                  id="menu-settings"
                  icon={<FontAwesomeIcon icon={ICONS.Setting[matchPath(location.pathname, '/backend/settings') ? 'regular' : 'light']} />}
                  active={!!matchPath(location.pathname, '/backend/settings')}
                  to={'/backend/settings'}
                >
                  Settings
                </MenuItem>
                {API_DOMAIN.includes('localhost') && (
                  <MenuItem
                    id="menu-developer"
                    icon={
                      <FontAwesomeIcon icon={ICONS.Setting[matchPath(location.pathname, '/backend/developer') ? 'regular' : 'light']} />
                    }
                    active={!!matchPath(location.pathname, '/backend/developer')}
                    to={'/backend/developer'}
                  >
                    Developer
                  </MenuItem>
                )}
                <MenuItem
                  id="menu-syndication-admin-logs"
                  icon={
                    <FontAwesomeIcon icon={ICONS.Logs[matchPath(location.pathname, '/backend/syndication/logs') ? 'regular' : 'light']} />
                  }
                  active={!!matchPath(location.pathname, '/backend/syndication/logs')}
                  to={'/backend/syndication/logs'}
                >
                  Logs
                </MenuItem>
              </>
            )}
          </div>
        )}
        <div className="flex-shrink-0 flex-grow-0 p-2">
          <div className="shadow rounded cursor-pointer">
            <Dropdown>
              <Dropdown.Toggle variant="" id="dropdown-basic" className="w-100 text-start pt-3 pb-3 ps-4 pe-4">
                <FontAwesomeIcon icon={ICONS.User.light} className="me-2 mt-1 mb-1" />
                <span className="d-inline-block w-75 me-3">{firstName || 'Account'}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="shadow-sm">
                <Dropdown.Item
                  onClick={() => this.props.navigate('/account/profile')}
                  className={`p-2 ${matchPath(location.pathname, '/account/profile') ? 'text-primary' : ''}`}
                >
                  <span className="d-inline-block text-center me-1" style={{ width: '32px' }}>
                    <FontAwesomeIcon icon={ICONS.User[matchPath(location.pathname, '/account/profile') ? 'regular' : 'light']} />
                  </span>
                  My account
                </Dropdown.Item>
                {canViewInvoices || canManageUsers ? <Dropdown.Divider /> : null}
                {customer && canViewInvoices && (
                  <Dropdown.Item
                    onClick={() => this.props.navigate('/subscriptions')}
                    className={`p-2 ${matchPath(location.pathname, '/subscriptions') ? 'text-primary' : ''}`}
                  >
                    <span className="d-inline-block text-center me-1" style={{ width: '32px' }}>
                      <FontAwesomeIcon icon={ICONS.Contract[matchPath(location.pathname, '/subscriptions') ? 'regular' : 'light']} />
                    </span>
                    Subscriptions
                  </Dropdown.Item>
                )}
                {canManageUsers && (
                  <Dropdown.Item
                    onClick={() => this.props.navigate('/account/users')}
                    className={`p-2 ${matchPath(location.pathname, '/account/users') ? 'text-primary' : ''}`}
                  >
                    <span className="d-inline-block text-center me-1" style={{ width: '32px' }}>
                      <FontAwesomeIcon icon={ICONS.Users[matchPath(location.pathname, '/account/users') ? 'regular' : 'light']} />
                    </span>
                    Users
                  </Dropdown.Item>
                )}
                {customer && canViewInvoices && (
                  <Dropdown.Item
                    onClick={() => this.props.navigate('/account/billing')}
                    className={`p-2 ${matchPath(location.pathname, '/account/billing') ? 'text-primary' : ''}`}
                  >
                    <span className="d-inline-block text-center me-1" style={{ width: '32px' }}>
                      <FontAwesomeIcon icon={ICONS.Invoice[matchPath(location.pathname, '/account/billing') ? 'regular' : 'light']} />
                    </span>
                    Billing
                  </Dropdown.Item>
                )}
                <Dropdown.Divider />
                <AuthenticationContext.Consumer>
                  {(auth: IInternalAuthentication) => (
                    <Dropdown.Item onClick={() => auth.logout()} className={`p-2`} style={{ width: '233px' }}>
                      <span className="d-inline-block text-center me-1" style={{ width: '32px' }}>
                        <FontAwesomeIcon icon={ICONS.Logout['light']} />
                      </span>
                      Logout
                    </Dropdown.Item>
                  )}
                </AuthenticationContext.Consumer>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  }
}

export const SidebarNavigation = withNavigate<Omit<IProps, 'appContext' | 'location' | 'navigate'>>(
  withLocation<Omit<IProps, 'appContext' | 'location'>>(withAppContext(SidebarNavigationComponent))
);
