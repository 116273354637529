import { isCloudIdeDomain, isLocalhostDomain } from '@edgebox/data-definition-kit';

export function siteMustBeLocal(baseUrl: string): boolean {
  if (process.env.REACT_APP_ENVIRONMENT_TYPE === 'local' && process.env.REACT_APP_LOCAL_ENVIRONMENT_UNRESTRICTED === 'true') {
    return false;
  }

  const host = new URL(baseUrl).hostname;
  return isLocalhostDomain(host) || isCloudIdeDomain(host);
}

export function urlSearchParamsToObject(entries: URLSearchParams): any {
  const result: any = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}
