import { Currency } from '@edgebox/data-definitions';
import { IContractRevisionDiscount } from '@edgebox/data-definitions/dist/EntityInterfaces/Billing/IContractRevision';
import React from 'react';
import { DataDefinitionsEnumValue } from '../../Localization/DataDefinitionsEnumValue';
import { MoneyAmount } from '../../Localization/MoneyAmount';

interface IProps {
  discounts: IContractRevisionDiscount[];
  currency: Currency;
}

export class ViewContractRevisionDiscounts extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { discounts, currency } = this.props;
    return (
      <>
        {discounts.map((discount, index) => {
          const discountPercentage = discount.amountPercentage ? `${Math.round(discount.amountPercentage * 100)}%` : undefined;
          const discountFixed = discount.amountFixed ? <MoneyAmount amount={discount.amountFixed} currency={currency} /> : undefined;

          return (
            <div key={index}>
              <strong>
                <DataDefinitionsEnumValue enumName={'DiscountType'} keyName={discount.type} />
              </strong>
              {discountPercentage ? (
                discountFixed ? (
                  <>
                    : {discountPercentage} and {discountFixed}
                  </>
                ) : (
                  <>: {discountPercentage}</>
                )
              ) : discountFixed ? (
                <>: {discountFixed}</>
              ) : undefined}
              {}
            </div>
          );
        })}
      </>
    );
  }
}
