import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

interface IProps<Type extends string = string> {
  selected: Type;
  onChange: (selected: Type) => void;
  options: { label: React.ReactNode; value: Type }[];
  disabled?: boolean;
}

export class SwitchButton<Type extends string = string> extends React.Component<IProps<Type>, {}> {
  render() {
    const { selected, onChange, options, disabled } = this.props;

    return (
      <ButtonGroup className="mb-2">
        {options.map((option, index) => {
          const isSelected = selected === option.value;
          const roundLeft = index === 0;
          const roundRight = index === options.length - 1;
          return (
            <Button
              key={index}
              variant={isSelected ? 'primary' : 'light'}
              className={`${roundLeft ? 'rounded-start border-end-0' : ''} ${roundRight ? 'rounded-end border-start-0' : ''} ${
                isSelected ? '' : ' border-light border'
              }`}
              onClick={() => {
                onChange(option.value);
              }}
              disabled={disabled}
            >
              {option.label}
            </Button>
          );
        })}
      </ButtonGroup>
    );
  }
}
