import {
  ClientCustomerEntity,
  ClientDiscountEntity,
  ClientPricingDraft,
  ClientPricingEntity,
  ClientProjectDraft,
} from '@edgebox/api-rest-client';
import { IRuntimeReference, ISerializedReference } from '@edgebox/data-definition-kit';
import {
  Currency,
  DataDefinitionsEnumTranslator,
  ISerializedPricingProperties,
  PricingStatus,
  PricingVisibility,
  UserType,
} from '@edgebox/data-definitions';
import { ContentCol, FormField, HeaderCol, MultiValueField, RemoveButton } from '@edgebox/react-components';
import { Package, Product, SalesRegion, SyncCoreDataDefinitionsEnumTranslator } from '@edgebox/sync-core-data-definitions';
import React, { PropsWithChildren } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { EditMode, EntityContext } from '../../../contexts/EntityContext';
import { EntityForm, entityFormWithValidation, IEntityFormProps, IEntityFormState } from '../../Form/EntityForm';
import { EntityFormRow } from '../../Form/EntityFormRow';
import { EnumAsCheckboxes } from '../../Form/EnumAsCheckboxes';
import { EnumAsDropdown } from '../../Form/EnumAsDropdown';
import { MoneyAmount } from '../../Localization/MoneyAmount';
import { SyncCoreDataDefinitionsEnumValue } from '../../Localization/SyncCoreDataDefinitionsEnumValue';
import { PricingStatusBadge } from '../Badges/PricingStatusBadge';
import { PricingVisibilityBadge } from '../Badges/PricingVisibilityBadge';
import { CustomerLink } from '../Links/CustomerLink';
import { CustomerSelector } from '../Selectors/CustomerSelector';
import { DiscountSelector } from '../Selectors/DiscountSelector';
import { ViewDiscounts } from '../Views/ViewDiscounts';

interface IProps<Entity extends ClientPricingDraft> extends IEntityFormProps<Entity, ISerializedPricingProperties>, PropsWithChildren {
  onSave: (entity: Entity) => Promise<void>;
  className?: string;
  mode: 'edit-full' | 'create' | 'replace' | 'view-full-backend';
}

const EDIT_MODES = {
  'view-full-backend': EditMode.None,
  'edit-full': EditMode.Full,
  replace: EditMode.Full,
  create: EditMode.Full,
};

interface IState<Entity extends ClientPricingDraft> extends IEntityFormState<Entity> {
  priceMode: 'price-per-site' | 'fixed-price-per-month';
  customer?: ClientCustomerEntity;
  selectedDiscounts?: ClientDiscountEntity[];
}

const FormWithValidation = entityFormWithValidation<ISerializedPricingProperties, ClientPricingDraft>(
  ClientPricingDraft,
  ClientPricingEntity
);

export class PricingForm<Entity extends ClientPricingDraft = ClientPricingDraft> extends EntityForm<
  Entity,
  ISerializedPricingProperties,
  IProps<Entity>,
  IState<Entity>
> {
  constructor(props: IProps<Entity>) {
    super(props, {
      edit: EDIT_MODES[props.mode],
      priceMode: props.entity.fixedMonthlyPrice ? 'fixed-price-per-month' : 'price-per-site',
    });
  }

  async load(): Promise<Partial<IState<Entity>>> {
    const customer = await this.props.entity.customer?.get();
    const selectedDiscounts = this.props.entity.discounts
      ? ((await Promise.all(this.props.entity.discounts.map((c) => c.get()))) as ClientDiscountEntity[])
      : [];

    return {
      customer,
      selectedDiscounts,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps<Entity>>, prevState: Readonly<IState<Entity>>, snapshot?: any): void {
    if (this.props.mode !== prevProps.mode) {
      this.setState({
        edit: EDIT_MODES[this.props.mode],
      });
    }
  }

  render(): React.ReactElement {
    const { mode, children } = this.props;

    const { priceMode, customer, selectedDiscounts } = this.state;

    const renderBackendProperties = this.api.currentUser!.type === UserType.Internal;

    const createNew = mode === 'create' || mode === 'replace';

    if (!selectedDiscounts) {
      return this.renderRequest();
    }

    return (
      <EntityContext.Provider value={this.entityContext}>
        <FormWithValidation
          {...(this.formProperties() as any)}
          apiComponent={this}
          initialValues={this.props.entity}
          labels={'above'}
          onSubmit={async (draft) => {
            this.setState({
              extendProperties: [],
            });

            await this.props.onSave(draft as Entity);
          }}
        >
          {({ values, setValue }) => (
            <>
              {this.props.name || <h2>{values.name}</h2>}

              <EntityFormRow<ClientProjectDraft>
                name={'name'}
                label={'Name'}
                edit={() => <FormField<ClientProjectDraft> type={'text'} name={'name'} labelPlacement={'none'} autoFocus noRow />}
                view={values.name ? () => values.name : undefined}
              />

              <EnumAsDropdown<ClientPricingEntity, PricingVisibility>
                enumValues={DataDefinitionsEnumTranslator.translateEnum('PricingVisibility')}
                currentValue={values.visibility}
                name={'visibility'}
                label={'Visibility'}
                setValue={setValue as any}
                renderValue={(value) => <PricingVisibilityBadge visibility={value} />}
              />

              <EnumAsDropdown<ClientPricingEntity, PricingStatus>
                enumValues={DataDefinitionsEnumTranslator.translateEnum('PricingStatus')}
                currentValue={values.status}
                name={'status'}
                label={'Status'}
                setValue={setValue as any}
                renderValue={(value) => <PricingStatusBadge status={value} />}
              />

              {createNew && (
                <EntityFormRow<ClientPricingDraft>
                  name={'priceMode' as 'pricePerSite'}
                  label={'Price mode'}
                  edit={() => (
                    <div>
                      <Form.Check
                        type={'radio'}
                        value={'price-per-site'}
                        id={'price-mode'}
                        label={`Per site`}
                        onChange={(e) => this.setState({ priceMode: e.target.value as any })}
                      />
                      <Form.Check
                        type={'radio'}
                        value={'fixed-price-per-month'}
                        id={'price-mode'}
                        label={`Fixed price per month`}
                        onChange={(e) => this.setState({ priceMode: e.target.value as any })}
                      />
                    </div>
                  )}
                  view={() => (priceMode === 'fixed-price-per-month' ? 'Fixed per month' : 'Per site')}
                />
              )}

              {priceMode === 'price-per-site' && (
                <EntityFormRow<ClientPricingDraft>
                  name={'pricePerSite'}
                  label={'Price per site'}
                  edit={!createNew ? undefined : () => <FormField name={'pricePerSite'} label={'Price per site'} type={'number'} noRow />}
                  view={() => <MoneyAmount amount={values.pricePerSite || 0} currency={values.currency} />}
                />
              )}

              {priceMode === 'price-per-site' && (
                <EntityFormRow<ClientPricingDraft>
                  name={'minMonthlyPrice'}
                  label={'Min price per month'}
                  edit={
                    !createNew ? undefined : () => <FormField name={'minMonthlyPrice'} label={'Min monthly price'} type={'number'} noRow />
                  }
                  view={() => <MoneyAmount amount={values.minMonthlyPrice || 0} currency={values.currency} />}
                />
              )}

              {priceMode === 'fixed-price-per-month' && (
                <EntityFormRow<ClientPricingDraft>
                  name={'fixedMonthlyPrice'}
                  label={'Fixed price per month'}
                  edit={
                    !createNew
                      ? undefined
                      : () => <FormField name={'fixedMonthlyPrice'} label={'Fixed price per month'} type={'number'} noRow />
                  }
                  view={() => <MoneyAmount amount={values.fixedMonthlyPrice || 0} currency={values.currency} />}
                />
              )}

              <EntityFormRow<ClientPricingDraft>
                name={'maxUpdatesPerSitePerMonth'}
                label={'Max pushes per month'}
                edit={() => <FormField name={'maxRootPushOperationsPerMonth'} label={'Max pushes per month'} type={'number'} noRow />}
                view={() => (values.maxUpdatesPerSitePerMonth ? values.maxUpdatesPerSitePerMonth : <em>unlimited</em>)}
              />

              {values.visibility === PricingVisibility.Custom && (
                <EntityFormRow<ClientPricingDraft>
                  name={'customer'}
                  label={'Customer'}
                  edit={() => (
                    <CustomerSelector
                      value={customer}
                      onSelected={(customer) => {
                        if (createNew) {
                          setValue('customer.id' as 'customer', customer);
                          this.setState({ customer });
                        }
                      }}
                      onRemove={() => {
                        if (createNew) {
                          setValue('customer.id' as 'customer', undefined);
                          this.setState({ customer: undefined });
                        }
                      }}
                    />
                  )}
                  view={
                    values.customer && (values.customer as any).id
                      ? () => <CustomerLink entityId={(values.customer as any).id} />
                      : undefined
                  }
                />
              )}

              {values.visibility !== PricingVisibility.Custom && (
                <EnumAsCheckboxes<ClientPricingEntity, SalesRegion>
                  enumValues={SyncCoreDataDefinitionsEnumTranslator.translateEnum('SalesRegion')}
                  currentValue={values.regions || []}
                  name={'regions'}
                  label={'Regions'}
                  setValue={setValue as any}
                />
              )}

              {createNew ? (
                <EnumAsDropdown<ClientPricingEntity, Product>
                  enumValues={SyncCoreDataDefinitionsEnumTranslator.translateEnum('Product')}
                  currentValue={values.product}
                  name={'product'}
                  label={'Product'}
                  setValue={setValue as any}
                />
              ) : (
                <EntityFormRow<ClientPricingDraft>
                  name={'product'}
                  label={'Product'}
                  view={() => <SyncCoreDataDefinitionsEnumValue enumName={'Product'} keyName={values.product} />}
                />
              )}

              {createNew ? (
                <EnumAsDropdown<ClientPricingEntity, Package>
                  enumValues={SyncCoreDataDefinitionsEnumTranslator.translateEnum('Package')}
                  currentValue={values.packageType}
                  name={'packageType'}
                  label={'Package'}
                  setValue={setValue as any}
                />
              ) : (
                <EntityFormRow<ClientPricingDraft>
                  name={'packageType'}
                  label={'Package'}
                  view={() => <SyncCoreDataDefinitionsEnumValue enumName={'Package'} keyName={values.packageType} />}
                />
              )}

              {createNew && (
                <EnumAsDropdown<ClientPricingEntity, Currency>
                  enumValues={DataDefinitionsEnumTranslator.translateEnum('Currency')}
                  currentValue={values.currency}
                  name={'currency'}
                  label={'Currency'}
                  setValue={setValue as any}
                />
              )}

              <EntityFormRow<ClientPricingDraft>
                name={'discounts'}
                label={'Discounts'}
                view={
                  this.props.entity.discounts && renderBackendProperties
                    ? () => (
                        <ViewDiscounts
                          discounts={this.props.entity.discounts as IRuntimeReference<ClientDiscountEntity>[]}
                          currency={values.currency}
                        />
                      )
                    : undefined
                }
                edit={
                  !createNew
                    ? undefined
                    : () => (
                        <MultiValueField<ISerializedPricingProperties, ISerializedReference>
                          name={'discounts'}
                          header={
                            <Row>
                              <HeaderCol xs={10}>Discount</HeaderCol>
                              <HeaderCol xs={2} />
                            </Row>
                          }
                          addButtons={{
                            Discount: {
                              id: '',
                            } as ISerializedReference,
                          }}
                        >
                          {(index, { values, setValue, removeItem }) => (
                            <Row key={index}>
                              <ContentCol xs={10}>
                                <DiscountSelector
                                  value={selectedDiscounts[index]}
                                  name={`discounts.${index}.id`}
                                  onSelected={(selected) => {
                                    while (selectedDiscounts.length <= index) {
                                      selectedDiscounts.push(undefined as any);
                                    }
                                    selectedDiscounts[index] = selected;
                                    setValue(`discounts.${index}.id` as 'discounts', selected.id);
                                  }}
                                />
                              </ContentCol>
                              <ContentCol xs={2}>
                                <RemoveButton
                                  onClick={() => {
                                    selectedDiscounts.splice(index, 1);
                                    removeItem('discounts', index);
                                  }}
                                />
                              </ContentCol>
                            </Row>
                          )}
                        </MultiValueField>
                      )
                }
              />

              {children}
            </>
          )}
        </FormWithValidation>
      </EntityContext.Provider>
    );
  }
}
