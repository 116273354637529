import { ContentSyncApi, IContentSyncApi, IInternalAuthentication } from '@edgebox/api-rest-client';
import { IAppConfigurationProp } from '@edgebox/react-components';
import React, { PropsWithChildren } from 'react';
import { IDataComponentConfiguration } from '../services/ApiComponent';
import { AuthenticationContext } from './AuthenticationContext';

export const ApiContext: React.Context<IContentSyncApi | undefined> = React.createContext<IContentSyncApi | undefined>(undefined);

interface IProps extends IAppConfigurationProp<IDataComponentConfiguration>, PropsWithChildren {}
interface IState {}

interface IInnerProps extends IProps {
  authentication: IInternalAuthentication;
}
interface IInnerState {
  jwt?: string;
  loading?: boolean;
}
class ApiProviderInner extends React.Component<IInnerProps, IInnerState> {
  protected api: ContentSyncApi;

  constructor(props: IInnerProps) {
    super(props);

    this.api = ContentSyncApi.getInstance({
      services: {
        api: {
          baseDomain: this.props.appConfiguration.apiDomain,
        },
      },
    });

    const { authentication } = this.props;
    this.state = {
      jwt: authentication.jwt,
      loading: !!authentication.jwt,
    };

    if (authentication) {
      this.updateAuth(authentication);
    }
  }

  updateAuth(authentication: IInternalAuthentication) {
    this.api
      .setAuth(authentication)
      .then(() => {
        if (!this.__isMounted) {
          return;
        }

        // After .setAuth() is finished, the .currentUser property will be set and everything's ready to be used by
        // the subscribing components. So only then do we set the JWT to re-render the component tree.
        this.setState({
          loading: false,
        });
      })
      .catch((error) => console.error('failed to load auth', error));
  }

  __isMounted = true;
  componentWillUnmount() {
    this.__isMounted = false;
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    const { authentication } = this.props;

    if (authentication.jwt != this.state.jwt) {
      this.setState({
        jwt: authentication.jwt,
        loading: true,
      });

      this.updateAuth(authentication);
    }
  }

  public render(): React.ReactElement {
    const { children } = this.props;

    // We're using the loaded state as the key here so that the whole component tree is re-rendered if the JWT changes
    // and the user is loaded (asynchronously, so delayed), updating access.
    return (
      <ApiContext.Provider key={this.state.loading ? 'loading' : 'loaded'} value={this.api}>
        {children}
      </ApiContext.Provider>
    );
  }
}

export class ApiProvider extends React.Component<IProps, IState> {
  static contextType = AuthenticationContext;
  context!: IInternalAuthentication;

  public render(): React.ReactElement {
    return <ApiProviderInner {...this.props} authentication={this.context} />;
  }
}
