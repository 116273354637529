import {
  ClientBasketDraft,
  ClientBasketEntity,
  ClientContractEntity,
  ClientContractRevisionEntity,
  ClientPricingEntity,
} from '@edgebox/api-rest-client';
import { InternalId, StaticReference } from '@edgebox/data-definition-kit';
import { BasketStatus, BasketType, ISerializedBasketProperties, PaymentCycleType } from '@edgebox/data-definitions';
import { FormField, FormRow, IconButton, InfoIcon, LeftRightH3, Right } from '@edgebox/react-components';
import { HostingType, Package, Product } from '@edgebox/sync-core-data-definitions';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import React, { ChangeEvent } from 'react';
import { Alert, Button, Col, Container, Form, Image, Modal, Row } from 'react-bootstrap';
import {
  ApiComponent,
  DataDefinitionsEnumValue,
  entityFormWithValidation,
  IApiComponentState,
  IItemProps,
  SyncCoreDataDefinitionsEnumValue,
  ViewContractName,
} from '../../../common/index';
import BeforePurchaseImage from '../../../images/undraw_To_the_stars_qhyy.svg';
import { IIdParamProp, ILocationProp, INavigateProp, withIdParamAndLocationAndNavigate } from '../../RouterHelper';
import { ContentBox } from '../../Shared/ContentBox';
import { PricingComparison } from '../../Shared/PricingComparison';
import { SwitchButton } from '../../Shared/SwitchButton';
import { ViewDiscountedPrice } from './ViewDiscountedPrice';
import { HeadlineWithBreadcrumbNavigation } from '../../../common/components/BreadcrumbNavigation';

interface IExternalProps extends IItemProps {}
interface IProps extends IExternalProps, ILocationProp, INavigateProp, IIdParamProp {}

interface IState extends IApiComponentState {
  contract?: ClientContractEntity;
  contractRevision?: ClientContractRevisionEntity;
  basket?: ClientBasketDraft;
  packages?: ClientPricingEntity[];
  showPricingSelector?: boolean;
  contractIsTrial?: boolean;
  product?: Product;
  // The pricing ID as given by the URL. If set, the user is not asked to select
  // a pricing with the popup but can continue immediately. This is the case
  // when they want to change the number of sites after they have already
  // started the checkout.
  selectPricingId?: InternalId;

  selectedEnterprise?: boolean;
  submitting?: boolean;
  selectedPricing?: ClientPricingEntity;
  requestProposal?: ClientBasketDraft;
}

const FormWithValidation = entityFormWithValidation<ISerializedBasketProperties, ClientBasketDraft>(
  ClientBasketDraft,
  ClientBasketEntity as any
);

class ContractUpgradeComponent extends ApiComponent<IProps, IState> {
  async load() {
    const paramsRaw = this.props.location.search.length > 1 ? new URLSearchParams(this.props.location.search) : undefined;
    const selectPricingId = (paramsRaw?.get('pricing') as InternalId) || undefined;
    const selectYearlyCycle = paramsRaw?.get('cycle') === PaymentCycleType.Yearly;

    const contractRevision = await this.api.billing.contractRevisions.item(this.props.id);
    const contract = await contractRevision.contract.get();

    const contractPricing = await contractRevision.pricing.get();
    const contractIsTrial = contractRevision.packageType === Package.Trial;

    const packageOrder = [Package.Trial, Package.Starter, Package.Business, Package.Enterprise];

    const customer = await this.getCurrentCustomer(true);

    const pricings = await this.api.billing.pricing.list(undefined, contractPricing.product, undefined, customer?.id);
    const nonTrialPricings = pricings.filter((c) => c.packageType !== Package.Trial);
    // Customers can only purchase Enterprise packages if they're set to pay
    // manually. So in this case we can not offer them the starter package but
    // only the Enterprise package.
    // So starterPricing may be undefined.
    let starterPricing = nonTrialPricings.find((c) => (selectPricingId ? c.id === selectPricingId : c.packageType === Package.Starter));
    // Maybe this is an Enterprise pricing we have to load first.
    if (!starterPricing && selectPricingId) {
      starterPricing = await this.api.billing.pricing.item(selectPricingId);
    }

    nonTrialPricings.sort((a, b) => {
      const indexA = packageOrder.indexOf(a.packageType);
      const indexB = packageOrder.indexOf(b.packageType);
      return indexA - indexB;
    });

    const mustRequestEnterprise = contractIsTrial && !starterPricing;
    const basket = new ClientBasketDraft({
      type: BasketType.RenewContractManually,
      status: BasketStatus.Created,
      cycle: selectYearlyCycle || contractRevision.advancePaymentsInMonths === 12 ? PaymentCycleType.Yearly : PaymentCycleType.Monthly,
      product: contractRevision.product,
      packageType: contractRevision.packageType,
      currency: customer.billingCurrency,
      pricing: mustRequestEnterprise ? undefined : new StaticReference(contractIsTrial ? starterPricing! : contractPricing),
      numberOfSites: Math.max(contract.currentProductionSites, 1),
      customer: new StaticReference(customer),
      createdBy: new StaticReference(this.api.currentUser!),
      contract: new StaticReference(contract),
      renewAutomatically: contractIsTrial ? true : contract.renewAutomatically,
    });

    let goEnterprise = mustRequestEnterprise;
    if (this.props.location.search) {
      const params = new URLSearchParams(this.props.location.search);
      if (params.get('type') === HostingType.OnPremise) {
        goEnterprise = true;
      }
    }
    if (goEnterprise) basket.status = BasketStatus.Requested;

    return {
      basket,
      packages: nonTrialPricings,
      selectedPricing: mustRequestEnterprise || contractIsTrial ? (selectPricingId ? starterPricing : undefined) : contractPricing,
      contract,
      contractRevision,
      contractIsTrial,
      selectPricingId,
      selectedEnterprise: goEnterprise,
      showPricingSelector: contractIsTrial && !goEnterprise && !selectPricingId,
      product: contractPricing.product,
    };
  }

  render() {
    const {
      contract,
      contractRevision,
      basket,
      showPricingSelector,
      contractIsTrial,
      selectedPricing,
      selectedEnterprise,
      requestProposal,
      submitting,
    } = this.state;

    if (!contractRevision || !contract || !basket) {
      return this.renderRequest();
    }

    const isRenewal = contractRevision.packageType !== Package.Trial;
    const onCancel = () => {
      this.props.navigate(-1);
    };

    const saveBasket = async (draft: ClientBasketDraft) => {
      this.setState({ submitting: true });
      try {
        const entity = await this.api.billing.baskets.create(draft);
        this.props.navigate(`/checkout/${entity.id}`);
      } finally {
        this.setState({ submitting: false });
      }
    };

    return (
      <Container>
        <HeadlineWithBreadcrumbNavigation className={'mb-4'}>
          {isRenewal ? 'Renew' : 'Upgrade'}{' '}
          {contractIsTrial ? (
            'Trial'
          ) : (
            <em>
              <ViewContractName entity={contract} />
            </em>
          )}
        </HeadlineWithBreadcrumbNavigation>

        <ContentBox>
          <Row>
            <Col xs={6} className={'p-5'}>
              <Image src={BeforePurchaseImage} width={'100%'} />
            </Col>
            <Col className={'pt-5 ps-5'}>
              <FormWithValidation
                initialValues={basket}
                onSubmit={async (draft) => {
                  // Let users apply for additional discounts and on-premise hosting.
                  if (selectedEnterprise) {
                    this.setState({ requestProposal: draft });
                    return;
                  }

                  await saveBasket(draft);
                }}
                apiComponent={this}
                labels={'above'}
              >
                {({ values, setValue }) => (
                  <>
                    <FormRow name={'pricing'} label={'Package'} required={true}>
                      <div>
                        {selectedPricing ? (
                          <SyncCoreDataDefinitionsEnumValue enumName={'Package'} keyName={selectedPricing.packageType} />
                        ) : selectedEnterprise ? (
                          <SyncCoreDataDefinitionsEnumValue enumName={'Package'} keyName={Package.Enterprise} />
                        ) : (
                          <em>None</em>
                        )}
                        <Button variant={'light'} onClick={() => this.setState({ showPricingSelector: true })}>
                          Change
                        </Button>
                      </div>
                      {selectedPricing && selectedPricing.pricePerSite && (
                        <div>
                          <ViewDiscountedPrice
                            price={selectedPricing.pricePerSite}
                            currency={selectedPricing.currency}
                            paymentCycle={values.cycle}
                            pricingDiscounts={selectedPricing.discounts}
                          />{' '}
                          per site per month
                        </div>
                      )}

                      {selectedPricing && selectedPricing.fixedMonthlyPrice && (
                        <div>
                          <ViewDiscountedPrice
                            price={selectedPricing.fixedMonthlyPrice}
                            currency={selectedPricing.currency}
                            paymentCycle={values.cycle}
                            pricingDiscounts={selectedPricing.discounts}
                          />{' '}
                          per month
                        </div>
                      )}
                    </FormRow>

                    <div className={'mt-3'} />

                    <FormRow name={'cycle'} label={'Payment cycle'} required={true}>
                      <SwitchButton<PaymentCycleType>
                        selected={values.cycle}
                        onChange={(cycle) => setValue('cycle', cycle)}
                        options={[PaymentCycleType.Monthly, PaymentCycleType.Yearly].map((value) => ({
                          value,
                          label: <DataDefinitionsEnumValue enumName={'PaymentCycleType'} keyName={value} />,
                        }))}
                      />{' '}
                      {selectedEnterprise && <InfoIcon>Pay one year in advance for a 15% discount.</InfoIcon>}
                    </FormRow>

                    <div className={'mt-3'} />

                    <FormRow name={'numberOfSites'} label={'Number of sites'} required={true}>
                      <FormField
                        name={'numberOfSites'}
                        type={'number'}
                        label={'Number of sites'}
                        labelPlacement={'none'}
                        upDown
                        noRow
                        min={Math.max(contract.currentProductionSites, 1)}
                      />
                    </FormRow>

                    <div className={'mt-3'} />

                    <Modal show={showPricingSelector} size={'lg'} onHide={() => this.setState({ showPricingSelector: false })}>
                      <Modal.Header>
                        <LeftRightH3
                          className={'flex-grow-1'}
                          left={'Select your package'}
                          right={
                            <SwitchButton<PaymentCycleType>
                              selected={values.cycle}
                              onChange={(cycle) => setValue('cycle', cycle)}
                              options={[PaymentCycleType.Monthly, PaymentCycleType.Yearly].map((value) => ({
                                value,
                                label: <DataDefinitionsEnumValue enumName={'PaymentCycleType'} keyName={value} />,
                              }))}
                            />
                          }
                        />
                      </Modal.Header>
                      <Modal.Body>
                        <PricingComparison
                          paymentInterval={values.cycle}
                          contract={contract}
                          product={contractRevision.product}
                          selectedPricing={selectedPricing}
                          onSelectEnterprise={(cycle) => {
                            setValue('pricing.id' as 'pricing', undefined);
                            setValue('cycle', cycle);
                            setValue('status', BasketStatus.Requested);
                            this.setState({
                              showPricingSelector: false,
                              selectedEnterprise: true,
                              selectedPricing: undefined,
                            });
                          }}
                          onSelectPricing={(selectedPricing, cycle) => {
                            setValue('pricing.id' as 'pricing', selectedPricing.id);
                            setValue('cycle', cycle);
                            setValue('status', BasketStatus.Created);
                            this.setState({
                              showPricingSelector: false,
                              selectedEnterprise: false,
                              selectedPricing,
                            });
                          }}
                        />
                      </Modal.Body>
                    </Modal>

                    <Right>
                      <Button type={'button'} variant={'light'} onClick={onCancel}>
                        Cancel
                      </Button>
                      <IconButton
                        icon={faArrowRight}
                        iconPosition={'right'}
                        variant={'primary'}
                        type={'submit'}
                        className={'fw-bold'}
                        disabled={submitting}
                      >
                        {selectedEnterprise ? 'Request proposal' : 'Continue to Checkout'}
                      </IconButton>
                    </Right>
                  </>
                )}
              </FormWithValidation>
            </Col>
          </Row>
        </ContentBox>

        {requestProposal && (
          <Modal show={true} size={'lg'} onHide={() => this.setState({ requestProposal: undefined })}>
            <Modal.Header>
              <Modal.Title>Request Enterprise license</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Alert variant="primary">Thanks for your interest! Please let us know any specialties and we'll get right back to you.</Alert>
              <div>
                <label className="fw-bold mt-3">On-premise hosting</label>
              </div>
              <Form.Check
                type={'checkbox'}
                id={'use-on-premise'}
                label={'I want to host the Sync Core myself'}
                defaultChecked={requestProposal.useOnPremise}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  requestProposal.useOnPremise = e.target.checked;
                  this.setState({
                    requestProposal,
                  });
                }}
              />
              <br />
              <div>
                <label className="fw-bold mt-3">Discount</label>
              </div>
              <SwitchButton
                selected={requestProposal.applyAsNonprofit ? 'non-profit' : requestProposal.applyAsReseller ? 'reseller' : 'none'}
                onChange={(value) => {
                  requestProposal.applyAsNonprofit = value === 'non-profit';
                  requestProposal.applyAsReseller = value === 'reseller';
                  this.setState({
                    requestProposal,
                  });
                }}
                options={[
                  { value: 'none', label: 'None' },
                  { value: 'non-profit', label: 'Non-profit' },
                  { value: 'reseller', label: 'Reseller *' },
                ]}
              />
              <br />* Resellers are required to take over the first level and second level support.
            </Modal.Body>
            <Modal.Footer>
              <Right>
                <Button disabled={submitting} variant="primary" onClick={() => saveBasket(requestProposal)}>
                  Send
                </Button>
              </Right>
            </Modal.Footer>
          </Modal>
        )}
      </Container>
    );
  }
}

export const ContractUpgrade = withIdParamAndLocationAndNavigate<IExternalProps>(ContractUpgradeComponent);
