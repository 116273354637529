import { ClientUserDraft, ClientUserEntity } from '@edgebox/api-rest-client';
import { CustomerRole, DataDefinitionsEnumTranslator, InternalRole, ISerializedUserProperties, UserType } from '@edgebox/data-definitions';
import { EmailLink, FormField } from '@edgebox/react-components';
import React, { PropsWithChildren } from 'react';
import { EditMode, EntityContext } from '../../../contexts/EntityContext';
import { EntityForm, entityFormWithValidation, IEntityFormProps, IEntityFormState } from '../../Form/EntityForm';
import { EntityFormRow } from '../../Form/EntityFormRow';
import { EnumAsCheckboxes } from '../../Form/EnumAsCheckboxes';
import { DataDefinitionsEnumValue } from '../../Localization/DataDefinitionsEnumValue';
import { UserStatusBadge } from '../Badges/UserStatusBadge';

interface IProps<Entity extends ClientUserDraft> extends IEntityFormProps<Entity, ISerializedUserProperties>, PropsWithChildren {
  onSave: (user: Entity) => Promise<void>;
  className?: string;
  mode: 'view-full' | 'edit-full' | 'registration';
}

const EDIT_MODES = {
  'view-full': EditMode.None,
  'edit-full': EditMode.Full,
  registration: EditMode.Full,
};

interface IState<Entity extends ClientUserDraft> extends IEntityFormState<Entity> {}

const FormWithValidation = entityFormWithValidation<ISerializedUserProperties, ClientUserDraft>(ClientUserDraft, ClientUserEntity);

export class UserForm<Entity extends ClientUserDraft = ClientUserDraft> extends EntityForm<
  Entity,
  ISerializedUserProperties,
  IProps<Entity>,
  IState<Entity>
> {
  constructor(props: IProps<Entity>) {
    super(props, {
      edit: EDIT_MODES[props.mode],
    });
  }

  async load(): Promise<Partial<IState<Entity>>> {
    return {};
  }

  componentDidUpdate(prevProps: Readonly<IProps<Entity>>, prevState: Readonly<IState<Entity>>, snapshot?: any): void {
    if (this.props.mode !== prevProps.mode) {
      this.setState({
        edit: EDIT_MODES[this.props.mode],
      });
    }
  }

  render(): React.ReactElement {
    const { className, onSave, children, mode } = this.props;

    const renderBackendProperties = this.api.currentUser?.type === UserType.Internal;
    const isOwner = this.api.currentUser?.customerRoles?.includes(CustomerRole.Owner);

    const basicOnly = mode === 'registration';

    const viewOnly = mode === 'view-full';

    return (
      <EntityContext.Provider value={this.entityContext}>
        <FormWithValidation
          apiComponent={this}
          className={className}
          initialValues={this.props.entity}
          labels={'above'}
          onSubmit={async (draft) => {
            this.setState({
              extendProperties: [],
            });

            await onSave(draft as Entity);
          }}
        >
          {({ values, setValue }) => (
            <>
              {this.props.name || `${values.firstName} ${values.lastName}`}

              {!viewOnly && (
                <EntityFormRow<ClientUserDraft>
                  name={'firstName'}
                  label={'First name'}
                  view={() => values.firstName}
                  edit={() => <FormField name={'firstName'} type={'text'} noRow={true} placeholder={'First name'} />}
                />
              )}

              {!viewOnly && (
                <EntityFormRow<ClientUserDraft>
                  name={'lastName'}
                  label={'Last name'}
                  view={() => values.lastName}
                  edit={() => <FormField name={'lastName'} type={'text'} noRow={true} placeholder={'Last name'} />}
                />
              )}

              {renderBackendProperties && (
                <EntityFormRow<ClientUserDraft>
                  name={'type'}
                  label={'Type'}
                  view={() => <DataDefinitionsEnumValue enumName={'UserType'} keyName={values.type} />}
                />
              )}

              <EntityFormRow<ClientUserDraft>
                name={'primaryEmail'}
                label={'Email'}
                edit={() => (
                  <FormField
                    name={'primaryEmail'}
                    type={'email'}
                    noRow={true}
                    placeholder={'EMail'}
                    disabled={!!this.props.entity.primaryEmail}
                  />
                )}
                view={() => <EmailLink email={values.primaryEmail} />}
              />

              {!basicOnly && [UserType.Customer, UserType.Internal].includes(values.type) && (
                <EnumAsCheckboxes<ClientUserDraft, CustomerRole>
                  name={'customerRoles'}
                  label={'Roles'}
                  currentValue={values.customerRoles || []}
                  disabled={!isOwner}
                  enumValues={DataDefinitionsEnumTranslator.translateEnum('CustomerRole')}
                  setValue={setValue}
                />
              )}

              {!basicOnly && values.type === UserType.Internal && (
                <EnumAsCheckboxes<ClientUserDraft, InternalRole>
                  name={'internalRoles'}
                  label={'Internal Roles'}
                  currentValue={values.internalRoles || []}
                  disabled={!isOwner}
                  enumValues={DataDefinitionsEnumTranslator.translateEnum('InternalRole')}
                  setValue={setValue}
                />
              )}

              {children}
            </>
          )}
        </FormWithValidation>
      </EntityContext.Provider>
    );
  }
}
