import { ClientContractEntity, ClientCustomerEntity, ClientProjectEntity, ClientSiteEntity } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import { UserType } from '@edgebox/data-definitions';
import { ButtonCheckbox, CustomCardColumns, ExternalLink } from '@edgebox/react-components';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faDollarSign } from '@fortawesome/pro-light-svg-icons/faDollarSign';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import React from 'react';
import { Alert, Badge, Col, Form, Row } from 'react-bootstrap';
import {
  ApiComponent,
  ContractLink,
  ContractSelector,
  CustomerLink,
  CustomerSelector,
  IApiComponentState,
  PagedList,
  ProjectSelector,
  UserLink,
} from '../../common';
import { IconSummaryItem } from './IconSummaryItem';
import { SummaryCard } from './SummaryCard';
import { ViewDomain } from './ViewDomain';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { SiteIcons } from '../Icons';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
  backend?: boolean;
  customerId?: InternalId;
  emptyMessage?: React.ReactNode;
  emptyMessageWithNoFilters?: React.ReactNode;
  forProject?: ClientProjectEntity;
  forContract?: ClientContractEntity;
  message?: React.ReactNode;
  itemDisplay?: 'list' | 'cards';
}

interface IFilters {
  search?: string;
  customerId?: InternalId;
  contractId?: InternalId;
  projectId?: InternalId;
  includeInactive?: boolean;
  mySites?: boolean;
}

interface IState extends IApiComponentState {
  search?: string;
  filterCustomer?: ClientCustomerEntity;
  filterContract?: ClientContractEntity;
  filterProject?: ClientProjectEntity;
  includeInactive?: boolean;
  mySites?: boolean;
}

export class PagedSiteList extends ApiComponent<IProps, IState> {
  async load() {
    return {
      filterProject: this.props.forProject,
      filterContract: this.props.forContract,
    };
  }

  render() {
    const { backend, customerId, emptyMessage, emptyMessageWithNoFilters, forProject, forContract, message, itemDisplay } = this.props;
    const { filterCustomer, filterContract, filterProject, includeInactive, mySites } = this.state;

    const renderBackendProperties = this.api.currentUser?.type === UserType.Internal && backend;

    return (
      <PagedList<ClientSiteEntity, IFilters>
        searchable
        hidePagerIfNotNeeded
        renderList={(items) => (itemDisplay === 'cards' ? <CustomCardColumns columnCount={3}>{items}</CustomCardColumns> : items)}
        emptyMessageWithNoFilters={emptyMessageWithNoFilters}
        request={(page, filter) =>
          this.api.billing.sites.search(
            { page, itemsPerPage: 9 },
            {
              customerId: filter?.customerId || customerId || this.api.currentUser?.customer?.getId(),
              contractId: filter?.contractId || forContract?.id,
              projectId: filter?.projectId || forProject?.id,
              userOwnerId: filter?.mySites ? this.api.currentUser?.id : undefined,
            },
            {
              search: filter?.search,
              includeInactive: filter?.includeInactive,
            }
          )
        }
        renderListHeader={message ? () => message : undefined}
        renderFilters={(onChange) => {
          const onType = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | React.KeyboardEvent<any>) => {
            const value = (e.target as HTMLInputElement).value;
            onChange('search', value);
            this.setState({ search: value });
          };
          const backendFilters =
            renderBackendProperties && !customerId ? (
              <Col xs={3}>
                <CustomerSelector
                  value={filterCustomer}
                  onRemove={() => {
                    this.setState({ filterCustomer: undefined });
                    onChange('customerId', undefined, true);
                  }}
                  onSelected={(filterCustomer) => {
                    this.setState({ filterCustomer });
                    onChange('customerId', filterCustomer.id, true);
                  }}
                  emptyLabel={'Filter by customer'}
                />
              </Col>
            ) : undefined;
          return (
            <Row>
              <Col className={'p-0'}>
                <Form.Control placeholder={'Enter search term...'} onKeyPress={onType} onChange={onType} />
              </Col>
              {backendFilters}
              {forProject ? undefined : (
                <>
                  {forContract ? undefined : (
                    <Col xs={3}>
                      <ContractSelector
                        value={filterContract}
                        onRemove={() => {
                          this.setState({ filterContract: undefined });
                          onChange('contractId', undefined, true);
                        }}
                        onSelected={(filterContract) => {
                          this.setState({ filterContract });
                          onChange('contractId', filterContract.id, true);
                        }}
                        emptyLabel={'Filter by subscription'}
                      />
                    </Col>
                  )}
                  <Col xs={2}>
                    <ProjectSelector
                      value={filterProject}
                      onRemove={() => {
                        this.setState({ filterProject: undefined });
                        onChange('projectId', undefined, true);
                      }}
                      onSelected={(filterProject) => {
                        this.setState({ filterProject });
                        onChange('projectId', filterProject.id, true);
                      }}
                      emptyLabel={'Filter by project'}
                    />
                  </Col>
                </>
              )}
              <Col xs={3}>
                <ButtonCheckbox
                  checked={!!includeInactive}
                  onToggle={() => {
                    this.setState({ includeInactive: !includeInactive });
                    onChange('includeInactive', !includeInactive, true);
                  }}
                >
                  Show inactive
                </ButtonCheckbox>
                <ButtonCheckbox
                  checked={!!mySites}
                  onToggle={() => {
                    this.setState({ mySites: !mySites });
                    onChange('mySites', !mySites, true);
                  }}
                >
                  My sites
                </ButtonCheckbox>
              </Col>
            </Row>
          );
        }}
        emptyMessage={emptyMessage || <Alert variant={'light'}>No sites match your filter.</Alert>}
        renderItem={(site) =>
          itemDisplay === 'cards' ? (
            <SummaryCard
              key={site.id}
              target={`/sites/${site.id}`}
              title={
                <>
                  <SiteIcons site={site} />
                  <span className="text-truncate mw-100 d-inline-block" title={site.name}>
                    {site.name}
                  </span>{' '}
                  {site.domains && site.domains.length > 1 ? <Badge bg="light">{site.domains.length} domains</Badge> : undefined}
                </>
              }
            >
              <IconSummaryItem icon={faLink}>
                <ExternalLink to={site.baseUrl}>
                  <ViewDomain>{site.baseUrl}</ViewDomain>
                </ExternalLink>
              </IconSummaryItem>
              {renderBackendProperties && !customerId && (
                <IconSummaryItem icon={faBuilding}>
                  <CustomerLink entityId={site.customer.getId()} />
                </IconSummaryItem>
              )}
              {!forContract && (
                <IconSummaryItem icon={faDollarSign}>
                  <ContractLink entityId={site.contract.getId()} />
                </IconSummaryItem>
              )}
              {site.userOwner?.getId() && (
                <IconSummaryItem icon={faUser}>
                  <UserLink entityId={site.userOwner.getId()!} />
                </IconSummaryItem>
              )}
            </SummaryCard>
          ) : (
            <Link
              to={`/sites/${site.id}`}
              className={'text-black ps-3 pe-1 pt-1 pb-1 d-block rounded bg-white mb-2 hover-shadow text-decoration-none'}
              key={site.id}
            >
              <Row className="m-0 p-0">
                <Col className="ms-0 me-0 mt-1 mb-1 p-0 fs-4 text-truncate mw-100" sm={5}>
                  <SiteIcons site={site} />
                  <span className="ms-3">{site.name}</span>{' '}
                  {site.domains && site.domains.length > 1 ? <Badge bg="light">{site.domains.length} domains</Badge> : undefined}
                </Col>
                <Col sm={6} className="fs-6 pt-3">
                  <ExternalLink to={site.baseUrl}>
                    <ViewDomain>{site.baseUrl}</ViewDomain>
                  </ExternalLink>
                </Col>
                <Col sm={1} className="text-end fs-4 text-light pt-1">
                  <FontAwesomeIcon icon={faChevronRight} />
                </Col>
              </Row>
            </Link>
          )
        }
      />
    );
  }
}
