import {
  ClientContractEntity,
  ClientCustomerEntity,
  ClientProjectEntity,
  ClientSiteDraft,
  ClientSiteEntity,
  ClientSyncCoreEntity,
} from '@edgebox/api-rest-client';
import { EnumTranslation, IRuntimeSiteProperties, ISerializedSiteProperties, SyncCoreType, UserType } from '@edgebox/data-definitions';
import { EditButton, InfoIcon, ValidationError } from '@edgebox/react-components';
import { Product, SiteEnvironmentType, SiteStatus, SyncCoreDataDefinitionsEnumTranslator } from '@edgebox/sync-core-data-definitions';
import React, { PropsWithChildren } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { NULL_ID } from '../../../constants';
import { EditMode, EntityContext } from '../../../contexts/EntityContext';
import { EntityForm, entityFormWithValidation, IEntityFormProps, IEntityFormState } from '../../Form/EntityForm';
import { EntityFormRow } from '../../Form/EntityFormRow';
import { EnumAsDropdown } from '../../Form/EnumAsDropdown';
import { EnumAsRadios } from '../../Form/index';
import { EditName } from '../../Form/Properties/EditName';
import { FormatDateTime } from '../../Localization/FormatDateTime';
import { SyncCoreDataDefinitionsEnumValue } from '../../Localization/SyncCoreDataDefinitionsEnumValue';
import { ProjectLink } from '../../Syndication/Links/ProjectLink';
import { SyncCoreLink } from '../../Syndication/Links/SyncCoreLink';
import { ProjectSelector } from '../../Syndication/Selectors/ProjectSelector';
import { SyncCoreSelector } from '../../Syndication/Selectors/SyncCoreSelector';
import { WithMostRecentContractRevision } from '../Entities/WithMostRecentContractRevision';
import { ContractLink } from '../Links/ContractLink';
import { ContractQuickSelect } from '../Selectors/ContractQuickSelect';
import { ViewContractName } from '../Views/ViewContractName';
import { siteMustBeLocal } from '../../../helpers';
import { SiteEnvironmentTypeSelector } from '../Selectors/SiteEnvironmentTypeSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';

interface IProps<Entity extends ClientSiteDraft> extends IEntityFormProps<Entity, ISerializedSiteProperties>, PropsWithChildren {
  onSave: (entity: Entity) => Promise<void>;
  onContractSelected?: (contract?: ClientContractEntity) => void;
  className?: string;
  mode: 'view-full' | 'edit-full' | 'register' | 'registered' | 'view-full-backend';
}

const EDIT_MODES = {
  'view-full': EditMode.None,
  'view-full-backend': EditMode.None,
  'edit-full': EditMode.Full,
  register: EditMode.Full,
  registered: EditMode.None,
};

const STEP_CONTRACT = 0;
const STEP_ENVIRONMENT = 1;
const STEP_PROJECT = 2;
const STEP_SYNC_CORE = 3;

interface IState<Entity extends ClientSiteDraft> extends IEntityFormState<Entity> {
  selectedCustomer?: ClientCustomerEntity;
  selectedContract?: ClientContractEntity;
  selectedProject?: ClientProjectEntity;
  selectedSyncCore?: ClientSyncCoreEntity;
  hasMultipleContracts?: boolean;
  selectProjectManually?: boolean;
  selectSyncCoreManually?: boolean;
  selectPublicSyncCore?: boolean;
  step?: number;
}

const FormWithValidation = entityFormWithValidation<ISerializedSiteProperties, ClientSiteDraft & IRuntimeSiteProperties>(
  ClientSiteDraft as any,
  ClientSiteEntity
);

export class SiteForm<Entity extends ClientSiteDraft = ClientSiteDraft> extends EntityForm<
  Entity,
  ISerializedSiteProperties,
  IProps<Entity>,
  IState<Entity>
> {
  constructor(props: IProps<Entity>) {
    super(props, {
      edit: EDIT_MODES[props.mode],
    });
  }

  async load(): Promise<Partial<IState<Entity>>> {
    const { entity } = this.props;

    const [selectedCustomer, selectedContract, selectedProject, selectedSyncCore] = await Promise.all([
      entity.customer.getId() === NULL_ID ? undefined : await entity.customer?.get(),
      entity.contract.getId() === NULL_ID ? undefined : await entity.contract?.get(),
      entity.project.getId() === NULL_ID ? undefined : await entity.project?.get(),
      entity.syncCore.getId() === NULL_ID ? undefined : await entity.syncCore.get(),
    ]);

    return {
      selectedCustomer,
      selectedContract,
      selectedProject,
      selectedSyncCore,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps<Entity>>, prevState: Readonly<IState<Entity>>, snapshot?: any): void {
    if (this.props.mode !== prevProps.mode) {
      this.setState({
        edit: EDIT_MODES[this.props.mode],
      });
    }
  }

  render(): React.ReactElement {
    const { mode, children, onToggleEdit, onContractSelected } = this.props;
    const {
      selectedCustomer,
      selectedProject,
      selectedContract,
      selectedSyncCore,
      selectPublicSyncCore,
      selectSyncCoreManually,
      selectProjectManually,
      hasMultipleContracts,
    } = this.state;

    const renderBackendProperties = this.api.currentUser!.type === UserType.Internal;

    const createNew = mode === 'register';

    const small = mode === 'register' || mode === 'registered';

    const step = this.state.step || 0;

    if (![NULL_ID, undefined].includes(this.props.entity.customer.getId()) && !selectedCustomer) {
      return this.renderRequest();
    }

    const mustBeLocal = siteMustBeLocal(this.props.entity.baseUrl);

    return (
      <EntityContext.Provider value={this.entityContext}>
        <FormWithValidation
          {...(this.formProperties() as any)}
          apiComponent={this}
          initialValues={this.props.entity}
          labels={'above'}
          onSubmit={async (draft) => {
            this.setState({
              extendProperties: [],
            });

            await this.props.onSave(draft as any as Entity);
          }}
        >
          {({ values, setValue, errors }) => {
            const staticInfo = (
              <>
                <EntityFormRow<ClientSiteDraft>
                  name={'baseUrl'}
                  label={'Base URL'}
                  view={
                    values.baseUrl
                      ? () => (
                          <>
                            {errors?.baseUrl ? <div className={'text-danger mb-2'}>{values.baseUrl}</div> : values.baseUrl}
                            {errors?.baseUrl ? (
                              <Form.Control.Feedback type={'invalid'} className={'d-block'}>
                                <ValidationError name={'baseUrl'} />
                              </Form.Control.Feedback>
                            ) : undefined}
                          </>
                        )
                      : undefined
                  }
                />

                <EntityFormRow<ClientSiteDraft>
                  name={'domains'}
                  label={
                    <>
                      Licensed domains{' '}
                      <InfoIcon>
                        Each domain of your production site requires a license, e.g. if you register two production sites with 5 domains
                        each you will be invoiced for 10 sites.
                      </InfoIcon>
                    </>
                  }
                  view={
                    values.domains?.length
                      ? () => (
                          <ul>
                            {values.domains!.map((domain, index) => (
                              <li key={index}>{domain}</li>
                            ))}
                          </ul>
                        )
                      : undefined
                  }
                />
              </>
            );

            return (
              <>
                {this.props.name || <EditName onToggleEdit={onToggleEdit} value={values.name} label={'Name'} />}

                {createNew && staticInfo}

                <EntityFormRow<ClientSiteDraft>
                  name={'contract'}
                  label={'Subscription'}
                  view={
                    values.contract &&
                    (() =>
                      createNew ? (
                        <>
                          <ViewContractName entity={selectedContract!} />{' '}
                          {hasMultipleContracts && (
                            <EditButton
                              onClick={() => {
                                setValue('contract.id' as 'contract', undefined);
                                setValue('environmentType', '');
                                setValue('project.id' as 'project', undefined);
                                setValue('syncCore.id' as 'syncCore', undefined);
                                this.setState({
                                  selectedContract: undefined,
                                  selectedProject: undefined,
                                  selectedSyncCore: undefined,
                                  step: STEP_CONTRACT,
                                });
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <ContractLink backend={renderBackendProperties} entityId={values.contract!.id!} />
                      ))
                  }
                  edit={
                    createNew && step === STEP_CONTRACT
                      ? () => (
                          <>
                            <ContractQuickSelect
                              variant={createNew ? 'radios' : 'drop-down'}
                              name={'contract.id'}
                              error={errors?.contract?.id as any as string}
                              forCustomer={selectedCustomer?.id}
                              onChange={(selectedContract, hasMultipleContracts) => {
                                if (mustBeLocal) {
                                  setValue('environmentType', SiteEnvironmentType.Local);
                                  setValue('userOwner.id' as any, this.api.currentUser!.id);
                                } else {
                                  setValue('environmentType', '');
                                  setValue('userOwner.id' as any, undefined);
                                }

                                if (selectedProject) {
                                  setValue('project.id' as 'project', undefined);
                                }

                                if (selectedSyncCore) {
                                  setValue('syncCore.id' as 'syncCore', undefined);
                                }

                                setValue('contract.id' as 'contract', selectedContract?.id);

                                this.setState({
                                  selectedContract,
                                  selectedProject: undefined,
                                  selectedSyncCore: undefined,
                                  step: step === STEP_CONTRACT ? step + (mustBeLocal ? 2 : 1) : step,
                                  hasMultipleContracts,
                                });

                                if (onContractSelected) {
                                  onContractSelected(selectedContract);
                                }
                              }}
                            />
                            <div className={'text-warning'}>
                              The subscription can't be changed later.{' '}
                              <InfoIcon>Sites that are not in the same subscription can't exchange any content.</InfoIcon>
                            </div>
                          </>
                        )
                      : undefined
                  }
                />

                {/* TODO: Allow updating the environment type. May require a purchase. */}
                {/* TODO: When changing the environment type, we need to pass a different purchase property to ContractQuickSelect */}
                {createNew && step === STEP_ENVIRONMENT ? (
                  <WithMostRecentContractRevision contractId={selectedContract?.id}>
                    {(mostRecent) => {
                      let availableEnvironmentTypes: EnumTranslation<SiteEnvironmentType> = {
                        ...SyncCoreDataDefinitionsEnumTranslator.translateEnum('SiteEnvironmentType'),
                      };
                      if (mostRecent.current?.product !== Product.Staging) {
                        delete (availableEnvironmentTypes as any)[SiteEnvironmentType.Staging];
                      }
                      if (mustBeLocal) {
                        availableEnvironmentTypes = {
                          [SiteEnvironmentType.Local]: availableEnvironmentTypes[SiteEnvironmentType.Local],
                        } as any;
                      }

                      const enumProps = {
                        enumValues: availableEnvironmentTypes,
                        currentValue: values.environmentType,
                        name: 'environmentType' as 'environmentType',
                        label: 'Environment',
                        error: errors?.environmentType,
                        setValue: (name: string, environmentType?: SiteEnvironmentType) => {
                          setValue('environmentType', environmentType);
                          setValue(
                            'userOwner.id' as any,
                            environmentType === SiteEnvironmentType.Local ? this.api.currentUser!.id : undefined
                          );
                          setValue('project.id' as 'project', undefined);
                          setValue('syncCore.id' as 'syncCore', undefined);

                          this.setState({
                            selectedProject: undefined,
                            selectedSyncCore: undefined,
                            step: step === STEP_ENVIRONMENT ? step + 1 : step,
                          });
                        },
                      };

                      return (
                        <>
                          {createNew ? (
                            <EntityFormRow
                              className="mb-0"
                              name={'environmentType'}
                              label={'Environment'}
                              edit={() => (
                                <SiteEnvironmentTypeSelector
                                  contractId={selectedContract?.id}
                                  className="mt-2"
                                  mode={mustBeLocal ? 'local' : mostRecent.current?.product === Product.Staging ? 'staging' : 'syndication'}
                                  setValue={(environmentType) => {
                                    setValue('environmentType', environmentType);
                                    setValue(
                                      'userOwner.id' as any,
                                      environmentType === SiteEnvironmentType.Local ? this.api.currentUser!.id : undefined
                                    );
                                    setValue('project.id' as 'project', undefined);
                                    setValue('syncCore.id' as 'syncCore', undefined);

                                    this.setState({
                                      selectedProject: undefined,
                                      selectedSyncCore: undefined,
                                      step: step === STEP_ENVIRONMENT ? step + 1 : step,
                                    });
                                  }}
                                />
                              )}
                            />
                          ) : (
                            /*<EnumAsRadios<ClientSiteDraft, SiteEnvironmentType> {...enumProps} />*/
                            <EnumAsDropdown<ClientSiteDraft, SiteEnvironmentType> {...enumProps} />
                          )}
                          {
                            <div className={'text-warning ms-3 mb-3'}>
                              The environment type can't be changed later.{' '}
                              <InfoIcon>
                                Sites that are of type <em>Testing</em> or <em>Local</em> can only exchange content with other sites of type{' '}
                                <em>Testing</em> or <em>Local</em>.
                                <br />
                                Sites of type <em>Testing</em> or <em>Local</em> don't count into your usage limit, so you can connect as
                                many sites for testing as you like.
                                <br />
                                Sites of type <em>Testing</em> and <em>Local</em> are not covered by any SLA and are only supported on a
                                best-effort basis.
                                <br />
                                Incorrectly assigning <em>Testing</em> or <em>Local</em> to sites you are using in production can result in
                                an immediate deactivation of your account and cancellation of all services.
                              </InfoIcon>
                            </div>
                          }
                        </>
                      );
                    }}
                  </WithMostRecentContractRevision>
                ) : step >= STEP_ENVIRONMENT ? (
                  <EntityFormRow<ClientSiteDraft>
                    name={'environmentType'}
                    label={'Environment'}
                    view={() => (
                      <>
                        <SyncCoreDataDefinitionsEnumValue enumName={'SiteEnvironmentType'} keyName={values.environmentType} />{' '}
                        {createNew &&
                          (mustBeLocal ? (
                            <FontAwesomeIcon icon={faLock} className="text-muted ms-1" title="Must be local based on the site URL." />
                          ) : (
                            <EditButton
                              onClick={() => {
                                setValue('environmentType', '');
                                setValue('project.id' as 'project', undefined);
                                setValue('syncCore.id' as 'syncCore', undefined);
                                this.setState({
                                  selectedProject: undefined,
                                  selectedSyncCore: undefined,
                                  step: STEP_ENVIRONMENT,
                                });
                              }}
                            />
                          ))}
                      </>
                    )}
                  />
                ) : undefined}

                {(!createNew || step >= STEP_PROJECT) && (
                  <EntityFormRow<ClientSiteDraft>
                    name={'project'}
                    label={'Project'}
                    view={
                      selectedProject
                        ? () =>
                            createNew ? (
                              <>
                                {selectedProject.name}{' '}
                                <EditButton
                                  onClick={() => {
                                    setValue('project.id' as 'project', undefined);
                                    setValue('syncCore.id' as 'syncCore', undefined);
                                    this.setState({
                                      selectedProject: undefined,
                                      selectedSyncCore: undefined,
                                      step: STEP_PROJECT,
                                      selectProjectManually: true,
                                    });
                                  }}
                                />
                              </>
                            ) : (
                              <ProjectLink entityId={selectedProject.id} />
                            )
                        : selectedContract
                          ? () => <em>Please select a contract</em>
                          : undefined
                    }
                    edit={
                      selectedContract && createNew && step === STEP_PROJECT
                        ? values.environmentType
                          ? () => (
                              <>
                                <ProjectSelector
                                  forCustomer={values.customer.id}
                                  contract={selectedContract}
                                  name={'project.id'}
                                  error={errors?.project?.id as any as string}
                                  value={selectedProject}
                                  forType={
                                    values.environmentType === SiteEnvironmentType.Testing ||
                                    values.environmentType === SiteEnvironmentType.Local
                                      ? SiteEnvironmentType.Testing
                                      : SiteEnvironmentType.Production
                                  }
                                  forAppType={values.appType}
                                  onSelected={(selectedProject) => {
                                    setValue('project.id' as 'project', selectedProject.id);

                                    setValue('syncCore.id' as 'syncCore', selectedProject.syncCore?.getId() || undefined);

                                    this.setState({
                                      selectedProject,
                                      selectedSyncCore: undefined,
                                      step: step === STEP_PROJECT ? step + 1 : step,
                                      selectProjectManually: undefined,
                                    });

                                    if (selectedProject.syncCore) {
                                      selectedProject.syncCore.get().then(
                                        (selectedSyncCore) =>
                                          this.state.selectedProject?.id === selectedProject.id &&
                                          this.setState({
                                            selectedSyncCore,
                                            step: this.state.step === STEP_SYNC_CORE ? this.state.step + 1 : this.state.step,
                                          })
                                      );
                                    }
                                  }}
                                  variant={createNew ? 'radios' : 'drop-down'}
                                  autoSelectIfOnlyOneExists={!selectProjectManually}
                                />
                                <div className={'text-warning'}>
                                  The project can't be changed later.{' '}
                                  <InfoIcon>Sites that are not in the same project can't exchange any content.</InfoIcon>
                                </div>
                              </>
                            )
                          : () => <em>Please select an environment to continue.</em>
                        : undefined
                    }
                  />
                )}

                {(!createNew || step >= STEP_SYNC_CORE) && (
                  <EntityFormRow
                    name={'syncCore'}
                    label={'Sync Core'}
                    view={
                      selectedSyncCore &&
                      (() =>
                        selectedSyncCore.type === SyncCoreType.OnPremise && !createNew ? (
                          <SyncCoreLink entityId={selectedSyncCore.id} />
                        ) : (
                          <>
                            {selectedSyncCore.name}
                            {createNew && !selectedProject?.syncCore?.getId() && (
                              <EditButton
                                onClick={() => {
                                  setValue('syncCore.id' as 'syncCore', undefined);
                                  this.setState({
                                    selectedSyncCore: undefined,
                                    step: STEP_SYNC_CORE,
                                    selectSyncCoreManually: true,
                                  });
                                }}
                              />
                            )}
                          </>
                        ))
                    }
                    edit={
                      createNew && step === STEP_SYNC_CORE && !selectedProject?.syncCore?.getId()
                        ? () => (
                            <Row className={'m-0 p-0'}>
                              <Col className={'m-0 p-0'}>
                                <SyncCoreSelector
                                  forCustomer={selectPublicSyncCore ? undefined : selectedCustomer?.id}
                                  forContract={selectPublicSyncCore ? undefined : selectedContract?.id}
                                  key={selectPublicSyncCore ? 'public' : 'default'}
                                  autoSelect={!selectSyncCoreManually}
                                  name={'syncCore.id'}
                                  error={errors?.syncCore?.id as any as string}
                                  value={selectedSyncCore}
                                  onSelected={(selectedSyncCore) => {
                                    setValue('syncCore.id' as 'syncCore', selectedSyncCore.id);
                                    this.setState({
                                      selectedSyncCore,
                                      step: step === STEP_SYNC_CORE ? step + 1 : step,
                                      selectSyncCoreManually: false,
                                    });
                                  }}
                                  variant={createNew ? 'radios' : 'drop-down'}
                                />
                              </Col>
                              {(selectedSyncCore?.type === SyncCoreType.OnPremise || selectPublicSyncCore) && (
                                <Col xs={4}>
                                  <Button
                                    className={'p-1'}
                                    variant={'link'}
                                    onClick={() =>
                                      this.setState({
                                        selectPublicSyncCore: !selectPublicSyncCore,
                                        selectedSyncCore: undefined,
                                        selectSyncCoreManually: true,
                                      })
                                    }
                                  >
                                    {selectPublicSyncCore ? 'Use Private' : 'Use Public'}
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          )
                        : undefined
                    }
                  />
                )}

                {!small && (
                  <EnumAsDropdown<ClientSiteDraft, SiteStatus>
                    enumValues={SyncCoreDataDefinitionsEnumTranslator.translateEnum('SiteStatus')}
                    currentValue={values.status}
                    name={'status'}
                    label={'Status'}
                    setValue={setValue as any}
                  />
                )}

                {!small && (
                  <EntityFormRow<ClientSiteDraft>
                    name={'appType'}
                    label={'Framework'}
                    view={() => (
                      <>
                        <SyncCoreDataDefinitionsEnumValue enumName={'SiteApplicationType'} keyName={values.appType} /> {values.appVersion}
                      </>
                    )}
                  />
                )}

                {!small && (
                  <EntityFormRow<ClientSiteDraft>
                    name={'appModuleVersion'}
                    label={'Module version'}
                    view={values.appModuleVersion ? () => values.appModuleVersion : undefined}
                  />
                )}

                {!createNew && (
                  <EntityFormRow<ClientSiteDraft>
                    name={'lastActivity'}
                    label={'Last activity'}
                    view={values.lastActivity ? () => <FormatDateTime date={values.lastActivity} /> : undefined}
                  />
                )}

                {!small && (
                  <EntityFormRow<ClientSiteDraft> name={'uuid' as any} label={'Token'} view={values.uuid ? () => values.uuid : undefined} />
                )}

                {!createNew && staticInfo}

                {children}
              </>
            );
          }}
        </FormWithValidation>
      </EntityContext.Provider>
    );
  }
}
