import { ClientSiteEntity } from '@edgebox/api-rest-client';
import { InternalId, IPagedListResponse } from '@edgebox/data-definition-kit';
import { ExternalLink } from '@edgebox/react-components';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { EntitySelector, IEntitySelectorProps, IEntitySelectorState } from '../../Form/EntitySelector';
import { FormatDateTime } from '../../Localization/FormatDateTime';
import { ITextSearchFilter } from '../../PagedList';

interface IProps extends IEntitySelectorProps<ClientSiteEntity> {
  forCustomer?: InternalId;
  forContract?: InternalId;
  forProject?: InternalId;
}

interface IState extends IEntitySelectorState<ClientSiteEntity> {}

export class SiteSelector extends EntitySelector<ClientSiteEntity, IProps, IState> {
  renderEmpty() {
    return 'Select site';
  }

  renderCurrentValue(selected: ClientSiteEntity): React.ReactElement {
    return <span title={selected.baseUrl}>{selected.name}</span>;
  }

  async search(page: number, filters?: ITextSearchFilter): Promise<IPagedListResponse<ClientSiteEntity>> {
    const { forCustomer, forContract, forProject } = this.props;

    return await this.api.billing.sites.search(
      { page },
      { customerId: forCustomer, contractId: forContract, projectId: forProject },
      { search: filters?.search || '' }
    );
  }

  renderAddForm(): React.ReactElement | null {
    return null;
  }

  renderItem(entity: ClientSiteEntity): React.ReactElement {
    return (
      <Row>
        <Col>
          <h3>{entity.name}</h3>
          <div className={'text-muted'}>
            <ExternalLink to={entity.baseUrl}>{entity.baseUrl}</ExternalLink>
            <br />
            Created: <FormatDateTime date={entity.createdAt} />
          </div>
        </Col>
      </Row>
    );
  }

  renderItemAsText(entity: ClientSiteEntity): string {
    return entity.name;
  }

  renderItemAsSubText(entity: ClientSiteEntity): string | null {
    return entity.baseUrl.replace(/^https?:\/\//, '');
  }
}
