import { ClientContractRevisionEntity, ClientCustomerEntity } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import React from 'react';
import { Link } from 'react-router-dom';
import { ApiComponent, IApiComponentState } from '../../../services/ApiComponent';
import { ViewContractRevisionName } from '../Views/ViewContractRevisionName';

interface IProps {
  entityId: InternalId;
  backend?: boolean;
}

interface IState extends IApiComponentState {
  entity?: ClientContractRevisionEntity;
  customer?: ClientCustomerEntity;
}

export class ContractRevisionLink extends ApiComponent<IProps, IState> {
  async load(): Promise<Partial<IState>> {
    const entity = await this.api.billing.contractRevisions.item(this.props.entityId);
    const customer = await entity?.customer.get();

    return {
      entity,
      customer,
    };
  }

  render(): React.ReactElement {
    const { backend } = this.props;
    const { entity, customer } = this.state;

    if (!entity) {
      return this.renderRequest();
    }

    if (backend) {
      return (
        <Link to={`/backend/billing/contracts/${entity.contract.getId()}/revisions/${entity.id}`}>
          <ViewContractRevisionName entity={entity} /> for {customer?.name}
        </Link>
      );
    }

    return (
      <Link to={`/subscriptions/${entity.contract.getId()}/revisions/${entity.id}`}>
        <ViewContractRevisionName entity={entity} />
      </Link>
    );
  }
}
