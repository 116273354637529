import { formatMoneyAmount } from '@edgebox/data-definition-kit';
import { Currency } from '@edgebox/data-definitions';
import React, { PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren {
  amount: number;
  currency: Currency;
}

export class MoneyAmount extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    return (
      <span className={'money-amount'}>
        {formatMoneyAmount(this.props.amount, this.props.currency)}
        {this.props.children}
      </span>
    );
  }
}
