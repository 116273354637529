import { ClientContractDraft, ClientCustomerEntity } from '@edgebox/api-rest-client';
import { StaticReference } from '@edgebox/data-definition-kit';
import { ButtonLink, Right } from '@edgebox/react-components';
import moment from 'moment';
import React from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import { ApiComponent, ContractForm, IApiComponentState, IItemProps } from '../../../common';
import { IIdParamProp, INavigateProp, withIdParamAndNavigate } from '../../RouterHelper';
import { ContentBox } from '../../Shared/ContentBox';

interface IExternalProps extends IItemProps {}
interface IProps extends IExternalProps, INavigateProp, IIdParamProp {}

interface IState extends IApiComponentState {
  customer?: ClientCustomerEntity;
  draft?: ClientContractDraft;
}

class BackendAddContractComponent extends ApiComponent<IProps, IState> {
  async load() {
    const customer = await this.api.billing.customers.item(this.props.id);

    const startDate = moment();

    return {
      customer,
      draft: new ClientContractDraft({
        region: customer.region,
        licensedSites: 0,
        autoScaleLicenses: false,
        startDate,
        renewAutomatically: false,
        customer: new StaticReference(customer),
        currentStagingSites: 0,
        currentTestingSites: 0,
        currentProductionSites: 0,
      }),
    };
  }

  render() {
    const { customer, draft } = this.state;

    if (!customer || !draft) {
      return this.renderRequest();
    }

    const backUrl = `/backend/billing/customers/${customer.id}/contracts`;

    return (
      <Container>
        <h1>Add contract</h1>
        <Alert variant={'danger'}>
          The customer will not be charged for this. Only create contracts manually to migrate existing contracts or to create a contract
          for an Enterprise customer that can't be invoiced automatically.
        </Alert>
        <ContentBox>
          <ContractForm
            onSave={async (draft: ClientContractDraft) => {
              const entity = await this.api.billing.contracts.create(draft);
              this.props.navigate(`/backend/billing/customers/${customer.id}/contracts/${entity.id}/add`, { replace: true });
            }}
            mode={'create'}
            entity={draft}
            name={<></>}
          >
            <Right>
              <ButtonLink to={backUrl} variant={'light'}>
                Cancel
              </ButtonLink>
              <Button variant={'primary'} type={'submit'}>
                Save
              </Button>
            </Right>
          </ContractForm>
        </ContentBox>
      </Container>
    );
  }
}

export const BackendAddContract = withIdParamAndNavigate<IExternalProps>(BackendAddContractComponent);
