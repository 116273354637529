import {
  ClientContractEntity,
  ClientContractRevisionDraft,
  ClientContractRevisionEntity,
  ClientCustomerEntity,
  ClientPricingEntity,
} from '@edgebox/api-rest-client';
import { DateTimeFormat } from '@edgebox/data-definition-kit';
import { ISerializedContractRevisionProperties, UserType } from '@edgebox/data-definitions';
import { EditButton, FormField, LeftRightH2, SelectField } from '@edgebox/react-components';
import { HostingType, Package, SyncCoreDataDefinitionsEnumTranslator } from '@edgebox/sync-core-data-definitions';
import moment from 'moment';
import React, { PropsWithChildren } from 'react';
import { Alert } from 'react-bootstrap';
import { EditMode, EntityContext } from '../../../contexts/EntityContext';
import { EntityForm, entityFormWithValidation, IEntityFormProps, IEntityFormState } from '../../Form/EntityForm';
import { EntityFormRow } from '../../Form/EntityFormRow';
import { EnumAsDropdown } from '../../Form/EnumAsDropdown';
import { FormatDateTime } from '../../Localization/FormatDateTime';
import { SyncCoreDataDefinitionsEnumValue } from '../../Localization/SyncCoreDataDefinitionsEnumValue';
import { PricingStatusBadge } from '../Badges/PricingStatusBadge';
import { PricingVisibilityBadge } from '../Badges/PricingVisibilityBadge';
import { ContractRevisionLink } from '../Links/ContractRevisionLink';
import { ContractRevisionSelector } from '../Selectors/ContractRevisionSelector';
import { CustomerSelector } from '../Selectors/CustomerSelector';
import { PricingSelector } from '../Selectors/PricingSelector';
import { ViewContractRevisionDiscounts } from '../Views/ViewContractRevisionDiscounts';
import { ViewPricingSummary } from '../Views/ViewPricingSummary';

interface IProps<Entity extends ClientContractRevisionDraft>
  extends IEntityFormProps<Entity, ISerializedContractRevisionProperties>,
    PropsWithChildren {
  onSave: (entity: Entity) => Promise<void>;
  className?: string;
  mode: 'view-full' | 'edit-full' | 'create' | 'view-full-backend';
}

const EDIT_MODES = {
  'view-full': EditMode.None,
  'view-full-backend': EditMode.None,
  'edit-full': EditMode.Full,
  create: EditMode.Full,
};

interface IState<Entity extends ClientContractRevisionDraft> extends IEntityFormState<Entity> {
  selectedContract?: ClientContractEntity;
  selectedPricing?: ClientPricingEntity;
  selectedCustomer?: ClientCustomerEntity;
  selectedPreviousContractRevision?: ClientContractRevisionEntity;
}

const FormWithValidation = entityFormWithValidation<ISerializedContractRevisionProperties, ClientContractRevisionDraft>(
  ClientContractRevisionDraft,
  ClientContractRevisionEntity as any
);

export class ContractRevisionForm<Entity extends ClientContractRevisionDraft = ClientContractRevisionDraft> extends EntityForm<
  Entity,
  ISerializedContractRevisionProperties,
  IProps<Entity>,
  IState<Entity>
> {
  constructor(props: IProps<Entity>) {
    super(props, {
      edit: EDIT_MODES[props.mode],
    });
  }

  async load(): Promise<Partial<IState<Entity>>> {
    const { entity } = this.props;

    const selectedPricing = await entity.pricing?.get();
    const selectedCustomer = await entity.customer?.get();
    const selectedContract = await entity.contract?.get();

    const selectedPreviousContractRevision = await entity.previousContractRevision?.get();

    return {
      selectedPricing,
      selectedCustomer,
      selectedContract,
      selectedPreviousContractRevision,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps<Entity>>, prevState: Readonly<IState<Entity>>, snapshot?: any): void {
    if (this.props.mode !== prevProps.mode) {
      this.setState({
        edit: EDIT_MODES[this.props.mode],
      });
    }
  }

  render(): React.ReactElement {
    const { mode, children, onToggleEdit } = this.props;
    const { selectedPricing, selectedCustomer, selectedContract, selectedPreviousContractRevision } = this.state;

    const renderBackendProperties = this.api.currentUser!.type === UserType.Internal;

    const createNew = mode === 'create';

    if (this.props.entity.pricing.getId() && !selectedPricing) {
      return this.renderRequest();
    }

    if (!selectedCustomer || !selectedContract) {
      return this.renderRequest();
    }

    return (
      <EntityContext.Provider value={this.entityContext}>
        <FormWithValidation
          {...(this.formProperties() as any)}
          apiComponent={this}
          initialValues={this.props.entity}
          labels={'above'}
          onSubmit={async (draft) => {
            this.setState({
              extendProperties: [],
            });

            await this.props.onSave(draft as Entity);
          }}
        >
          {({ values, setValue }) => (
            <>
              {this.props.name ||
                (onToggleEdit ? (
                  <LeftRightH2 left={selectedPricing?.name} right={<EditButton onClick={onToggleEdit} />} />
                ) : (
                  <h2>{selectedPricing?.name}</h2>
                ))}

              <EntityFormRow<ClientContractRevisionDraft>
                name={'customerReference'}
                label={'Customer reference'}
                edit={
                  createNew
                    ? () => <FormField name={'customerReference'} label={'E.g. purchase order number'} type={'text'} noRow />
                    : undefined
                }
              />

              <EnumAsDropdown<ClientContractRevisionDraft, HostingType>
                name={'hostingType'}
                enumValues={SyncCoreDataDefinitionsEnumTranslator.translateEnum('HostingType')}
                currentValue={values.hostingType}
                label={'Select...'}
                showStatic={!renderBackendProperties || !createNew || selectedPricing?.packageType !== Package.Enterprise}
                setValue={setValue as any}
              />

              {renderBackendProperties && (
                <EntityFormRow<ClientContractRevisionDraft>
                  name={'customer'}
                  label={'Customer'}
                  view={selectedCustomer && (() => <span>{selectedCustomer.name}</span>)}
                  edit={
                    createNew
                      ? () => (
                          <CustomerSelector
                            value={selectedCustomer}
                            onRemove={() => setValue('customer', undefined)}
                            onSelected={(selectedCustomer) => {
                              setValue('customer.id' as 'customer', selectedCustomer.id);
                              this.setState({ selectedCustomer });
                            }}
                          />
                        )
                      : undefined
                  }
                />
              )}

              <EntityFormRow<ClientContractRevisionDraft>
                name={'previousContractRevision'}
                label={'Previous subscription'}
                view={
                  values.previousContractRevision &&
                  (() => <ContractRevisionLink backend={renderBackendProperties} entityId={values.previousContractRevision!.id!} />)
                }
                edit={
                  createNew
                    ? () => (
                        <ContractRevisionSelector
                          value={selectedPreviousContractRevision}
                          forCustomer={selectedCustomer?.id}
                          includeInactive
                          onRemove={() => setValue('previousContractRevision', undefined)}
                          onSelected={(selectedPreviousContractRevision) => {
                            setValue('previousContractRevision.id' as 'previousContractRevision', selectedPreviousContractRevision.id);
                            this.setState({ selectedPreviousContractRevision });
                          }}
                        />
                      )
                    : undefined
                }
              />

              {renderBackendProperties ? (
                <EntityFormRow<ClientContractRevisionDraft>
                  name={'pricing'}
                  label={'Pricing'}
                  view={
                    selectedPricing &&
                    (() => (
                      <span>
                        {selectedPricing.name} <PricingVisibilityBadge visibility={selectedPricing.visibility} />{' '}
                        <PricingStatusBadge status={selectedPricing.status} />
                      </span>
                    ))
                  }
                  edit={
                    createNew
                      ? () => (
                          <PricingSelector
                            forCustomer={selectedCustomer?.id}
                            value={selectedPricing}
                            onRemove={() => setValue('pricing', undefined)}
                            onSelected={(selectedPricing) => {
                              setValue('pricing.id' as 'pricing', selectedPricing.id);
                              if (selectedPricing.packageType !== Package.Enterprise) {
                                setValue('hostingType', HostingType.Cloud);
                              }
                              setValue('packageType', selectedPricing.packageType);
                              setValue('product', selectedPricing.product);
                              setValue('maxUpdatesPerMonth', selectedPricing.maxUpdatesPerSitePerMonth);
                              setValue('maxUpdatesDuringThisRevision', undefined);
                              this.setState({ selectedPricing });
                            }}
                          />
                        )
                      : undefined
                  }
                />
              ) : (
                <ViewPricingSummary pricing={selectedPricing!} withTitle />
              )}

              <EntityFormRow<ClientContractRevisionDraft>
                name={'product'}
                label={'Product'}
                view={values.product ? () => <SyncCoreDataDefinitionsEnumValue enumName={'Product'} keyName={values.product} /> : undefined}
              />

              <EntityFormRow<ClientContractRevisionDraft>
                name={'packageType'}
                label={'Package'}
                view={
                  values.packageType
                    ? () => <SyncCoreDataDefinitionsEnumValue enumName={'Package'} keyName={values.packageType} />
                    : undefined
                }
              />

              <EntityFormRow<ClientContractRevisionDraft>
                name={'startDate'}
                label={'Start'}
                view={() => <FormatDateTime date={values.startDate} format={DateTimeFormat.Date} />}
                edit={renderBackendProperties ? () => <FormField name={'startDate'} label={'Start'} type={'date'} noRow /> : undefined}
              />

              <EntityFormRow<ClientContractRevisionDraft>
                name={'endDate'}
                label={'End'}
                view={() => <FormatDateTime date={values.endDate} format={DateTimeFormat.Date} />}
                edit={createNew ? () => <FormField name={'endDate'} label={'End'} type={'date'} noRow /> : undefined}
              />

              {(values.discounts || mode === 'create') && (
                <EntityFormRow<ClientContractRevisionDraft>
                  name={'discounts'}
                  label={'Discounts'}
                  view={() =>
                    mode === 'create' ? (
                      <Alert variant={'light'}>Discount will be calculated when saving.</Alert>
                    ) : (
                      <ViewContractRevisionDiscounts discounts={values.discounts!} currency={selectedPricing!.currency} />
                    )
                  }
                />
              )}

              <EntityFormRow<ClientContractRevisionDraft>
                name={'advancePaymentsInMonths'}
                label={'Plan'}
                edit={
                  createNew
                    ? () => (
                        <SelectField
                          options={{
                            1: 'Monthly',
                            12: 'Yearly',
                          }}
                          noRow
                          name={'advancePaymentsInMonths'}
                          onChange={(e) => {
                            const months = parseInt(e.target.value);
                            setValue('advancePaymentsInMonths', months);
                            setValue('contractTermInMonths', months);
                          }}
                        />
                      )
                    : undefined
                }
              />

              <EntityFormRow<ClientContractRevisionEntity>
                name={'maxUpdatesPerMonth'}
                label={'Max updates per month'}
                edit={() => <FormField name={'maxRootPushOperationsPerMonth'} label={'Max pushes per month'} type={'number'} noRow />}
                view={() => (values.maxUpdatesPerMonth ? values.maxUpdatesPerMonth : <em>unlimited</em>)}
              />

              <EntityFormRow<ClientContractRevisionEntity>
                name={'maxUpdatesDuringThisRevision'}
                label={'Max updates during this revision'}
                edit={() => (
                  <FormField name={'maxUpdatesDuringThisRevision'} label={'Max updates during this revision'} type={'number'} noRow />
                )}
                view={() => (values.maxUpdatesDuringThisRevision ? values.maxUpdatesDuringThisRevision : <em>unlimited</em>)}
              />

              {children}
            </>
          )}
        </FormWithValidation>
      </EntityContext.Provider>
    );
  }
}
