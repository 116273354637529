import React, { PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren {
  glueBottom?: boolean;
  glueTop?: boolean;
  className?: string;
}

export class ContentBox extends React.Component<IProps, {}> {
  render() {
    const { children, glueBottom, glueTop, className } = this.props;

    return (
      <div
        className={`content-box bg-white ${glueBottom ? (glueTop ? '' : 'rounded-top') : glueTop ? 'rounded-bottom' : 'rounded'} p-2 ${
          className || ''
        }`}
      >
        {children}
      </div>
    );
  }
}
