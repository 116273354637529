import { EnumTranslation } from '@edgebox/data-definitions';
import { FormRow, ValidationError } from '@edgebox/react-components';
import React from 'react';
import Form from 'react-bootstrap/Form';
import { EntityFormRow } from './EntityFormRow';

interface IProps<Type, Enum extends string> {
  enumValues: EnumTranslation<Enum>;
  currentValue: Enum;
  name: keyof Type;
  label: string;
  setValue: (name: keyof Type, value: Enum) => void;
  withoutEntity?: boolean;
  error?: string;
  showStatic?: boolean;
  help?: React.ReactNode;
}

export class EnumAsRadios<Type, Enum extends string> extends React.Component<IProps<Type, Enum>> {
  render(): React.ReactElement {
    const { name, enumValues, currentValue, setValue, label, withoutEntity, error, showStatic, help } = this.props;

    const keys = Object.keys(enumValues);

    const edit = () => (
      <div>
        {keys.map((key) => {
          const enumKey = key as Enum;

          return (
            <Form.Check
              label={<span className="cursor-pointer">{enumValues[enumKey]}</span>}
              key={key}
              type={'radio'}
              id={`${name.toString()}-${key}`}
              checked={currentValue === enumKey}
              onChange={() => {
                setValue(name, enumKey);
              }}
            />
          );
        })}

        {error && (
          <Form.Control.Feedback type={'invalid'}>
            <ValidationError name={name as any} touched />
          </Form.Control.Feedback>
        )}
      </div>
    );

    if (withoutEntity) {
      return (
        <FormRow name={name as string} label={label}>
          {edit()}
        </FormRow>
      );
    }

    return (
      <EntityFormRow
        name={name}
        label={label}
        edit={showStatic ? undefined : edit}
        view={currentValue ? () => enumValues[currentValue] : undefined}
        help={help}
      />
    );
  }
}
