import { INamed, InternalId } from '@edgebox/data-definition-kit';

export interface IItemRouteProps {
  id: InternalId;
}

export interface IItemProps {}

export function sortByName(entities: INamed[]): void {
  entities.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (b.name > a.name) {
      return -1;
    }
    return 0;
  });
}

export function getStyleColors(display?: 'default' | 'menu' | 'menu-solid' | 'menu-inactive') {
  const prefix = display === 'menu' || display === 'menu-solid' ? '--highlight' : display === 'menu-inactive' ? '--dark' : '--primary';

  const bodyStyles = window.getComputedStyle(document.body);
  const primary = bodyStyles.getPropertyValue(prefix);
  const primaryDarker = bodyStyles.getPropertyValue(prefix + '-darker');
  const primaryLighter = bodyStyles.getPropertyValue(prefix + '-lighter');
  const primaryLighter50 = bodyStyles.getPropertyValue(prefix + '-lighter-50');
  const danger = bodyStyles.getPropertyValue('--danger');

  return {
    primary,
    primaryDarker,
    primaryLighter,
    primaryLighter50,
    danger,
  };
}
