import { formatDuration } from '@edgebox/data-definition-kit';
import React from 'react';

interface IProps {
  hours: number;
}

export class FormatDuration extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { hours } = this.props;

    return <span>{formatDuration(hours)}</span>;
  }
}
