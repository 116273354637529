import { ClientError } from '@edgebox/data-definition-kit';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import './index.css';

if (!process.env.REACT_APP_ENVIRONMENT_TYPE) {
  throw new Error(`Missing environment variable REACT_APP_ENVIRONMENT_TYPE.`);
}
if (!process.env.REACT_APP_ENVIRONMENT_ID && process.env.REACT_APP_ENVIRONMENT_TYPE === 'local') {
  throw new Error(`Missing environment variable REACT_APP_ENVIRONMENT_ID.`);
}

const environment =
  process.env.REACT_APP_ENVIRONMENT_TYPE === 'local'
    ? `${process.env.REACT_APP_ENVIRONMENT_TYPE}-${process.env.REACT_APP_ENVIRONMENT_ID}`
    : process.env.REACT_APP_ENVIRONMENT_TYPE;

const checkForResizeObserverError = (event: Sentry.Event) => {
  const message = (event.extra as any)?.__serialized__?.message;
  return typeof message === 'string' && message.includes('ResizeObserver');
};

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  beforeSend(event, hint) {
    // Group exceptions not just by the error callstack, as that would be the same for any ClientError.
    // Instead, we provide the current path as an additional "fingerprint".
    event.fingerprint = [window.location.pathname];

    // But as different errors can occur on the same page, we also add the error stack trace if
    // there is one.
    if (event.exception && event.exception.values?.length) {
      if (event.exception.values[0].type) {
        event.fingerprint.push(event.exception.values[0].type);
      }
      if (event.exception.values[0].stacktrace?.frames) {
        event.fingerprint = [...event.fingerprint, ...event.exception.values[0].stacktrace.frames.map((f) => `${f.filename}: ${f.lineno}`)];
      }
    }

    if ((window as any).AUTH0_CONTEXT_VALUE?.user) {
      event.user = (window as any).AUTH0_CONTEXT_VALUE.user;
    } else if ((window as any).LATEST_USER) {
      event.user = (window as any).LATEST_USER;
    }

    // ClientError will provide additional details like the exact validation error in a "details" attribute.
    if (hint?.originalException && hint?.originalException instanceof ClientError) {
      if (!event.extra) {
        event.extra = {};
      }
      event.extra.type = hint.originalException.type;
      event.extra.details = hint.originalException.details;
    }

    // Check if it is an exception, and if so, show the report dialog
    if (event.exception && !checkForResizeObserverError(event)) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        user: event.user,
      });
    }

    return event;
  },
  environment: environment,
  release: 'app@' + process.env.REACT_APP_VERSION,
  integrations: [new BrowserTracing() as any],
  tracesSampleRate: 0,
});

const root = createRoot(document.getElementById('root')!);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
