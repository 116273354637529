import { formatInteger } from '@edgebox/data-definition-kit';
import { IBasketPricingSummary, IRuntimeContractRevisionDiscount } from '@edgebox/data-definitions';
import { ButtonLink, InfoIcon } from '@edgebox/react-components';
import React from 'react';
import Table from 'react-bootstrap/cjs/Table';
import { DataDefinitionsEnumValue, MoneyAmount } from '../../common';

interface IProps {
  label: React.ReactNode;
  showTaxes: boolean;
  pricing: IBasketPricingSummary;
  chargeMultiplier?: number;
  chargeMultiplierMonths?: number;
  chargeDiscounted?: boolean;
  discounts?: IRuntimeContractRevisionDiscount[];
  onChangeQuantity?: string;
}

export class PricingTable extends React.Component<IProps, {}> {
  render() {
    const { label, showTaxes, pricing, chargeDiscounted, chargeMultiplier, chargeMultiplierMonths, discounts, onChangeQuantity } =
      this.props;

    const showFull = chargeMultiplier === undefined && chargeMultiplierMonths === undefined;
    const totalChargeMultiplier = (chargeMultiplierMonths || 1) * (chargeMultiplier || 1);

    const showDiscount = showFull && pricing.discount > 0;
    const showMinimumFee = showFull && pricing.totalWithoutTaxes > pricing.discountedTotalWithoutTaxes;

    const nameDiscount = (discount: IRuntimeContractRevisionDiscount) => (
      <>
        <DataDefinitionsEnumValue enumName="DiscountType" keyName={discount.type} />: -
        {discount.amountFixed ? (
          <MoneyAmount amount={discount.amountFixed} currency={pricing.currency} />
        ) : (
          `${(discount.amountPercentage! * 100).toFixed(2)}%`
        )}
      </>
    );
    const discountList = discounts?.length ? (
      discounts.length === 1 ? (
        nameDiscount(discounts[0])
      ) : (
        <ul>
          {discounts.map((discount, index) => (
            <li key={index}>{nameDiscount(discount)}</li>
          ))}
          <li>
            <strong>Total (multiplied): {pricing.discount * 100}%</strong>
          </li>
        </ul>
      )
    ) : undefined;

    const baseTotal = chargeDiscounted ? pricing.discountedTotalWithoutTaxes : pricing.totalWithoutTaxes;
    const quantity = showFull ? (
      `${pricing.quantity} sites`
    ) : chargeMultiplier && chargeMultiplierMonths ? (
      <>
        {totalChargeMultiplier.toFixed(2)}{' '}
        <InfoIcon>
          {chargeMultiplier.toFixed(2)} x {chargeMultiplierMonths} months
        </InfoIcon>
      </>
    ) : chargeMultiplierMonths ? (
      `${chargeMultiplierMonths} months`
    ) : (
      totalChargeMultiplier.toFixed(2)
    );
    const pricePerUnit = showFull ? pricing.pricePerUnit : baseTotal;
    const total = showFull ? pricing.totalWithoutTaxes : Math.round(baseTotal * totalChargeMultiplier);
    const totalTaxes = showFull ? pricing.totalTaxes : Math.round(baseTotal * pricing.vatPercentage * totalChargeMultiplier);
    const totalWithTaxes = showFull ? pricing.totalWithTaxes : Math.round(baseTotal * (1 + pricing.vatPercentage) * totalChargeMultiplier);

    return (
      <Table>
        <thead>
          <tr>
            <th>Item</th>
            <th style={{ minWidth: '110px' }}>Quantity</th>
            <th className={'text-end'} style={{ minWidth: '140px' }}>
              Price
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{label}</td>
            <td>
              {quantity}
              {onChangeQuantity && (
                <>
                  {' '}
                  <ButtonLink variant="link" to={onChangeQuantity}>
                    change
                  </ButtonLink>
                </>
              )}
            </td>
            <td className={'text-end'}>
              <MoneyAmount amount={pricePerUnit} currency={pricing.currency} />
            </td>
          </tr>
          {showDiscount ? (
            <>
              <tr>
                <th>Sum</th>
                <td></td>
                <th className={'text-end'}>
                  <MoneyAmount amount={pricing.undiscountedTotalWithoutTaxes} currency={pricing.currency} />
                </th>
              </tr>
              <tr>
                <td>Discount {discountList && <InfoIcon>{discountList}</InfoIcon>}</td>
                <td>-{(pricing.discount * 100).toFixed(2)}%</td>
                <td className={'text-end'}>
                  -
                  <MoneyAmount
                    amount={pricing.undiscountedTotalWithoutTaxes - pricing.discountedTotalWithoutTaxes}
                    currency={pricing.currency}
                  />
                </td>
              </tr>
              <tr>
                <th>Discounted total</th>
                <td></td>
                <th className={'text-end'}>
                  <MoneyAmount amount={pricing.discountedTotalWithoutTaxes} currency={pricing.currency} />
                </th>
              </tr>
            </>
          ) : undefined}
          {showMinimumFee ? (
            <>
              <tr>
                <td>Minimum fee</td>
                <td></td>
                <td className={'text-end'}>
                  <MoneyAmount amount={pricing.minimumFee} currency={pricing.currency} />
                </td>
              </tr>
            </>
          ) : undefined}
          <tr>
            <th>Total</th>
            <td></td>
            <th className={'text-end'}>
              <MoneyAmount amount={total} currency={pricing.currency} />
            </th>
          </tr>
          {showTaxes ? (
            <>
              <tr>
                <td>Taxes</td>
                <td>+{formatInteger(pricing.vatPercentage * 100)}% VAT</td>
                <td className={'text-end'}>
                  <MoneyAmount amount={totalTaxes} currency={pricing.currency} />
                </td>
              </tr>
              <tr>
                <th>Total inc. taxes</th>
                <td></td>
                <th className={'text-end'}>
                  <MoneyAmount amount={totalWithTaxes} currency={pricing.currency} />
                </th>
              </tr>
            </>
          ) : undefined}
        </tbody>
      </Table>
    );
  }
}
