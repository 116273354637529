import { CardLink } from '@edgebox/react-components';
import React, { PropsWithChildren } from 'react';
import { Card } from 'react-bootstrap';

interface IProps extends PropsWithChildren {
  title: React.ReactNode;
  target?: string;
}

export class SummaryCard extends React.Component<IProps, {}> {
  render() {
    const { title, target, children } = this.props;

    if (target) {
      return (
        <CardLink to={target} className={'cursor-pointer b-0 summary-card'}>
          <Card.Header className={'bg-white'}>
            <Card.Title className={'m-0 p-0'}>{title}</Card.Title>
          </Card.Header>
          <Card.Body>{children}</Card.Body>
        </CardLink>
      );
    }

    return (
      <Card className={'b-0 summary-card'}>
        <Card.Header className={'bg-white'}>
          <Card.Title className={'m-0 p-0'}>{title}</Card.Title>
        </Card.Header>
        <Card.Body>{children}</Card.Body>
      </Card>
    );
  }
}
