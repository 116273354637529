import React from 'react';

interface IProps {
  count: number;
}

export class FormatStatsNumber extends React.Component<IProps, {}> {
  render() {
    let { count } = this.props;

    let suffix: string | undefined = undefined;
    if (count > 1_000_000) {
      count /= 1_000_000;
      suffix = 'M';
    } else if (count > 1_000) {
      count /= 1_000;
      suffix = 'K';
    }

    return suffix ? `${count.toFixed(1)} ${suffix}` : count;
  }
}
