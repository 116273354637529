import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface IProps {
  icon: IconProp;
  withBackground?: boolean;
  size?: 'sm' | 'md';
  className?: string;
}

export class CircleIcon extends React.Component<IProps, {}> {
  render() {
    const { icon, size, withBackground, className } = this.props;

    const backgroundPadding = withBackground ? 10 : 0;

    const sizeInPixels = (size === 'sm' ? 30 : 40) + backgroundPadding;

    return (
      <div
        className={`flex-shrink-0 flex-grow-0 ${className || ''} ${
          withBackground ? 'bg-light' : ''
        } text-center rounded-circle d-flex align-items-center justify-content-center`}
        style={{ width: `${sizeInPixels}px`, height: `${sizeInPixels}px`, lineHeight: 'inherit' }}
      >
        <FontAwesomeIcon className={'text-dark'} icon={icon} />
      </div>
    );
  }
}
