import { ClientInvoiceEntity, IInternalAuthentication } from '@edgebox/api-rest-client';
import { DateTimeFormat } from '@edgebox/data-definition-kit';
import { InvoiceStatus, UserType } from '@edgebox/data-definitions';
import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Alert from 'react-bootstrap/cjs/Alert';
import Col from 'react-bootstrap/cjs/Col';
import Container from 'react-bootstrap/cjs/Container';
import Row from 'react-bootstrap/cjs/Row';
import { Link } from 'react-router-dom';
import { ApiComponent, AuthenticationContext, FormatDateTime, InvoiceStatusBadge, MoneyAmount, PagedList } from '../../../common';
import { API_DOMAIN } from '../../../constants';
import { ContentBox } from '../../Shared/ContentBox';
import { AccountCompany } from './AccountCompany';
import { AccountPayment } from './AccountPayment';
import { HeadlineWithBreadcrumbNavigation } from '../../../common/components/BreadcrumbNavigation';

export class AccountBilling extends ApiComponent {
  async load() {
    return {};
  }

  render() {
    return (
      <Container className={'content'}>
        <HeadlineWithBreadcrumbNavigation>Billing</HeadlineWithBreadcrumbNavigation>

        <Tabs defaultActiveKey={this.api.currentUser?.type === UserType.Internal ? 'billing-address' : 'invoices'}>
          {this.api.currentUser?.type !== UserType.Internal && (
            <Tab title={'Invoices'} eventKey={'invoices'}>
              <ContentBox className={'pt-4 pb-4'}>
                <PagedList<ClientInvoiceEntity>
                  emptyMessage={<Alert variant={'light'}>You don't have any invoices yet.</Alert>}
                  renderListHeader={() => (
                    <Row className={'mb-3'}>
                      <Col xs={3}>
                        <strong>Name</strong>
                      </Col>
                      <Col xs={3}>
                        <strong>Subscription</strong>
                      </Col>
                      <Col xs={2}>
                        <strong>Total</strong>
                      </Col>
                      <Col xs={2}>
                        <strong>Payment date</strong>
                      </Col>
                      <Col xs={2}>
                        <strong>Actions</strong>
                      </Col>
                    </Row>
                  )}
                  renderItem={(item) => {
                    let actions: React.ReactNode = undefined;
                    if (item.status === InvoiceStatus.PaymentFailed || item.status === InvoiceStatus.RetryPayment) {
                      actions = <Link to={'/account/payment'}>Update payment details</Link>;
                    } else {
                      actions = (
                        <AuthenticationContext.Consumer>
                          {(auth: IInternalAuthentication) => {
                            return (
                              <a href={`//${API_DOMAIN}/invoice/${item.id}/pdf?jwt=${encodeURIComponent(auth.jwt!)}`} download>
                                Download PDF
                              </a>
                            );
                          }}
                        </AuthenticationContext.Consumer>
                      );
                    }

                    return (
                      <Row className={'mb-3'} key={item.id}>
                        <Col xs={3}>
                          {item.name} <InvoiceStatusBadge status={item.status} />
                        </Col>
                        <Col xs={3}>{/*<ContractLink entityId={item.contract.getId()} />*/}</Col>
                        <Col xs={2}>
                          {item.totalWithTaxes || item.totalWithTaxes === 0 ? (
                            <MoneyAmount amount={item.totalWithTaxes} currency={item.currency} />
                          ) : (
                            <em>TBD</em>
                          )}
                        </Col>
                        <Col xs={2}>
                          {item.paymentDate ? (
                            <FormatDateTime date={item.paymentDate.valueOf()} format={DateTimeFormat.Date} />
                          ) : (
                            <em>Not paid yet</em>
                          )}
                        </Col>
                        <Col xs={2}>{actions}</Col>
                      </Row>
                    );
                  }}
                  request={(page) => this.api.billing.invoices.list({ page })}
                />
              </ContentBox>
            </Tab>
          )}

          {this.api.currentUser?.type !== UserType.Internal && (
            <Tab title={'Payment'} eventKey={'payment'}>
              <ContentBox className={'pt-4 pb-4'}>
                <AccountPayment />
              </ContentBox>
            </Tab>
          )}

          <Tab title={'Billing address'} eventKey={'billing-address'}>
            <ContentBox className={'pt-4 pb-4'}>
              <AccountCompany />
            </ContentBox>
          </Tab>
        </Tabs>
      </Container>
    );
  }
}
