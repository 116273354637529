import { ClientBasketDraft, ClientContractEntity, ClientPricingEntity } from '@edgebox/api-rest-client';
import { InternalId, StaticReference } from '@edgebox/data-definition-kit';
import { BasketStatus, BasketType, CustomerRole, PaymentCycleType } from '@edgebox/data-definitions';
import { Package, Product, SyncCoreDataDefinitionsEnumTranslator } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import { Alert, Button, Form, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ApiComponent, IApiComponentState, isContractActive, SyncCoreDataDefinitionsEnumValue } from '../../common/index';
import { PrimaryActionButton } from './PrimaryActionButton';
import { LeftRightContainer, Right } from '@edgebox/react-components';
import StagingImage from '../../images/subscription-staging.png';
import SyndicationImage from '../../images/subscription-syndication.png';

const REDIRECT_URL_AFTER_STARTING_TRIAL = 'https://www.content-sync.io/thank-you-trial';

interface IProps {
  name?: string;
  forCustomer?: InternalId;
  onChange?: (contract: ClientContractEntity, initial: boolean) => void;
  noPurchaseRequired?: boolean;
  forceNew?: boolean;
  numberOfSites: number;
}

interface IState extends IApiComponentState {
  activeContracts?: ClientContractEntity[];
  selectableContracts?: ClientContractEntity[];
  trialPricing?: ClientPricingEntity[];
  startTrial?: boolean;
  selectedPricing?: ClientPricingEntity;
  saving?: boolean;
  createNew?: boolean;
  selectedContract?: ClientContractEntity;
  hover?: number;
}

export class StartTrial extends ApiComponent<IProps, IState> {
  async load() {
    const { forCustomer, onChange } = this.props;
    const load = async (page = 0) => {
      const contracts = await this.api.billing.contracts.search(undefined, { page }, forCustomer, true);
      let items = contracts.items;
      if (contracts.page + 1 < contracts.numberOfPages) {
        const add = await load(page + 1);
        items = items.concat(add);
      }
      return items;
    };

    // TODO: Check whether the user can start a new trial. If they can't and there's no valid contract, redirect them
    //  to whatever trial they started before to upgrade that first.

    const allContracts = await load();
    const startTrial = allContracts.length === 0 || this.props.forceNew;
    const activeContracts = allContracts.filter((c) => isContractActive(c));

    const trialPricing = await this.api.billing.pricing.list(false, undefined, Package.Trial, forCustomer);

    const selectableContracts = activeContracts.filter((c) => this.isEnabled(c));
    const selectedContract = selectableContracts[0];

    if (selectedContract && onChange && !this.props.forceNew) {
      onChange(selectedContract, true);
    }

    if (trialPricing) {
      trialPricing.sort((a, b) => (a.product === Product.Syndication ? -1 : 1));
    }

    return {
      activeContracts,
      startTrial,
      trialPricing,
      selectableContracts,
      selectedContract,
    };
  }

  isEnabled(item: ClientContractEntity) {
    if (this.props.noPurchaseRequired) {
      return true;
    }

    return item.currentProductionSites < item.licensedSites || item.autoScaleLicenses;
  }

  render() {
    const { numberOfSites, onChange } = this.props;
    const { activeContracts, startTrial, trialPricing, saving, selectedContract, selectableContracts, selectedPricing, hover } = this.state;

    if (!activeContracts) {
      return this.renderRequest();
    }

    const set = (selectedContract: ClientContractEntity) => {
      this.setState({
        selectedContract,
      });
      if (onChange) {
        onChange(selectedContract, false);
      }
    };

    if (startTrial) {
      return (
        <>
          <div className={`ms-2 me-2 mt-3 pricing-container hover-${hover}`}>
            <div className="d-flex pricing-row" style={{ gap: '1em' }}>
              {trialPricing!.map((pricing, index) => {
                return (
                  <>
                    {index !== 0 && (
                      <div key={index} className={'p-4 flex-shrink-0 flex-grow-0 text-muted'} style={{ width: '120px' }}></div>
                    )}
                    <div
                      key={pricing.id}
                      className={'p-3 flex-even cursor-pointer'}
                      onClick={() => this.setState({ selectedPricing: pricing })}
                      onMouseEnter={() => this.setState({ hover: index })}
                      onMouseLeave={() => this.setState({ hover: undefined })}
                    >
                      {pricing.product === Product.Staging ? (
                        <>
                          <h2>Staging</h2>
                          <p className="text-center">
                            <img src={StagingImage} style={{ maxWidth: '60%' }} />
                          </p>
                        </>
                      ) : (
                        <>
                          <h2>Syndication</h2>
                          <p className="text-center">
                            <Image src={SyndicationImage} style={{ maxWidth: '60%' }} />
                          </p>
                        </>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
            <div className="d-flex pricing-row" style={{ gap: '1em' }}>
              {trialPricing!.map((pricing, index) => {
                return (
                  <>
                    {index !== 0 && (
                      <div key={index} className={'p-4 flex-shrink-0 flex-grow-0 text-muted fw-bold fs-5'} style={{ width: '120px' }}>
                        - OR -
                      </div>
                    )}
                    <div
                      key={pricing.id}
                      className={'p-3 flex-even cursor-pointer'}
                      onClick={() => this.setState({ selectedPricing: pricing })}
                      onMouseEnter={() => this.setState({ hover: index })}
                      onMouseLeave={() => this.setState({ hover: undefined })}
                    >
                      {pricing.product === Product.Staging ? (
                        <>
                          <p>
                            Content staging allows you to prepare content in a private staging environment and then push it to production
                            when it's ready.
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            Use content syndication to share content between any number of sites. Each site can decide what content it wants
                            to push or pull from the pool.
                          </p>
                        </>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
            <div className="d-flex pricing-row" style={{ gap: '1em' }}>
              {trialPricing!.map((pricing, index) => {
                return (
                  <>
                    {index !== 0 && (
                      <div key={index} className={'p-4 flex-shrink-0 flex-grow-0 text-muted'} style={{ width: '120px' }}></div>
                    )}
                    <div
                      key={pricing.id}
                      className={'p-3 flex-even cursor-pointer'}
                      onClick={() => this.setState({ selectedPricing: pricing })}
                      onMouseEnter={() => this.setState({ hover: index })}
                      onMouseLeave={() => this.setState({ hover: undefined })}
                    >
                      {pricing.product === Product.Staging ? (
                        <>
                          <p>
                            <strong>Pricing</strong>: With this package, production sites are slightly more expensive than with content
                            syndication, but all staging environments are free of charge.
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            <strong>Pricing</strong>: With this package, you pay for all connected sites.
                          </p>
                        </>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
            <div className="d-flex pricing-row" style={{ gap: '1em' }}>
              {trialPricing!.map((pricing, index) => {
                return (
                  <>
                    {index !== 0 && (
                      <div key={index} className={'p-4 flex-shrink-0 flex-grow-0 text-muted'} style={{ width: '120px' }}></div>
                    )}
                    <div
                      key={pricing.id}
                      className={'p-5 flex-even cursor-pointer'}
                      onClick={() => this.setState({ selectedPricing: pricing })}
                      onMouseEnter={() => this.setState({ hover: index })}
                      onMouseLeave={() => this.setState({ hover: undefined })}
                    >
                      <p className="text-center">
                        <Form.Check
                          className="d-inline-block"
                          label={
                            <>
                              Select{' '}
                              <span className="fw-bold">
                                {SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('Product', pricing.product)}
                              </span>
                            </>
                          }
                          key={pricing.id}
                          type={'radio'}
                          id={`pricing-${pricing.id}`}
                          checked={selectedPricing === pricing}
                          onChange={() => {
                            this.setState({ selectedPricing: pricing });
                          }}
                        />
                      </p>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <LeftRightContainer
            className="mt-5"
            left={
              <p className="ms-5 text-muted">
                Start your evaluation now - <strong>No credit card required</strong>.<br />
                Test sites are always free of charge.
              </p>
            }
            right={
              <>
                <div title={selectedPricing ? undefined : 'Please select a product above to continue.'} className="text-end">
                  <PrimaryActionButton
                    className="me-5"
                    disabled={saving || !selectedPricing}
                    type={'button'}
                    id={selectedPricing?.product || ''}
                    onClick={async () => {
                      this.setState({ saving: true });

                      const basketDraft = new ClientBasketDraft({
                        type: BasketType.NewContract,
                        status: BasketStatus.Created,
                        cycle: PaymentCycleType.Monthly,
                        product: selectedPricing!.product,
                        packageType: selectedPricing!.packageType,
                        currency: selectedPricing!.currency,
                        createdBy: new StaticReference(this.api.currentUser!),
                        customer: new StaticReference(await this.getCurrentCustomer(true)),
                        pricing: new StaticReference(selectedPricing!),
                        numberOfSites,
                        renewAutomatically: false,
                      });

                      let basketEntity = await this.api.billing.baskets.create(basketDraft);
                      await this.api.billing.baskets.purchase(basketEntity.id);

                      // But not locally to not count new registrations when we only test.
                      if (process.env.REACT_APP_ENVIRONMENT_TYPE === 'local') {
                        window.alert('Please reload the page.');
                      }
                      // Navigate the user to the website to thank them for starting the trial.
                      else {
                        window.location.href = REDIRECT_URL_AFTER_STARTING_TRIAL;
                      }
                    }}
                  >
                    Start{' '}
                    {selectedPricing ? (
                      <SyncCoreDataDefinitionsEnumValue enumName={'Product'} keyName={selectedPricing.product} />
                    ) : undefined}{' '}
                    trial
                  </PrimaryActionButton>
                </div>
                {selectedContract && (
                  <div className={'mt-3 me-5 mb-5 text-end'}>
                    ...or{' '}
                    <Button variant="link" onClick={() => set(selectedContract)}>
                      use existing
                    </Button>
                  </div>
                )}
              </>
            }
          />
        </>
      );
    }

    const canCreateNew =
      this.api.currentUser?.customerRoles?.includes(CustomerRole.Owner) ||
      this.api.currentUser?.customerRoles?.includes(CustomerRole.ManageFinances);

    if (!selectableContracts?.length && !canCreateNew) {
      return (
        <Alert variant="warning">
          There is no active subscription available to add the site to. Please contact your account owner to purchase a subscription or
          increase the site count of an existing subscription.
        </Alert>
      );
    }

    return (
      <>
        {!selectableContracts?.length && (
          <Alert variant="light">
            There is no active subscription available to add the site to. Please update one of your{' '}
            <Link to={'/subscriptions'}>existing subscriptions</Link> or{' '}
            <Button variant={'link'} onClick={() => this.setState({ startTrial: true })}>
              start a new subscription
            </Button>{' '}
            to continue.
          </Alert>
        )}
      </>
    );
  }
}
