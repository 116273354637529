import { CustomerPaymentStatus } from '@edgebox/data-definitions';
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { DataDefinitionsEnumValue } from '../../Localization/DataDefinitionsEnumValue';

interface IProps {
  status: CustomerPaymentStatus;
  size?: 'small';
}

export class CustomerPaymentStatusBadge extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { status, size } = this.props;

    const classes = size ? 'small' : '';

    const variants = {
      [CustomerPaymentStatus.None]: 'light',
      [CustomerPaymentStatus.CheckoutStarted]: 'light',
      [CustomerPaymentStatus.CheckoutPending]: 'light',

      [CustomerPaymentStatus.CheckoutCancelled]: 'warning',
      [CustomerPaymentStatus.CheckoutRefused]: 'warning',

      [CustomerPaymentStatus.PaymentFailed]: 'danger',
      [CustomerPaymentStatus.CheckoutError]: 'danger',

      [CustomerPaymentStatus.PaymentAuthorised]: 'success',
      [CustomerPaymentStatus.PaymentSuccessful]: 'success',
    };

    return (
      <Badge bg={variants[status]} className={classes}>
        <DataDefinitionsEnumValue enumName={'CustomerPaymentStatus'} keyName={status} />
      </Badge>
    );
  }
}
