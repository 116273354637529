import { ClientBasketEntity, ClientContractRevisionEntity, ClientCustomerEntity, ClientPricingEntity } from '@edgebox/api-rest-client';
import { BasketStatus, CustomerPaymentStatus, CustomerVatValidationStatus, UserType } from '@edgebox/data-definitions';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/cjs/Alert';
import Button from 'react-bootstrap/cjs/Button';
import Container from 'react-bootstrap/Container';
import {
  ApiComponent,
  Checkout,
  DataDefinitionsEnumValue,
  IApiComponentState,
  IItemProps,
  RequestReason,
  SyncCoreDataDefinitionsEnumValue,
} from '../../common/index';
import { TERMS_AND_CONDITIONS_URL } from '../../constants';
import { ButtonCheckbox } from '@edgebox/react-components';
import { ExternalLink } from '@edgebox/react-components';
import { Right } from '@edgebox/react-components';
import { Throbber } from '@edgebox/react-components';
import { Navigate, NavLink } from 'react-router-dom';
import { IIdParamProp, withIdParam } from '../RouterHelper';
import { Basket } from '../Shared/Basket';
import { ContentBox } from '../Shared/ContentBox';
import { ContainerWithImage } from '../ContainerWithImage';
import WaitingForProposal from '../../images/undraw_Appreciation_re_p6rl.svg';
import { HeadlineWithBreadcrumbNavigation } from '../../common/components/BreadcrumbNavigation';
import { IAppContextProp, withAppContext } from '../../common/contexts/AppContext';

interface IExternalProps {}
interface IProps extends JSX.IntrinsicAttributes, IExternalProps, IIdParamProp, IAppContextProp {}

enum SavingStatus {
  None,
  SavingBasket,
  SavedBasket,
  Purchasing,
  Purchased,
}

interface IState extends IApiComponentState {
  editingRole?: number;
  saving: SavingStatus;
  basket?: ClientBasketEntity;
  pricing?: ClientPricingEntity;
  customer?: ClientCustomerEntity;
  contractRevision?: ClientContractRevisionEntity;
  paid?: boolean;
  agreeToTerms?: boolean;
  last4CardDigits?: string;

  newReference?: string;
  enterReference?: boolean;
  savingReference?: boolean;
}

class CheckoutPageComponent extends ApiComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props, {
      editingRole: 0,
      saving: SavingStatus.None,
    });

    this.purchase = this.wrapApiCallFunction(this.purchase.bind(this), RequestReason.Save);
  }

  async load(): Promise<Partial<IState>> {
    const basket = await this.api.billing.baskets.item(this.props.id);
    const pricing = await basket.pricing?.get();

    const customer = await this.getCurrentCustomer();

    const isInternal = this.api.currentUser!.type === UserType.Internal;

    let last4CardDigits: string | undefined = undefined;
    try {
      const paymentMethod = isInternal ? undefined : await this.api.integrationStripe.stripe.getPaymentMethod();
      last4CardDigits = paymentMethod?.last4CardDigits || undefined;
    } catch (e) {}

    let contractRevision: ClientContractRevisionEntity | undefined = undefined;
    if (basket.contract?.getId()) {
      const mostRecent = await this.api.billing.contractRevisions.getMostRecentForContract(basket.contract.getId());
      contractRevision = mostRecent.current;
    }

    return {
      last4CardDigits,
      contractRevision,
      basket,
      pricing,
      customer,
    };
  }

  async purchase() {
    this.setState({
      saving: SavingStatus.Purchasing,
    });

    const basket = await this.api.billing.baskets.purchase(this.state.basket!.id);

    // Update local cache
    await this.api.billing.contracts.item(basket.contract!.getId(), true);

    this.setState({
      saving: SavingStatus.Purchased,
      basket,
    });

    this.props.appContext.refreshContracts?.();
  }

  render() {
    const {
      saving,
      customer,
      paid,
      basket,
      agreeToTerms,
      last4CardDigits,
      pricing,
      enterReference,
      savingReference,
      newReference,
      contractRevision,
    } = this.state;

    if (!customer || !basket) {
      return this.renderRequest();
    }

    if (basket.status === BasketStatus.Requested || !pricing) {
      return (
        <ContainerWithImage title="Thanks for your request!" image={WaitingForProposal}>
          <Alert variant="success">
            We are crunching the numbers and will provide you with a proposal soon. This process usually takes around 24h to complete. If
            you have any questions in the meantime, don't hesitate to contact us!
          </Alert>
        </ContainerWithImage>
      );
    }

    if (saving === SavingStatus.Purchased) {
      return <Navigate to={`/subscriptions/${basket.contract!.getId()}?purchased=yes`} />;
    }

    const referenceRequired = customer.requiresPurchaseOrder;
    const saveReference = (
      <>
        <Modal show={enterReference} scrollable onHide={() => this.setState({ enterReference: undefined })}>
          <Modal.Header closeButton>
            <Modal.Title>Set reference / PO number{referenceRequired && <span className="text-danger"> *</span>}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <Form.Control
                  id="customerReference"
                  type="text"
                  value={newReference}
                  onChange={(e) => {
                    this.setState({ newReference: e.target.value });
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Right>
              <Button
                variant="primary"
                disabled={savingReference || (referenceRequired && !newReference) || newReference === (basket.customerReference || '')}
                onClick={async () => {
                  try {
                    this.setState({ savingReference: true });
                    const changed = new ClientBasketEntity({ ...basket, customerReference: newReference });
                    const update = await this.api.billing.baskets.update(changed);
                    this.setState({ basket: update, enterReference: false, newReference: undefined });
                  } finally {
                    this.setState({ savingReference: false });
                  }
                }}
              >
                Save
              </Button>
            </Right>
          </Modal.Footer>
        </Modal>
        <div>
          <h4>Reference {referenceRequired && <span className="text-danger"> *</span>}</h4>
          <div>
            {basket.customerReference || <em>(none)</em>}{' '}
            <Button variant="link" onClick={() => this.setState({ enterReference: true, newReference: basket.customerReference || '' })}>
              change
            </Button>
          </div>
          <div className="text-muted">
            <em>
              <small>E.g. your purchase order number.</small>
            </em>
          </div>
        </div>
      </>
    );

    const secureCheckout = (
      <Alert variant={'light'} className={'mb-0 pt-1 pb-1'}>
        <FontAwesomeIcon icon={faLock} className={'me-2'} />
        100% PCL compliant and secure checkout via Stripe.
      </Alert>
    );

    let checkout: React.ReactNode = undefined;
    if (paid) {
      // TODO: Show "request" instead (so that any error is shown).
      checkout = <Throbber />;
    }

    const header = (
      <Row>
        <Col xs={3}>
          <h4>Billing address</h4>
          <p className={'mb-5'}>
            {customer.billingAddress?.name}
            <br />
            {customer.billingAddress?.name2 ? (
              <>
                {customer.billingAddress?.name2}
                <br />
              </>
            ) : undefined}
            {customer.billingAddress?.street}
            <br />
            {customer.billingAddress?.zip} {customer.billingAddress?.city}
            <br />
            <DataDefinitionsEnumValue enumName={'Country'} keyName={customer.billingAddress!.country} />
            <br />
            {customer.vatId}
          </p>
        </Col>
        <Col xs={3}>{saveReference}</Col>
        <Col xs={3}></Col>
        <Col xs={3}>
          <h4>Provider</h4>
          <p className={'mb-5'}>
            Edge Box GmbH
            <br />
            Am roten Morgen 75
            <br />
            64846 Gross-Zimmern, Germany
            <br />
            Registration number: HRB 94472
          </p>
        </Col>
      </Row>
    );

    if (
      (![CustomerPaymentStatus.PaymentAuthorised, CustomerPaymentStatus.PaymentSuccessful].includes(customer.paymentStatus) ||
        paid === false) &&
      !customer.dontBill &&
      !customer.payManually
    ) {
      checkout = (
        <>
          <ButtonCheckbox className={'mt-3 mb-2'} checked={!!agreeToTerms} onToggle={() => this.setState({ agreeToTerms: !agreeToTerms })}>
            <span className="text-align-left">
              I agree to the{' '}
              <ExternalLink to={TERMS_AND_CONDITIONS_URL}>Terms and Conditions and the Refund and Cancellation policy</ExternalLink>
            </span>
          </ButtonCheckbox>

          <Checkout
            className={'mt-5'}
            disabled={!agreeToTerms}
            onPaid={async () => {
              this.setState({ paid: true });
              await this.purchase();
            }}
          >
            {secureCheckout}
            {paid === false ? (
              <Button variant={'link'} onClick={() => this.setState({ paid: undefined })}>
                Use existing card
              </Button>
            ) : undefined}
          </Checkout>
        </>
      );
    } else {
      checkout = (
        <>
          {customer.dontBill || !customer.vatValidationStatus || customer.vatValidationStatus === CustomerVatValidationStatus.Approved ? (
            <>
              <Right className={'mt-2 mb-2'}>
                <ButtonCheckbox checked={!!agreeToTerms} onToggle={() => this.setState({ agreeToTerms: !agreeToTerms })}>
                  I agree to the{' '}
                  <ExternalLink to={TERMS_AND_CONDITIONS_URL}>Terms and Conditions and the Refund and Cancellation policy</ExternalLink>
                </ButtonCheckbox>
              </Right>

              {customer.dontBill || customer.payManually ? undefined : (
                <Alert variant={'info'} className={'mt-4 mb-4'}>
                  Your existing credit card from the last checkout will be used
                  {last4CardDigits ? ` that ends with ${last4CardDigits}` : undefined}.
                </Alert>
              )}

              <Right className={'mt-1'}>
                {customer.dontBill || customer.payManually ? undefined : (
                  <>
                    {secureCheckout}
                    <Button disabled={!agreeToTerms} variant={'link'} onClick={() => this.setState({ paid: false })}>
                      Change
                    </Button>
                  </>
                )}
                <Button
                  disabled={!agreeToTerms || (referenceRequired && !basket.customerReference)}
                  variant={'primary'}
                  onClick={this.purchase}
                >
                  Buy
                </Button>
              </Right>
            </>
          ) : customer.vatValidationStatus === CustomerVatValidationStatus.Declined ? (
            <Alert variant="danger">
              Validating your VAT ID has failed. Please go to your
              <NavLink to="/account/company"> company profile </NavLink>
              and update your VAT ID or company address to try again.
            </Alert>
          ) : (
            <Alert variant="warning">
              EU law requires us to validate your VAT ID before you can make a purchase. Validation can take up to a day. Please check back
              later.
            </Alert>
          )}
        </>
      );
    }

    return (
      <Container className={'content'}>
        <HeadlineWithBreadcrumbNavigation>
          Purchase{' '}
          <strong>
            Content <SyncCoreDataDefinitionsEnumValue enumName={'Product'} keyName={pricing.product} />{' '}
            <SyncCoreDataDefinitionsEnumValue enumName={'Package'} keyName={pricing.packageType} />
          </strong>
        </HeadlineWithBreadcrumbNavigation>

        <ContentBox className="mt-4 pt-3">
          {header}

          <Basket
            key={basket.id}
            basket={basket}
            onChangeQuantity={
              contractRevision &&
              `/subscriptions/${contractRevision.contract.getId()}/revisions/${
                contractRevision.id
              }/upgrade?pricing=${basket.pricing.getId()}&cycle=${basket.cycle}`
            }
          />

          <Row>
            <Col>{checkout}</Col>
          </Row>
        </ContentBox>
      </Container>
    );
  }
}

export const CheckoutPage = withIdParam<{}>(withAppContext(CheckoutPageComponent));
