import { ClientContractDraft, ClientContractEntity, ClientContractRevisionEntity, ClientCustomerEntity } from '@edgebox/api-rest-client';
import { DateTimeFormat } from '@edgebox/data-definition-kit';
import { ISerializedContractProperties, UserType } from '@edgebox/data-definitions';
import { EditButton, FormField, LeftRightH2 } from '@edgebox/react-components';
import { Package } from '@edgebox/sync-core-data-definitions';
import React, { PropsWithChildren } from 'react';
import { Alert } from 'react-bootstrap';
import { EditMode, EntityContext } from '../../../contexts/EntityContext';
import { EntityForm, entityFormWithValidation, IEntityFormProps, IEntityFormState } from '../../Form/EntityForm';
import { EntityFormRow } from '../../Form/EntityFormRow';
import { FormatDateTime } from '../../Localization/FormatDateTime';
import { CustomerSelector } from '../Selectors/CustomerSelector';
import { ViewContractName } from '../Views/ViewContractName';

interface IProps<Entity extends ClientContractDraft> extends IEntityFormProps<Entity, ISerializedContractProperties>, PropsWithChildren {
  onSave: (entity: Entity) => Promise<void>;
  className?: string;
  mode: 'view-full' | 'edit-full' | 'create' | 'view-full-backend';
}

const EDIT_MODES = {
  'view-full': EditMode.None,
  'view-full-backend': EditMode.None,
  'edit-full': EditMode.Full,
  create: EditMode.Full,
};

interface IState<Entity extends ClientContractDraft> extends IEntityFormState<Entity> {
  selectedCustomer?: ClientCustomerEntity;
  currentRevision?: ClientContractRevisionEntity;
}

const FormWithValidation = entityFormWithValidation<ISerializedContractProperties, ClientContractDraft>(
  ClientContractDraft,
  ClientContractEntity as any
);

export class ContractForm<Entity extends ClientContractDraft = ClientContractDraft> extends EntityForm<
  Entity,
  ISerializedContractProperties,
  IProps<Entity>,
  IState<Entity>
> {
  constructor(props: IProps<Entity>) {
    super(props, {
      edit: EDIT_MODES[props.mode],
    });
  }

  async load(): Promise<Partial<IState<Entity>>> {
    const { entity } = this.props;

    const selectedCustomer = await entity.customer?.get();
    const revisions =
      entity instanceof ClientContractEntity && entity.id
        ? await this.api.billing.contractRevisions.getMostRecentForContract(entity.id)
        : undefined;

    return {
      selectedCustomer,
      currentRevision: revisions?.current,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps<Entity>>, prevState: Readonly<IState<Entity>>, snapshot?: any): void {
    if (this.props.mode !== prevProps.mode) {
      this.setState({
        edit: EDIT_MODES[this.props.mode],
      });
    }
  }

  render(): React.ReactElement {
    const { mode, children, onToggleEdit } = this.props;
    const { selectedCustomer, currentRevision } = this.state;

    const renderBackendProperties = this.api.currentUser!.type === UserType.Internal;

    const createNew = mode === 'create';
    const editing = createNew || mode === 'edit-full';

    return (
      <EntityContext.Provider value={this.entityContext}>
        <FormWithValidation
          {...(this.formProperties() as any)}
          apiComponent={this}
          initialValues={this.props.entity}
          labels={'above'}
          validate={(values) => {
            if (values.autoScaleLicenses && values.minProdSites !== undefined && values.maxProdSites !== undefined) {
              if (values.minProdSites > values.maxProdSites) {
                return {
                  maxProdSites: "Can't be smaller than the minimum number of sites.",
                };
              }
            }
          }}
          onSubmit={async (draft) => {
            this.setState({
              extendProperties: [],
            });

            await this.props.onSave(draft as Entity);
          }}
        >
          {({ values, setValue }) => (
            <>
              {this.props.name || (
                <LeftRightH2
                  left={
                    this.props.entity instanceof ClientContractEntity ? <ViewContractName entity={this.props.entity} /> : 'New contract'
                  }
                  right={onToggleEdit ? <EditButton onClick={onToggleEdit} /> : undefined}
                />
              )}

              {editing && (
                <EntityFormRow<ClientContractDraft>
                  name={'name'}
                  label={'Name'}
                  edit={() => <FormField name={'name'} label={'Customize name...'} type={'text'} noRow />}
                />
              )}

              {renderBackendProperties && (
                <EntityFormRow<ClientContractDraft>
                  name={'customer'}
                  label={'Customer'}
                  view={selectedCustomer && (() => <span>{selectedCustomer.name}</span>)}
                  edit={
                    createNew
                      ? () => (
                          <CustomerSelector
                            value={selectedCustomer}
                            onRemove={() => setValue('customer', undefined)}
                            onSelected={(selectedCustomer) => {
                              setValue('customer.id' as 'customer', selectedCustomer.id);
                              this.setState({ selectedCustomer });
                            }}
                          />
                        )
                      : undefined
                  }
                />
              )}

              <EntityFormRow<ClientContractDraft>
                name={'startDate'}
                label={'Start'}
                view={() => <FormatDateTime date={values.startDate} format={DateTimeFormat.Date} />}
                edit={renderBackendProperties ? () => <FormField name={'startDate'} label={'Start'} type={'date'} noRow /> : undefined}
              />

              <EntityFormRow<ClientContractDraft>
                name={'endDate'}
                label={'End'}
                view={() => (values.endDate ? <FormatDateTime date={values.endDate} format={DateTimeFormat.Date} /> : 'none')}
                edit={createNew ? () => <FormField name={'endDate'} label={'End'} type={'date'} noRow /> : undefined}
              />

              {renderBackendProperties && (
                <EntityFormRow<ClientContractDraft>
                  name={'shortCode'}
                  label={'Short code'}
                  edit={() => <FormField name={'shortCode'} label={'Short code'} type={'text'} noRow />}
                  view={values.shortCode ? () => values.shortCode : undefined}
                />
              )}

              {values.uuid && <EntityFormRow<ClientContractDraft> name={'uuid' as any} label={'Token'} view={() => values.uuid} />}

              {createNew ||
                (currentRevision && currentRevision.packageType !== Package.Trial && (
                  <EntityFormRow<ClientContractDraft>
                    name={'renewAutomatically'}
                    required={false}
                    label={'Automatically renew'}
                    edit={() => (
                      <FormField
                        name={'renewAutomatically'}
                        label={'Automatically renew'}
                        type={'checkbox'}
                        noRow
                        description={
                          "Contracts that are set to automatically renew will be extended for the same period after the current contract expires. You can cancel the renewal up to one hour before it's due. The number of licenses will be decreased automatically if fewer sites are connected at that time."
                        }
                      />
                    )}
                    view={() => (values.renewAutomatically ? 'yes' : 'no')}
                  />
                ))}

              <EntityFormRow<ClientContractDraft>
                name={'autoScaleLicenses'}
                required={false}
                label={'Auto scale licenses'}
                edit={() => (
                  <FormField
                    name={'autoScaleLicenses'}
                    label={'Auto scale licenses'}
                    type={'checkbox'}
                    noRow
                    description={
                      'If enabled, your bill will increase whenever you connect a new site. Otherwise you have to purchase the licenses manually before you can connect a new site.'
                    }
                  />
                )}
                view={() => (values.autoScaleLicenses ? 'yes' : 'no')}
              />

              {values.autoScaleLicenses && (
                <EntityFormRow<ClientContractDraft>
                  name={'minProdSites'}
                  label={'Min licensed sites'}
                  edit={() => <FormField name={'minProdSites'} label={'Min licensed sites'} type={'number'} noRow />}
                  view={values.minProdSites ? () => values.minProdSites : undefined}
                />
              )}

              {values.autoScaleLicenses && (
                <EntityFormRow<ClientContractDraft>
                  name={'maxProdSites'}
                  label={'Max licensed sites'}
                  edit={() => (
                    <FormField
                      name={'maxProdSites'}
                      label={'Max licensed sites'}
                      type={'number'}
                      noRow
                      min={values.currentProductionSites}
                    />
                  )}
                  view={values.maxProdSites ? () => values.maxProdSites : undefined}
                />
              )}

              <EntityFormRow<ClientContractDraft>
                name={'licensedSites'}
                label={'Licensed sites'}
                edit={
                  mode === 'create' ? () => <FormField name={'licensedSites'} label={'Licensed sites'} type={'number'} noRow /> : undefined
                }
                view={() => values.licensedSites || 0}
              />

              <EntityFormRow<ClientContractDraft>
                name={'currentProductionSites'}
                label={'Active sites'}
                view={() => (
                  <div>
                    {values.currentProductionSites ? <div>{values.currentProductionSites}x production</div> : undefined}
                    {values.currentStagingSites ? <div>{values.currentStagingSites}x staging</div> : undefined}
                    {values.currentTestingSites ? <div>{values.currentTestingSites}x testing</div> : undefined}
                    {!values.currentProductionSites && !values.currentStagingSites && !values.currentTestingSites ? 'none' : undefined}
                  </div>
                )}
              />

              <EntityFormRow<ClientContractDraft>
                name={'updatesPerMonthNotificationThreshold'}
                required={false}
                label={'Update overage notification'}
                edit={() => (
                  <FormField
                    name={'updatesPerMonthNotificationThreshold'}
                    label={'Max expected updates / month'}
                    type={'number'}
                    noRow
                    description={
                      "If set, you will receive notifications when your expected usage for the current month exceeds 100% based on this number of updates. Defaults to the contract's limit."
                    }
                  />
                )}
                view={
                  (values as unknown as ClientContractEntity).updatesPerMonthNotificationThreshold
                    ? () => (
                        <em>
                          Notify when the expected usage exceeds{' '}
                          {(values as unknown as ClientContractEntity).updatesPerMonthNotificationThreshold} updates in the current month.
                        </em>
                      )
                    : undefined
                }
              />

              {children}
            </>
          )}
        </FormWithValidation>
      </EntityContext.Provider>
    );
  }
}
