import { ClientCustomerDraft, ClientCustomerEntity } from '@edgebox/api-rest-client';
import {
  CustomerVatValidationStatus,
  DataDefinitionsEnumTranslator,
  EUCountries,
  isCountryPartOfEU,
  ISerializedCustomerProperties,
  UserType,
} from '@edgebox/data-definitions';
import { FormField, SelectField } from '@edgebox/react-components';
import React, { PropsWithChildren } from 'react';
import { Badge, Button } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { EditMode, EntityContext } from '../../../contexts/EntityContext';
import { EntityForm, entityFormWithValidation, IEntityFormProps, IEntityFormState } from '../../Form/EntityForm';
import { EntityFormRow } from '../../Form/EntityFormRow';
import { EditName } from '../../Form/Properties/EditName';
import { DataDefinitionsEnumValue } from '../../Localization/DataDefinitionsEnumValue';
import { CustomerStatusBadge } from '../Badges/CustomerStatusBadge';
import { VatValidationStatusBadge } from '../Badges/VatValidationStatusBadge';

interface IProps<Entity extends ClientCustomerDraft> extends IEntityFormProps<Entity, ISerializedCustomerProperties>, PropsWithChildren {
  onSave: (entity: Entity) => Promise<void>;
  className?: string;
  mode: 'view-full' | 'edit-full' | 'registration' | 'view-billing-address' | 'view-full-backend';
}

const EDIT_MODES = {
  'view-full': EditMode.None,
  'view-full-backend': EditMode.None,
  'view-billing-address': EditMode.None,
  'edit-full': EditMode.Full,
  registration: EditMode.Full,
};

interface IState<Entity extends ClientCustomerDraft> extends IEntityFormState<Entity> {
  submittingVatValidationStatus?: boolean;
}

const FormWithValidation = entityFormWithValidation<ISerializedCustomerProperties, ClientCustomerDraft>(
  ClientCustomerDraft,
  ClientCustomerEntity as any
);

export class CustomerForm<Entity extends ClientCustomerDraft = ClientCustomerDraft> extends EntityForm<
  Entity,
  ISerializedCustomerProperties,
  IProps<Entity>,
  IState<Entity>
> {
  constructor(props: IProps<Entity>) {
    super(props, {
      edit: EDIT_MODES[props.mode],
    });
  }

  async load(): Promise<Partial<IState<Entity>>> {
    return {};
  }

  componentDidUpdate(prevProps: Readonly<IProps<Entity>>, prevState: Readonly<IState<Entity>>, snapshot?: any): void {
    if (this.props.mode !== prevProps.mode) {
      this.setState({
        edit: EDIT_MODES[this.props.mode],
      });
    }
  }

  render(): React.ReactElement {
    const { submittingVatValidationStatus } = this.state;
    const { mode, children } = this.props;

    const renderBackendProperties = this.api.currentUser!.type === UserType.Internal && mode !== 'view-billing-address';
    const createNew = mode === 'registration';
    const renderAdditionalProps = mode !== 'view-billing-address' && mode !== 'registration';

    return (
      <EntityContext.Provider value={this.entityContext}>
        <FormWithValidation
          {...(this.formProperties() as any)}
          apiComponent={this}
          initialValues={this.props.entity}
          labels={mode === 'registration' ? 'none' : 'above'}
          validate={(values) => {
            if (values.billingAddress?.country && isCountryPartOfEU(values.billingAddress.country)) {
              if (!values.vatId) {
                return {
                  vatId: 'Please provide your VAT ID.',
                };
              }
            }
          }}
          onSubmit={async (draft) => {
            this.setState({
              extendProperties: [],
            });

            await this.props.onSave(draft as Entity);
          }}
        >
          {({ values, setValue }) => (
            <>
              {this.props.name || (
                <EditName value={values.name} showLabel={true} label={'Profile name'} onToggleEdit={this.props.onToggleEdit} />
              )}

              {renderBackendProperties && (
                <EntityFormRow<ClientCustomerDraft>
                  name={'dontBill'}
                  label={'Billing status'}
                  view={() => (values.dontBill ? <Badge bg={'danger'}>Don't bill</Badge> : <Badge bg={'light'}>Bill</Badge>)}
                  edit={() => <FormField name={'dontBill'} label={"Don't bill"} type={'checkbox'} noRow />}
                />
              )}

              {renderBackendProperties && (
                <EntityFormRow<ClientCustomerDraft>
                  name={'requiresPurchaseOrder'}
                  label={'Purchase order'}
                  view={() =>
                    values.requiresPurchaseOrder ? <Badge bg={'danger'}>Required</Badge> : <Badge bg={'light'}>No requirement</Badge>
                  }
                  edit={() => <FormField name={'requiresPurchaseOrder'} label={'Required'} type={'checkbox'} noRow />}
                />
              )}

              {renderBackendProperties && (
                <EntityFormRow<ClientCustomerDraft>
                  name={'payManually'}
                  label={'Pay manually'}
                  view={() => (values.payManually ? <Badge bg={'danger'}>Yes</Badge> : <Badge bg={'light'}>No</Badge>)}
                  edit={() => <FormField name={'payManually'} label={'Yes'} type={'checkbox'} noRow />}
                />
              )}

              {renderBackendProperties && values.payManually && (
                <EntityFormRow<ClientCustomerDraft>
                  name={'paymentDaysNet'}
                  label={'Payment days net'}
                  view={() => <Badge bg={'light'}>{values.paymentDaysNet} days</Badge>}
                  edit={() => <FormField name={'paymentDaysNet'} label={'Due within'} type={'number'} min={1} suffix={'days'} noRow />}
                />
              )}

              {renderBackendProperties && (
                <EntityFormRow<ClientCustomerDraft>
                  name={'status'}
                  label={'Status'}
                  view={() => <CustomerStatusBadge status={values.status} />}
                />
              )}

              {renderAdditionalProps && (
                <EntityFormRow<ClientCustomerDraft>
                  name={'vatValidationStatus'}
                  label={'Account validation'}
                  view={() => (
                    <>
                      <VatValidationStatusBadge status={values.vatValidationStatus} />{' '}
                      {renderBackendProperties && values.vatValidationStatus === CustomerVatValidationStatus.ManualValidationPending && (
                        <>
                          <Button
                            disabled={submittingVatValidationStatus}
                            variant={'primary'}
                            onClick={async () => {
                              this.setState({
                                submittingVatValidationStatus: true,
                              });
                              await this.api.billing.customers.manualUpdateVatIdStatus(
                                (this.props.entity as any).id,
                                CustomerVatValidationStatus.Approved
                              );
                              setValue('vatValidationStatus', CustomerVatValidationStatus.Approved);
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            disabled={submittingVatValidationStatus}
                            variant={'danger'}
                            onClick={async () => {
                              this.setState({
                                submittingVatValidationStatus: true,
                              });
                              await this.api.billing.customers.manualUpdateVatIdStatus(
                                (this.props.entity as any).id,
                                CustomerVatValidationStatus.Declined
                              );
                              setValue('vatValidationStatus', CustomerVatValidationStatus.Declined);
                            }}
                          >
                            Decline
                          </Button>
                        </>
                      )}
                    </>
                  )}
                />
              )}

              {renderBackendProperties && (
                <EntityFormRow<ClientCustomerDraft>
                  name={'internalNotes'}
                  label={'INTERNAL notes'}
                  edit={() => <FormField name={'internalNotes'} label={'E.g. special contract terms...'} rows={4} noRow />}
                  view={values.internalNotes ? () => <div style={{ whiteSpace: 'pre-line' }}>{values.internalNotes}</div> : undefined}
                />
              )}

              <h4>Billing address</h4>

              <EntityFormRow<ClientCustomerDraft>
                name={'billingAddress.name' as 'billingAddress'}
                label={'Entity legal name'}
                edit={() => <FormField name={'billingAddress.name'} label={'Entity legal name'} type={'text'} noRow />}
                view={() => values.billingAddress?.name}
              />

              <EntityFormRow<ClientCustomerDraft>
                name={'billingAddress.country' as 'billingAddress'}
                label={'Country'}
                edit={() => (
                  <SelectField
                    name={'billingAddress.country'}
                    disabled={!renderBackendProperties && !createNew}
                    label={'Country'}
                    options={DataDefinitionsEnumTranslator.translateEnum('Country')}
                    noRow
                    sort
                  />
                )}
                view={
                  values.billingAddress
                    ? () => <DataDefinitionsEnumValue enumName={'Country'} keyName={values.billingAddress!.country} />
                    : undefined
                }
              />

              <EntityFormRow<ClientCustomerDraft>
                name={'billingAddress.city' as 'billingAddress'}
                label={'City'}
                edit={() => (
                  <Form.Group as={Row} className={'mb-0'}>
                    <Col xs={4} className={'ps-0'}>
                      <FormField name={'billingAddress.zip'} label={'ZIP code'} type={'text'} noRow />
                    </Col>
                    <Col xs={8} className={'pe-0'}>
                      <FormField name={'billingAddress.city'} label={'City'} type={'text'} noRow />
                    </Col>
                  </Form.Group>
                )}
                view={
                  values.billingAddress?.zip && values.billingAddress?.city
                    ? () => `${values.billingAddress?.zip} ${values.billingAddress?.city}`
                    : undefined
                }
              />

              <EntityFormRow<ClientCustomerDraft>
                name={'billingAddress.street' as 'billingAddress'}
                label={'Street + number'}
                edit={() => <FormField name={'billingAddress.street'} label={'Street + number'} type={'text'} noRow />}
                view={values.billingAddress?.street ? () => values.billingAddress?.street : undefined}
              />

              <EntityFormRow<ClientCustomerDraft>
                name={'vatId'}
                label={'VAT ID'}
                edit={() => <FormField name={'vatId'} label={'VAT or tax ID'} type={'text'} noRow />}
                view={values.vatId ? () => values.vatId : undefined}
                required={!!values.billingAddress?.country && isCountryPartOfEU(values.billingAddress.country)}
              />

              <EntityFormRow<ClientCustomerDraft>
                name={'sendInvoicesToEmail'}
                label={'Send invoices to'}
                edit={() => <FormField name={'sendInvoicesToEmail'} label={'Billing email'} type={'email'} noRow />}
                view={values.sendInvoicesToEmail ? () => values.sendInvoicesToEmail : undefined}
              />

              {children}
            </>
          )}
        </FormWithValidation>
      </EntityContext.Provider>
    );
  }
}
