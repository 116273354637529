import { DiscountStatus } from '@edgebox/data-definitions';
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { DataDefinitionsEnumValue } from '../../Localization/DataDefinitionsEnumValue';

interface IProps {
  status: DiscountStatus;
  size?: 'small';
}

export class DiscountStatusBadge extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { status, size } = this.props;

    const classes = size ? 'small' : '';

    const variants = {
      [DiscountStatus.Active]: 'light',
      [DiscountStatus.Retired]: 'danger',
    };

    return (
      <Badge bg={variants[status]} className={classes}>
        <DataDefinitionsEnumValue enumName={'DiscountStatus'} keyName={status} />
      </Badge>
    );
  }
}
