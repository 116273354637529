import React, { PropsWithChildren } from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import { ContentBox } from './Shared/ContentBox';

interface IProps extends PropsWithChildren {
  title: React.ReactNode;
  image: string;
}
interface IState {}

export class ContainerWithImage extends React.Component<IProps, IState> {
  render() {
    const { children, image, title } = this.props;

    return (
      <Container className="mt-3">
        <h1>{title}</h1>

        <ContentBox>
          <Row>
            <Col xs={6} className={'p-5'}>
              <Image src={image} width={'100%'} />
            </Col>
            <Col className={'p-5'}>{children}</Col>
          </Row>
        </ContentBox>
      </Container>
    );
  }
}
