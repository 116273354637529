import { InvoiceStatus } from '@edgebox/data-definitions';
import React from 'react';
import Badge from 'react-bootstrap/cjs/Badge';
import Container from 'react-bootstrap/cjs/Container';
import Tab from 'react-bootstrap/cjs/Tab';
import Tabs from 'react-bootstrap/cjs/Tabs';
import { ApiComponent, IApiComponentState } from '../../../common/index';
import { INavigateProp, IParamsProp, withParamsAndNavigate } from '../../RouterHelper';
import { InvoiceListByStatus } from './InvoiceListByStatus';

export interface IRouteProps extends Record<string, string | undefined> {
  status?: string;
}
export interface IExternalProps {}
export interface IProps extends IExternalProps, IParamsProp<IRouteProps>, INavigateProp {}

interface IState extends IApiComponentState {
  countNew?: number;
  countApproved?: number;
  countPaid?: number;
  countProblematic?: number;
  countCancelled?: number;
  lastStatusChange?: number;
  activeTab?: string;
}

class BackendInvoiceListComponent extends ApiComponent<IProps, IState> {
  async load(): Promise<Partial<IState>> {
    return {
      activeTab: this.props.params.status || 'new',
    };
  }

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<IState>, snapshot?: any) {
    if (this.state.activeTab !== prevState.activeTab) {
      if (this.props.params.status !== this.state.activeTab) {
        this.props.navigate(`/backend/billing/invoices/${this.state.activeTab}`);
        return;
      }
    }

    if (this.props.params.status && this.props.params.status !== this.state.activeTab) {
      this.setState({
        activeTab: this.props.params.status,
      });
      return;
    }

    if (
      !this.state.activeTab &&
      this.state.countNew !== undefined &&
      this.state.countApproved !== undefined &&
      this.state.countPaid !== undefined
    ) {
      this.setState({
        activeTab: this.state.countNew ? 'new' : this.state.countApproved ? 'approved' : 'paid',
      });
    }
  }

  render() {
    const { countNew, countApproved, countPaid, countProblematic, countCancelled, lastStatusChange, activeTab } = this.state;

    if (!activeTab) {
      return this.renderRequest();
    }

    const title = (name: string, count?: number, attention?: boolean) => {
      if (count === undefined) {
        return name;
      }

      return (
        <>
          {name} <Badge bg={count === 0 ? 'light' : attention ? 'danger' : 'dark'}>{count}</Badge>
        </>
      );
    };

    const onChange = () => {
      this.setState({ lastStatusChange: Date.now() });
    };

    return (
      <Container className={'content'}>
        <h1>Invoices</h1>

        <Tabs activeKey={activeTab} id="invoices-tabs" onSelect={(activeTab) => this.setState({ activeTab: activeTab || undefined })}>
          <Tab eventKey="new" title={title('New', countNew, true)}>
            <InvoiceListByStatus
              key={lastStatusChange}
              onStatusChange={onChange}
              onTotalNumberOfItems={(countNew) => this.setState({ countNew })}
              status={[InvoiceStatus.Draft, InvoiceStatus.New, InvoiceStatus.Saved, InvoiceStatus.FeedbackRequired]}
            />
          </Tab>
          <Tab eventKey="approved" title={title('Approved', countApproved)}>
            <InvoiceListByStatus
              key={lastStatusChange}
              onStatusChange={onChange}
              onTotalNumberOfItems={(countApproved) => this.setState({ countApproved })}
              status={[InvoiceStatus.Approved, InvoiceStatus.ProcessingPayment]}
            />
          </Tab>
          <Tab eventKey="paid" title={title('Paid', countPaid)}>
            <InvoiceListByStatus
              key={lastStatusChange}
              onStatusChange={onChange}
              onTotalNumberOfItems={(countPaid) => this.setState({ countPaid })}
              status={[InvoiceStatus.Paid]}
            />
          </Tab>
          <Tab eventKey="problematic" title={title('Problematic', countProblematic, true)}>
            <InvoiceListByStatus
              key={lastStatusChange}
              onStatusChange={onChange}
              onTotalNumberOfItems={(countProblematic) => this.setState({ countProblematic })}
              status={[InvoiceStatus.RetryPayment, InvoiceStatus.PaymentFailed, InvoiceStatus.PaymentDispute]}
            />
          </Tab>
          <Tab eventKey="cancelled" title={title('Cancelled', countCancelled, false)}>
            <InvoiceListByStatus
              key={lastStatusChange}
              onStatusChange={onChange}
              onTotalNumberOfItems={(countCancelled) => this.setState({ countCancelled })}
              status={[InvoiceStatus.Cancelled]}
            />
          </Tab>
        </Tabs>
      </Container>
    );
  }
}

export const BackendInvoiceList = withParamsAndNavigate<IExternalProps, IRouteProps>(BackendInvoiceListComponent);
