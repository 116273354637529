import { ClientSiteEntity } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import React from 'react';
import { Link } from 'react-router-dom';
import { ApiComponent, IApiComponentState } from '../../../services/ApiComponent';

interface IProps {
  entityId: InternalId;
  backend?: boolean;
}

interface IState extends IApiComponentState {
  entity?: ClientSiteEntity;
}

export class SiteLink extends ApiComponent<IProps, IState> {
  async load(): Promise<Partial<IState>> {
    const entity = await this.api.billing.sites.item(this.props.entityId);

    return {
      entity,
    };
  }

  render(): React.ReactElement {
    const { backend } = this.props;
    const { entity } = this.state;

    if (!entity) {
      return this.renderRequest();
    }

    return <Link to={backend ? `/backend/syndication/sites/${entity.id}` : `/sites/${entity.id}`}>{entity.name}</Link>;
  }
}
