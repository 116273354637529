import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { ContentBox } from './Shared/ContentBox';
import NotFoundImage from '../images/undraw_not_found_60pq.svg';

export class NotFoundPage extends React.Component<{}, {}> {
  render() {
    return (
      <Container>
        <h1>Page not found</h1>

        <ContentBox>
          <Row>
            <Col xs={6} className={'p-5'}>
              <Image src={NotFoundImage} width={'100%'} />
            </Col>
            <Col className={'p-5'}>We are sorry, the page you are looking for has moved or doesn't exist any longer.</Col>
          </Row>
        </ContentBox>
      </Container>
    );
  }
}
