import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren {
  icon: IconProp;
}

export function Nugget(props: IProps) {
  return (
    <span className="bg-light d-inline-block me-2" style={{ borderRadius: '50px', height: '1.7em', opacity: '85%' }}>
      <span
        className="bg-dark text-light text-center pt-1 d-inline-block h-100"
        style={{ borderRadius: '50px', aspectRatio: '1 / 1', opacity: '80%' }}
      >
        <FontAwesomeIcon icon={props.icon} style={{ zoom: 0.9 }} />
      </span>
      <span className="ms-0 me-2 p-1 pt-2 text-dark">{props.children}</span>
    </span>
  );
}
