import React from 'react';
import logo from '../resources/CS-Logo-Positive-(Primary).png';

interface IProps {
  height?: number;
  className?: string;
}

export function Logo({ height, className }: IProps) {
  return <img alt={'Logo'} src={logo} height={height} className={className} />;
}
