import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React, { PropsWithChildren } from 'react';
import { CircleIcon } from './CircleIcon';

interface IProps extends PropsWithChildren {
  icon: IconProp;
}

export class IconSummaryItem extends React.Component<IProps, {}> {
  render() {
    const { icon, children } = this.props;

    return (
      <div className={'d-flex flex-columns align-items-top mt-1 mb-1'}>
        <CircleIcon withBackground icon={icon} size={'sm'} />
        <div className={'flex-grow-1 d-flex align-items-center ps-3'}>
          <div>{children}</div>
        </div>
      </div>
    );
  }
}
