import React from 'react';
import { PagedProjectList } from '../../Shared/PagedProjectList';

export class BackendProjectList extends React.Component {
  async load() {
    return {};
  }

  render() {
    return <PagedProjectList backend />;
  }
}
