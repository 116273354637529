import { ClientSyncCoreEntity } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import React from 'react';
import { Link } from 'react-router-dom';
import { ApiComponent, IApiComponentState } from '../../../services/ApiComponent';
import { SyncCoreType } from '@edgebox/data-definitions';
import { SyncCoreTypeIcon } from '../../../../components/Icons';

interface IProps {
  entityId: InternalId;
  backend?: boolean;
  withIcon?: boolean;
}

interface IState extends IApiComponentState {
  entity?: ClientSyncCoreEntity;
}

export class SyncCoreLink extends ApiComponent<IProps, IState> {
  async load(): Promise<Partial<IState>> {
    const entity = await this.api.syndication.syncCores.item(this.props.entityId);

    return {
      entity,
    };
  }

  render(): React.ReactElement {
    const { backend, withIcon } = this.props;
    const { entity } = this.state;

    if (!entity) {
      return this.renderRequest();
    }

    const icon = withIcon ? <SyncCoreTypeIcon type={entity.type} /> : undefined;

    if (!backend && entity.type === SyncCoreType.SaaS_Prod) {
      return (
        <>
          {icon} {entity.name}
        </>
      );
    }

    return (
      <>
        {icon}
        <Link to={backend ? `/backend/syndication/sync-cores/${entity.id}` : `/sync-cores/${entity.id}`}>{entity.name}</Link>
      </>
    );
  }
}
