import { INamed } from '@edgebox/data-definition-kit';
import React from 'react';
import { FormField, LeftRightH2 } from '@edgebox/react-components';
import { EditButton } from '@edgebox/react-components';
import { EntityFormElement } from '../EntityFormElement';
import { EntityFormRow } from '../EntityFormRow';

interface IProps {
  value?: string;
  onToggleEdit?: () => void;
  showLabel?: boolean;
  help?: React.ReactNode;
  label?: string;
}

interface IState {}

export class EditName extends EntityFormElement<INamed, IProps, IState> {
  render(): React.ReactElement {
    const { value, showLabel, help, label } = this.props;

    return (
      <EntityFormRow<INamed>
        name={'name'}
        label={this.entityContext.isEditing('name') && showLabel ? label || 'Name' : undefined}
        edit={() => <FormField name={'name'} type={'text'} noRow placeholder={'Name'} autoFocus />}
        view={
          value
            ? () => {
                if (this.props.onToggleEdit) {
                  const toggleEditing = () => {
                    if (this.props.onToggleEdit) {
                      this.props.onToggleEdit();
                    }
                  };
                  return <LeftRightH2 left={value} right={<EditButton onClick={toggleEditing} />} />;
                } else {
                  return <h2>{value}</h2>;
                }
              }
            : undefined
        }
        help={help}
      />
    );
  }
}
