import React from 'react';

interface IProps {
  children: string;
}

export class ViewDomain extends React.Component<IProps, {}> {
  render() {
    const { children } = this.props;

    const asObject = new URL(children);

    return asObject.hostname;
  }
}
