import { ClientContractEntity, ClientCustomerEntity } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import { CustomerRole } from '@edgebox/data-definitions';
import React from 'react';
import { Link } from 'react-router-dom';
import { ApiComponent, IApiComponentState } from '../../../services/ApiComponent';
import { ViewContractName } from '../Views/ViewContractName';

interface IProps {
  entityId: InternalId;
  backend?: boolean;
}

interface IState extends IApiComponentState {
  entity?: ClientContractEntity;
  customer?: ClientCustomerEntity;
}

export class ContractLink extends ApiComponent<IProps, IState> {
  async load(): Promise<Partial<IState>> {
    const entity = await this.api.billing.contracts.item(this.props.entityId);
    const customer = await entity?.customer.get();

    return {
      entity,
      customer,
    };
  }

  render(): React.ReactElement {
    const { backend } = this.props;
    const { entity, customer } = this.state;

    if (!entity) {
      return this.renderRequest();
    }

    if (backend) {
      return (
        <Link to={`/backend/billing/contracts/${entity.id}`}>
          <ViewContractName entity={entity} /> for {customer?.name}
        </Link>
      );
    }

    const privileged =
      this.api.currentUser?.customerRoles?.includes(CustomerRole.Owner) ||
      this.api.currentUser?.customerRoles?.includes(CustomerRole.ManageFinances);
    if (!privileged) {
      return <ViewContractName entity={entity} />;
    }

    return (
      <Link to={`/subscriptions/${entity.id}`}>
        <ViewContractName entity={entity} />
      </Link>
    );
  }
}
