import { CustomerRole } from '@edgebox/data-definitions';
import React, { useContext } from 'react';
import { ApiContext } from '../contexts';

export function UserRoleGate<Type>(props: { allowed: CustomerRole[]; children: () => Type }) {
  const api = useContext(ApiContext);

  const roles = api?.currentUser?.customerRoles || [];

  if (props.allowed.find((role) => roles.includes(role))) {
    return props.children();
  }

  return null;
}

export function withUserRoles<Props extends JSX.IntrinsicAttributes>(
  Component: React.ComponentType<Props>,
  allowed: CustomerRole[]
): React.ComponentType<Props> {
  return function WithNavigation(props: Props) {
    return <UserRoleGate allowed={allowed}>{() => <Component {...props} />}</UserRoleGate>;
  };
}
