import { CustomerStatus } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { SyncCoreDataDefinitionsEnumValue } from '../../Localization/SyncCoreDataDefinitionsEnumValue';

interface IProps {
  status: CustomerStatus;
  size?: 'small';
}

export class CustomerStatusBadge extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { status, size } = this.props;

    const classes = size ? 'small' : '';

    const variants = {
      [CustomerStatus.Active]: 'primary',
      [CustomerStatus.Inactive]: 'danger',
    };

    return (
      <Badge bg={variants[status]} className={classes}>
        <SyncCoreDataDefinitionsEnumValue enumName={'CustomerStatus'} keyName={status} />
      </Badge>
    );
  }
}
