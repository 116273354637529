import { ClientCustomerEntity } from '@edgebox/api-rest-client';
import React from 'react';
import { Hierarchy, Group } from '@visx/visx';
import { Right } from '@edgebox/react-components';
import { UserType } from '@edgebox/data-definitions';
import { formatIntegerShort } from '@edgebox/data-definition-kit';
import { ApiComponent, CustomerSelector, IApiComponentState } from '../../../common';
import { IAppContextProp, withAppContext } from '../../../common/contexts/AppContext';
import { propertyChangeReload } from '../../../common/helpers/propertyChangeReload';

interface IProps extends JSX.IntrinsicAttributes, IAppContextProp {
  forCustomer?: ClientCustomerEntity;
}
interface IState extends IApiComponentState {
  data?: IStatItem[];
}

interface IStatItem {
  updates: number;
  freeUpdates: number;
  name: string;
  children?: IStatItem[];
}

const primaryColor = '#009ee3';
const secondaryColor = '#71d3ff';
function colorToHex(color: string): number[] {
  return [parseInt(color.substring(1, 3), 16), parseInt(color.substring(3, 5), 16), parseInt(color.substring(5, 7), 16)];
}
function hexToColor(color: number[]): string {
  return `#${color[0].toString(16).padStart(2, '0')}${color[1].toString(16).padStart(2, '0')}${color[2].toString(16).padStart(2, '0')}`;
}
function getHexColorGradient(color1: number[], color2: number[], weight: number) {
  var w1 = weight;
  var w2 = 1 - w1;
  var rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2),
  ];
  return rgb;
}
function getColorGradient(color1: string, color2: string, weight: number): string {
  return hexToColor(getHexColorGradient(colorToHex(color1), colorToHex(color2), weight));
}

const MIN_RADIUS = 25;

class PackStatsClass extends ApiComponent<IProps, IState> {
  async load() {
    const selectedCustomer = this.props.forCustomer || this.props.appContext.customer;

    const full = await this.api.syndication.usageStats.totalsBySite(0, selectedCustomer?.id);
    const data: IStatItem[] = full.items.map((c) => ({ name: c.site.name, updates: c.updates, freeUpdates: c.freeUpdates }));

    return {
      data,
    };
  }

  render() {
    const { data } = this.state;

    if (!data) {
      return this.renderRequest();
    }

    const root = Hierarchy.hierarchy<IStatItem>({
      name: '',
      children: data || [],
      updates: 0,
      freeUpdates: 0,
    })
      .sum((c) => c.updates + c.freeUpdates)
      .sort((a, b) => b.value! - a.value!);

    const siteCount = root.children?.length || 0;

    const outerSize = siteCount > 200 ? 1_090 : siteCount > 100 ? 850 : 600;
    const padding = 25;
    const innerSize = outerSize - 2 * padding;

    const sumUpdates = root.children?.reduce((a, b) => a + b.value!, 0) || 1;
    // Leave 10% wiggle room for the layouter for padding and error tolerance.
    const areaAll = Math.PI * (innerSize / 2) ** 2 * 0.75;
    // If there are many sites with e.g. 0 updates then we're going to require a
    // lot of additional space just to respect the MIN_RADIUS. So we are checking
    // how much more area we'll be needing and then add that as an additional
    // multiplier to the available area.
    const minArea = Math.PI * MIN_RADIUS ** 2;
    const missingArea =
      (root.children?.reduce((a, b) => {
        const area = (areaAll / sumUpdates) * b.value!;
        return a + (area < minArea ? minArea - area : 0);
      }, 0) || 0) * 1.2;
    const availableArea = missingArea > areaAll ? 0 : areaAll - missingArea;

    function getRadius(updates: number, uncorrected = false) {
      const area = (availableArea / sumUpdates) * updates;
      const original = Math.sqrt(area / Math.PI);
      if (uncorrected) {
        return original;
      }
      return original < MIN_RADIUS ? MIN_RADIUS : original;
    }

    return (
      <>
        <div className="d-flex justify-content-around">
          <svg width={outerSize} height={outerSize} style={{ minWidth: `${outerSize}px`, margin: '10px' }}>
            <Hierarchy.Pack root={root} size={[innerSize, innerSize]} padding={2} radius={(item) => getRadius(item.value!)}>
              {(item) => (
                <Group.Group>
                  {item.descendants().map((node, i) => {
                    // 11 characters per min radius is always within the circle.
                    // 13 characters goes slightly outside he circle but that's
                    // fine with us if it helps users identify the sites.
                    const textMaxLength = Math.max(13, (node.r / MIN_RADIUS) * 11);
                    return (
                      <Group.Group top={node.y + padding} left={node.x + padding} key={`node-${i}`}>
                        <circle
                          r={node.r}
                          fill={
                            node.children
                              ? '#FFFFFF'
                              : getColorGradient(secondaryColor, primaryColor, (node.r - getRadius(node.value!, true)) / MIN_RADIUS)
                          }
                          fillOpacity={node.children ? 0 : 1}
                          strokeWidth={0}
                        />

                        <title>{node.data.name}</title>

                        {!node.children && (
                          <text
                            dy="0.1em"
                            fill={'#000000'}
                            style={{
                              font: '10px sans-serif',
                              textAnchor: 'middle',
                            }}
                          >
                            <tspan x="0" dy="0em">
                              {node.data.name.length > textMaxLength
                                ? node.data.name.substring(0, textMaxLength - 2) + '...'
                                : node.data.name}
                            </tspan>
                            <tspan x="0" dy="1.2em" fill={'#FFFFFF'}>
                              {formatIntegerShort(node.value!)}
                            </tspan>
                          </text>
                        )}
                      </Group.Group>
                    );
                  })}
                </Group.Group>
              )}
            </Hierarchy.Pack>
          </svg>
        </div>
      </>
    );
  }
}

export const PackStats = withAppContext(propertyChangeReload(PackStatsClass, (props) => props.appContext.projectKey));
