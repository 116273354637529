import { ClientContractEntity, ClientContractRevisionEntity } from '@edgebox/api-rest-client';
import { DateTimeFormat } from '@edgebox/data-definition-kit';
import { InfoIcon } from '@edgebox/react-components';
import { Package } from '@edgebox/sync-core-data-definitions';
import moment from 'moment';
import React from 'react';
import { ApiComponent, FormatDateTime, IApiComponentState } from '../../common/index';

interface IProps {
  entity: ClientContractEntity;
  multiLine?: boolean;
}

interface IState extends IApiComponentState {
  currentContractRevision?: ClientContractRevisionEntity;
  nextContractRevision?: ClientContractRevisionEntity;
  loaded?: boolean;
}

export class ViewContractPeriod extends ApiComponent<IProps, IState> {
  async load() {
    const mostRecent = await this.api.billing.contractRevisions.getMostRecentForContract(this.props.entity.id);

    return {
      currentContractRevision: mostRecent.current,
      nextContractRevision: mostRecent.next,
      loaded: true,
    };
  }

  render() {
    const { entity, multiLine } = this.props;
    const { currentContractRevision, nextContractRevision, loaded } = this.state;

    if (!loaded) {
      return this.renderRequest();
    }

    const endDate = currentContractRevision?.endDate || entity.endDate;
    const contractEnds =
      (!entity.renewAutomatically || currentContractRevision?.packageType === Package.Trial) &&
      (!nextContractRevision || !currentContractRevision);
    const contractEnded =
      (!currentContractRevision && entity.startDate.isBefore(moment())) || (entity.endDate && entity.endDate.isBefore(moment()));
    const contractEndsInDays = endDate?.diff(moment(), 'days');
    const contractEndsSoon = !contractEnded && contractEndsInDays && contractEndsInDays <= 31;

    const start = (
      <>
        {entity.startDate.isAfter(moment()) ? 'Starting' : 'Started'}{' '}
        <FormatDateTime date={entity.startDate} format={DateTimeFormat.Date} />
      </>
    );

    const endVerb = contractEnded ? 'Ended' : contractEnds ? 'Ends' : 'Renews';
    const viewEndDate = contractEndsSoon ? (
      `in ${contractEndsInDays} days`
    ) : endDate ? (
      <FormatDateTime date={endDate} format={DateTimeFormat.Date} />
    ) : (
      <em>failed to load</em>
    );

    const end = (
      <span className={contractEndsSoon ? `text-${contractEnds ? 'danger' : 'success'} fw-bold` : ''}>
        {multiLine ? endVerb : endVerb.toLowerCase()} {viewEndDate}
      </span>
    );

    if (multiLine) {
      return (
        <div>
          <div>{start}.</div>
          <div>{end}.</div>
        </div>
      );
    }

    return (
      <>
        {start}, {end}.{' '}
        <InfoIcon>
          Contracts that are set to automatically renew will be extended for the same period after the current contract expires. You can
          cancel the renewal up to one hour before it's due. The number of licenses will be decreased automatically if fewer sites are
          connected at that time.
        </InfoIcon>
      </>
    );
  }
}
