import { ClientDiscountEntity } from '@edgebox/api-rest-client';
import { IRuntimeReference } from '@edgebox/data-definition-kit';
import { Currency, DiscountType, IDiscountByThreshold, PaymentCycleType } from '@edgebox/data-definitions';
import React from 'react';
import { ApiComponent, IApiComponentState, MoneyAmount } from '../../../common';

interface IProps {
  price: number;
  currency: Currency;
  paymentCycle: PaymentCycleType;
  pricingDiscounts?: IRuntimeReference<ClientDiscountEntity>[];
}

interface IState extends IApiComponentState {
  discounts?: ClientDiscountEntity[];
}

export const getDiscountedContractPrice = (
  price: number,
  advancePaymentsInMonths: number,
  paymentIntervalInMonths: number,
  discounts?: ClientDiscountEntity[]
): number => {
  if (!discounts?.length) {
    return price;
  }

  const getBiggestDiscount = (discounts: ClientDiscountEntity[], percentage: boolean, value: number): number => {
    const property = percentage ? 'amountPercentage' : 'amountFixed';
    const getBiggestThresholdDiscount = (thresholdAmounts: IDiscountByThreshold[]): number => {
      const candidates = thresholdAmounts.filter((c) => !!c[property] && c.threshold <= value);
      if (candidates.length === 0) {
        return 0;
      }
      if (candidates.length === 1) {
        return candidates[0][property]!;
      }
      return candidates.reduce((a, b) => (a[property]! > b[property]! ? a : b))[property]!;
    };

    const candidates: number[] = discounts
      .map((c) => {
        if (c.amountByThreshold) {
          const byThreshold = getBiggestThresholdDiscount(c.amountByThreshold);
          if (!c[property] || byThreshold > c[property]!) {
            return byThreshold;
          }
          return c[property]!;
        }
        if (c[property]) {
          return c[property]!;
        }
        return 0;
      })
      .filter((c) => !!c);
    if (candidates.length === 0) {
      return 0;
    }
    if (candidates.length === 1) {
      return candidates[0];
    }
    return candidates.reduce((a, b) => (a > b ? a : b));
  };

  const advancePaymentDiscountPercentage = getBiggestDiscount(
    discounts.filter((c) => c.type === DiscountType.AdvancePayments),
    true,
    advancePaymentsInMonths
  );
  const contractTermDiscountPercentage = getBiggestDiscount(
    discounts.filter((c) => c.type === DiscountType.ContractTerm),
    true,
    paymentIntervalInMonths
  );

  const advancePaymentDiscountFixed = getBiggestDiscount(
    discounts.filter((c) => c.type === DiscountType.AdvancePayments),
    false,
    advancePaymentsInMonths
  );
  const contractTermDiscountFixed = getBiggestDiscount(
    discounts.filter((c) => c.type === DiscountType.ContractTerm),
    false,
    paymentIntervalInMonths
  );

  const totalDiscount = (1 - advancePaymentDiscountPercentage) * (1 - contractTermDiscountPercentage);

  return Math.ceil((price * totalDiscount) / 100) * 100 - advancePaymentDiscountFixed - contractTermDiscountFixed;
};

export class ViewDiscountedPrice extends ApiComponent<IProps, IState> {
  async load() {
    const discounts = this.props.pricingDiscounts ? await Promise.all(this.props.pricingDiscounts.map((c) => c.get())) : [];

    return {
      discounts,
    };
  }

  render() {
    const { paymentCycle, price, currency } = this.props;
    const { discounts } = this.state;
    const paymentIntervalInMonths = paymentCycle === PaymentCycleType.Yearly ? 12 : 1;

    if (!discounts) {
      return this.renderRequest();
    }

    return (
      <MoneyAmount
        amount={getDiscountedContractPrice(price, paymentIntervalInMonths, paymentIntervalInMonths, discounts)}
        currency={currency}
      />
    );
  }
}
