import React from 'react';

export function propertyChangeReload<Props>(
  Component: React.ComponentType<Props>,
  getKey: (props: Props) => string
): React.ComponentType<Props> {
  return function PropertyChangeReload(props: Props) {
    return <Component {...props} key={getKey(props)} />;
  };
}
