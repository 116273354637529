import { ClientInvoiceEntity, IInternalAuthentication } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import { ExternalLink } from '@edgebox/react-components';
import React from 'react';
import { ApiComponent, AuthenticationContext, IApiComponentState, InvoiceStatusBadge } from '../../common';
import { API_DOMAIN } from '../../constants';

interface IProps {
  entityId: InternalId;
}

interface IState extends IApiComponentState {
  invoice?: ClientInvoiceEntity;
}

export class InvoiceLink extends ApiComponent<IProps, IState> {
  async load() {
    const invoice = await this.api.billing.invoices.item(this.props.entityId);

    return {
      invoice,
    };
  }

  render() {
    const { invoice } = this.state;

    if (!invoice) {
      return this.renderRequest();
    }

    return (
      <>
        {invoice.name ? (
          <AuthenticationContext.Consumer>
            {(auth: IInternalAuthentication) => {
              return (
                <ExternalLink to={`//${API_DOMAIN}/invoice/${invoice.id}/in-xero?jwt=${encodeURIComponent(auth.jwt!)}`}>
                  {invoice.name}
                </ExternalLink>
              );
            }}
          </AuthenticationContext.Consumer>
        ) : (
          <span className="text-break">{invoice.id}</span>
        )}{' '}
        <InvoiceStatusBadge status={invoice.status} />
      </>
    );
  }
}
