import { ClientCustomerEntity, ClientProjectListEntity } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import { UserType } from '@edgebox/data-definitions';
import { ButtonCheckbox, CustomCardColumns } from '@edgebox/react-components';
import { faDollarSign } from '@fortawesome/pro-light-svg-icons/faDollarSign';
import { faBrowser } from '@fortawesome/pro-light-svg-icons/faBrowser';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import React from 'react';
import { Alert, Badge, Col, Form, Row } from 'react-bootstrap';
import {
  ApiComponent,
  ContractLink,
  CustomerLink,
  CustomerSelector,
  IApiComponentState,
  PagedList,
  SyncCoreDataDefinitionsEnumValue,
  SyncCoreLink,
} from '../../common/index';
import { IconSummaryItem } from './IconSummaryItem';
import { SummaryCard } from './SummaryCard';
import { ProjectStatusBadge } from '../../common/components/Syndication/Badges/ProjectStatusBadge';
import { faServer } from '@fortawesome/pro-light-svg-icons/faServer';
import { SiteApplicationType } from '@edgebox/sync-core-data-definitions';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProjectIcons } from '../Icons';

interface IProps {
  backend?: boolean;
  customerId?: InternalId;
  emptyMessage?: React.ReactNode;
  emptyMessageWithNoFilters?: React.ReactNode;
  itemDisplay?: 'list' | 'cards';
}

interface IFilters {
  search?: string;
  customerId?: InternalId;
  includeInactive?: boolean;
}

interface IState extends IApiComponentState {
  search?: string;
  filterCustomer?: ClientCustomerEntity;
  includeInactive?: boolean;
}

export class PagedProjectList extends ApiComponent<IProps, IState> {
  async load() {
    const { customerId } = this.props;
    const filterCustomer = customerId ? await this.api.billing.customers.item(customerId) : undefined;

    return {
      filterCustomer,
    };
  }

  render() {
    const { backend, customerId, emptyMessage, emptyMessageWithNoFilters, itemDisplay } = this.props;
    const { filterCustomer, includeInactive } = this.state;

    const renderBackendProperties = this.api.currentUser?.type === UserType.Internal && backend;

    return (
      <PagedList<ClientProjectListEntity, IFilters>
        searchable
        hidePagerIfNotNeeded
        renderList={(items) => (itemDisplay === 'cards' ? <CustomCardColumns columnCount={3}>{items}</CustomCardColumns> : items)}
        request={(page, filter) =>
          this.api.syndication.projects.search(
            {
              customerId: filter?.customerId || customerId || this.api.currentUser?.customer?.getId(),
            },
            { page, itemsPerPage: 9 },
            {
              search: filter?.search,
              includeInactive: filter?.includeInactive,
            }
          )
        }
        renderFilters={(onChange) => {
          const onType = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | React.KeyboardEvent<any>) => {
            const value = (e.target as HTMLInputElement).value;
            onChange('search', value);
            this.setState({ search: value });
          };
          const backendFilters =
            renderBackendProperties && !customerId ? (
              <Col xs={3}>
                <CustomerSelector
                  value={filterCustomer}
                  onRemove={() => {
                    this.setState({ filterCustomer: undefined });
                    onChange('customerId', undefined, true);
                  }}
                  onSelected={(filterCustomer) => {
                    this.setState({ filterCustomer });
                    onChange('customerId', filterCustomer.id, true);
                  }}
                  emptyLabel={'Filter by customer'}
                />
              </Col>
            ) : undefined;
          return (
            <Row>
              <Col className={'p-0'}>
                <Form.Control placeholder={'Enter search term...'} onKeyPress={onType} onChange={onType} />
              </Col>
              <Col xs={2}>
                <ButtonCheckbox
                  checked={!!includeInactive}
                  onToggle={() => {
                    this.setState({ includeInactive: !includeInactive });
                    onChange('includeInactive', !includeInactive, true);
                  }}
                >
                  Show inactive
                </ButtonCheckbox>
              </Col>
              {backendFilters}
            </Row>
          );
        }}
        emptyMessageWithNoFilters={emptyMessageWithNoFilters}
        emptyMessage={emptyMessage || <Alert variant={'light'}>No projects match your filter.</Alert>}
        renderItem={(project) =>
          itemDisplay === 'cards' ? (
            <SummaryCard
              key={project.id}
              target={`/projects/${project.id}`}
              title={
                <>
                  <span className="text-truncate mw-100 d-inline-block" title={project.name}>
                    {project.name}
                  </span>{' '}
                  <Badge bg={'light'}>
                    <SyncCoreDataDefinitionsEnumValue
                      enumName={'SiteApplicationType'}
                      keyName={project.appType || SiteApplicationType.Drupal}
                    />
                  </Badge>{' '}
                  <Badge bg={'light'}>
                    <SyncCoreDataDefinitionsEnumValue enumName={'SiteEnvironmentType'} keyName={project.type} />
                  </Badge>{' '}
                  <ProjectStatusBadge status={project.status} />
                </>
              }
            >
              <IconSummaryItem icon={faBrowser}>{project.siteCount} sites</IconSummaryItem>
              <IconSummaryItem icon={faDollarSign}>
                <ContractLink entityId={project.contract.getId()} />
              </IconSummaryItem>
              {!!project.syncCore?.getId() && (
                <IconSummaryItem icon={faServer}>
                  <SyncCoreLink entityId={project.syncCore.getId()!} />
                </IconSummaryItem>
              )}
              {renderBackendProperties && !customerId && (
                <IconSummaryItem icon={faBuilding}>
                  <CustomerLink entityId={project.customer.getId()} />
                </IconSummaryItem>
              )}
            </SummaryCard>
          ) : (
            <Link
              to={`/projects/${project.id}`}
              className={'text-black ps-3 pe-1 pt-1 pb-1 d-block rounded bg-white mb-2 hover-shadow text-decoration-none'}
              key={project.id}
            >
              <Row className="m-0 p-0">
                <Col className="ms-0 me-0 mt-1 mb-1 p-0 fs-4 text-truncate mw-100" sm={4}>
                  <ProjectIcons project={project} />
                  <span className="ms-3">{project.name}</span>{' '}
                </Col>
                <Col sm={2} className="fs-6 pt-3">
                  {project.siteCount} sites
                </Col>
                <Col sm={2} className="fs-6 pt-3">
                  {!!project.syncCore?.getId() && <SyncCoreLink entityId={project.syncCore.getId()!} withIcon />}
                </Col>
                <Col sm={2} className="fs-6 pt-3">
                  {renderBackendProperties && !customerId && <CustomerLink entityId={project.customer.getId()} />}
                </Col>
                <Col sm={2} className="text-end fs-4 text-light pt-1">
                  <FontAwesomeIcon icon={faChevronRight} />
                </Col>
              </Row>
            </Link>
          )
        }
      />
    );
  }
}
