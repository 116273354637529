import { ClientCustomerEntity, ClientUserDraft, ClientUserEntity } from '@edgebox/api-rest-client';
import { DynamicReference } from '@edgebox/data-definition-kit';
import { CustomerRole, UserStatus } from '@edgebox/data-definitions';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { EditButton, EmailLink, IconButton, LeftRightContainer, LeftRightH1, RemoveButton } from '@edgebox/react-components';
import { Right } from '@edgebox/react-components';
import { LeftRightH2 } from '@edgebox/react-components';
import { ApiComponent, DataDefinitionsEnumValue, IApiComponentState, PagedList, UserForm, UserStatusBadge } from '../../../common/index';
import { Tracking, TrackingEvent } from '../../../contexts/TrackingContext';
import { ContentBox } from '../../Shared/ContentBox';
import { HeadlineWithBreadcrumbNavigation } from '../../../common/components/BreadcrumbNavigation';
import { UserIcons } from '../../Icons';

interface IState extends IApiComponentState {
  customer?: ClientCustomerEntity;
  editUser?: ClientUserEntity;
  addUser?: ClientUserDraft;
  invitationsSent?: string[];
  changedTime?: number;
}

export class AccountUsers extends ApiComponent<{}, IState> {
  async load(): Promise<Partial<IState>> {
    const customer = await this.getCurrentCustomer();

    return {
      customer,
    };
  }

  render() {
    const { customer, editUser, addUser, invitationsSent, changedTime } = this.state;

    if (!customer) {
      return this.renderRequest();
    }

    const onSaveNew = async (draft: ClientUserDraft) => {
      const entity = await this.api.authentication.users.create(draft);

      this.setState({
        changedTime: Date.now(),
        addUser: undefined,
      });

      Tracking.track({ type: TrackingEvent.InviteCustomerMember, 'user id': entity.id });
    };

    return (
      <Container>
        <LeftRightContainer
          left={<HeadlineWithBreadcrumbNavigation>Users</HeadlineWithBreadcrumbNavigation>}
          right={
            <IconButton
              icon={faUserPlus}
              variant={'light'}
              onClick={() => {
                this.setState({
                  addUser: new ClientUserDraft({
                    firstName: '',
                    lastName: '',
                    primaryEmail: '',
                    customerRoles: [],
                    customer: new DynamicReference(customer!.id, this.api.billing.customers.item),
                    type: this.api.currentUser!.type,
                    status: UserStatus.Invited,
                  }),
                });

                Tracking.startTimer(TrackingEvent.SavedPersonalProfile);
              }}
            >
              Invite user
            </IconButton>
          }
          className={'mb-3 pe-3'}
        />

        <PagedList<ClientUserEntity>
          hidePagerIfNotNeeded
          key={changedTime}
          renderItem={(user) => {
            const sentInvitation = invitationsSent && invitationsSent.includes(user.id);

            const userName = `${user.firstName} ${user.lastName}`;

            return (
              <Row className={'mb-2 bg-white rounded p-0 m-0'} key={user.id}>
                <Col className="pt-2 pb-2 ps-3 fs-4" sm={5}>
                  <UserIcons user={user} />
                  <span className="pe-3 ps-3">{userName}</span>
                </Col>
                <Col xs={4} className="pt-3">
                  <EmailLink email={user.primaryEmail} className="text-dark" />
                </Col>
                <Col xs={3} className="text-end pt-2">
                  {user.status === UserStatus.Invited ? (
                    <Button
                      variant={'light'}
                      disabled={sentInvitation}
                      onClick={async () => {
                        this.setState({
                          invitationsSent: invitationsSent ? invitationsSent.concat([user.id]) : [user.id],
                        });
                        await this.api.authentication.users.resendInvitation(user.id);
                      }}
                    >
                      {sentInvitation ? 'Invitation sent' : 'Re-send invitation'}
                    </Button>
                  ) : undefined}
                  {user.status === UserStatus.Active && user.id !== this.api.currentUser?.id ? (
                    <Button
                      variant={'danger'}
                      disabled={!!editUser || !!addUser}
                      onClick={async () => {
                        user.status = UserStatus.Inactive;
                        user = await this.api.authentication.users.update(user);
                        this.setState({
                          changedTime: Date.now(),
                        });
                      }}
                    >
                      Deactivate
                    </Button>
                  ) : undefined}
                  {user.status === UserStatus.Inactive ? (
                    <Button
                      variant={'light'}
                      disabled={!!editUser || !!addUser}
                      onClick={async () => {
                        user.status = UserStatus.Active;
                        user = await this.api.authentication.users.update(user);
                        this.setState({
                          changedTime: Date.now(),
                        });
                      }}
                    >
                      Reactivate
                    </Button>
                  ) : undefined}
                  {user.status === UserStatus.Inactive || user.status === UserStatus.Invited ? (
                    <RemoveButton
                      onClick={async () => {
                        await this.api.authentication.users.delete(user);
                      }}
                      disabled={!!editUser || !!addUser}
                    />
                  ) : undefined}
                  {user.status !== UserStatus.Inactive ? (
                    <EditButton
                      onClick={() => {
                        this.setState({ editUser: user });
                      }}
                      disabled={!!editUser || !!addUser}
                    />
                  ) : undefined}
                </Col>
              </Row>
            );
          }}
          request={(page) => this.api.authentication.users.find(undefined, customer.id, undefined, { page, itemsPerPage: 5 })}
        />

        <Modal show={!!editUser || !!addUser} scrollable onHide={() => this.setState({ addUser: undefined, editUser: undefined })}>
          <Modal.Header>
            <Modal.Title>{addUser ? 'Invite' : 'Edit'} user</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {editUser && (
              <UserForm<ClientUserEntity>
                className={'mb-3'}
                key={editUser.id}
                entity={editUser}
                mode={'edit-full'}
                name={<></>}
                onSave={async (update) => {
                  await this.api.authentication.users.update(update);

                  this.setState({
                    editUser: undefined,
                    changedTime: Date.now(),
                  });
                }}
              >
                <Right className={'me-1 mb-1'}>
                  <Button
                    variant={'light'}
                    onClick={() => {
                      this.setState({ editUser: undefined });
                    }}
                  >
                    Cancel
                  </Button>
                  {!editUser.customerRoles || !editUser.customerRoles.includes(CustomerRole.Owner) ? (
                    <Button
                      variant={'danger'}
                      onClick={async () => {
                        editUser.status = UserStatus.Inactive;
                        await this.api.authentication.users.update(editUser);

                        this.setState({
                          editUser: undefined,
                        });
                      }}
                    >
                      Deactivate
                    </Button>
                  ) : undefined}
                  <Button type={'submit'}>Save</Button>
                </Right>
              </UserForm>
            )}

            {addUser ? (
              <UserForm onSave={onSaveNew} entity={addUser} mode={'edit-full'} name=" ">
                <Right className={'mb-1 me-1'}>
                  <Button
                    variant={'light'}
                    onClick={() => {
                      this.setState({ addUser: undefined });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type={'submit'} variant={'primary'}>
                    Save
                  </Button>
                </Right>
              </UserForm>
            ) : undefined}
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
}
