import { CustomerStats } from '@edgebox/api-rest-client/dist/client/StatsApi';
import { DateTimeFormat, formatDate } from '@edgebox/data-definition-kit';
import { InfoIcon } from '@edgebox/react-components';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ApiComponent, IApiComponentState, MoneyAmount } from '../../common/index';
import { ContentBox } from '../Shared/ContentBox';
import { faLongArrowUp } from '@fortawesome/pro-light-svg-icons/faLongArrowUp';
import { faLongArrowDown } from '@fortawesome/pro-light-svg-icons/faLongArrowDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetStartedInstructions } from './GetStartedInstructions';
import { FormatStatsNumber } from '../FormatStatsNumber';
import { ClientSiteEntity, ClientSyncCoreEntity } from '@edgebox/api-rest-client';
import { Embed } from '../Shared/Embed';
import { GraphStats } from './Stats/GraphStats';
import { HeatmapStats } from './Stats/HeatmapStats';
import { PackStats } from './Stats/PackStats';
import { UserType } from '@edgebox/data-definitions';
import { IAppContextProp, withAppContext } from '../../common/contexts/AppContext';
import { propertyChangeReload } from '../../common/helpers/propertyChangeReload';
import { HeadlineWithBreadcrumbNavigation } from '../../common/components/BreadcrumbNavigation';
import { withParams } from '../RouterHelper';

interface IProps extends IAppContextProp, JSX.IntrinsicAttributes {
  params: {
    project?: string;
    site?: string;
  };
}

interface IState extends IApiComponentState {
  stats?: CustomerStats;

  contentCountSite?: ClientSiteEntity;
  contentCountSyncCore?: ClientSyncCoreEntity;
}

class DashboardClass extends ApiComponent<IProps, IState> {
  async load() {
    const { appContext } = this.props;
    let { customer, contract, project, site } = appContext;

    const stats = await this.api.stats.customer.get({
      customerId: customer?.id,
      contractId: contract?.id,
      projectId: project?.id,
    });

    // Only use site initially if it's provided by the URL.
    /*if(!this.props.params.site) {
      if(site) {
        site = undefined;
        appContext.setSite?.();
      }
    }*/

    if (!site) {
      // Just take the first active site and use that for the Sync Core and token.
      const sites = await this.api.billing.sites.search(
        { itemsPerPage: 1 },
        {
          customerId: customer?.id,
          contractId: contract?.id,
          projectId: project?.id,
        }
      );
      site = sites.items[0];
    }

    let contentCountSyncCore: ClientSyncCoreEntity | undefined = undefined;
    if (site) {
      contentCountSyncCore = await site.syncCore.get();
    }

    return {
      stats,
      contentCountSite: site,
      contentCountSyncCore,
    };
  }

  render() {
    const { stats, contentCountSite, contentCountSyncCore } = this.state;

    if (!stats) {
      return this.renderRequest();
    }

    const weeklyUsageDecreased = stats.usageLastWeek > stats.usageThisWeek;

    return (
      <Container>
        <Row className={'d-flex justify-content-between align-items-stretch'}>
          <Col xs={2} className={'p-0'}>
            <ContentBox className="h-100">
              <div className={'text-center text-size-3 fw-bold h-50'}>
                <FormatStatsNumber count={stats.activeSites} />
              </div>
              <div className={'text-center'}>Sites</div>
            </ContentBox>
          </Col>
          {contentCountSite && contentCountSyncCore && (
            <Col xs={2} className={'p-0'}>
              <ContentBox className="h-100">
                <div className={'text-center text-size-3 fw-bold h-50'}>
                  <Embed
                    embed="box/content-count/customer"
                    scope="content"
                    syncCore={contentCountSyncCore}
                    site={contentCountSite}
                    width={'100%'}
                    height={'40px'}
                    options={{
                      className: 'text-center text-size-3 fw-bold',
                    }}
                  />
                </div>
                <div className={'text-center'}>
                  Content entries
                  <InfoIcon>Counting all revisions of your content.</InfoIcon>
                </div>
              </ContentBox>
            </Col>
          )}
          {stats.usageThisMonth !== undefined ? (
            <Col xs={2} className={'p-0'}>
              <ContentBox className="h-100">
                <div
                  className={`text-center text-size-3 fw-bold h-50 ${
                    stats.usageLimitHit ? 'text-danger' : stats.usageLimitClose ? 'text-warning' : ''
                  }`}
                >
                  <FormatStatsNumber count={stats.usageThisMonth} />
                  {stats.usageLimit ? <span className={'text-muted'}> / {stats.usageLimit}</span> : undefined}
                </div>
                <div className={'text-center'}>
                  Updates
                  <InfoIcon>Updated once an hour.</InfoIcon>
                </div>
                <div className={'text-center text-muted'}>This contract period</div>
              </ContentBox>
            </Col>
          ) : undefined}
          <Col xs={2} className={'p-0'}>
            <ContentBox className="h-100">
              <div className={`text-center h-50`}>
                <span className="text-size-3 fw-bold">
                  <FormatStatsNumber count={stats.usageThisWeek} />
                </span>
                <FontAwesomeIcon
                  icon={weeklyUsageDecreased ? faLongArrowDown : faLongArrowUp}
                  className={`ms-2 me-1 ${weeklyUsageDecreased ? 'text-danger' : 'text-success'}`}
                />
                <span className={weeklyUsageDecreased ? 'text-danger' : 'text-success'}>
                  <FormatStatsNumber count={Math.abs(stats.usageThisWeek - stats.usageLastWeek)} />
                </span>
              </div>
              <div className={'text-center'}>
                Updates
                <InfoIcon>Updated once an hour.</InfoIcon>
              </div>
              <div className={'text-center text-muted'}>Last 7 days</div>
            </ContentBox>
          </Col>
          {stats.nextInvoiceAmount && stats.nextInvoiceDate && stats.nextInvoiceCurrency ? (
            <Col xs={2} className={'p-0'}>
              <ContentBox className="h-100">
                <div className={'text-center text-size-2 h-50 align-middle'}>
                  <MoneyAmount amount={stats.nextInvoiceAmount} currency={stats.nextInvoiceCurrency} />
                </div>
                <div className={'text-center'}>Next invoice</div>
                <div className={'text-center text-muted'}>{formatDate(stats.nextInvoiceDate, DateTimeFormat.Date)}</div>
              </ContentBox>
            </Col>
          ) : undefined}
        </Row>

        <div className={'mt-5'} />

        {/* TODO: Must use local user timezone. */}
        {/* TODO: Use XY graph to display usage in the last 7 days (+free updates as second line). Allow to filter per site. */}
        {/* TODO: Use heatmap to show 24h distribution in the last 7 days. */}
        {/* TODO: Use Hierarchy/Pack to display number of updates per site in the last 7 days. */}
        {/* TODO: Use Network/Graph to display relationship between sites with Flows/Pools between them. */}
        {/* TODO: Create admin variant that goes across all customers, optionally filtered by customer. */}

        {stats.activeSites ? (
          <div>
            <Row>
              <Col className="ps-0 pe-4" xs={12} sm={6}>
                <h3>Daily updates</h3>
                <ContentBox className={'pb-2'}>
                  <GraphStats displayType="bars" />
                </ContentBox>
              </Col>
              <Col className="pe-0 ps-4" xs={12} sm={6}>
                <h3>Heatmap</h3>
                <ContentBox className={'pb-2'}>
                  <HeatmapStats />
                </ContentBox>
              </Col>
            </Row>

            {(stats.activeSites > 2 || this.api.currentUser!.type === UserType.Internal) && (
              <Row className="mt-5">
                <Col className="ps-0 pe-0" xs={12}>
                  <h3>Most active sites</h3>
                  <ContentBox className={'pb-2'}>
                    <PackStats />
                  </ContentBox>
                </Col>
              </Row>
            )}
          </div>
        ) : (
          <div>
            <HeadlineWithBreadcrumbNavigation>Welcome to Content Sync!</HeadlineWithBreadcrumbNavigation>

            <ContentBox className={'pb-4'}>
              <GetStartedInstructions />
            </ContentBox>
          </div>
        )}
      </Container>
    );
  }
}

export const Dashboard = withParams<{}, { site?: string; project?: string }>(
  withAppContext(propertyChangeReload(DashboardClass, (props) => props.appContext.projectKey))
);
