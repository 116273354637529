import { CustomerVatValidationStatus } from '@edgebox/data-definitions';
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { DataDefinitionsEnumValue } from '../../Localization/DataDefinitionsEnumValue';

export interface ICustomerVatValidationStatusBadgeProps {
  status?: CustomerVatValidationStatus;
}

export const CustomerVatValidationStatusBadge = ({ status }: ICustomerVatValidationStatusBadgeProps): React.ReactElement | null =>
  status ? (
    <Badge
      as="div"
      bg={
        status === CustomerVatValidationStatus.Declined ? 'danger' : status === CustomerVatValidationStatus.Approved ? 'success' : 'warning'
      }
    >
      <DataDefinitionsEnumValue enumName="CustomerVatValidationStatus" keyName={status} />
    </Badge>
  ) : null;
