import { IconButton } from '@edgebox/react-components';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { ICONS } from '../Icons';
import { IContextIdFilters, PagedLogItemList } from './PagedLogItemList';

interface IProps extends IContextIdFilters {
  name: string;
}
interface IState {
  open?: boolean;
}

export class LogsButton extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { name, ...filters } = this.props;
    const { open } = this.state;

    return (
      <>
        <IconButton icon={ICONS.Logs.light} onClick={() => this.setState({ open: true })} />

        {open && (
          <Modal size="xl" show onHide={() => this.setState({ open: false })}>
            <Modal.Header closeButton>
              <Modal.Title>Logs for {name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PagedLogItemList id={JSON.stringify(filters)} {...filters} />
            </Modal.Body>
          </Modal>
        )}
      </>
    );
  }
}
