import { ClientContractEntity } from '@edgebox/api-rest-client';
import moment from 'moment';
import React from 'react';
import Badge from 'react-bootstrap/Badge';

interface IProps {
  contract: ClientContractEntity;
  size?: 'small';
}

export function isContractActive(contract: ClientContractEntity) {
  const now = moment();
  return (!contract.endDate || now.isBefore(contract.endDate)) && now.isAfter(contract.startDate);
}

export function isContractUpcoming(contract: ClientContractEntity) {
  const now = moment();
  return now.isBefore(contract.startDate);
}

export class ContractStatusBadge extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { contract, size } = this.props;

    const classes = size ? 'small' : '';

    const isUpcoming = isContractUpcoming(contract);
    const isActive = isContractActive(contract);

    return (
      <Badge bg={isActive ? 'primary' : 'dark'} className={classes}>
        {isActive ? 'Active' : isUpcoming ? 'Upcoming' : 'Finished'}
      </Badge>
    );
  }
}
